import { IBatch, IClassroom, IDepartment, IStaffMember, pluralize } from "crm_core";
import { Badge, Col, Row } from "react-bootstrap";
import StaffMemberBadge from "src/components/widgets/person_badge";

interface ClassroomCardProps {
  classroom: IClassroom & {
    batch: IBatch;
    department: IDepartment;
    teachers: IStaffMember[];
    students: string[];
  };
  onClick?: () => void;
  href?: string;
}

export default function ClassroomCard({
  classroom,
  onClick,
  href,
}: ClassroomCardProps) {
  return (
    <a href={href} onClick={onClick} className="text-decoration-none text-inherit">
      <div
        className="py-3 px-3 bg-custom-grey rounded-lg hover:shadow-md cursor-pointer"
      >
        <Row className="align-items-center">
          <Col lg="auto" md="auto" sm="auto" xs="auto">
            <h5 className="m-0 font-light">
              {classroom.batch.name}-{classroom.name}
            </h5>
          </Col>
          <Col lg="auto" md="auto" sm="auto" xs="auto" className="ml-auto">
            <Badge bg="accent">{classroom.type.toLocaleUpperCase()}</Badge>
          </Col>
        </Row>

        <Row className="align-items-center mt-2">
          <Col lg="auto" md="auto" sm="auto" xs="auto">
            <h6 className="m-0 text-muted font-light">
              {classroom.department.name}
            </h6>
          </Col>

          <Col lg="auto" md="auto" sm="auto" xs="auto" className="ml-auto">
            <h6 className="font-light text-muted m-0">
              {classroom.students.length} {pluralize(classroom.students.length, "Student", "Students")}
            </h6>
          </Col>
        </Row>
        <Row className="align-items-center mt-2">
          <Col lg="auto" md="auto" sm="auto" xs="auto" className="m-0 pr-0">
            <h6 className="font-light text-muted m-0">Teachers: </h6>
          </Col>
          <Col lg="auto" md="auto" sm="auto" xs="auto" className="m-0">
            {classroom.teachers.map((t, idx) => (
              <StaffMemberBadge key={idx} staffMember={t} />
            ))}
          </Col>
        </Row>
      </div>
    </a>
  );
}
