import { IOrganization } from "../../../hierarchy";
import { ISession } from "./session";

export enum HolidayReason {
  EVENT = "Event",
  WEEKEND = "Weekend",
  OTHER = "Other",
}

export const HolidayReasonOptions = [
  { value: HolidayReason.EVENT, label: "Event" },
  { value: HolidayReason.WEEKEND, label: "Weekend" },
  { value: HolidayReason.OTHER, label: "Other" },
];

export interface IInstituteCalendarDay {
  _id: string;
  date: Date;
  events: IInstituteCalendarEvent[];
  working_for_students: boolean;
  working_for_staff: boolean;
  holiday_reason?: HolidayReason;
  holiday_description?: string;
  organization?: string | IOrganization;
  session?: string | ISession;
}

export interface IInstituteCalendarEvent {
  title: string;
  description: string;
}
