import { useState } from "react";
import LabelledField from "../form_generator/labelled_field";
import { Col, Form, Row } from "react-bootstrap";

interface Props {
  onChange: (checked: any) => void;
  check?: boolean;
  label?: string;
  disabled?: boolean;
}

export default function ToggleButton(props: Props) {
  const [id] = useState("toggle-button-id");

  return (
    <LabelledField label={props.label}>
      <Form.Switch
        className="ml-1"
        id={id}
        checked={props.check ?? false}
        onChange={(e) => props.onChange(e.target.checked)}
        disabled={props.disabled}
      />
    </LabelledField>
  );
}


interface ToggleRowProps extends Props {
  rightComp: JSX.Element;
}

export function ToggleRow({ rightComp, ...buttonProps }: ToggleRowProps) {
  return <Row className="m-0">
    <Col className="p-0 col-auto">
      <ToggleButton
        {...buttonProps}
      />
    </Col>
    <Col className="p-0 col-auto">
      {rightComp}
    </Col>
  </Row>
}

