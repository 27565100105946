import API from "../../api";
import { IAttendanceShift } from "crm_core";

export class AttendanceShiftService extends API {
  constructor() {
    super("/shift");
  }
  async getAttendanceShifts(): Promise<IAttendanceShift[]> {
    const res = await this._get({});
    return res.data as IAttendanceShift[];
  }

  async getAttendanceShift(id: string): Promise<IAttendanceShift> {
    const res = await this._get({ url: `/${id}` });
    return res.data as IAttendanceShift;
  }

  async createAttendanceShift(
    shift: IAttendanceShift
  ): Promise<IAttendanceShift> {
    const res = await this._post({ body: shift });
    return res.data as IAttendanceShift;
  }

  async updateAttendanceShift(
    shift: Partial<IAttendanceShift & { people: string[] }>
  ): Promise<IAttendanceShift> {
    const res = await this._put({ url: `/${shift._id}`, body: shift });
    return res.data as IAttendanceShift;
  }

  async deleteAttendanceShift(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }
}
