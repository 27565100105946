import { getAuthBasedRoutes } from "crm_core";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Loader } from "src/components/loader";
import Header from "src/components/navbar/header";
import { useAuth } from "src/context/auth_context";
import { usePageState } from "src/context/page_state_context";
import { IRouteData, getRoutes, routesData } from "src/routes";
import { LocalStorageKeys } from "src/utils/constants";

export default function TestLayout({}: any) {
  const { isSmallScreen } = usePageState();
  const { selectedAuthorization, authenticated } = useAuth();
  const navigate = useNavigate();
  const LAYOUT_NAME = "test";
  let SIDEBAR_WIDTH = isSmallScreen ? "50px" : "5vw";
  const HEADER_HEIGHT = isSmallScreen ? "60px" : "8vh";

  const roleBasedRoutes = getAuthBasedRoutes<IRouteData>(
    routesData.filter((route) => route.layout === LAYOUT_NAME),
    selectedAuthorization
  );

  const siderBarRoutes = roleBasedRoutes.filter((route) => route.icon);
  let defaultRoute = "/c/dashboard";
  if (siderBarRoutes.length > 0) {
    defaultRoute = `/${siderBarRoutes[0].layout}${siderBarRoutes[0].path}`;
  }

  useEffect(() => {
    if (!authenticated) {
      localStorage.setItem(LocalStorageKeys.REDIRECT, window.location.pathname);
      navigate("/auth");
    }
  }, [authenticated]);

  return (
    <div className="bg-off-white">
      {!selectedAuthorization && (
        <Row style={{ height: "100vh", paddingTop: "40vh" }}>
          <Col>
            <Loader text="" />
          </Col>
        </Row>
      )}
      {selectedAuthorization && (
        <>
          <Header
            height={HEADER_HEIGHT}
            brandWidth={`calc(${SIDEBAR_WIDTH}+${SIDEBAR_WIDTH}+${SIDEBAR_WIDTH})`}
          />

          <div
            style={{
              position: "fixed",
              top: HEADER_HEIGHT,
              left: 0,
              width: `100%`,
              height: `calc(100% - ${HEADER_HEIGHT})`,
              overflow: "scroll",
            }}
          >
            <div style={{ minHeight: "100%" }} className="bg-off-white">
              <Routes>
                {getRoutes(roleBasedRoutes)}
                <Route
                  path="/"
                  element={
                    <Navigate
                      to={{ ...location, pathname: defaultRoute }}
                      replace
                    />
                  }
                />
              </Routes>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
