import { useEffect, useState } from "react";
import LabelledField, { LabelledFieldProps } from "./labelled_field";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "crm_core";
import { joinClasses } from "src/utils/functions";
import { useModal } from "src/context/modal_context";

interface DatePickerProps extends LabelledFieldProps {
  value?: string;
  onChange: (date: string) => void;
  disabled?: boolean;
}

export default function SimpleDatePicker(props: DatePickerProps) {
  const [date, setDate] = useState<Date>();
  const {showModal } = useModal()

  useEffect(() => {
    if (props.value) {
      let dt = moment(props.value, DEFAULT_DATE_FORMAT).toDate();
      if (dt != date) {
        setDate(dt);
      }
    }
  }, [props.value]);

  useEffect(() => {
    if (date) {
      props.onChange(moment(date).format(DEFAULT_DATE_FORMAT));
    }
  }, [date]);

  return (
    <LabelledField label={props.label} required={props.required}>
      <DatePicker
        className={joinClasses(
          "z-100 w-full p-2 text-sm ring-0.5 ring-custom-grey bg-custom-grey rounded-md outline-none focus:outline-none  focus:ring-1 focus:ring-dark-accent",
          props.disabled ? "text-muted" : ""
        )}
        selected={date}
        onChange={(date: any) => setDate(date)}
        dateFormat="dd/MM/yyyy"
        portalId={showModal ? undefined : "root"}
        disabled={props.disabled}
      />
    </LabelledField>
  );
}
