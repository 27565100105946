import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { joinClasses } from "src/utils/functions";
import { getContrastColor } from "src/utils/functions";

type colorOptions =
  | "white"
  | "gray"
  | "red"
  | "yellow"
  | "green"
  | "blue"
  | "indigo"
  | "purple"
  | "pink"
  | "accent"
  | "black";

export interface ButtonProps {
  className?: string;
  onClick?: any;
  onMouseEnter?: (() => void) | undefined;
  onMouseLeave?: (() => void) | undefined;
  href?: string | undefined;
  children?: any;
  style?: React.CSSProperties;
  variant?: string | undefined;
  ref?:
    | ((instance: HTMLButtonElement | null) => void)
    | React.RefObject<HTMLButtonElement>
    | null
    | undefined;
  rounded?: boolean;
  squared?: boolean;
  square?: boolean;
  color?: colorOptions | string;
  darkModeColor?: colorOptions | string;
  icon?: JSX.Element;
  disabled?: boolean;
  submit?: boolean;
  isFat?: boolean;
  label?: string | JSX.Element;
  noshadow?: boolean;
  contentClass?: string;
  isSlim?: boolean;
}

const colors: any = {
  white:
    "bg-white hover:bg-gray-100 focus:ring-white focus:ring-offset-white text-black",
  gray: "bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-200 text-white",
  red: "bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white",
  yellow:
    "bg-yellow-400 hover:bg-yellow-500 focus:ring-yellow-200 focus:ring-offset-yellow-200 text-white",
  green:
    "bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white",
  blue: "bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white",
  indigo:
    "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white",
  purple:
    "bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white",
  pink: "bg-pink-600 hover:bg-pink-700 focus:ring-pink-500 focus:ring-offset-pink-200 text-white",
  black:
    "bg-black hover:bg-gray-700 focus:ring-black-500 focus:ring-offset-black-200 text-white",
  accent:
    "bg-accent hover:bg-accent-900 focus:ring-accent-900 focus:ring-offset-accent-900 text-white",
};

const getColorConfig = (color: string) => {
  return joinClasses(
    `bg-${color}-600`,
    `hover:bg-${color}-700`,
    `focus:ring-${color}-500`,
    `focus:ring-offset-${color}-200`,
    `text-${getContrastColor(color)}`
  );
};

export function Button({
  darkModeColor = undefined,
  color = "gray",
  href = undefined,
  ...props
}: ButtonProps) {
  const navigate = useNavigate();

  if (!darkModeColor) {
    darkModeColor = color == "white" ? "gray" : color;
  }
  if (!props.onClick && href) {
    props.onClick = () => navigate(href);
  }

  return (
    <button
      onClick={props.onClick}
      style={props.style}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      type={props.submit ? "submit" : "button"}
      disabled={props.disabled}
      className={joinClasses(
        props.isFat ? "py-4 px-6 " : props.isSlim ? "p-0" : "py-2 px-2",
        props.icon ? "flex justify-center items-center " : "",
        "transition ease-in duration-200 text-center text-base font-normal focus:outline-none focus:ring-1 focus:ring-offset-1",
        props.noshadow ? "" : "shadow-md",
        colors[color],
        // getColorConfig(
        //   getThemeBasedValue(color, darkModeColor) ?? theme.colors.button
        // ),
        props.disabled ? "opacity-70 cursor-not-allowed" : "",
        !props.label ? "" : "",
        props.rounded
          ? "rounded-full"
          : props.squared
          ? "rounded-none"
          : "rounded-lg ",
        props.square ? "w-full h-full" : "",
        props.className ?? ""
      )}
    >
      <div className={props.contentClass ?? ""}>
        <Row className="m-0 p-0 align-items-center">
          <Col className="col-auto m-0 p-0">
            {props.icon && props.icon}
          </Col>
          <Col className="col-auto m-0 p-0">
            {props.label && props.label}
          </Col>
        </Row>
      </div>
    </button>
  );
}

export default Button;
