import { Card, Col, Row } from "react-bootstrap";
import Expandable from "../expandable";
import { useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { joinClasses } from "src/utils/functions";
import Button from "../buttons/button";

interface ExpandableCardProps {
  title: string;
  headerRight?: React.ReactNode;
  className?: string;
  defaultExpanded?: boolean;
  renderBody?: (
    toggle: () => void,
    isOpen: boolean,
    inTransition: boolean
  ) => any;
  headerClassName?: string;
}

export default function ExpandableCard({
  title,
  headerRight,
  className = "",
  defaultExpanded,
  renderBody = (a, b, c) => <></>,
  headerClassName,
}: ExpandableCardProps) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <Expandable
      expanded={expanded}
      transitionTime={200}
      onOpen={() => setExpanded(true)}
      onClose={() => setExpanded(false)}
      render={(expandableProps, toggle, isOpen, inTransition) => {
        return (
          <Card
            className={joinClasses(
              "p-3 m-0 border-0 bg-transparent shadow-none",
              className
            )}
          >
            <Card.Header
              onClick={toggle}
              className={joinClasses(
                "cursor-pointer border-1 py-3 p-0 ",
                isOpen ? "rounded-t-md" : "rounded-md",
                headerClassName ?? "bg-white border-white"
              )}
            >
              <Row className="m-0 p-0 align-items-center">
                <Col md="auto" sm="auto" xs="auto" className="m-0 p-0">
                  <aside
                    className="border-3 py-3 border-accent"
                    aria-label={`accent background color`}
                  />
                </Col>
                <Col md="auto" sm="auto" xs="auto" className="m-0 p-0">
                  <Card.Title className="leading-6 my-0 p-0 ml-4 font-normal text-md">
                    {title}
                  </Card.Title>
                </Col>
                <Col className="my-0 p-0 ml-4">
                  {headerRight && headerRight}
                </Col>
                <Col md="auto" sm="auto" xs="auto" className="p-0 ml-auto">
                  <div className="mr-4">
                    <Button
                      icon={
                        isOpen ? (
                          <ChevronUpIcon className="h-6 w-6" />
                        ) : (
                          <ChevronDownIcon className="h-6 w-6" />
                        )
                      }
                      isSlim
                      color="accent"
                      className="p-2"
                      rounded
                    />
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body
              {...expandableProps}
              className="bg-white p-0 m-0 border-0 py-3"
            >
              {renderBody(toggle, isOpen, inTransition)}
            </Card.Body>
          </Card>
        );
      }}
    />
  );
}
