import API from "./api";

export class ErrorReporterService extends API {
  constructor() {
    super("/error-reporter");
  }
  async sendErrorReport(
    message: string,
    stack: string,
    where: string
  ): Promise<any> {
    return await this._post({
      body: {
        message,
        stack,
        where,
      },
    });
  }
}
