import { Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "./context/auth_context";
import { useTheme } from "./context/theme_context";
import GenericModal from "./components/modals/generic_modal";
import CompanyLayout from "./layouts/company";
import AuthLayout from "./layouts/auth";
import HomeLayout from "./layouts/home";
import { usePageState } from "./context/page_state_context";
import TestLayout from "./layouts/test";
import { onMessageListener, requestToken } from "./firebase";
import { useNotification } from "./context/notification_context";
import { IPushNotification } from "crm_core";

function App() {
  const { pushNotification } = useNotification();
  const { authenticated, user } = useAuth();
  const { theme } = useTheme();
  const { isSmallScreen, setChatNotifications } = usePageState();

  onMessageListener()
    .then(async (payload: any) => {
      console.log("Received foreground message", payload);
      const data = JSON.parse(payload.data.stringified) as IPushNotification;
      if (authenticated && user && user._id == data.userId) {
        pushNotification({
          title: data.title,
          message: data.body,
          type: "success",
          dismiss: {
            onScreen: false,
            duration: 5000,
          },
        });
        if ((data.href.web ?? "").includes("/c/chat")) {
          setChatNotifications((prev) => prev + 1);
        }
      }
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    document.body.style.backgroundColor = theme.colors.background;
    document.body.style.color = theme.colors.textPrimary;
  }, [theme]);

  useEffect(() => {
    // if (isSmallScreen) {
    //   const initialValue = (document.body.style as any).zoom;
    //   (document.body.style as any).zoom = "85%";
    //   return () => {
    //     (document.body.style as any).zoom = initialValue;
    //   };
    // }
  }, [isSmallScreen]);

  useEffect(() => {
    if (authenticated && user) {
      requestToken(user);
    }
  }, [authenticated, user]);

  return (
    <>
      <GenericModal />
      <Suspense fallback={<div className="bg-dark"></div>}>
        <Routes>
          {authenticated && <Route path="c/*" element={<CompanyLayout />} />}
          <Route path="home/*" element={<HomeLayout />} />
          <Route path="/auth/*" element={<AuthLayout />} />
          <Route path="/test/*" element={<TestLayout />} />
          <Route
            path="*"
            element={<Navigate to={authenticated ? "/c" : "/auth"} replace />}
          />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
