import API from "../api";
import { IBatch } from "crm_core"

export class BatchService extends API {
  static NAME = "BatchService";

  constructor() {
    super("/batch");
  }
  async getBatches(): Promise<IBatch[]> {
    const res = await this._get({});
    return res.data as IBatch[];
  }

  async getBatch(id: string): Promise<IBatch> {
    const res = await this._get({ url: `/${id}` });
    return res.data as IBatch;
  }

  async createBatch(batch: IBatch): Promise<IBatch> {
    const res = await this._post({ body: batch });
    return res.data as IBatch;
  }

  async updateBatch(batch: IBatch): Promise<IBatch> {
    const res = await this._put({ url: `/${batch._id}`, body: batch });
    return res.data as IBatch;
  }

  async deleteBatch(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }
}
