import { IClassroomExtended } from "crm_core";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CustomCard from "src/components/custom_card";
import { Loader } from "src/components/loader";
import { ClassroomService } from "src/services/institute/classroom";
import ClassroomCard from "../institute_structure/classroom/card";
import Filters from "src/features/filters";
import { FilterKeys } from "src/utils/constants";
import { useTable } from "src/context/table_context";
import { useDataProvider } from "src/context/data_provider";
import { PaginatedGetter } from "src/services/paginated_getter";

export default function StaffMemberClassroomList() {
  const FILTER_KEY = FilterKeys.CLASSROOM_LIST_IN_STAFF_MEMBER;
  const paginatedGetter = new PaginatedGetter<
    ClassroomService,
    IClassroomExtended
  >(ClassroomService);
  const [loading, setLoading] = useState<boolean>(false);
  const [classrooms, setClassrooms] = useState<IClassroomExtended[]>([]);
  const [fetchedConfig, setFetchedConfig] = useState<string>("");
  const { selectedSession } = useDataProvider();
  const { filterPreferences, getFilterPreferences } = useTable();

  const fetchData = async () => {
    let config = {
      filters: getFilterPreferences(FILTER_KEY),
    };
    if (JSON.stringify(config) === fetchedConfig) {
      return;
    }
    setLoading(true);
    const data = await paginatedGetter.get(config, {
      extended: true,
      assigned: true,
    });
    setFetchedConfig(JSON.stringify(config));
    setClassrooms(data.results);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedSession && !loading) {
      fetchData();
    }
  }, [filterPreferences, selectedSession, loading]); 

  useEffect(() => {
    document.title = "Teachbond - Your Classrooms";
  }, []);

  return (
    <CustomCard title="Classrooms">
      {loading && <Loader />}
      <div hidden={loading} className="-mt-3">
        <Filters filterKey={FILTER_KEY} />
        <Row className="m-0 pl-0 py-0 pr-3">
          {classrooms.map((classroom, idx) => (
            <Col
              key={idx}
              xs="12"
              sm="12"
              md="6"
              lg="6"
              className="pl-3 pr-0 mt-3"
            >
              <ClassroomCard
                href={`/c/classrooms/${classroom._id}/view`}
                classroom={classroom}
              />
            </Col>
          ))}
        </Row>
      </div>
    </CustomCard>
  );
}
