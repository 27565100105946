import { joinClasses } from "src/utils/functions";

interface IProfilePhoto {
  url?: string;
  placeholderIcon?: JSX.Element;
  className?: string;
}

export default function ProfilePhoto({
  url,
  placeholderIcon,
  className,
}: IProfilePhoto) {
  return (
    <div className={joinClasses("rounded-full shadow-xl")}>
      {url && (
        <img
          className={joinClasses(className, "m-0 p-0 rounded-full")}
          src={url}
          alt=""
        />
      )}
      {!url && placeholderIcon}
    </div>
  );
}
