import { IQuestion, ISection, ISubjectTopic } from "crm_core";
import { useState } from "react";
import { DEFAULT_QUESTION } from "../defaults";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import FormSelect from "src/components/form_select";
import Question from "../components/question";
import SaveButton from "src/components/widgets/save_button";
import LabelledField from "src/components/form_generator/labelled_field";
import { tabClassName } from "src/utils/constants";
import QuestionsList from "src/features/superadmin_features/questions/list";

export interface AddNewQuestionsProps {
  topics?: ISubjectTopic[];
  onComplete?: (
    questions: IQuestion[],
    method: AddQuestionMethod
  ) => Promise<void>;
  sections?: ISection[];
}

export enum AddQuestionMethod {
  MANUAL = "manual",
  BANK = "bank",
}

export default function AddNewQuestions({
  topics,
  onComplete,
  sections,
}: AddNewQuestionsProps) {
  const [submitting, setSubmitting] = useState(false);
  const [questions, setQuestions] = useState<{ [key: string]: IQuestion[] }>(
    {}
  ); // topicId -> questions
  const [typedQuestion, setTypedQuestion] =
    useState<IQuestion>(DEFAULT_QUESTION);
  const [method, setMethod] = useState<AddQuestionMethod>(
    AddQuestionMethod.BANK
  );

  const handleSubmit = async () => {
    setSubmitting(true);
    let questionsToSubmit = [typedQuestion];
    if (method == AddQuestionMethod.BANK) {
      questionsToSubmit = Object.values(questions).flat();
    }
    await onComplete?.(questionsToSubmit, method);
    setSubmitting(false);
  };

  return (
    <div>
      <Row className="align-items-center">
        <Col className="col-4">
          <LabelledField label="Method">
            <FormSelect
              options={[
                {
                  label: "Type your question",
                  value: AddQuestionMethod.MANUAL,
                },
                {
                  label: "Select from question bank",
                  value: AddQuestionMethod.BANK,
                },
              ]}
              value={method as any}
              onChange={(v) => setMethod(v as AddQuestionMethod)}
            />
          </LabelledField>
        </Col>
        {method == AddQuestionMethod.BANK && (
          <Col className="col-4">
            ({Object.values(questions).flat().length} questions selected)
          </Col>
        )}
      </Row>

      <div className="mt-2">
        {method == AddQuestionMethod.MANUAL ? (
          <div>
            <Question
              hideSaveCancel
              editable
              question={typedQuestion}
              onChange={(v) => setTypedQuestion(v)}
              sectionOptions={sections?.map((s) => ({
                label: s.name,
                value: s._id as string,
              }))}
            />
          </div>
        ) : (
          <div>
            {topics?.length == 0 && (
              <div className="text-danger">
                <h6>
                  Please select course, subject and topics to use question bank.
                  If your preferred course, subject or topic is not listed, do
                  reach out to us at{" "}
                  <a href="mailto:support@teachbond.com?subject=Request for course/subject/topic&body=Please add the following course/subject/topic to the Teachbond platform.%0D%0A%0D%0ACourse:%0D%0ASubject:%0D%0ATopic:">
                    support@teachbond.com
                  </a>
                </h6>
              </div>
            )}
            <Tabs color="primary" variant="pills">
              {topics?.map((topic) => (
                <Tab
                  tabClassName={tabClassName}
                  key={topic._id}
                  eventKey={topic._id}
                  title={topic.name}
                >
                  <QuestionsList
                    topicId={topic._id}
                    tableOnly
                    selectedQuestions={questions[topic._id as string] || []}
                    onSelectedQuestionsChange={(selectedQuestions) =>
                      setQuestions({
                        ...questions,
                        [topic._id as string]: selectedQuestions,
                      })
                    }
                  />
                </Tab>
              ))}
            </Tabs>
          </div>
        )}
      </div>

      <Row className="justify-content-end mt-4 align-items-center">
        <Col className="col-auto">
          <SaveButton
            label="Submit"
            loading={submitting}
            disabled={submitting}
            onClick={handleSubmit}
          />
        </Col>
      </Row>
    </div>
  );
}
