import { IDepartment, Permissions, pluralize } from "crm_core";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import { useDataProvider } from "src/context/data_provider";

export default function DepartmentCard({
  department,
}: {
  department: IDepartment;
}) {
  const { batches } = useDataProvider();
  const batchCount = batches.filter(
    (b) => b.department === department._id
  ).length;

  return (
    <a
      className="text-decoration-none text-inherit"
      href={`/c/departments/${department._id}/edit`}
    >
      <div className="border-0 bg-custom-grey py-3 px-3 rounded-md cursor-pointer hover:bg-light-accent">
        <h6>{department.name}</h6>
        <PermissionRestrictedComponent permissions={[Permissions.VIEW_BATCHES]}>
          <p className="text-sm text-gray-500 m-0 p-0">
            {batchCount} {pluralize(batchCount, "batch", "batches")}
          </p>
        </PermissionRestrictedComponent>
      </div>
    </a>
  );
}
