import API from "../api";
import { IStaffMember, IStaffMemberResponse } from "crm_core";

export class StaffMemberService extends API {
  static NAME = "StaffMemberService";

  constructor() {
    super("/staff_member");
  }
  async getStaffMembers(): Promise<IStaffMemberResponse[]> {
    const res = await this._get({});
    return res.data as IStaffMemberResponse[];
  }

  async getStaffMember(id: string): Promise<IStaffMember> {
    const res = await this._get({ url: `/${id}` });
    return res.data as IStaffMember;
  }

  async createStaffMember(
    staff_member: IStaffMember & { send_invite: boolean }
  ): Promise<IStaffMember> {
    const res = await this._post({ body: staff_member });
    return res.data as IStaffMember;
  }

  async updateStaffMember(staff_member: IStaffMember): Promise<IStaffMember> {
    const res = await this._put({
      url: `/${staff_member._id}`,
      body: staff_member,
    });
    return res.data as IStaffMember;
  }

  async deleteStaffMember(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }
}
