import CustomDataTable, {
  CustomTableColumn,
} from "src/components/custom_data_table";
import { ModalTypes, useModal } from "src/context/modal_context";
import {
  IBatch,
  IClassroom,
  IDepartment,
  IStaffMember,
  Permissions,
  SupportedFilterAttributes,
  SupportedFilterOperators,
  getInitials,
  pluralize,
} from "crm_core";
import { useEffect, useState } from "react";
import { ClassroomService } from "src/services/institute/classroom";
import { PencilIcon, PlusIcon } from "@heroicons/react/20/solid";
import TrackedButton from "src/components/buttons/tracked_button";
import LinkText from "src/components/text_fields/link_text";
import CustomCard, { CustomCardProps } from "src/components/custom_card";
import Aux from "src/components/aux";
import { PaginatedGetter } from "src/services/paginated_getter";
import { useTable } from "src/context/table_context";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import Button from "src/components/buttons/button";
import { AddUpdateClassroomProps } from "./add_update";
import { Badge, Col, Row } from "react-bootstrap";
import Filters from "src/features/filters";
import { FilterKeys } from "src/utils/constants";
import HelpTip from "src/components/helptip";
import { getFilterSpec } from "src/features/filters/utils";
import { conditionalCol } from "src/utils/functions";
import { AssociateStudentsProps } from "./associate_students";
import ClickableCell from "src/components/widgets/clickable_cell";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import { AssociateStaffMembersProps } from "../../staff_attendance/shifts/associate_staff";
import StaffMemberBadge from "src/components/widgets/person_badge";

interface ClassroomsListProps {
  department?: string;
  batch?: string;
  cardProps?: Partial<CustomCardProps>;
  filterKey?: FilterKeys;
}

export default function ClassroomsList({
  department,
  batch,
  cardProps = {},
  filterKey,
}: ClassroomsListProps) {
  const FILTER_KEY = filterKey || FilterKeys.CLASSROOM;
  const paginatedGetter = new PaginatedGetter<ClassroomService, IClassroom>(
    ClassroomService
  );
  const {
    setModalType,
    setShowModal,
    setModalSize,
    setModalHeading,
    setComponentProps,
    setModalBodyProps,
  } = useModal();
  const [classrooms, setClassrooms] = useState<IClassroom[]>([]);
  const [loading, setLoading] = useState(false);
  const [fetchedConfig, setFetchedConfig] = useState<string>("");
  const {
    getPaginationPreferences,
    getSortPreferences,
    getFilterPreferences,
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    updateTotalRows,
    applyFilter,
  } = useTable();
  const { fetchDataForUseCase, selectedSession } = useDataProvider();

  useEffect(() => {
    applyFilter(
      FILTER_KEY,
      getFilterSpec(
        SupportedFilterAttributes.DEPARTMENT,
        SupportedFilterOperators.EQUALS,
        department
      ),
      getFilterSpec(
        SupportedFilterAttributes.BATCH,
        SupportedFilterOperators.EQUALS,
        batch
      )
    );
  }, [batch, department]);

  const fetchData = async (force = false) => {
    let config = {
      pagination: getPaginationPreferences(FILTER_KEY),
      sort: getSortPreferences(FILTER_KEY),
      filters: getFilterPreferences(FILTER_KEY),
    };
    if (!force && JSON.stringify(config) === fetchedConfig) {
      return;
    }
    setLoading(true);
    const data = await paginatedGetter.get(config, { extended: true });
    setClassrooms(data.results);
    updateTotalRows(FILTER_KEY, data.totalCount);
    setFetchedConfig(JSON.stringify(config));
    setLoading(false);
  };

  useEffect(() => {
    if (selectedSession) fetchData();
  }, [
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    selectedSession,
  ]);

  const onAddUpdate = () => {
    fetchData(true);
    fetchDataForUseCase(DataUseCase.INSTITUTE, true);
    setShowModal(false);
  };

  const handleCreateClassroom = () => {
    setModalType(ModalTypes.CLASSROOM);
    setModalSize("lg");
    setModalHeading("Add New Classroom");
    let props: AddUpdateClassroomProps = {
      onComplete: onAddUpdate,
    };
    setComponentProps(props);
    setShowModal(true);
  };

  const handleManageStudents = (classroom: IClassroom) => {
    setModalType(ModalTypes.ASSOCIATE_STUDENTS);
    setModalHeading("Manage Students");
    setModalBodyProps({ className: "p-0" });
    setModalSize("xl");
    setComponentProps({
      classroom,
      onComplete: () => {
        setShowModal(false);
        fetchData(true);
      },
    } as AssociateStudentsProps);
    setShowModal(true);
  };

  const handleUpdateTeachers = (classroom: IClassroom) => {
    setModalType(ModalTypes.ASSOCIATE_STAFF_MEMBERS);
    setModalHeading("Manage Teachers");
    setModalBodyProps({ className: "p-0" });
    setModalSize("xl");
    setComponentProps({
      classroom,
      onComplete: () => {
        setShowModal(false);
        fetchData(true);
      },
    } as AssociateStaffMembersProps);
    setShowModal(true);
  };

  const columns: CustomTableColumn<IClassroom>[] = [
    {
      colId: 0,
      name: "Classroom Name",
      cell: ({ name, _id }) => (
        <ClickableCell href={`/c/classrooms/${_id}/view`}>{name}</ClickableCell>
      ),
      reorder: true,
      sortable: true,
      sortField: "name",
      width: "300px",
    },
    ...conditionalCol(!batch, {
      colId: 1,
      name: "Batch",
      cell: ({ batch }: IClassroom) => {
        return (
          <ClickableCell href={`/c/batches/${(batch as IBatch)?._id}/edit`}>
            {(batch as IBatch)?.name}
          </ClickableCell>
        );
      },
      reorder: true,
      sortable: true,
      sortField: "name",
      width: "150px",
    }),
    ...conditionalCol(!department && !batch, {
      colId: 2,
      name: "Department",
      cell: ({ department }: IClassroom) => {
        return (
          <ClickableCell
            href={`/c/departments/${(department as IDepartment)?._id}/edit`}
          >
            {(department as IDepartment)?.name}
          </ClickableCell>
        );
      },
      reorder: true,
      sortable: true,
      sortField: "name",
      width: "150px",
    }),
    {
      name: "Teachers",
      colId: 3,
      reorder: true,
      cell: (classroom) => (
        <div className="w-full">
          <Row className="m-0 p-0 align-items-center justify-content-start">
            {((classroom.teachers ?? []) as IStaffMember[]).map((t, idx) => {
              return (
                <Col
                  key={idx}
                  xs="auto"
                  sm="auto"
                  md="auto"
                  lg="auto"
                  className="m-0 p-0"
                >
                  <LinkText to={`/c/staff/${t._id}/edit`} className="block">
                    <StaffMemberBadge staffMember={t} />
                  </LinkText>
                </Col>
              );
            })}
            <Col md="auto" sm="auto" xs="auto" className="p-0 ml-auto">
              <PermissionRestrictedComponent
                permissions={[Permissions.ASSIGN_TEACHERS]}
              >
                <Button
                  icon={<PlusIcon className="h-4 w-4 mr-1" />}
                  isSlim
                  rounded
                  className="py-1 px-2 text-xs"
                  label="Manage Teachers"
                  color="accent"
                  onClick={() => handleUpdateTeachers(classroom)}
                />
              </PermissionRestrictedComponent>
            </Col>
          </Row>
        </div>
      ),
      width: "280px",
    },
    {
      colId: 4,
      name: "Students",
      reorder: true,
      cell: (classroom) => (
        <div className="w-full">
          <Row className="align-items-center">
            <Col xs="auto" sm="auto" md="auto" lg="auto" className="pr-0">
              <div className="text-muted mr-1">
                {classroom.students?.length ?? 0}{" "}
                {pluralize(
                  classroom.students?.length ?? 0,
                  "Student",
                  "Students"
                )}
              </div>
            </Col>
            <Col
              xs="auto"
              sm="auto"
              md="auto"
              lg="auto"
              className="p-0 ml-auto mr-4"
            >
              <PermissionRestrictedComponent
                permissions={[Permissions.ASSIGN_STUDENTS]}
              >
                <Button
                  icon={<PlusIcon className="h-4 w-4 mr-1" />}
                  isSlim
                  rounded
                  className="py-1 px-2 text-xs"
                  label="Manage Students"
                  color="accent"
                  onClick={() => handleManageStudents(classroom)}
                />
              </PermissionRestrictedComponent>
            </Col>
          </Row>
        </div>
      ),
      width: "270px",
    },
    {
      colId: 5,
      reorder: true,
      name: "Actions",
      cell: (row) => (
        <Row>
          <Col className="col-auto">
            <Button
              label={"Edit"}
              icon={<PencilIcon className="h-4 w-4 mr-2" />}
              isSlim
              className="py-1 px-2 text-sm"
              color="accent"
              href={`/c/classrooms/${row._id}/edit`}
            />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <CustomCard
      title={"All Classrooms"}
      {...cardProps}
      headerRight={
        <Aux>
          <PermissionRestrictedComponent
            permissions={[Permissions.CREATE_CLASSROOM]}
          >
            <TrackedButton
              icon={<PlusIcon className="h-3 w-3 mr-1" />}
              label={<span className="">Create New Classroom</span>}
              onClick={() => handleCreateClassroom()}
              color="accent"
              isSlim
              className="px-2 py-1 text-sm"
            />
          </PermissionRestrictedComponent>
        </Aux>
      }
    >
      <Filters
        filterKey={FILTER_KEY}
        visibleFilters={
          department || batch ? [SupportedFilterAttributes.SEARCH] : undefined
        }
      />
      <CustomDataTable
        tableKey={FILTER_KEY}
        columns={columns}
        data={classrooms}
        progressPending={loading}
      />
    </CustomCard>
  );
}
