import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { joinClasses } from "src/utils/functions";
import ActionButton, { ActionButtonProps } from "../buttons/action_button";

export interface CustomCardProps {
  title: string;
  children: React.ReactNode;
  headerRight?: React.ReactNode;
  className?: string;
  actionButtonProps?: ActionButtonProps;
  slim?: boolean;
}

export default function CustomCard({
  title,
  children,
  headerRight,
  className = "",
  actionButtonProps = { actions: [] },
  slim = false,
}: CustomCardProps) {
  return (
    <Card
      className={joinClasses(
        "p-3 m-0 border-0 bg-transparent shadow-none",
        className,
        slim ? "px-3 py-0" : "p-3"
      )}
    >
      <Card.Header
        className={joinClasses(
          "bg-white p-0",
          slim ? "py-1 border-0" : "py-3 border-1 ring-custom-grey"
        )}
      >
        <Row className="m-0 p-0 align-items-center">
          <Col hidden={slim} md="auto" sm="auto" xs="auto" className="m-0 p-0">
            <aside
              className="border-3 py-3 border-accent"
              aria-label={`accent background color`}
            />
          </Col>
          <Col md="auto" sm="auto" xs="auto" className="m-0 p-0">
            <Card.Title
              className={joinClasses(
                "leading-6 my-0 p-0 font-normal text-md",
                slim ? "ml-2" : "ml-4"
              )}
            >
              {title}
            </Card.Title>
          </Col>
          <Col className="my-0 p-0 ml-4">{headerRight && headerRight}</Col>
          <Col
            hidden={actionButtonProps.actions.length == 0}
            className="p-0 ml-auto col-auto mr-3"
          >
            <ActionButton {...actionButtonProps} />
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="bg-white p-0 m-0 border-0 py-3">
        {children}
      </Card.Body>
    </Card>
  );
}
