import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Aux from "src/components/aux";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { IStudentDashboard } from "crm_core";
import { DashboardService } from "src/services/dashboard";
import { useDataProvider } from "src/context/data_provider";
import CountCard, { ICountCardProps } from "src/components/widgets/count_card";
import UserTimetable from "src/features/timetable/user_timetable";
import EntityAttendanceGraph, {
  AttendanceReportEntityType,
} from "src/features/staff_attendance/analytics/graph";
import { useAuth } from "src/context/auth_context";
import Homeworks from "src/features/homework/list";
import { FilterKeys } from "src/utils/constants";
import { HandRaisedIcon } from "@heroicons/react/20/solid";

export default function StudentDashboard() {
  const [data, setData] = useState<IStudentDashboard>();
  const { selectedSession } = useDataProvider();
  const { user } = useAuth();

  useEffect(() => {
    document.title = "Teachbond - Dashboard";
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await new DashboardService().getDashboardData();
      setData(data as any);
    };
    fetchData();
  }, [selectedSession]);

  let className = "h-10 w-10";
  const countCards: ICountCardProps[] = [
    {
      count: data?.counts.classroom ?? 0,
      title: "Classrooms",
      icon: <MapPinIcon className={className} />,
      clickPath: "/c/classrooms",
    },
    {
      count: data?.counts.attendance ?? 0,
      title: "Attendance",
      icon: <HandRaisedIcon className={className} />,
      formatter: (value) => `${value} %`,
    },
  ];

  return (
    <Aux>
      <div className="p-3">
        <Row className="">
          {countCards.map((card, index) => (
            <Col className="mb-3" lg="3" md="4" sm="12" xs="12" key={index}>
              <CountCard {...card} />
            </Col>
          ))}
        </Row>
        <Row className="">
          <Col md={6} className="p-0">
            <UserTimetable
              tableProps={{
                fixedHeaderScrollHeight: "500px",
              }}
              cardProps={{
                className: "h-100",
              }}
            />
          </Col>
          <Col md={6} className="p-0">
            {/* <EntityAttendanceGraph
              entityId={user._id}
              entityType={AttendanceReportEntityType.STUDENT}
              cardProps={{
                title: "Attendance",
                className: "px-0",
                slim: false,
              }}
            /> */}
            <Homeworks
              filterKey={FilterKeys.HOMEWORK}
              cardProps={{ title: "Tasks" }}
              tableProps={{
                fixedHeaderScrollHeight: "300px",
              }}
            />
          </Col>
        </Row>
        <div></div>
      </div>
    </Aux>
  );
}
