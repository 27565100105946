import {
  ClassroomType,
  IBatch,
  IClassroom,
  IDepartment,
  Permissions,
} from "crm_core";
import { useEffect, useState } from "react";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useNotification } from "src/context/notification_context";
import { ClassroomService } from "src/services/institute/classroom";
import { FormFieldType } from "src/utils/constants";
import { catchRequest, validateFields } from "src/utils/functions";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ArchiveButton from "src/components/widgets/archive_button";
import SaveButton from "src/components/widgets/save_button";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";

export interface AddUpdateClassroomProps {
  onComplete?: () => void;
  id?: string;
  classroom?: IClassroom;
}

export default function AddUpdateClassroom({
  onComplete,
  id,
  classroom,
}: AddUpdateClassroomProps) {
  const service = new ClassroomService();
  const [classroomName, setClassroomName] = useState<string>();
  const [batch, setBatch] = useState<string>();
  const [department, setDepartment] = useState<string>();
  const [type, setType] = useState<ClassroomType>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();
  const {
    fetchDataForUseCase,
    departments,
    selectedSession,
    batches,
    staffMembers,
  } = useDataProvider();

  useEffect(() => {
    if (selectedSession) {
      fetchDataForUseCase(DataUseCase.INSTITUTE);
    }
  }, [selectedSession]);

  useEffect(() => {
    const fetchClassroom = async (id: string) => {
      setLoading(true);
      const currentClassroom = classroom || (await service.getClassroom(id));
      setClassroomName(currentClassroom.name);
      setDepartment((currentClassroom.department as IDepartment)._id);
      setBatch((currentClassroom.batch as IBatch)._id);
      setType(currentClassroom.type);
      setLoading(false);
    };
    if (id) {
      fetchClassroom(id);
    }
  }, [id]);

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(getFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);
    let classroom: IClassroom = {
      name: classroomName as any,
      type: type as any,
      batch: batch as any,
      department: department as any,
      teachers: [],
    };

    await catchRequest(async () => {
      if (id) {
        await service.updateClassroom({
          _id: id,
          ...classroom,
        });
        pushNotification({
          title: "Success",
          message: "Classroom updated successfully",
          type: "success",
        });
      } else {
        await service.createClassroom(classroom);
      }
    }, pushNotification)();
    setLoading(false);
    onComplete?.();
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.TEXT,
          value: classroomName,
          onChange: setClassroomName,
          extras: {
            placeholder: "Classroom Name",
            label: "Classroom Name",
          },
          required: true,
          updatable: true,
        },
      ],
      [
        {
          type: FormFieldType.SELECT,
          value: department,
          onChange: (v) => {
            setDepartment(v);
            setBatch(undefined);
          },
          extras: {
            label: "Department",
            options: departments.map((d) => ({ label: d.name, value: d._id })),
          },
          required: true,
        },
        {
          type: FormFieldType.SELECT,
          value: batch,
          onChange: setBatch,
          extras: {
            label: "Batch",
            options: batches
              .filter((b) => b.department == department)
              .map((b) => ({ label: b.name, value: b._id })),
          },
          required: true,
        },
        {
          type: FormFieldType.SELECT,
          value: type,
          onChange: setType,
          extras: {
            label: "Classroom Type",
            options: [
              { value: ClassroomType.Compulsory, label: "Compulsory" },
              { value: ClassroomType.Elective, label: "Elective" },
            ],
          },
          required: true,
          updatable: true,
        },
      ],
    ];

    if (id) {
      return allFields
        .map((fields) => fields.filter((f) => f.updatable))
        .filter((f) => f.length > 0);
    }
    return allFields;
  };

  const handleDeleteClassroom = async () => {
    await catchRequest(async () => {
      await service.deleteClassroom(id as string);
      pushNotification({
        title: "Success",
        message: "Classroom archived successfully",
        type: "success",
      });
      navigate("/c/classrooms");
    }, pushNotification)();
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      <Row className="justify-content-end mt-4 align-items-center">
        <Col hidden={!id} md="auto" sm="auto" xs="auto" className="p-0">
          <PermissionRestrictedComponent
            permissions={[Permissions.ARCHIVE_CLASSROOM]}
          >
            <ArchiveButton
              dialogTitle="Archive Classroom?"
              dialogContent="Are you sure you want to archive this classroom?"
              label="Archive Classroom"
              onClick={() => handleDeleteClassroom()}
            />
          </PermissionRestrictedComponent>
        </Col>
        <Col md="auto" sm="auto" xs="auto" className="">
          <PermissionRestrictedComponent
            permissions={[
              Permissions.UPDATE_CLASSROOM,
              Permissions.CREATE_CLASSROOM,
            ]}
          >
            <SaveButton
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            />
          </PermissionRestrictedComponent>
        </Col>
      </Row>
    </div>
  );
}
