import { IStudent } from "../people";
import { IAnswer } from "./answer";
import { IQuestion } from "./question";
import { ISection } from "./section";
import { ITestSubmission } from "./submission";
import { ITest } from "./test";

export enum QuestionSubmissionStatus {
  ATTEMPTED = "attempted",
  NOT_ATTEMPTED = "not_attempted",
}

export enum QuestionEvaluationStatus {
  COMPLETE = "complete",
  PENDING = "pending",
}

export interface IQuestionEvaluation {
  _id?: string;
  question: IQuestion | string;
  section?: ISection;
  answer?: IAnswer | string;
  selected_options?: number[][];
  remarks?: string;
  score?: number;
  status?: QuestionEvaluationStatus;
  submission_status?: QuestionSubmissionStatus;
  correct_count: number;
  incorrect_count: number;
  not_attempted_count: number;
  non_evaluated_count: number;
  evaluated_count: number;
}

export interface ISectionEvaluation {
  _id?: string;
  section: ISection;
  score?: number;
  correct_count?: number;
  incorrect_count?: number;
  not_attempted_count?: number;
  non_evaluated_count?: number;
  evaluated_count?: number;
}

export enum EvaluationAggregateType {
  OVERALL = "overall",
  USER = "user",
}

export interface ITestEvaluation {
  _id?: string;
  user?: string | IStudent;
  test: string | ITest;
  submission?: string | ITestSubmission;
  score?: number;
  question_evaluations: IQuestionEvaluation[];
  section_evaluations: ISectionEvaluation[];
  aggregate: EvaluationAggregateType;
  submissions?: string[] | ITestSubmission[];
  ai_feedback?: string;
}

export interface IOverallEvaluation extends ITestEvaluation {
  question_evaluations: (IQuestionEvaluation & { question: IQuestion })[];
  submissions: ITestSubmission[];
}

export interface IQuestionEvaluationResponse extends IQuestionEvaluation {
  question: IQuestion;
  answer: IAnswer;
}

export interface ISubmissionEvaluationResponse extends ITestEvaluation {
  question_evaluations: IQuestionEvaluationResponse[];
}
