export enum ParentType {
  Father = "Father",
  Mother = "Mother",
  Guardian = "Guardian",
}

export interface IParent {
  name: string;
  mobile?: string;
  email?: string;
  qualification?: string;
  occupation?: string;
  company_name?: string;
  designation?: string;
  income?: number;
  type: ParentType;
}
