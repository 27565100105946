import { Col } from "react-bootstrap";
import InstituteOverview from "./overview";
import BatchesList from "./batch/list";
import ClassroomsList from "./classroom/list";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import { Permissions } from "crm_core";
import { useEffect } from "react";

export default function InstituteStructure() {
  useEffect(() => {
    document.title = "Teachbond - Institute Structure";
  }, []);

  return (
    <Col className="m-0 p-0">
      <PermissionRestrictedComponent
        permissions={[Permissions.VIEW_DEPARTMENTS]}
      >
        <div id="departments">
          <InstituteOverview />
        </div>
      </PermissionRestrictedComponent>
      <PermissionRestrictedComponent permissions={[Permissions.VIEW_BATCHES]}>
        <div id="batches">
          <BatchesList cardProps={{ className: "py-0" }} />
        </div>
      </PermissionRestrictedComponent>
      <PermissionRestrictedComponent
        permissions={[Permissions.VIEW_CLASSROOMS]}
      >
        <div id="classrooms">
          <ClassroomsList />
        </div>
      </PermissionRestrictedComponent>
    </Col>
  );
}
