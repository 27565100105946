import API from "./api";

export class FileService extends API {
  constructor() {
    super("/files");
  }

  async uploadPdf(file: File): Promise<string> { 
    const formData = new FormData();
    formData.append("pdffile", file, file.name);
    const res = await this._postFile({url: "/upload/pdf", body: formData });
    return res.data as string;
  }

  async getSignedUrl(key: string): Promise<string> {
    const res = await this._get({ url: "/signed-url", params: { key } });
    return res.data as string;
  }

  async getUploadSignedUrl(key: string, size: number): Promise<string> {
    const res = await this._get({
      url: "/upload-signed-url",
      params: { key, size: String(size) },
    });
    return res.data as string;
  }
}
