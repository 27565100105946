import { IHomework } from "crm_core";
import { useEffect, useState } from "react";
import LabelledField from "src/components/form_generator/labelled_field";
import TextField from "src/components/form_generator/text_field";
import { useNotification } from "src/context/notification_context";
import moment from "moment";
import { HomeworkService } from "src/services/institute/lms/homework";
import DatetimePicker from "src/components/form_generator/datetime_picker";
import Attachments from "../tests/components/attachments";
import { useNavigate, useParams } from "react-router-dom";
import CustomCard from "src/components/custom_card";
import { Col, Row } from "react-bootstrap";
import SaveButton from "src/components/widgets/save_button";
import ArchiveButton from "src/components/widgets/archive_button";

export interface AddUpdateHomeworkProps {
  onComplete?: () => void;
  reference?: string;
}

export default function AddUpdateHomework({
  onComplete,
  reference,
}: AddUpdateHomeworkProps) {
  const { id, classroom } = useParams();
  reference =
    reference ?? `homeworks/${classroom}-${moment().format("DD-MM-YYYY")}`;
  const homeworkService = new HomeworkService();
  const { pushNotification } = useNotification();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [homework, setHomework] = useState<IHomework>({
    name: `Homework - ${moment().format("DD MMMM YYYY")}`,
    description: "",
    due_date: moment()
      .startOf("day")
      .add(2, "day")
      .subtract(1, "minute")
      .toDate(),
    classroom: classroom ?? "",
    files: [],
  });

  useEffect(() => {
    const fetchHomework = async (id: string) => {
      setLoading(true);
      const homework = await homeworkService.getHomework(id);
      setHomework(homework);
      setLoading(false);
    };
    if (id) {
      fetchHomework(id);
    }
  }, [id]);

  const handleUpload = async (homework: IHomework) => {
    setLoading(true);
    try {
      if (id) {
        await homeworkService.updateHomework(homework);
        pushNotification({
          title: "Success",
          message: "Homework updated successfully",
          type: "success",
        });
      } else {
        await homeworkService.createHomework(homework);
        pushNotification({
          title: "Success",
          message: "Homework created successfully",
          type: "success",
        });
        navigate(-1);
      }

      setHomework(homework);
      onComplete?.();
    } catch (e) {
      pushNotification({
        title: "Error",
        message: "Something went wrong",
        type: "danger",
      });
    }
    setLoading(false);
  };

  const handleArchive = async () => {
    setLoading(true);
    await homeworkService.deleteHomework(id as string);
    onComplete?.();
    setLoading(false);
    navigate(-1);
  };

  return (
    <CustomCard title={id ? "Update Homwork" : "Add Homework"}>
      <div className="px-3">
        <LabelledField label="Name">
          <TextField
            value={homework?.name ?? ""}
            onChange={(v) => setHomework({ ...homework, name: v })}
          />
        </LabelledField>
        <LabelledField label="Due date">
          <DatetimePicker
            value={moment(homework?.due_date)}
            onChange={(v) => setHomework({ ...homework, due_date: v.toDate() })}
          />
        </LabelledField>
        <LabelledField label="Description">
          <TextField
            value={homework?.description ?? ""}
            onChange={(v) => setHomework({ ...homework, description: v })}
            rows={3}
          />
        </LabelledField>
        <Attachments
          reference={reference ?? ""}
          attachments={homework?.files ?? []}
          onAdd={(file) =>
            handleUpload({
              ...homework,
              files: [...(homework?.files ?? []), file],
            })
          }
          onRemove={(file) =>
            handleUpload({
              ...homework,
              files: [
                ...(homework?.files ?? []).filter((f) => f._id != file._id),
              ],
            })
          }
          editable
        />
        <Row className="justify-content-end mt-4 align-items-center">
          <Col md="auto" sm="auto" xs="auto" className="p-0">
            <ArchiveButton
              dialogTitle="Archive Homework?"
              dialogContent="Are you sure you want to archive this homework?"
              label="Archive Homework"
              disabled={loading}
              onClick={handleArchive}
            />
          </Col>
          <Col md="auto" sm="auto" xs="auto" className="">
            <SaveButton
              disabled={loading}
              loading={loading}
              label={"Submit"}
              onClick={() => handleUpload(homework)}
            />
          </Col>
        </Row>
      </div>
    </CustomCard>
  );
}
