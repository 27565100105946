import { IStudentExtended, pluralize } from "crm_core";
import { Col, Row } from "react-bootstrap";
import InvitationStatusBadge from "src/components/widgets/badges";
import { joinClasses } from "src/utils/functions";

interface StudentCardProps {
  student: IStudentExtended;
  href?: string;
}

export default function StudentCard({ student, href }: StudentCardProps) {
  return (
    <a href={href} className="text-decoration-none text-inherit">
      <div
        className={joinClasses(
          "py-3 px-3 bg-custom-grey rounded-lg",
          href ? "hover:shadow-md cursor-pointer" : ""
        )}
      >
        <Row className="align-items-center">
          <Col lg="auto" md="auto" sm="auto" xs="auto">
            <h5 className="m-0 font-light">
              {student.first_name} {student.last_name}
            </h5>
          </Col>
        </Row>
        <Row className="align-items-center mt-1">
          <Col lg="auto" md="auto" sm="auto" xs="auto">
            <h6 className="m-0 font-light text-muted">
              {student.batch.name}-{student.department.name}
            </h6>
          </Col>
        </Row>
        <Row className="align-items-center mt-1">
          <Col lg="auto" md="auto" sm="auto" xs="auto" className="">
            <h6 className="m-0 font-light text-muted">
              {student.classrooms?.length ?? 0}{" "}
              {pluralize(
                student.classrooms?.length ?? 0,
                "Classroom",
                "Classrooms"
              )}
            </h6>
          </Col>
          <Col lg="auto" md="auto" sm="auto" xs="auto" className="ml-auto">
            <InvitationStatusBadge status={student.invitation?.status} />
          </Col>
        </Row>
      </div>
    </a>
  );
}
