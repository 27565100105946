import {
  ITest,
  Permissions,
  SupportedFilterAttributes,
  SupportedFilterOperators,
  TestStatus,
} from "crm_core";
import { useEffect, useState } from "react";
import { TestService } from "src/services/institute/lms/test";
import { PlusIcon } from "@heroicons/react/20/solid";
import CustomCard, { CustomCardProps } from "src/components/custom_card";
import Aux from "src/components/aux";
import { PaginatedGetter } from "src/services/paginated_getter";
import { useTable } from "src/context/table_context";
import { useDataProvider } from "src/context/data_provider";
import { Col, Row } from "react-bootstrap";
import Filters from "src/features/filters";
import { FilterKeys } from "src/utils/constants";
import LoadingButton from "src/components/buttons/loading_button";
import { useNavigate } from "react-router-dom";
import { DEFAULT_TEST } from "./defaults";
import { getFilterSpec } from "../filters/utils";
import { catchRequest } from "src/utils/functions";
import { useNotification } from "src/context/notification_context";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import TestCard from "./card";

interface TestListProps {
  filterKey?: FilterKeys;
  cardProps?: Partial<CustomCardProps>;
  classroom?: string;
  tableOnly?: boolean;
}

export default function TestsList({
  filterKey,
  cardProps,
  classroom,
  tableOnly,
}: TestListProps) {
  const FILTER_KEY = filterKey || FilterKeys.TEST;
  const service = new TestService();
  const paginatedGetter = new PaginatedGetter<TestService, ITest>(TestService);
  const [tests, setTests] = useState<ITest[]>([]);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const navigation = useNavigate();
  const [fetchedConfig, setFetchedConfig] = useState<string>("");
  const {
    getSortPreferences,
    getFilterPreferences,
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    updateTotalRows,
    applyFilter,
  } = useTable();
  const { selectedSession } = useDataProvider();
  const { pushNotification } = useNotification();

  useEffect(() => {
    applyFilter(
      FILTER_KEY,
      getFilterSpec(
        SupportedFilterAttributes.CLASSROOM,
        SupportedFilterOperators.EQUALS,
        classroom
      )
    );
  }, [classroom]);

  useEffect(() => {
    document.title = "Teachbond - Tests";
  }, []);

  const fetchData = async (force = false) => {
    let config = {
      sort: getSortPreferences(FILTER_KEY),
      filters: getFilterPreferences(FILTER_KEY),
    };
    if (!force && JSON.stringify(config) === fetchedConfig) {
      return;
    }
    setLoading(true);
    const data = await paginatedGetter.get(config);
    setTests(data.results);
    updateTotalRows(FILTER_KEY, data.totalCount);
    setFetchedConfig(JSON.stringify(config));
    setLoading(false);
  };

  useEffect(() => {
    if (selectedSession && !loading) fetchData();
  }, [
    loading,
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    selectedSession,
  ]);

  const handleCreateTest = async () => {
    setCreating(true);
    await catchRequest(async () => {
      const newTest = await service.createTest(DEFAULT_TEST);
      navigation(`/test/${newTest._id}/edit`);
    }, pushNotification)();
    setCreating(false);
  };

  const getTableView = () => {
    return (
      <>
        <Filters
          filterKey={FILTER_KEY}
          searchPlaceholder="Test name"
          visibleFilters={
            classroom ? [SupportedFilterAttributes.SEARCH] : undefined
          }
        />
        <Row className="m-0 pl-0 py-0 pr-3">
          {tests.map((test, idx) => (
            <Col
              key={idx}
              xs="12"
              sm="12"
              md="6"
              lg="4"
              className="pl-3 pr-0 mt-3"
            >
              <TestCard
                key={idx}
                href={
                  test.status == TestStatus.PUBLISHED
                    ? `/c/tests/${test._id}/view`
                    : `/test/${test._id}/edit`
                }
                test={test}
              />
            </Col>
          ))}
        </Row>
      </>
    );
  };

  if (tableOnly) {
    return getTableView();
  }

  return (
    <CustomCard
      title="All Tests"
      headerRight={
        <Aux>
          <PermissionRestrictedComponent
            permissions={[Permissions.CREATE_TESTS]}
          >
            <LoadingButton
              loading={creating}
              icon={<PlusIcon className="h-3 w-3 mr-1" />}
              label={<span className="">Create New Test</span>}
              onClick={() => handleCreateTest()}
              color="accent"
              isSlim
              className="px-2 py-1 text-sm"
            />
          </PermissionRestrictedComponent>
        </Aux>
      }
      {...cardProps}
    >
      {getTableView()}
    </CustomCard>
  );
}
