export enum Genders {
  MALE = "Male",
  FEMALE = "Female",
  OTHER = "Other",
}

export enum BloodGroups {
  A_POSITIVE = "A+",
  A_NEGATIVE = "A-",
  B_POSITIVE = "B+",
  B_NEGATIVE = "B-",
  O_POSITIVE = "O+",
  O_NEGATIVE = "O-",
  AB_POSITIVE = "AB+",
  AB_NEGATIVE = "AB-",
}

export interface IPerson {
  _id?: string;
  mobile?: string;
  email: string;
  first_name: string;
  middle_name?: string;
  last_name?: string;
  dob?: string;
  gender?: Genders;
  blood_group?: BloodGroups;
  current_address?: IAddress;
  permanent_address?: IAddress;
  photo?: string;
}

export interface IAddress {
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  country?: string;
  pincode?: string;
}
