import { IExportConfig } from "../../../utils";
import { IOption } from "./option";

export enum QuestionType {
  SingleAnswer = "single_choice",
  MultipleAnswer = "multiple_choice",
  Subjective = "subjective",
  FileUpload = "file_upload",
}

export enum QuestionDifficulty {
  Easy = "Easy",
  Medium = "Medium",
  Hard = "Hard",
  VERY_HARD = "Very Hard",
}

export const QuestionTypeOptions = [
  { value: QuestionType.SingleAnswer, label: "Single Answer" },
  { value: QuestionType.MultipleAnswer, label: "Multiple Answer" },
  { value: QuestionType.Subjective, label: "Subjective" },
  { value: QuestionType.FileUpload, label: "File Upload" },
];

export const QuestionDifficultyOptions = [
  { value: QuestionDifficulty.Easy, label: "Easy" },
  { value: QuestionDifficulty.Medium, label: "Medium" },
  { value: QuestionDifficulty.Hard, label: "Hard" },
  { value: QuestionDifficulty.VERY_HARD, label: "Very Hard" },
];

export interface ICorrectOption {
  explanation?: string;
  option_ids: number[];
  file?: string;
}

export interface IQuestionAttachment {
  _id?: string;
  name: string;
  key: string;
  url?: string;
}

export interface IQuestion {
  _id?: string;
  text: string;
  section?: string;
  files?: IQuestionAttachment[];
  options?: IOption[];
  correct_option: ICorrectOption;
  type: QuestionType;
  last_updated?: Date;
  difficulty?: QuestionDifficulty;
}

export const QuestionExportConfig: IExportConfig<IQuestion> = {
  fileName: "Questions.xlsx",
  headers: [
    { label: "Type", key: "type" },
    { label: "Question", key: "text" },
    {
      label: "Options",
      key: "options",
      whiltelisted_leaves: ["text", "files"],
      expand: true,
    },
    {
      label: "Answer",
      key: "correct_option.option_ids",
    },
    {
      label: "Answer Explanation",
      key: "correct_option.explanation",
    },
    {
      label: "Attachments",
      key: "files",
    },
    {
      label: "Difficulty",
      key: "difficulty",
    },
  ],
};
