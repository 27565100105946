import CustomDataTable, {
  CustomTableColumn,
} from "src/components/custom_data_table";
import { ModalTypes, useModal } from "src/context/modal_context";
import {
  IBatch,
  IDepartment,
  Permissions,
  SupportedFilterAttributes,
  SupportedFilterOperators,
} from "crm_core";
import { useEffect, useState } from "react";
import { BatchService } from "src/services/institute/batch";
import { PlusIcon } from "@heroicons/react/20/solid";
import TrackedButton from "src/components/buttons/tracked_button";
import CustomCard, { CustomCardProps } from "src/components/custom_card";
import Aux from "src/components/aux";
import { PaginatedGetter } from "src/services/paginated_getter";
import { useTable } from "src/context/table_context";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import { AddUpdateBatchProps } from "./add_update";
import Filters from "src/features/filters";
import { FilterKeys } from "src/utils/constants";
import { getFilterSpec } from "src/features/filters/utils";
import { conditionalCol } from "src/utils/functions";
import ClickableCell from "src/components/widgets/clickable_cell";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";

interface BatchesListProps {
  department?: string;
  filterKey?: FilterKeys;
  cardProps?: Partial<CustomCardProps>;
}

export default function BatchesList({
  department,
  filterKey,
  cardProps = {},
}: BatchesListProps) {
  const FILTER_KEY = filterKey || FilterKeys.BATCH;
  const paginatedGetter = new PaginatedGetter<BatchService, IBatch>(
    BatchService
  );
  const {
    setModalType,
    setShowModal,
    setModalSize,
    setModalHeading,
    setComponentProps,
  } = useModal();
  const [batchs, setBatchs] = useState<IBatch[]>([]);
  const [loading, setLoading] = useState(false);
  const [fetchedConfig, setFetchedConfig] = useState<string>("");
  const {
    getPaginationPreferences,
    getSortPreferences,
    getFilterPreferences,
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    updateTotalRows,
    applyFilter,
  } = useTable();
  const { fetchDataForUseCase, selectedSession } = useDataProvider();

  useEffect(() => {
    applyFilter(
      FILTER_KEY,
      getFilterSpec(
        SupportedFilterAttributes.DEPARTMENT,
        SupportedFilterOperators.EQUALS,
        department
      )
    );
  }, [department]);

  const fetchData = async (force?: boolean) => {
    let config = {
      pagination: getPaginationPreferences(FILTER_KEY),
      sort: getSortPreferences(FILTER_KEY),
      filters: getFilterPreferences(FILTER_KEY),
    };
    if (!force && JSON.stringify(config) === fetchedConfig) {
      return;
    }
    setLoading(true);
    const data = await paginatedGetter.get(config, { extended: true });
    setBatchs(data.results);
    updateTotalRows(FILTER_KEY, data.totalCount);
    setFetchedConfig(JSON.stringify(config));
    setLoading(false);
  };

  useEffect(() => {
    if (selectedSession) fetchDataForUseCase(DataUseCase.INSTITUTE);
  }, [selectedSession]);

  useEffect(() => {
    if (selectedSession) fetchData();
  }, [
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    selectedSession,
  ]);

  const handleCreateBatch = () => {
    setModalType(ModalTypes.BATCH);
    setModalSize("lg");
    setModalHeading("Add New Batch");
    let props: AddUpdateBatchProps = {
      onComplete: async () => {
        await fetchData(true);
        await fetchDataForUseCase(DataUseCase.INSTITUTE, true);
        setShowModal(false);
      },
    };
    setComponentProps(props);
    setShowModal(true);
  };

  const columns: CustomTableColumn<IBatch>[] = [
    {
      colId: 0,
      name: "Batch Name",
      cell: ({ name, _id }) => (
        <ClickableCell href={`/c/batches/${_id}/edit`}>{name}</ClickableCell>
      ),
      reorder: true,
      sortable: true,
      sortField: "name",
      width: "300px",
    },
    ...conditionalCol(!department, {
      colId: 2,
      name: "Department",
      cell: ({ department }: IBatch) => {
        return (
          <ClickableCell
            href={`/c/departments/${(department as IDepartment)?._id}/edit`}
          >
            {(department as IDepartment)?.name}
          </ClickableCell>
        );
      },
      reorder: true,
      sortable: true,
      sortField: "name",
      width: "180px",
    }),
  ];

  return (
    <CustomCard
      title="All Batches"
      headerRight={
        <Aux>
          <PermissionRestrictedComponent
            permissions={[Permissions.CREATE_BATCH]}
          >
            <TrackedButton
              icon={<PlusIcon className="h-3 w-3 mr-1" />}
              label={<span className="">Create New Batch</span>}
              onClick={() => handleCreateBatch()}
              color="accent"
              isSlim
              className="px-2 py-1 text-sm"
            />
          </PermissionRestrictedComponent>
        </Aux>
      }
      {...cardProps}
    >
      <Filters
        filterKey={FILTER_KEY}
        searchPlaceholder="Batch name"
        visibleFilters={
          department ? [SupportedFilterAttributes.SEARCH] : undefined
        }
      />
      <CustomDataTable
        tableKey={FILTER_KEY}
        columns={columns}
        data={batchs}
        progressPending={loading}
      />
    </CustomCard>
  );
}
