import { createContext, useContext, useEffect, useState } from "react";
import {
  IBatch,
  IClassroom,
  IClient,
  IDepartment,
  ISession,
  IStudent,
  IStaffMember,
  ICustomRole,
  IFeeStructure,
} from "crm_core";
import { ClientService } from "src/services/client";
import { useAuth } from "src/context/auth_context";
import { DepartmentService } from "src/services/institute/department";
import { SessionService } from "src/services/institute/session";
import { BatchService } from "src/services/institute/batch";
import {
  PaginatedGetter,
  PaginatedResultType,
} from "src/services/paginated_getter";
import { LocalStorageKeys } from "src/utils/constants";
import { StaffMemberService } from "src/services/institute/staff_member";
import { StudentService } from "src/services/institute/student";
import { ClassroomService } from "src/services/institute/classroom";
import { CustomRoleService } from "src/services/custom_role";
import { FeeStructureService } from "src/services/institute/fees/structure";

const dataProviderContext = createContext<DataProviderContextProps>({} as any);

export function useDataProvider() {
  return useContext(dataProviderContext);
}

export function DataProviderProvider({ children }: any) {
  return (
    <dataProviderContext.Provider value={useDataProviderProvider()}>
      {children}
    </dataProviderContext.Provider>
  );
}

export enum DataUseCase {
  CLIENTS,
  INSTITUTE,
  DASHBOARD,
  STAFF_MEMBER,
  STUDENT,
  CLASSROOMS,
  FILTERS,
  ROLES,
  FEE_STRUCTURES,
}

interface QueueProps {
  useCase: DataUseCase;
  force?: boolean;
}

interface UseCaseProps {
  service: any;
  data: any[];
  setData: (data: any[]) => void;
}

function useDataProviderProvider() {
  const { authenticated } = useAuth();
  const [selectedSession, setSelectedSession] = useState<ISession>();
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState<IClient[]>([]);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [sessions, setSessions] = useState<ISession[]>([]);
  const [batches, setBatches] = useState<IBatch[]>([]);
  const [queue, setQueue] = useState<QueueProps[]>([]);
  const [fetchCount, setFetchCount] = useState(0);
  const [requestedData, setRequestedData] = useState<string[]>([]);
  const [staffMembers, setStaffMembers] = useState<IStaffMember[]>([]);
  const [students, setStudents] = useState<IStudent[]>([]);
  const [classrooms, setClassrooms] = useState<IClassroom[]>([]);
  const [roles, setRoles] = useState<ICustomRole[]>([]);
  const [feeStructures, setFeeStructures] = useState<IFeeStructure[]>([]);

  useEffect(() => {
    if (sessions && sessions.length > 0) {
      let pref = localStorage.getItem(LocalStorageKeys.SESSION_ID);
      if (pref) {
        let session = sessions.find((s) => s._id === pref);
        if (session && session?._id === selectedSession?._id) return;
        if (session) {
          setSelectedSession(session);
        } else {
          setSelectedSession(sessions[0]);
        }
      } else {
        setSelectedSession(sessions[0]);
      }
    }
  }, [sessions, authenticated]);

  useEffect(() => {
    if (selectedSession && selectedSession._id) {
      localStorage.setItem(LocalStorageKeys.SESSION_ID, selectedSession._id);
    }
  }, [selectedSession, authenticated]);

  const useCaseToDataMap = {
    [DataUseCase.CLIENTS]: [
      { data: clients, setData: setClients, service: ClientService },
    ],
    [DataUseCase.INSTITUTE]: [
      {
        data: departments,
        setData: setDepartments,
        service: DepartmentService,
      },
      { data: batches, setData: setBatches, service: BatchService },
    ],
    [DataUseCase.DASHBOARD]: [
      { data: sessions, setData: setSessions, service: SessionService },
    ],
    [DataUseCase.STAFF_MEMBER]: [
      {
        data: staffMembers,
        setData: setStaffMembers,
        service: StaffMemberService,
      },
    ],
    [DataUseCase.STUDENT]: [
      { data: students, setData: setStudents, service: StudentService },
    ],
    [DataUseCase.CLASSROOMS]: [
      { data: classrooms, setData: setClassrooms, service: ClassroomService },
    ],
    [DataUseCase.FILTERS]: [
      {
        data: departments,
        setData: setDepartments,
        service: DepartmentService,
      },
      { data: batches, setData: setBatches, service: BatchService },
    ],
    [DataUseCase.ROLES]: [
      { data: roles, setData: setRoles, service: CustomRoleService },
    ],
    [DataUseCase.FEE_STRUCTURES]: [
      {
        data: feeStructures,
        setData: setFeeStructures,
        service: FeeStructureService,
      },
    ],
  } as { [key: number]: UseCaseProps[] };

  const fetchDataForUseCase = async (useCase: DataUseCase, force?: boolean) => {
    console.log(
      "Queuing request for use case",
      DataUseCase[useCase],
      queue.length
    );
    if (!queue.find((i) => i.useCase === useCase) || force) {
      console.log(
        "Accepted request for use case",
        DataUseCase[useCase],
        queue.length
      );
      setQueue((v) => [...v, { useCase, force }]);
      setFetchCount((v) => v + 1);
    }
  };

  const fetchData = async (useCase: DataUseCase, force?: boolean) => {
    let promises: any = [];
    let useCases = useCaseToDataMap[useCase];

    let eligibleCases: UseCaseProps[] = useCases.filter(
      (item: UseCaseProps) =>
        !requestedData.includes(item.service.NAME) || force
    );

    eligibleCases.forEach((item: any) => {
      promises.push(new PaginatedGetter<any, any>(item.service).get({}));
    });
    console.log(
      `Fetching for ${DataUseCase[useCase]}, eligiblePromises: ${eligibleCases.length}, promises: ${promises.length}, force: ${force}, existing: ${requestedData}`
    );

    try {
      let data: PaginatedResultType<any>[] = (await Promise.all(
        promises
      )) as any;

      eligibleCases.forEach((item: any, index: number) => {
        requestedData.push(item.service.NAME);
        item.setData(data[index].results);
      });
    } catch (e) {
      console.log({ e });
    }
  };

  useEffect(() => {
    const fetch = async (currentQueue: QueueProps[]) => {
      console.log(
        `Fetching data for ${currentQueue.length} use cases: ${currentQueue
          .map((i) => JSON.stringify({ c: DataUseCase[i.useCase], f: i.force }))
          .join(", ")}`
      );

      for (let i of currentQueue) {
        await fetchData(i.useCase, i.force);
      }
      setQueue((queue) =>
        queue.filter(
          (i) =>
            !currentQueue.find(
              (j) => j.useCase === i.useCase && j.force === i.force
            )
        )
      );
      setFetchCount((v) => v + 1);
      setLoading(false);
    };
    if (queue.length > 0 && !loading) {
      setLoading(true);
      fetch([...queue]);
    }
  }, [fetchCount]);

  return {
    clients,
    departments,
    batches,
    sessions,
    fetchDataForUseCase,
    loading,
    selectedSession,
    setSelectedSession,
    staffMembers,
    students,
    classrooms,
    roles,
    feeStructures,
  } as DataProviderContextProps;
}

interface DataProviderContextProps {
  clients: IClient[];
  departments: IDepartment[];
  batches: IBatch[];
  sessions: ISession[];
  fetchDataForUseCase: (useCase: DataUseCase, force?: boolean) => Promise<void>;
  loading: boolean;
  selectedSession: ISession;
  setSelectedSession: (session: ISession) => void;
  staffMembers: IStaffMember[];
  students: IStudent[];
  classrooms: IClassroom[];
  roles: ICustomRole[];
  feeStructures: IFeeStructure[];
}
