import { Tooltip } from "react-tooltip";
import { joinClasses } from "src/utils/functions";

interface HelpTipProps {
  id?: string;
  text: string;
  className?: string;
  place?: "top" | "bottom" | "left" | "right";
  effect?: "float" | "solid" | "light" | "dark";
  children?: JSX.Element;
}

export default function HelpTip({
  id,
  text,
  className,
  place = "top",
  effect = "solid",
  children,
}: HelpTipProps) {
  return (
    <>
      <span
        data-tooltip-id={id}
        data-tooltip-content={text}
      >
        {children}
      </span>
      <Tooltip
        className={joinClasses("bg-accent", className)}
        place={place}
        id={id}
      />
    </>
  );
}
