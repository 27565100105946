import { IOrganization, IUser } from "../../../hierarchy";
import { IClassroom, ISession } from "../academics";
import { ICourse, ICourseSubject, ISubjectTopic } from "../courses";
import { IStudent } from "../people";
import { IQuestion } from "./question";
import { ISection } from "./section";

export enum TestStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
  ARCHIVED = "archived",
}

export enum TestType {
  TEST = "test",
  HOMEWORK = "homework",
}

export interface ITest {
  _id?: string;
  course?: string | ICourse;
  subject?: string | ICourseSubject;
  topics?: string[] | ISubjectTopic[];
  name: string;
  description?: string;
  sections: ISection[];
  questions: IQuestion[] | string[];
  user?: string | IUser;
  status: TestStatus;
  organization?: IOrganization | string;
  session?: ISession | string;
  duration_minutes?: number;
  instructions?: string;
  result_schedule?: Date;
  start?: Date;
  end?: Date;
  classrooms?: string[] | IClassroom[];
  students?: string[] | IStudent[];
  type?: TestType;
}

export interface IPublishTestInterfaceBody {
  instructions?: string;
  name?: string;
  testId?: string;
  classrooms?: string[];
  students?: string[];
  duration_minutes: number;
  start: Date;
  end?: Date;
  result_schedule?: Date;
}
