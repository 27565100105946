import { Permissions } from "crm_core";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CustomCard from "src/components/custom_card";
import { isPermissible } from "src/utils/functions";
import FeeStructures from "./structure/list";
import FeeCollection from "./record/list";
import { tabClassName } from "src/utils/constants";

export default function FeeManagement({}) {
  const { tab } = useParams();
  let supportedTabs = [];
  const canSeeStructures = isPermissible(Permissions.VIEW_FEE_STRUCTURES);
  const canViewFees = isPermissible(
    Permissions.VIEW_FEES,
    Permissions.COLLECT_FEES
  );
  if (canSeeStructures) supportedTabs.push("structures");
  if (canViewFees) supportedTabs.push("fees");
  const [activeTab, setActiveTab] = useState<string>(
    supportedTabs.includes(tab || "") ? tab ?? "" : supportedTabs[0]
  );

  useEffect(() => {
    if (activeTab) {
      window.history.replaceState(null, "", `/c/fee-management/${activeTab}`);
    }
  }, [activeTab]);

  useEffect(() => {
    document.title = "Teachbond - Fee Management";
  }, []);

  // const tabClassName = "p-0 py-1 px-3 font-sm mr-1 mb-3";

  return (
    <CustomCard title="Fee Management" className="">
      <Tabs
        mountOnEnter
        color="primary"
        className="ml-3"
        variant="pills"
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k as string)}
      >
        {canSeeStructures && (
          <Tab
            eventKey="structures"
            title="Structures"
            tabClassName={tabClassName}
          >
            {activeTab === "structures" && <FeeStructures tableOnly />}
          </Tab>
        )}
        {canViewFees && (
          <Tab eventKey="fees" title="Collection" tabClassName={tabClassName}>
            <FeeCollection tableOnly />
          </Tab>
        )}
      </Tabs>
    </CustomCard>
  );
}
