import { IQuestionAttachment } from "./question";

export enum HomeworkStatus {
  PENDING = "PENDING",
  SUBMITTED = "SUBMITTED",
  LATE = "LATE",
}

export interface IHomework {
  _id?: string;
  name: string;
  description: string;
  due_date: Date;
  classroom: string;
  files: IQuestionAttachment[];
}

export interface IStudentHomework extends IHomework {
  status: HomeworkStatus;
}
