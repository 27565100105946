import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CustomCard from "src/components/custom_card";
import { Loader } from "src/components/loader";
import { AttendanceShiftService } from "src/services/institute/attendance/shift";
import Filters from "src/features/filters";
import { FilterKeys } from "src/utils/constants";
import { useTable } from "src/context/table_context";
import { useDataProvider } from "src/context/data_provider";
import { PaginatedGetter } from "src/services/paginated_getter";
import { IAttendanceShiftResponse } from "crm_core";
import AttendanceShiftCard from "./card";
import Aux from "src/components/aux";
import TrackedButton from "src/components/buttons/tracked_button";
import { PlusIcon } from "@heroicons/react/20/solid";
import { ModalTypes, useModal } from "src/context/modal_context";
import { AddUpdateAttendanceShiftProps } from "./add_update";

interface ShiftListProps {
  tableOnly?: boolean;
  setExportData?: (v: IAttendanceShiftResponse[]) => void;
}

export default function ShiftList({
  tableOnly,
  setExportData,
}: ShiftListProps) {
  const FILTER_KEY = FilterKeys.SHIFTS_LIST;
  const paginatedGetter = new PaginatedGetter<
    AttendanceShiftService,
    IAttendanceShiftResponse
  >(AttendanceShiftService);
  const [loading, setLoading] = useState<boolean>(false);
  const [shifts, setShifts] = useState<IAttendanceShiftResponse[]>([]);
  const { selectedSession } = useDataProvider();
  const { filterPreferences, getFilterPreferences } = useTable();
  const {
    setModalType,
    setModalSize,
    setModalHeading,
    setShowModal,
    setComponentProps,
  } = useModal();

  const fetchData = async () => {
    let config = {
      filters: getFilterPreferences(FILTER_KEY),
    };
    setLoading(true);
    const data = await paginatedGetter.get(config, { extended: true });
    setShifts(data.results);
    setExportData?.(data.results);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedSession) {
      fetchData();
    }
  }, [filterPreferences, selectedSession]);

  const onAddUpdate = () => {
    fetchData();
    setShowModal(false);
  };

  const handleCreateUpdateAttendanceShift = (id?: string) => {
    setModalType(ModalTypes.ATTENDANCE_SHIFT);
    setModalSize("lg");
    setModalHeading("Add New Shift");
    let props: AddUpdateAttendanceShiftProps = {
      onComplete: onAddUpdate,
      id,
    };
    setComponentProps(props);
    setShowModal(true);
  };

  const getTableView = () => {
    return (
      <>
        <Filters filterKey={FILTER_KEY} />
        <Row className="m-0 pl-0 py-0 pr-3">
          {shifts.map((shift, idx) => (
            <Col
              key={idx}
              xs="12"
              sm="6"
              md="6"
              lg="4"
              className="pl-3 pr-0 mt-3"
            >
              <AttendanceShiftCard
                onClick={() => handleCreateUpdateAttendanceShift(shift._id)}
                shift={shift}
                forceRefresh={fetchData}
              />
            </Col>
          ))}
        </Row>
      </>
    );
  };

  if (tableOnly) return getTableView();

  return (
    <CustomCard
      title="Work Shifts"
      headerRight={
        <Aux>
          <TrackedButton
            icon={<PlusIcon className="h-3 w-3 mr-1" />}
            label={<span className="">Add New Shift</span>}
            onClick={() => handleCreateUpdateAttendanceShift()}
            color="accent"
            isSlim
            className="px-2 py-1 text-sm"
          />
        </Aux>
      }
    >
      {loading && <Loader />}
      <div hidden={loading} className="-mt-3">
        {getTableView()}
      </div>
    </CustomCard>
  );
}
