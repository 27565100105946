import { IAttendanceShiftResponse, pluralize } from "crm_core";
import { Col, Row } from "react-bootstrap";
import Button from "src/components/buttons/button";
import { ModalTypes, useModal } from "src/context/modal_context";
import { AssociateStaffMembersProps } from "./associate_staff";
import { EyeIcon, PencilIcon } from "@heroicons/react/20/solid";

interface AttendanceShiftCardProps {
  shift: IAttendanceShiftResponse;
  onClick?: () => void;
  href?: string;
  forceRefresh?: () => void;
}

export default function AttendanceShiftCard({
  shift,
  onClick,
  href,
  forceRefresh,
}: AttendanceShiftCardProps) {
  const {
    setModalType,
    setModalHeading,
    setModalBodyProps,
    setComponentProps,
    setModalSize,
    setShowModal,
  } = useModal();

  const handleViewPeople = () => {
    setModalType(ModalTypes.ASSOCIATE_STAFF_MEMBERS);
    setModalHeading("Manage Staff Members");
    setModalBodyProps({ className: "p-0" });
    setModalSize("xl");
    setComponentProps({
      shift,
      onComplete: () => {
        setShowModal(false);
        forceRefresh && forceRefresh();
      },
    } as AssociateStaffMembersProps);
    setShowModal(true);
  };

  return (
    <a href={href} className="text-decoration-none text-inherit">
      <div className="py-3 px-3 bg-custom-grey rounded-lg">
        <Row className="align-items-center">
          <Col className="col-auto">
            <h5 className="m-0 font-light">{shift.name}</h5>
          </Col>
          <Col className="col-auto ml-auto">
            <Button
              label={"Edit"}
              isSlim
              className="py-1 px-2"
              color="transparent"
              icon={<PencilIcon className="h-4 w-4 mr-2" />}
              onClick={onClick}
            />
          </Col>
        </Row>

        <Row className="align-items-center mt-4">
          <Col className="col-auto">
            <h6 className="font-light text-muted m-0">
              {shift.check_in_time} to {shift.check_out_time}
            </h6>
          </Col>
          <Col className="col-auto ml-auto">
            <h6 className="font-light text-muted m-0">
              {shift.people.length}{" "}
              {pluralize(shift.people.length, "Person", "People")}
            </h6>
          </Col>
          <Col className="col-auto">
            <Button
              label={"Manage"}
              isSlim
              className="py-1 px-2"
              color="transparent"
              icon={<EyeIcon className="h-4 w-4 mr-2" />}
              onClick={handleViewPeople}
            />
          </Col>
        </Row>
      </div>
    </a>
  );
}
