import { Menu } from "@headlessui/react";
import { useTheme } from "src/context/theme_context";
import { joinClasses } from "src/utils/functions";
import Dropdown from "src/components/navbar/dropdown";
import Button from "src/components/buttons/button";
import { Badge, Col, Row } from "react-bootstrap";
import { useAuth } from "src/context/auth_context";
import { UserCircleIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import Logo from "../logo";
import { useNavigate } from "react-router-dom";
import ProfilePhoto from "../profile_photo";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import { useEffect, useState } from "react";
import {
  CheckCircleIcon,
  PencilSquareIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { CheckCircleIcon as OutlineCheckCircleIcon } from "@heroicons/react/24/outline";
import { ModalTypes, useModal } from "src/context/modal_context";
import { AddUpdateSessionProps } from "src/features/session/add_update";
import { LocalStorageKeys } from "src/utils/constants";
import SearchBox from "../text_fields/search_box";
import { AuthEntityType, Permissions, UserRole, roleToTitle } from "crm_core";
import { usePageState } from "src/context/page_state_context";
import PermissionRestrictedComponent from "../permission_restricted_component";

interface HeaderProps {
  children?: React.ReactNode;
  height?: string;
  brandWidth?: string;
}

export default function Header({
  children,
  height = "100px",
  brandWidth = "5vw",
}: HeaderProps) {
  const { theme, mode, flipMode } = useTheme();
  const { isSmallScreen, headless } = usePageState();
  const {
    signout,
    user,
    authenticated,
    selectedAuthorization,
    setSelectedAuthorization,
    authorizations,
    organizations,
    selectedOrganization,
    loggedIn,
  } = useAuth();
  const { fetchDataForUseCase, sessions, selectedSession, setSelectedSession } =
    useDataProvider();
  const {
    setModalHeading,
    setModalType,
    setComponentProps,
    setShowModal,
    setModalSize,
  } = useModal();
  const navigate = useNavigate();
  const [sessionSearch, setSessionSearch] = useState<string>("");

  useEffect(() => {
    if (authenticated && selectedAuthorization?.role !== UserRole.SuperAdmin) {
      fetchDataForUseCase(DataUseCase.DASHBOARD);
    }
  }, [selectedSession, authenticated]);

  const userActions = [
    ...(selectedAuthorization
      ? [
          {
            label: "Profile",
            onClick: () => {
              navigate("/c/user/profile");
            },
          },
        ]
      : []),
    // {
    //   label: "Flip mode",
    //   onClick: flipMode,
    // },
    {
      label: "Sign out",
      onClick: signout,
    },
  ];

  const onAddSession = () => {
    setShowModal(false);
    fetchDataForUseCase(DataUseCase.DASHBOARD, true);
  };

  const handleAddUpdateSession = (id?: string) => {
    setModalHeading(id ? "Update academic year" : "Add new academic year");
    setModalType(ModalTypes.SESSION);
    setComponentProps({
      onComplete: onAddSession,
      id,
    } as AddUpdateSessionProps);
    setModalSize("lg");
    setShowModal(true);
  };

  return (
    <Row
      hidden={headless}
      className={joinClasses(
        "m-0 p-0 z-50 shadow-none align-items-center",
        !!selectedAuthorization ? "bg-accent" : "bg-light-accent"
      )}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: height,
        backgroundColor: theme.colors.background,
        alignItems: "center",
      }}
    >
      <Col hidden={!!selectedAuthorization} className="col-auto">
        {!selectedAuthorization && (
          <div style={{ width: "130px", height: height }}>
            <Logo />
          </div>
        )}
      </Col>
      <Col
        hidden={!selectedAuthorization}
        md="auto"
        sm="auto"
        xs="auto"
        className="p-0 ml-4 my-0"
        style={{
          justifyContent: "right",
        }}
      >
        <Dropdown
          showChevron={false}
          buttonStyle={{}}
          origin="top-left"
          menuStyle={{
            width: isSmallScreen ? "340px" : "400px",
            marginTop: "25px",
            zIndex: 102,
          }}
          buttonChild={
            <Row className="m-0 p-0 align-items-center">
              <Col md="auto" sm="auto" xs="auto" className="p-0">
                <ProfilePhoto
                  url={selectedOrganization?.logo}
                  className="h-11 w-11"
                  placeholderIcon={
                    <UserGroupIcon className="h-8 w-8" strokeWidth={0.8} />
                  }
                />
              </Col>
              <Col
                hidden={isSmallScreen}
                md="auto"
                sm="auto"
                xs="auto"
                className="pl-4 text-start"
              >
                <div className="text-on-accent">
                  {selectedOrganization?.name}
                </div>
                <div className="text-xs">
                  {selectedAuthorization && (
                    <Badge bg="light-accent text-on-light-accent">
                      {roleToTitle[selectedAuthorization?.role]}
                    </Badge>
                  )}
                </div>
              </Col>
            </Row>
          }
        >
          {(authorizations ?? [])
            .filter((auth) => auth.entity_type == AuthEntityType.Organization)
            .map((auth, idx) => {
              const org = organizations.find((o) => o._id === auth.entity._id);
              const isSelected = selectedAuthorization?._id === auth._id;
              return (
                <Menu.Item key={`action-${idx}`}>
                  {({ active }: any) => (
                    <div className="px-2 py-2">
                      <div
                        onClick={() => {
                          setSelectedAuthorization(auth);
                          navigate(
                            auth.role === UserRole.OrganizationAdmin
                              ? "/c/organization/profile"
                              : "/c"
                          );
                          window.location.reload();
                        }}
                        className={joinClasses(
                          isSelected
                            ? "bg-accent text-on-accent"
                            : "bg-custom-grey",
                          !isSelected && active
                            ? "opacity-80 hover:bg-accent hover:text-on-accent"
                            : "",
                          "px-2 py-3 rounded w-full"
                        )}
                      >
                        <Row className="m-0 p-0 align-items-center">
                          <Col
                            lg="auto"
                            md="auto"
                            sm="auto"
                            xs="auto"
                            className="m-0 p-0"
                            hidden={isSmallScreen}
                          >
                            <ProfilePhoto
                              url={org?.logo}
                              className="h-16 w-16"
                              placeholderIcon={
                                <UserGroupIcon
                                  className="h-12 w-12"
                                  strokeWidth={0.8}
                                />
                              }
                            />
                          </Col>
                          <Col
                            lg="auto"
                            md="auto"
                            sm="auto"
                            xs="auto"
                            className="ml-4 p-0"
                          >
                            <h6 className="">{org?.name}</h6>
                            <div className="text-xs">
                              <Badge
                                bg={
                                  isSelected
                                    ? "light-accent text-on-light-accent"
                                    : "accent"
                                }
                              >
                                {roleToTitle[auth.role]}
                              </Badge>
                            </div>
                          </Col>
                          <Col
                            lg="auto"
                            md="auto"
                            sm="auto"
                            xs="auto"
                            className="p-0 ml-auto"
                          ></Col>
                          {/* <Col
                              lg="auto"
                              md="auto"
                              sm="auto"
                              xs="auto"
                              className="p-0"
                              hidden={auth.role !== UserRole.OrganizationAdmin}
                            >
                              <Button
                                label="Edit"
                                icon={
                                  <PencilSquareIcon className="h-4 w-4 mr-2" />
                                }
                                color="transparent"
                                className="text-sm hover:bg-gray-200 py-1 px-2"
                                onClick={() => {
                                  setSelectedAuthorization(auth);
                                  navigate("/c/organization/profile");
                                }}
                                isSlim
                              />
                            </Col> */}
                          <Col
                            lg="auto"
                            md="auto"
                            sm="auto"
                            xs="auto"
                            className="p-0 ml-1"
                            hidden
                          >
                            <Button
                              icon={
                                isSelected ? (
                                  <CheckCircleIcon className="h-8 w-8" />
                                ) : (
                                  <OutlineCheckCircleIcon className="h-8 w-8" />
                                )
                              }
                              color="transparent"
                              className={joinClasses(
                                "shadow-none hover:bg-gray-200",
                                isSelected ? "text-accent" : ""
                              )}
                              onClick={() => {
                                setSelectedAuthorization(auth);
                                navigate("/c");
                                window.location.reload();
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </Menu.Item>
              );
            })}
        </Dropdown>
      </Col>
      <Col className="m-0 p-0">{children as any}</Col>
      <Col
        hidden={!authenticated || !selectedSession}
        className="m-0 p-0 justify-self-right"
        lg="auto"
        md="auto"
        sm="auto"
        xs="auto"
      >
        <Dropdown
          showChevron={true}
          origin="top-right"
          className="mr-4"
          buttonClassName="pl-3 py-2 pr-2 rounded-md text-on-accent"
          menuStyle={{
            width: isSmallScreen ? "280px" : "350px",
            marginTop: "25px",
          }}
          buttonChild={
            <div className="">
              <h6 className="m-0 py-0 pr-2">{selectedSession?.name}</h6>
            </div>
          }
        >
          <div
            className={joinClasses(
              "border-accent border-2 rounded-lg",
              isSmallScreen ? "p-2 pt-3" : "p-3 pt-4 "
            )}
          >
            <h6>Select Academic Year</h6>
            <div>
              <SearchBox
                className="mb-4 mx-0 px-0"
                value={sessionSearch}
                onChange={(v) => setSessionSearch(v)}
                placeholder="Search academic year"
              />
            </div>
            {sessions
              .filter((s) =>
                s.name.toLowerCase().includes(sessionSearch.toLowerCase())
              )
              .map((session, idx) => (
                <Menu.Item key={`session-${idx}`}>
                  {({ active }: any) => (
                    <Row
                      className={joinClasses(
                        "m-0 p-0 mb-3 bg-custom-grey rounded-lg px-2 py-4 align-items-center"
                      )}
                    >
                      <Col md="auto" sm="auto" xs="auto">
                        <h6 className="m-0 p-0">{session.name}</h6>
                      </Col>
                      <Col
                        md="auto"
                        sm="auto"
                        xs="auto"
                        className="p-0 ml-auto"
                      >
                        <PermissionRestrictedComponent
                          permissions={[Permissions.UPDATE_SESSION]}
                        >
                          <Button
                            icon={<PencilSquareIcon className="h-6 w-6" />}
                            color="transparent"
                            className="shadow-none hover:bg-gray-200"
                            onClick={() => handleAddUpdateSession(session._id)}
                          />
                        </PermissionRestrictedComponent>
                      </Col>
                      <Col md="auto" sm="auto" xs="auto" className="p-0">
                        <Button
                          icon={<CheckCircleIcon className="h-6 w-6" />}
                          color="transparent"
                          className={joinClasses(
                            "shadow-none hover:bg-gray-200",
                            selectedSession?._id === session._id
                              ? "text-accent"
                              : ""
                          )}
                          onClick={() => {
                            if (session._id)
                              localStorage.setItem(
                                LocalStorageKeys.SESSION_ID,
                                session._id
                              );
                            setSelectedSession(session);
                            window.location.reload();
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                </Menu.Item>
              ))}
            <Menu.Item key={`session-add`}>
              {({ active }: any) => (
                <div className="">
                  <PermissionRestrictedComponent
                    permissions={[Permissions.UPDATE_SESSION]}
                  >
                    <Button
                      onClick={() => handleAddUpdateSession()}
                      label="Add new academic year"
                      color="transparent"
                      noshadow
                      icon={<PlusIcon className="h-4 w-4 mr-2" />}
                      className="mx-auto hover:bg-custom-grey text-accent"
                    />
                  </PermissionRestrictedComponent>
                </div>
              )}
            </Menu.Item>
          </div>
        </Dropdown>
      </Col>
      <Col
        hidden={loggedIn}
        className="p-0 mr-4 my-0"
        lg="auto"
        md="auto"
        sm="auto"
        xs="auto"
      >
        <>
          <Button
            className="mr-2 py-1"
            label="Login"
            href="/auth/login"
            rounded
            contentClass="px-2 font-light"
            color="accent"
            isSlim
          />
          {/* <Button
            label="Sign up"
            href="/auth/signup"
            rounded
            contentClass="px-2 font-light"
            color="purple"
            isSlim
          /> */}
        </>
      </Col>
      <Col
        hidden={!loggedIn}
        className="p-0 mr-4 my-0"
        lg="auto"
        md="auto"
        sm="auto"
        xs="auto"
      >
        <Dropdown
          origin="top-right"
          className="text-on-accent"
          menuStyle={{
            width: "200px",
            marginTop: "25px",
          }}
          buttonChild={
            <Row className="m-0 p-0">
              <Col className="d-flex align-items-center p-0">
                <ProfilePhoto
                  url={user?.photo}
                  className="h-11 w-11"
                  placeholderIcon={
                    <UserCircleIcon className="h-8 w-8" strokeWidth={0.8} />
                  }
                />
              </Col>
              {/* <Col className="d-flex align-items-center pl-2">
                    {user?.first_name}
                  </Col> */}
            </Row>
          }
        >
          {userActions.map((action, idx) => (
            <Menu.Item key={`action-${idx}`}>
              {({ active }: any) => (
                <a
                  onClick={action.onClick}
                  className={joinClasses(
                    active ? "bg-gray-200" : "",
                    "block rounded px-4 py-2 text-sm text-gray-700  no-underline"
                  )}
                >
                  {action.label}
                </a>
              )}
            </Menu.Item>
          ))}
        </Dropdown>
      </Col>
    </Row>
  );
}
