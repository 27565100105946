import { createContext, useContext, useState } from "react";

export enum ModalTypes {
  PROJECT = "project",
  CLIENT = "client",
  DEPARTMENT = "department",
  BATCH = "batch",
  CLASSROOM = "classroom",
  SESSION = "session",
  STUDENT = "student",
  STAFF_MEMBER = "staffMember",
  SECTION = "section",
  ASSOCIATE_STUDENTS = "associate_students",
  ASSOCIATE_STAFF_MEMBERS = "associate_staff_members",
  SLOTS_EDITOR = "slots_editor",
  ATTENDANCE_SHIFT = "attendance_shift",
  CUSTOM_ROLE = "custom_role",
  UPDATE_CALENDAR_DAY = "update_calendar_day",
  ASSIGN_STUDENT = "assign_student",
  ADD_UPDATE_FOLDER = "add_update_folder",
  ADD_FILE = "add_file",
  ADD_FEE_STRUCTURE = "add_fee_structure",
  ADD_UPDATE_FEE_PAYMENT = "add_update_fee_payment",
  BULK_IMPORT = "bulk_import",
  ADD_UPDATE_CHATROOM = "add_update_chatroom",
  ADD_UPDATE_HOMEWORK = "add_update_homework",
  ADD_QUESTION_ATTACHMENT = "add_question_attachment",
  ADD_UDPATE_COURSE = "add_update_course",
  ADD_UPDATE_COURSE_SUBJECT = "add_update_course_subject",
  ADD_UPDATE_SUBJECT_TOPIC = "add_update_subject_topic",
  ADD_UPDATE_QUESTION = "add_update_question",
  VIEW_ATTACHMENT = "view_attachment",
  ADD_NEW_QUESTIONS = "add_new_questions",
  EXIT_STUDENTS = "exit_students",
}

const modalContext = createContext<ModalContextProps>({} as any);

export function useModal() {
  return useContext(modalContext);
}

export function ModalProvider({ children }: any) {
  return (
    <modalContext.Provider value={useModalProvider()}>
      {children}
    </modalContext.Provider>
  );
}

type modalBodyProps = {className?: string};

function useModalProvider() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalHeading, setModalHeading] = useState<string>("");
  const [modalType, setModalType] = useState<ModalTypes>(ModalTypes.PROJECT);
  const [modalSize, setModalSize] = useState<"sm" | "lg" | "xl">("lg");
  const [componentProps, setComponentProps] = useState<any>({});
  const [modalBodyProps, setModalBodyProps] = useState<modalBodyProps>({});

  return {
    modalType,
    setModalType,
    showModal,
    setShowModal,
    modalHeading,
    setModalHeading,
    modalSize,
    setModalSize,
    componentProps,
    setComponentProps,
    modalBodyProps,
    setModalBodyProps,
  } as ModalContextProps;
}

interface ModalContextProps {
  modalType: ModalTypes;
  setModalType: (v: any) => void;
  showModal: boolean;
  setShowModal: (v: any) => void;
  modalHeading: string;
  setModalHeading: (v: any) => void;
  modalSize: "sm" | "lg" | "xl";
  setModalSize: (v: "sm" | "lg" | "xl") => void;
  componentProps: any;
  setComponentProps: (v: any) => void;
  modalBodyProps: modalBodyProps;
  setModalBodyProps: (v: modalBodyProps) => void;
}
