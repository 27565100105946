import { PencilIcon, PlusIcon } from "@heroicons/react/20/solid";
import {
  IHomework,
  IStudentHomework,
  Permissions,
  SupportedFilterAttributes,
  SupportedFilterOperators,
  UserRole,
} from "crm_core";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "src/components/buttons/button";
import CustomDataTable, {
  CustomDataTableProps,
  CustomTableColumn,
} from "src/components/custom_data_table";
import ClickableCell from "src/components/widgets/clickable_cell";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import { HomeworkService } from "src/services/institute/lms/homework";
import { FilterKeys } from "src/utils/constants";
import moment from "moment";
import { useAuth } from "src/context/auth_context";
import { conditionalCol } from "src/utils/functions";
import { HomeworkStatusBadge } from "src/components/widgets/badges";
import { useTable } from "src/context/table_context";
import { PaginatedGetter } from "src/services/paginated_getter";
import { getFilterSpec } from "../filters/utils";
import Filters from "../filters";
import { useDataProvider } from "src/context/data_provider";
import CustomCard, { CustomCardProps } from "src/components/custom_card";

interface HomeworksProps {
  classroom?: string;
  filterKey?: FilterKeys;
  tableOnly?: boolean;
  cardProps?: Partial<CustomCardProps>;
  tableProps?: Partial<CustomDataTableProps<IHomework>>;
}

export default function Homeworks({
  classroom,
  filterKey,
  tableOnly,
  cardProps = {},
  tableProps = {},
}: HomeworksProps) {
  const FILTER_KEY = filterKey || FilterKeys.HOMEWORK;
  const paginatedGetter = new PaginatedGetter<HomeworkService, IHomework>(
    HomeworkService
  );
  const { selectedSession } = useDataProvider();
  const [fetchedConfig, setFetchedConfig] = useState<string>("");
  const { selectedAuthorization } = useAuth();
  const [homeworks, setHomeworks] = useState<IHomework[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    getPaginationPreferences,
    getSortPreferences,
    getFilterPreferences,
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    updateTotalRows,
    applyFilter,
  } = useTable();

  useEffect(() => {
    applyFilter(
      FILTER_KEY,
      getFilterSpec(
        SupportedFilterAttributes.CLASSROOM,
        SupportedFilterOperators.EQUALS,
        classroom
      )
    );
  }, [classroom]);

  const fetchData = async (force = false) => {
    setLoading(true);
    let config = {
      pagination: getPaginationPreferences(FILTER_KEY),
      sort: getSortPreferences(FILTER_KEY, "end", "asc"),
      filters: getFilterPreferences(FILTER_KEY),
    };
    const derivedFilters = !!classroom;
    if (derivedFilters && !(config.filters.length > 0)) {
      return;
    }
    if (JSON.stringify(config) === fetchedConfig && !force) {
      return;
    }
    const data = await paginatedGetter.get(config, { extended: true });
    setHomeworks(data.results);
    updateTotalRows(FILTER_KEY, data.totalCount);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedSession) {
      fetchData();
    }
  }, [
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    selectedSession,
  ]);

  const columns: CustomTableColumn<IHomework>[] = [
    ...conditionalCol<IHomework>(
      selectedAuthorization.role == UserRole.Student,
      {
        name: "Status",
        cell: (row) => (
          <HomeworkStatusBadge status={(row as IStudentHomework).status} />
        ),
        width: "90px",
      }
    ),
    {
      name: "Name",
      sortable: true,
      cell: (row) => (
        <ClickableCell
          href={
            selectedAuthorization.role == UserRole.Student
              ? `/c/homeworks/${row._id}`
              : `/c/tests/${row._id}/view`
          }
        >
          {row.name}
        </ClickableCell>
      ),
      width: "300px",
      sortField: "name",
    },
    {
      name: "Due by",
      sortable: true,
      cell: (row) => (
        <div>{moment(row.due_date).format("DD MMM YYYY hh:mma")}</div>
      ),
      width: "200px",
      sortField: "end",
    },

    ...conditionalCol<IHomework>(
      selectedAuthorization.role != UserRole.Student,
      {
        colId: 5,
        reorder: true,
        name: "Actions",
        cell: (row) => (
          <Row>
            <Col className="col-auto">
              <Button
                label={"Edit"}
                icon={<PencilIcon className="h-4 w-4 mr-2" />}
                isSlim
                className="py-1 px-2 text-sm"
                color="accent"
                href={`/c/homeworks/add-update/${classroom}/${row._id}`}
              />
            </Col>
          </Row>
        ),
      }
    ),
  ];

  const getTableView = () => {
    return (
      <div>
        <PermissionRestrictedComponent
          permissions={[
            Permissions.VIEW_CLASSROOMS,
            Permissions.VIEW_ASSIGNED_CLASSROOMS,
          ]}
        >
          <Row className="m-0 mb-4">
            <Col>
              <Button
                label={"Add Homework"}
                isSlim
                className="px-2 py-1 text-sm"
                icon={<PlusIcon className="h-4 w-4 mr-2" />}
                color="accent"
                href={`/c/homeworks/add-update/${classroom}`}
              />
            </Col>
          </Row>
        </PermissionRestrictedComponent>
        <Filters filterKey={FILTER_KEY} />
        <Row className="m-0">
          <CustomDataTable
            tableKey={FILTER_KEY}
            columns={columns}
            data={homeworks}
            progressPending={loading}
            noDataComponent="No homeworks have been created yet."
            selectableRows={false}
            {...tableProps}
          />
        </Row>
      </div>
    );
  };

  if (tableOnly) {
    return getTableView();
  }

  return (
    <CustomCard title="Homework" {...cardProps}>
      {getTableView()}
    </CustomCard>
  );
}
