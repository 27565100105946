import { useEffect, useState } from "react";
import { createEditor, Descendant } from "slate";
import { withReact } from "slate-react";
import TextField from "../form_generator/text_field";
import { SlateMarkdownEditor } from "./slate_editor";
import { TiptapEditor } from "./tiptap_editor";

interface MarkDownEditorProps {
  label?: string;
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  className?: string;
  viewer?: boolean;
}

export default function MarkDownEditor({
  value,
  onChange = () => {},
  disabled,
  className,
  viewer = false,
  label,
}: MarkDownEditorProps) {
  return (
    <TiptapEditor
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={className}
      viewer={viewer}
      label={label}
    />
  );
  return (
    <SlateMarkdownEditor
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={className}
      viewer={viewer}
      label={label}
    />
  );

  const [editor] = useState(() => withReact(createEditor()));
  const initialValue: Descendant[] = [
    {
      type: "paragraph",
      children: [{ text: "A line of text in a paragraph." }],
    },
  ];
  const [markdown, setMarkdown] = useState<string>(value);

  useEffect(() => {
    if (markdown != value) {
      onChange(markdown);
    }
  }, [markdown]);

  useEffect(() => {
    if (value != markdown) {
      setMarkdown(value);
    }
  }, [value]);

  // const editor: BlockNoteEditor | null = useBlockNote({
  //   onEditorContentChange: (editor: BlockNoteEditor) => {
  //     console.log(editor.topLevelBlocks.length);
  //     const saveBlocksAsMarkdown = async () => {
  //       const markdown: string = await editor.blocksToMarkdown(
  //         editor.topLevelBlocks
  //       );
  //       setMarkdown(markdown);
  //     };
  //     saveBlocksAsMarkdown();
  //   },
  //   onEditorReady(editor) {
  //     const getBlocks = async () => {
  //       const blocks: Block<DefaultBlockSchema>[] =
  //         await editor.markdownToBlocks(markdown);
  //       editor.replaceBlocks(editor.topLevelBlocks, blocks);
  //     };
  //     getBlocks();
  //   },
  //   editable: true,
  // });

  if (viewer) {
    return (
      <div className="ml-1" dangerouslySetInnerHTML={{ __html: markdown }} />
    );
  }

  return (
    <TextField
      className={className}
      disabled={disabled}
      value={markdown}
      onChange={setMarkdown}
    />
  );

  return (
    <div className="ring-1 ring-accent rounded-md">
      {/* <BlockNoteView editor={editor} /> */}
      {/* <Slate editor={editor} initialValue={initialValue}>
        <Editable />
      </Slate> */}
      <TextField value={markdown} onChange={setMarkdown} />
    </div>
  );
}
