import API from "../../api";
import { IImportRecord, IImportRecordExtended } from "crm_core";

export class ImportRecordService extends API {
  constructor() {
    super("/import-record");
  }

  async getImportRecords(
    params: {
      [x: string]: string;
    } = {}
  ): Promise<IImportRecordExtended[]> {
    const res = await this._get({ params });
    return res.data;
  }

  async getImportRecord(
    id: string,
    params: {
      [x: string]: string;
    } = {}
  ): Promise<IImportRecord | IImportRecordExtended> {
    const res = await this._get({ url: `/${id}`, params });
    return res.data;
  }

  async createImportRecord(
    importRecord: IImportRecord
  ): Promise<IImportRecord> {
    const res = await this._post({ body: importRecord });
    return res.data as IImportRecord;
  }
}
