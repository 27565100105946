import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import LabelledField from "./labelled_field";
import moment from "moment";

interface TimeRangePickerProps {
  start: string;
  end: string;
  onChange: (start: string, end: string) => void;
  required?: boolean;
  startLabel?: string;
  endLabel?: string;
}

export default function TimeRangePicker(props: TimeRangePickerProps) {
  const FORMAT = "hh:mm a";
  const [start, setStart] = useState(
    props.start ? moment(props.start, FORMAT).toDate() : undefined
  );
  const [end, setEnd] = useState(
    props.end ? moment(props.end, FORMAT).toDate() : undefined
  );

  useEffect(() => {
    let formattedStart = props.start
      ? moment(props.start, FORMAT).toDate()
      : undefined;
    let formattedEnd = props.end
      ? moment(props.end, FORMAT).toDate()
      : undefined;
    if (formattedStart !== start || formattedEnd !== end) {
      setStart(formattedStart);
      setEnd(formattedEnd);
    }
  }, [props.start, props.end]);

  useEffect(() => {
    if (start || end) {
      props.onChange(
        start ? moment(start).format(FORMAT) : "",
        end ? moment(end).format(FORMAT) : ""
      );
    }
  }, [start, end]);

  return (
    <Row className="m-0 p-0">
      <Col className="pl-0">
        <CustomDatePicker
          selected={start}
          onChange={(date: any) => (date ? setStart(date) : null)}
          selectsStart
          startDate={start}
          endDate={end}
          dateFormat="hh:mm aa"
          label={props.startLabel || "From"}
          required={props.required}
        />
      </Col>
      <Col className="pr-0">
        <CustomDatePicker
          selected={end}
          onChange={(date: any) => (date ? setEnd(date) : null)}
          selectsEnd
          startDate={start}
          endDate={end}
          minDate={start}
          dateFormat="hh:mm aa"
          label={props.endLabel || "To"}
          required={false}
        />
      </Col>
    </Row>
  );
}

export function CustomDatePicker(props: any) {
  return (
    <LabelledField label={props.label} required={props.required}>
      <DatePicker
        className="w-full p-2 text-sm ring-0.5 ring-custom-grey bg-custom-grey rounded-md  outline-none focus:outline-none  focus:ring-1 focus:ring-dark-accent"
        {...props}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
      />
    </LabelledField>
  );
}
