import {
  IDateAttendanceData,
  IStaffAttendance,
  IStaffMemberAggregatedAttendance,
  IStudentAggregatedAttendance,
  ITimeAttendanceData,
} from "crm_core";
import API from "../../api";

export class PeopleAttendanceService extends API {
  constructor() {
    super("/people-attendance");
  }

  async getStaffAttendance(date: string): Promise<IStaffAttendance[]> {
    const res = await this._get({ url: `/staff/${date}` });
    return res.data as IStaffAttendance[];
  }

  async getClassroomStudentAttendance(
    classroomId: string,
    date: string
  ): Promise<ITimeAttendanceData[]> {
    const res = await this._get({ url: `/student/${classroomId}/${date}` });
    return res.data as ITimeAttendanceData[];
  }

  async getStaffAttendanceForDateRange(
    fromDate: string,
    toDate: string,
    staffId?: string,
  ): Promise<IStaffMemberAggregatedAttendance[]> {
    let url = `/staff/aggregate/${fromDate}/${toDate}`;
    if(staffId) url += `/${staffId}`;
    const res = await this._get({ url });
    return res.data;
  }

  async getStudentAttendanceForDateRange(
    fromDate: string,
    toDate: string,
    classroomId: string = "",
    studentId: string = ""
  ): Promise<IStudentAggregatedAttendance[]> {
    let url = `/student/aggregate/${fromDate}/${toDate}`;
    if (classroomId) url += `/${classroomId}`;
    if (studentId) url += `/${studentId}`;
    const res = await this._get({ url });
    return res.data;
  }

  async getStaffAttendanceSummary(
    fromDate: string,
    toDate: string,
    staffId?: string
  ): Promise<IDateAttendanceData[]> {
    let url = `/staff/summary/${fromDate}/${toDate}`;
    if (staffId) url += `/${staffId}`;
    const res = await this._get({
      url,
    });
    return res.data;
  }

  async getStudentAttendanceSummary(
    fromDate: string,
    toDate: string,
    studentId?: string
  ): Promise<IDateAttendanceData[]> {
    let url = `/student/summary/${fromDate}/${toDate}`;
    if (studentId) url += `/${studentId}`;
    const res = await this._get({
      url,
    });
    return res.data;
  }

  async updateStaffAttendance(
    date: string,
    data: IStaffAttendance[]
  ): Promise<void> {
    await this._post({ url: `/staff/${date}`, body: data });
  }

  async updateClassroomStudentAttendance(
    classroomId: string,
    date: string,
    data: ITimeAttendanceData[]
  ): Promise<void> {
    await this._post({ url: `/student/${classroomId}/${date}`, body: data });
  }

  async getStudentAttendanceMarkingStatus(
    fromDate: string,
    toDate: string,
    classroomId?: string
  ): Promise<IDateAttendanceData[]> {
    const res = await this._get({
      url: `/student/${classroomId}/${fromDate}/${toDate}`,
    });
    return res.data;
  }
}
