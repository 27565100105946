import { DEFAULT_DATE_FORMAT, ISession, Permissions } from "crm_core";
import { useEffect, useState } from "react";
import LoadingButton from "src/components/buttons/loading_button";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useNotification } from "src/context/notification_context";
import { SessionService } from "src/services/institute/session";
import { FormFieldType } from "src/utils/constants";
import {
  catchRequest,
  isPermissible,
  validateFields,
} from "src/utils/functions";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import moment from "moment";
import ConfirmableButton from "src/components/buttons/confirmable_button";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";

export interface AddUpdateSessionProps {
  onComplete?: () => void;
  id?: string;
}

export default function AddUpdateSession({
  onComplete,
  id,
}: AddUpdateSessionProps) {
  const canUpdate = isPermissible(Permissions.UPDATE_SESSION);
  const service = new SessionService();
  const [sessionName, setSessionName] = useState<string>(
    `${moment().format("YYYY")} - ${moment().add(1, "year").format("YYYY")}`
  );
  const [description, setDescription] = useState<string>();
  const [active, setActive] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<string>(
    moment().startOf("year").format(DEFAULT_DATE_FORMAT)
  );
  const [endDate, setEndDate] = useState<string>(
    moment().endOf("year").format(DEFAULT_DATE_FORMAT)
  );

  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();
  const { fetchDataForUseCase, selectedSession } = useDataProvider();

  useEffect(() => {
    if (selectedSession) fetchDataForUseCase(DataUseCase.INSTITUTE);
  }, [selectedSession]);

  useEffect(() => {
    const fetchSession = async (id: string) => {
      setLoading(true);
      const session = await service.getSession(id);
      setSessionName(session.name);
      setDescription(session.description);
      setActive(session.is_active);
      setStartDate(session.start_date);
      setEndDate(session.end_date);
      setLoading(false);
    };
    if (id) {
      fetchSession(id);
    }
  }, [id]);

  const handleArchive = async () => {
    await catchRequest(async () => {
      await service.deleteSession(id as string);
      onComplete?.();
    }, pushNotification)();
  };

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(getFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);
    let session: ISession = {
      name: sessionName as any,
      description: description as any,
      start_date: startDate,
      end_date: endDate,
      is_active: active,
    };
    await catchRequest(async () => {
      if (id) {
        await service.updateSession({
          _id: id,
          ...session,
        });
      } else {
        await service.createSession(session);
      }
      onComplete?.();
    }, pushNotification)();
    setLoading(false);
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.TEXT,
          value: sessionName,
          onChange: setSessionName,
          extras: {
            placeholder: "Name",
            label: "Name",
            disabled: !canUpdate,
          },
          required: true,
          updatable: true,
        },
      ],
      [
        {
          type: FormFieldType.DATERANGE,
          value: { description },
          extras: {
            placeholder: "Description",
            label: "Description",
            startDate: startDate,
            endDate: endDate,
            onChange: (startDate: string, endDate: string) => {
              setStartDate(startDate);
              setEndDate(endDate);
            },
            disabled: !canUpdate,
          },
          required: true,
          updatable: true,
        },
      ],
    ];

    if (id) {
      return allFields
        .map((fields) => fields.filter((f) => f.updatable))
        .filter((f) => f.length > 0);
    }
    return allFields;
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      <div className="d-flex justify-content-end mt-4">
        <div hidden={!id}>
          <PermissionRestrictedComponent
            permissions={[Permissions.ARCHIVE_SESSION]}
          >
            <ConfirmableButton
              dialogTitle="Archive Session"
              dialogContent="Are you sure you want to archive this session?"
              color="red"
              loading={loading}
              disabled={loading}
              label="Archive"
              contentClass="font-light"
              rounded
              className="px-4 mr-2"
              onClick={handleArchive}
            />
          </PermissionRestrictedComponent>
        </div>
        <PermissionRestrictedComponent
          permissions={[Permissions.UPDATE_SESSION]}
        >
          <LoadingButton
            color="accent"
            loading={loading}
            disabled={loading}
            label="Save"
            contentClass="font-light"
            rounded
            className="px-4"
            onClick={handleSubmit}
          />
        </PermissionRestrictedComponent>
      </div>
    </div>
  );
}
