import { EditorProvider } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useState } from "react";
import { TiptapToolbar } from "./toolbar";
import Mathematics from "@tiptap-pro/extension-mathematics";
import { Col, Row } from "react-bootstrap";
import Button from "src/components/buttons/button";
import SettingsIcon from "@mui/icons-material/Settings";
import LabelledField from "src/components/form_generator/labelled_field";

interface TiptapEditorProps {
  label?: string;
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  className?: string;
  viewer?: boolean;
}

// define your extension array
const extensions = [StarterKit, Mathematics];

export function TiptapEditor({
  label,
  value,
  onChange,
  disabled,
  className,
  viewer,
}: TiptapEditorProps) {
  const [val, setVal] = useState<string>();
  const [showToolbar, setShowToolbar] = useState(false);

  useEffect(() => {
    if (value && value != val) {
      setVal(value);
    }
  }, [value]);

  useEffect(() => {
    if (val && val != value && !disabled && !viewer) {
      onChange?.(val);
    }
  }, [val]);

  if (viewer) {
    return (
      <EditorProvider
        slotBefore={<TiptapToolbar value={val} show={false} />}
        extensions={extensions}
        content={val}
        editable={false}
        editorProps={{
          attributes: {
            class: className ?? "",
          },
        }}
      >
        <span></span>
      </EditorProvider>
    );
  }

  return (
    <LabelledField label={label} className={className}>
      <Row className="m-0 align-items-center">
        <Col className="p-0">
          <EditorProvider
            slotBefore={<TiptapToolbar value={val} show={showToolbar} />}
            extensions={extensions}
            content={val}
            onUpdate={({ editor }) => {
              setVal(editor.getHTML());
            }}
            editorProps={{
              attributes: {
                class:
                  "bg-custom-grey px-2 py-2 rounded-md appearance-none leading-tight focus:ring-1 focus:ring-dark-accent focus:outline-none",
              },
            }}
          >
            <span></span>
          </EditorProvider>
        </Col>
        <Col className="col-auto pr-0">
          <Button
            rounded
            color="transparent"
            className="h-6 w-6"
            icon={<SettingsIcon className="h-5 w-5" />}
            onClick={() => setShowToolbar(!showToolbar)}
          />
        </Col>
      </Row>
    </LabelledField>
  );
}
