import { IMedicalRecord } from "crm_core";
import { Dispatch, SetStateAction } from "react";
import FormGenerator, { FormField } from "src/components/form_generator";
import { FormFieldType } from "src/utils/constants";

interface MedicalRecordEditorProps {
  medicalRecord: IMedicalRecord;
  onChange: Dispatch<SetStateAction<IMedicalRecord>>;
  canEdit?: boolean;
}

export default function MedicalRecordEditor({
  medicalRecord,
  onChange,
  canEdit = true,
}: MedicalRecordEditorProps) {
  const getFields = (index: number) => {
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.DATE,
          value: medicalRecord.date,
          onChange: (v) => onChange((p) => ({ ...p, date: v })),
          extras: {
            label: "Date",
          },
        },
        {
          type: FormFieldType.NUMBER,
          value: medicalRecord.weight,
          onChange: (v) => onChange((p) => ({ ...p, weight: v })),
          extras: {
            label: "Weight (Kg)",
          },
        },
        {
          type: FormFieldType.NUMBER,
          value: medicalRecord.height,
          onChange: (v) => onChange((p) => ({ ...p, height: v })),
          extras: {
            label: "Height (cm)",
          },
        },
      ],
      [
        {
          type: FormFieldType.NUMBER,
          value: medicalRecord.bmi,
          onChange: (v) => onChange((p) => ({ ...p, bmi: v })),
          extras: {
            label: "BMI (Kg/m2)",
          },
        },
        {
          type: FormFieldType.NUMBER,
          value: medicalRecord.pulse_rate,
          onChange: (v) =>
            onChange((p) => ({
              ...p,
              pulse_rate: v,
            })),
          extras: {
            label: "Pulse Rate (/min)",
          },
        },
        {
          type: FormFieldType.NUMBER,
          value: medicalRecord.haemoglobin,
          onChange: (v) =>
            onChange((p) => ({
              ...p,
              haemoglobin: v,
            })),
          extras: {
            label: "Haemoglobin (g/dl)",
          },
        },
      ],
      [
        {
          type: FormFieldType.TEXTAREA,
          value: medicalRecord.remarks,
          onChange: (v) => onChange((p) => ({ ...p, remarks: v })),
          extras: {
            label: "Remarks",
          },
        },
      ],
    ];
    if (!canEdit) {
      allFields = allFields.map((row) =>
        row.map((field) => ({ ...field, extras: { ...field.extras, disabled: true } }))
      );
    }
    return allFields;
  };

  return <FormGenerator getFields={getFields} />;
}
