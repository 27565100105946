import {
  IStaffMember,
  Genders,
  BloodGroups,
  IAddress,
  Religion,
  Category,
  Permissions,
} from "crm_core";
import { useEffect, useState } from "react";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useNotification } from "src/context/notification_context";
import { StaffMemberService } from "src/services/institute/staff_member";
import { FormFieldType } from "src/utils/constants";
import {
  catchRequest,
  isPermissible,
  validateFields,
} from "src/utils/functions";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import { Col, Row } from "react-bootstrap";
import ArchiveButton from "src/components/widgets/archive_button";
import SaveButton from "src/components/widgets/save_button";
import { useNavigate } from "react-router-dom";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";

export interface AddUpdateStaffMemberProps {
  onComplete?: () => void;
  id?: string;
  teacher?: IStaffMember;
}

export default function AddUpdateStaffMember({
  onComplete,
  id,
  teacher,
}: AddUpdateStaffMemberProps) {
  const canAssignRoles = isPermissible(Permissions.ASSIGN_ROLE);
  const service = new StaffMemberService();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState<string>();
  const [middleName, setMiddleName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [mobile, setMobile] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [dob, setDob] = useState<string>();
  const [gender, setGender] = useState<Genders>();
  const [bloodGroup, setBloodGroup] = useState<BloodGroups>();
  const [currentAddress, setCurrentAddress] = useState<IAddress>();
  const [permanentAddress, setPermanentAddress] = useState<IAddress>();
  const [employeeId, setEmployeeId] = useState<string>();
  const [permanentAddressSameAsCurrent, setPermanentAddressSameAsCurrent] =
    useState<boolean>();

  const [religion, setReligion] = useState<Religion>();
  const [category, setCategory] = useState<Category>();
  const [sendInvite, setSendInvite] = useState<boolean>(false);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();
  const { fetchDataForUseCase, selectedSession, roles } = useDataProvider();

  useEffect(() => {
    if (selectedSession) {
      fetchDataForUseCase(DataUseCase.INSTITUTE);
      fetchDataForUseCase(DataUseCase.ROLES);
    }
  }, [selectedSession]);

  useEffect(() => {
    const fetchStaffMember = async (id: string) => {
      setLoading(true);
      const currentStaffMember = teacher || (await service.getStaffMember(id));
      setFirstName(currentStaffMember.first_name);
      setMiddleName(currentStaffMember.middle_name);
      setLastName(currentStaffMember.last_name);
      setMobile(currentStaffMember.mobile);
      setEmail(currentStaffMember.email);
      setEmployeeId(currentStaffMember.employee_id);
      setDob(currentStaffMember.dob);
      setCategory(currentStaffMember.category);
      setReligion(currentStaffMember.religion);
      setCurrentAddress(currentStaffMember.current_address);
      setPermanentAddress(currentStaffMember.permanent_address);
      setPermanentAddressSameAsCurrent(
        currentStaffMember.current_address ===
          currentStaffMember.permanent_address
      );
      setGender(currentStaffMember.gender);
      setBloodGroup(currentStaffMember.blood_group);
      setSelectedRoles(currentStaffMember.roles as string[]);
      setLoading(false);
    };
    if (id) {
      fetchStaffMember(id);
    }
  }, [id]);

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(getFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);
    let update: IStaffMember = {
      first_name: firstName as any,
      middle_name: middleName,
      last_name: lastName,
      mobile,
      employee_id: employeeId,
      email: email as any,
      dob,
      gender,
      blood_group: bloodGroup,
      current_address: currentAddress,
      permanent_address: permanentAddressSameAsCurrent
        ? currentAddress
        : permanentAddress,
      religion,
      category,
      roles: canAssignRoles ? selectedRoles : undefined,
    };

    await catchRequest(async () => {
      if (id) {
        await service.updateStaffMember({
          _id: id,
          ...update,
        });
        pushNotification({
          title: "Success",
          message: "Staff member updated successfully",
          type: "success",
        });
      } else {
        await service.createStaffMember({
          ...update,
          send_invite: sendInvite,
        });
      }
    }, pushNotification)();
    setLoading(false);
    onComplete?.();
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    const updating = !!id || !!teacher;
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.TEXT,
          value: firstName,
          onChange: setFirstName,
          extras: {
            placeholder: "First Name",
            label: "First Name",
          },
          required: true,
        },
        {
          type: FormFieldType.TEXT,
          value: middleName,
          onChange: setMiddleName,
          extras: {
            placeholder: "Middle Name",
            label: "Middle Name",
          },
        },
        {
          type: FormFieldType.TEXT,
          value: lastName,
          onChange: setLastName,
          extras: {
            placeholder: "Last Name",
            label: "Last Name",
          },
          required: true,
        },
      ],
      [
        {
          type: FormFieldType.TEXT,
          value: employeeId,
          onChange: setEmployeeId,
          extras: {
            placeholder: "Employee ID",
            label: "Employee ID",
            disabled: updating,
          },
          required: true,
        },
        {
          type: FormFieldType.TEXT,
          value: email,
          onChange: setEmail,
          extras: {
            placeholder: "Email",
            label: "Email",
            disabled: updating,
          },
          required: true,
        },
        {
          type: FormFieldType.TEXT,
          value: mobile,
          onChange: setMobile,
          extras: {
            placeholder: "Mobile",
            label: "Mobile",
          },
        },
      ],
      [
        {
          type: FormFieldType.DATE,
          value: dob,
          onChange: setDob,
          extras: {
            placeholder: "Date of Birth",
            label: "Date of Birth",
          },
        },
        {
          type: FormFieldType.SELECT,
          value: gender,
          onChange: setGender,
          extras: {
            label: "Gender",
            options: Object.values(Genders).map((b) => ({
              label: b,
              value: b,
            })),
            searchable: true,
          },
        },
        {
          type: FormFieldType.SELECT,
          value: bloodGroup,
          onChange: setBloodGroup,
          extras: {
            label: "Blood Group",
            options: Object.values(BloodGroups).map((b) => ({
              label: b,
              value: b,
            })),
            searchable: true,
          },
        },
      ],
      [
        {
          type: FormFieldType.SELECT,
          value: selectedRoles,
          onChange: setSelectedRoles,
          extras: {
            multi: true,
            label: "Roles",
            options: roles.map((b) => ({
              label: b.name,
              value: b._id,
            })),
            searchable: true,
            disabled: canAssignRoles ? false : true,
          },
          required: true,
          updatable: true,
        },
      ],
      updating
        ? []
        : [
            {
              type: FormFieldType.TOGGLE,
              value: sendInvite,
              onChange: setSendInvite,
              extras: {
                label: "Send Invitation Email",
              },
              required: true,
            },
          ],
      [
        // {
        //   type: FormFieldType.TOGGLE,
        //   value: permanentAddressSameAsCurrent,
        //   onChange: setPermanentAddressSameAsCurrent,
        // },
      ],
    ];

    if (!updating) {
      return allFields
        .map((fields) => fields.filter((f) => f.required))
        .filter((f) => f.length > 0);
    }
    return allFields;
  };

  const handleDeleteStaffMember = async () => {
    await catchRequest(async () => {
      await service.deleteStaffMember(id as string);
      pushNotification({
        title: "Success",
        message: "Student archived successfully",
        type: "success",
      });
      navigate("/c/students");
    }, pushNotification)();
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      <Row className="justify-content-end mt-4 align-items-center">
        <Col hidden={!id} md="auto" sm="auto" xs="auto" className="p-0">
          <PermissionRestrictedComponent
            permissions={[Permissions.ARCHIVE_STAFF]}
          >
            <ArchiveButton
              dialogTitle="Archive Staff Member?"
              dialogContent="Are you sure you want to archive this member?"
              label="Archive Staff Member"
              onClick={() => handleDeleteStaffMember()}
            />
          </PermissionRestrictedComponent>
        </Col>
        <Col md="auto" sm="auto" xs="auto" className="">
          <PermissionRestrictedComponent
            permissions={[
              Permissions.UPDATE_STAFF_DETAILS,
              Permissions.CREATE_STAFF,
            ]}
          >
            <SaveButton
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            />
          </PermissionRestrictedComponent>
        </Col>
      </Row>
    </div>
  );
}
