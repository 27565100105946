import { Form } from "react-bootstrap";
import { joinClasses } from "src/utils/functions";
import { useTheme } from "src/context/theme_context";

export interface LabelledFieldProps {
  label?: string;
  children?: any;
  className?: string;
  required?: boolean;
}

export default function LabelledField({
  label,
  children,
  ...props
}: LabelledFieldProps) {
  const { getThemeBasedValue } = useTheme();

  return (
    <Form.Group
      style={{ color: getThemeBasedValue("black", "white") }}
      className={joinClasses("m-0 p-0 w-full", props.className ?? "")}
    >
      {label && (
        <Form.Label
          style={{ color: getThemeBasedValue("gray", "white") }}
          className="mt-0 mr-0 p-0 mb-1 ml-1 text-xs"
        >
          {label} {props.required && <span className="text-red-500">*</span>}
        </Form.Label>
      )}
      {children}
    </Form.Group>
  );
}
