import { useCurrentEditor } from "@tiptap/react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import LooksThreeIcon from "@mui/icons-material/Looks3";
import FunctionsIcon from "@mui/icons-material/Functions";
import MathInputModal from "./math_input";

export function TiptapToolbar({
  value,
  show,
}: {
  value?: string;
  show?: boolean;
}) {
  const { editor } = useCurrentEditor();
  const [showMathModal, setShowMathModal] = useState(false);

  useEffect(() => {
    if (editor && value && editor.getHTML() != value) {
      editor?.commands.setContent(value);
    }
  }, [value]);

  if (!editor || !show) {
    return null;
  }

  const actions = [
    {
      onClick: () => setShowMathModal(true),
      className: editor.isActive("mathematics") ? "is-active" : "",
      label: (
        <FunctionsIcon
          className={editor.isActive("mathematics") ? "" : "opacity-40"}
        />
      ),
    },
    {
      onClick: () => editor.chain().focus().toggleBold().run(),
      disabled: !editor.can().chain().focus().toggleBold().run(),
      className: editor.isActive("bold") ? "is-active" : "",
      label: (
        <FormatBoldIcon
          className={editor.isActive("bold") ? "" : "opacity-40"}
        />
      ),
    },
    {
      onClick: () => editor.chain().focus().toggleItalic().run(),
      disabled: !editor.can().chain().focus().toggleItalic().run(),
      className: editor.isActive("italic") ? "is-active" : "",
      label: (
        <FormatItalicIcon
          className={editor.isActive("italic") ? "" : "opacity-40"}
        />
      ),
    },
    {
      onClick: () => editor.chain().focus().toggleStrike().run(),
      disabled: !editor.can().chain().focus().toggleStrike().run(),
      className: editor.isActive("strike") ? "is-active" : "",
      label: (
        <StrikethroughSIcon
          className={editor.isActive("strike") ? "" : "opacity-40"}
        />
      ),
    },
    {
      onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      disabled: !editor.can().chain().focus().toggleHeading({ level: 1 }).run(),
      className: editor.isActive("heading", { level: 1 }) ? "is-active" : "",
      label: (
        <LooksOneIcon
          className={
            editor.isActive("heading", { level: 1 }) ? "" : "opacity-40"
          }
        />
      ),
    },
    {
      onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      disabled: !editor.can().chain().focus().toggleHeading({ level: 2 }).run(),
      className: editor.isActive("heading", { level: 2 }) ? "is-active" : "",
      label: (
        <LooksTwoIcon
          className={
            editor.isActive("heading", { level: 2 }) ? "" : "opacity-40"
          }
        />
      ),
    },
    {
      onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
      disabled: !editor.can().chain().focus().toggleHeading({ level: 3 }).run(),
      className: editor.isActive("heading", { level: 3 }) ? "is-active" : "",
      label: (
        <LooksThreeIcon
          className={
            editor.isActive("heading", { level: 3 }) ? "" : "opacity-40"
          }
        />
      ),
    },
    // {
    //   onClick: () => editor.chain().focus().toggleBulletList().run(),
    //   disabled: !editor.can().chain().focus().toggleBulletList().run(),
    //   className: editor.isActive("bulletList") ? "is-active" : "",
    //   label: "bullet list",
    // },
    // {
    //   onClick: () => editor.chain().focus().toggleOrderedList().run(),
    //   disabled: !editor.can().chain().focus().toggleOrderedList().run(),
    //   className: editor.isActive("orderedList") ? "is-active" : "",
    //   label: "ordered list",
    // },
    // {
    //   onClick: () => editor.chain().focus().undo().run(),
    //   disabled: !editor.can().chain().focus().undo().run(),
    //   label: "undo",
    // },
    // {
    //   onClick: () => editor.chain().focus().redo().run(),
    //   disabled: !editor.can().chain().focus().redo().run(),
    //   label: "redo",
    // },
  ];

  return (
    <Row className="ml-1">
      <MathInputModal
        show={showMathModal}
        setShow={setShowMathModal}
        onComplete={async (v) => {
          editor?.chain().focus().insertContent(` $${v}$.`).run();
          setShowMathModal(false);
        }}
      />
      {actions.map((action, idx) => {
        return (
          <Col key={`action-${idx}`} className="col-auto pl-0 pr-1">
            <button
              onClick={action.onClick}
              disabled={action.disabled}
              className={action.className}
            >
              {action.label}
            </button>
          </Col>
        );
      })}
    </Row>
  );
}
