import { IExportConfig, JSONHandler } from "crm_core";
import XLSX from "sheetjs-style";

export class DataExportHandler<T> {
  config: IExportConfig<T>;
  data: T[];

  constructor(config: IExportConfig<T>, data: T[]) {
    this.config = config;
    this.data = data;
  }

  handle() {
    const headerUpdatedData = new JSONHandler().getHeaderUpdatedData(
      this.data,
      this.config.headers
    );
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(headerUpdatedData);
    for (let i = 0; i < this.config.headers.length; i++) {
      ws[XLSX.utils.encode_cell({ c: i, r: 0 })].s = {
        font: {
          sz: 14,
          bold: true,
        },
        fill: {
          fgColor: {
            rgb: "FFFAFA8E",
          },
          patternType: "solid",
        },
      };
    }

    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    XLSX.writeFile(wb, this.config.fileName);
  }
}
