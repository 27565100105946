import { Col, Row } from "react-bootstrap";
import { joinClasses } from "src/utils/functions";

interface ClickableCellProps {
  children?: any;
  href?: string;
  onClick?: () => void;
  className?: string;
}

export default function ClickableCell({
  href,
  children,
  onClick,
  className = "",
}: ClickableCellProps) {
  return (
    <a
      onClick={onClick}
      href={href}
      className={joinClasses(
        "text-decoration-none text-inherit w-full h-full",
        className
      )}
    >
      <Row className="w-full h-full align-items-center text-muted m-0">
        <Col className="p-0">{children}</Col>
      </Row>
    </a>
  );
}
