import { createContext, useContext, useEffect, useState } from "react";
import {
  FilterSpec,
  PaginationPreferences,
  SortPreferences,
} from "crm_core"

const tableContext = createContext<TableContextProps>({} as any);

export function useTable() {
  return useContext(tableContext);
}

export function TableProvider({ children }: any) {
  return (
    <tableContext.Provider value={useTableProvider()}>
      {children}
    </tableContext.Provider>
  );
}

const LOCAL_STORAGE_KEYS = {
  PAGINATION_PREFERENCES: "paginationPreferences",
  SORT_PREFERENCES: "sortPreferences",
  FILTER_PREFERENCES: "filterPreferences",
  COLUMN_ORDER: "columnOrder",
};

function getSavedPreferences<T>(key: string) {
  return JSON.parse(localStorage.getItem(key) || "{}") as T;
}

function useTableProvider() {
  const [paginationPreferences, setPaginationPreferences] = useState<{
    [key: string]: PaginationPreferences;
  }>(getSavedPreferences(LOCAL_STORAGE_KEYS.PAGINATION_PREFERENCES));
  const [sortPreferences, setSortPreferences] = useState<{
    [key: string]: SortPreferences;
  }>({}
    // getSavedPreferences(LOCAL_STORAGE_KEYS.SORT_PREFERENCES)
  );
  const [filterPreferences, setFilterPreferences] = useState<{
    [key: string]: FilterSpec[];
  }>({}
    // getSavedPreferences(LOCAL_STORAGE_KEYS.FILTER_PREFERENCES)
  );
  const [columnOrder, setColumnOrder] = useState<{ [key: string]: number[] }>(
    getSavedPreferences(LOCAL_STORAGE_KEYS.COLUMN_ORDER)
  );
  const [tableTotalRows, setTableTotalRows] = useState<{ [key: string]: number }>({})

  const getPaginationPreferences = (key: string) => {
    return paginationPreferences[key] || { page: 1, perPage: 20 };
  };

  const updatePaginationPreferences = (
    key: string,
    value: PaginationPreferences
  ) => {
    setPaginationPreferences({ ...paginationPreferences, [key]: value });
  };

  const getSortPreferences = (
    key: string,
    defaultColumn: string = "",
    defaultOrder: "asc" | "desc" | "" = ""
  ) => {
    return (
      sortPreferences[key] ||
      ({ column: defaultColumn, order: defaultOrder } as SortPreferences)
    );
  };

  const updateSortPreferences = (key: string, value: SortPreferences) => {
    setSortPreferences(sortPref => ({ ...sortPref, [key]: value }));
  };

  const getFilterPreferences = (key: string) => {
    return filterPreferences[key] || [];
  };

  const updateFilterPreferences = (key: string, value: FilterSpec[]) => {
    setFilterPreferences(pref => ({ ...pref, [key]: value }));
  };

  const getColumnOrder = (key: string) => {
    return columnOrder[key] || [];
  };

  const updateColumnOrder = (key: string, value: number[]) => {
    setColumnOrder(order => ({ ...order, [key]: value }));
  };

  const getTotalRows = (key: string) => {
    return tableTotalRows[key] || 0;
  };

  const updateTotalRows = (key: string, value: number) => {
    setTableTotalRows(rows => ({ ...rows, [key]: value }));
  };

  useEffect(() => {
    // Do not save page number
   let prefToSave = Object.keys(paginationPreferences).map((key) => {
      return {...paginationPreferences[key], page: 1}
    });
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.PAGINATION_PREFERENCES,
      JSON.stringify(prefToSave)
    );
  }, [paginationPreferences]);

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.SORT_PREFERENCES,
      JSON.stringify(sortPreferences)
    );
  }, [sortPreferences]);

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.FILTER_PREFERENCES,
      JSON.stringify(filterPreferences)
    );
  }, [filterPreferences]);

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.COLUMN_ORDER,
      JSON.stringify(columnOrder)
    );
  }, [columnOrder]);

  const applyFilter = (key: string, ...filterSpecs: FilterSpec[]) => {
    
    setFilterPreferences(pref => {
      let filters = pref[key] || [];
      filterSpecs.forEach((filterSpec) => {
        if (!filterSpec.value) {
          return;
        }
        let index = filters.findIndex(
          (f) => f.attribute === filterSpec.attribute
        );
        if (index > -1) {
          filters[index] = filterSpec;
        } else {
          filters.push(filterSpec);
        }
      });
      return { ...pref, [key]: filters };
    })
  };

  return {
    getPaginationPreferences,
    updatePaginationPreferences,
    getSortPreferences,
    updateSortPreferences,
    getFilterPreferences,
    updateFilterPreferences,
    getColumnOrder,
    updateColumnOrder,
    sortPreferences,
    filterPreferences,
    paginationPreferences,
    columnOrder,
    getTotalRows,
    updateTotalRows,
    tableTotalRows,
    applyFilter,
  } as TableContextProps;
}

interface TableContextProps {
  getPaginationPreferences: (key: string) => PaginationPreferences;
  updatePaginationPreferences: (
    key: string,
    value: PaginationPreferences
  ) => void;
  getSortPreferences: (
    key: string,
    defaultColumn?: string,
    defaultOrder?: "asc" | "desc"
  ) => SortPreferences;
  updateSortPreferences: (key: string, value: SortPreferences) => void;
  getFilterPreferences: (key: string) => FilterSpec[];
  updateFilterPreferences: (key: string, value: FilterSpec[]) => void;
  paginationPreferences: { [key: string]: PaginationPreferences };
  sortPreferences: { [key: string]: SortPreferences };
  filterPreferences: { [key: string]: FilterSpec[] };
  getColumnOrder: (key: string) => number[];
  updateColumnOrder: (key: string, value: number[]) => void;
  columnOrder: { [key: string]: number[] };
  getTotalRows: (key: string) => number;
  updateTotalRows: (key: string, value: number) => void;
  tableTotalRows: { [key: string]: number };
  applyFilter: (key: string, ...filterSpecs: FilterSpec[]) => void;
}
