import API from "../api";
import { ISessionWiseDetails, IStudent } from "crm_core";

export class StudentService extends API {
  static NAME = "StudentService";

  constructor() {
    super("/student");
  }
  async getStudents(): Promise<IStudent[]> {
    const res = await this._get({});
    return res.data as IStudent[];
  }

  async getStudent(id: string): Promise<IStudent> {
    const res = await this._get({ url: `/${id}` });
    return res.data as IStudent;
  }

  async createStudent(student: IStudent): Promise<IStudent> {
    const res = await this._post({ body: student });
    return res.data as IStudent;
  }

  async updateStudent(
    student: Partial<IStudent> & { session_wise_details: ISessionWiseDetails[] }
  ): Promise<IStudent> {
    const res = await this._put({ url: `/${student._id}`, body: student });
    return res.data as IStudent;
  }

  async deleteStudent(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }

  async exitStudents(ids: string[], lastSession: string, lastDate: string) {
    const res = await this._post({
      url: "/exit",
      body: {
        student_ids: ids,
        exit_date: lastDate,
        last_session_id: lastSession,
      },
    });
    return res.data;
  }
}
