import { IStudentExtended, Permissions } from "crm_core";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import SimpleDatePicker from "src/components/form_generator/date_picker";
import FormSelect from "src/components/form_select";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import SaveButton from "src/components/widgets/save_button";
import { useDataProvider } from "src/context/data_provider";
import { StudentService } from "src/services/institute/student";

export interface ExitStudentsProps {
  students: IStudentExtended[];
  onComplete?: () => Promise<void>;
}

export default function ExitStudents({
  students,
  onComplete,
}: ExitStudentsProps) {
  const service = new StudentService();
  const [loading, setLoading] = useState<boolean>(false);
  const { sessions } = useDataProvider();
  const [lastSession, setLastSession] = useState<string>();
  const [lastDate, setLastDate] = useState<string>();

  const handleSubmit = async () => {
    if (!lastSession || !lastDate) return;
    setLoading(true);
    await service.exitStudents(
      students.map((s) => s._id),
      lastSession,
      lastDate
    );
    await onComplete?.();
    setLoading(false);
  };

  return (
    <div>
      <Row>
        <Col className="col-6">
          <FormSelect
            value={lastSession as string}
            options={sessions.map((s) => ({
              label: s.name,
              value: s._id as string,
            }))}
            onChange={(s) => setLastSession(s as string)}
            label="Last Session"
          />
        </Col>
        <Col className="col-6">
          <SimpleDatePicker
            value={lastDate}
            onChange={setLastDate}
            label="Last Date"
          />
        </Col>
        <Col className="col-6"></Col>
      </Row>
      <Row className="justify-content-end align-items-center mt-4">
        <Col md="auto" sm="auto" xs="auto" className="">
          <PermissionRestrictedComponent
            permissions={[
              Permissions.UPDATE_STUDENT,
              Permissions.CREATE_STUDENT,
            ]}
          >
            <SaveButton
              loading={loading}
              disabled={loading || !lastSession || !lastDate}
              onClick={handleSubmit}
            />
          </PermissionRestrictedComponent>
        </Col>
      </Row>
    </div>
  );
}
