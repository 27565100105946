import "src/styles/tailwind.css";
import "src/styles/index.css";
import "react-notifications-component/dist/theme.css";
import "react-chat-elements/dist/main.css";
import "katex/dist/katex.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./context/auth_context";
import { ThemeProvider } from "./context/theme_context";
import { ModalProvider } from "./context/modal_context";
import { PageStateProvider } from "./context/page_state_context";
import { TableProvider } from "./context/table_context";
import { DataProviderProvider } from "./context/data_provider";
import { NotificationProvider } from "./context/notification_context";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <ThemeProvider>
      <NotificationProvider>
        <PageStateProvider>
          <ModalProvider>
            <AuthProvider>
              <DataProviderProvider>
                <TableProvider>
                  <App />
                </TableProvider>
              </DataProviderProvider>
            </AuthProvider>
          </ModalProvider>
        </PageStateProvider>
      </NotificationProvider>
    </ThemeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
