import { useEffect, useState } from "react";

interface RangeSliderProps {
  minValue: number;
  maxValue: number;
  value: number;
  onChange?: (value: number) => void;
}

export default function RangeSlider(props: RangeSliderProps) {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(Number(value));
    }
  }, [value]);

  return (
    <div className="relative">
      <input
        type="range"
        // className="form-range appearance-none w-full h-6 p-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none"
        className="w-full h-6 p-0 m-0 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none"
        id="customRange1"
        value={value}
        min={props.minValue}
        max={props.maxValue}
        onChange={(e) => setValue(Number(e.target.value))}
      />
    </div>
  );
}
