import { IInstituteCalendarDay } from "crm_core";
import API from "../api";

export class InstituteCalendarService extends API {
  constructor() {
    super("/institute-calendar");
  }

  async getInstituteCalendarForDateRange(
    fromDate: string,
    toDate: string
  ): Promise<IInstituteCalendarDay[]> {
    const res = await this._get({ url: `/${fromDate}/${toDate}` });
    return res.data;
  }

  async updateInstituteCalendarDay(
    day: IInstituteCalendarDay
  ): Promise<IInstituteCalendarDay> {
    const res = await this._put({
      url: `/${day._id}`,
      body: day,
    });
    return res.data as IInstituteCalendarDay;
  }
}
