import { BulkImportDataType, ICourse } from "crm_core";
import { useEffect, useState } from "react";
import CustomCard from "src/components/custom_card";
import CustomDataTable, {
  CustomTableColumn,
} from "src/components/custom_data_table";
import ProfilePhoto from "src/components/profile_photo";
import { ModalTypes, useModal } from "src/context/modal_context";
import { useNotification } from "src/context/notification_context";
import { CourseService } from "src/services/institute/lms/courses/course";
import { AddUpdateCourseProps } from "./add_update";
import TrackedButton from "src/components/buttons/tracked_button";
import Aux from "src/components/aux";
import { ArrowUpTrayIcon, PlusIcon } from "@heroicons/react/20/solid";
import ClickableCell from "src/components/widgets/clickable_cell";
import { handleBulkImport } from "src/utils/functions";

interface CourseListProps {}

export default function CourseList({}: CourseListProps) {
  const organizationService = new CourseService();
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState<ICourse[]>([]);
  const { pushNotification } = useNotification();
  const {
    setModalType,
    setModalSize,
    setModalHeading,
    setComponentProps,
    setShowModal,
  } = useModal();

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await organizationService.getCourses();
      setCourses(res);
    } catch (e) {
      pushNotification({
        title: "Error",
        message: "Failed to fetch courses",
        type: "danger",
      });
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreateCourse = () => {
    setModalType(ModalTypes.ADD_UDPATE_COURSE);
    setModalSize("lg");
    setModalHeading("Add New Course");
    let props: AddUpdateCourseProps = {
      onComplete: () => {
        fetchData();
        setShowModal(false);
      },
    };
    setComponentProps(props);
    setShowModal(true);
  };

  const tableColumns: CustomTableColumn<ICourse>[] = [
    {
      name: "",
      cell: (row) => (
        <ClickableCell href={`/c/courses/${row._id}`} className="py-2">
          <ProfilePhoto url={row.logo} className="h-12 w-12" />
        </ClickableCell>
      ),
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => (
        <ClickableCell href={`/c/courses/${row._id}`}>{row.name}</ClickableCell>
      ),
      width: "300px",
    },
    {
      name: "Description",
      cell: (row) => <div className="text-muted">{row.description}</div>,
    },
  ];

  return (
    <CustomCard
      title="All Courses"
      headerRight={
        <Aux>
          <TrackedButton
            icon={<PlusIcon className="h-3 w-3 mr-1" />}
            label={<span className="">Create New Course</span>}
            onClick={() => handleCreateCourse()}
            color="accent"
            isSlim
            className="px-2 py-1 text-sm"
          />
        </Aux>
      }
      actionButtonProps={{
        actions: [
          {
            label: "Import Course",
            onClick: handleBulkImport(
              BulkImportDataType.COURSE_WITH_SUBJECTS_AND_QUESTIONS
            ),
            icon: ArrowUpTrayIcon,
          },
        ],
      }}
    >
      <CustomDataTable
        tableKey="courses"
        data={courses}
        columns={tableColumns}
        selectableRows={false}
        highlightOnHover={false}
        progressPending={loading}
        noDataComponent={
          <div className="py-3">
            <h5 className="text-center">No data available</h5>
          </div>
        }
      />
    </CustomCard>
  );
}
