import { IExportHeader } from "../models";
import { dataToHeaders, generateHeaders, getHeaderValue } from "./csv_handler";

export class JSONHandler {
  getHeaderUpdatedData<T>(data: T[], headers: IExportHeader<any>[]) {
    const newHeaders = generateHeaders(data, headers);
    const updatedData = [];
    for (const item of data) {
      const newItem: any = {};
      for (let header of newHeaders) {
        newItem[header.label] = getHeaderValue(header.key as string, item);
        if (typeof newItem[header.label] === "object") {
          newItem[header.label] = JSON.stringify(newItem[header.label]);
        }
      }
      updatedData.push(newItem);
    }
    return updatedData;
  }

  revertHeaderUpdatedData<T>(data: T[], headers: IExportHeader<any>[]) {
    const newHeaders = dataToHeaders(data, headers);
    const updatedData = [];
    for (const item of data) {
      const newItem: any = {};
      for (let header of newHeaders) {
        const v = getHeaderValue(header.label, item);
        const keys = (header.key as string).split(".");
        let currentObj = newItem;
        for (let k = 0; k < keys.length - 1; k++) {
          const isNumber = !isNaN(Number(keys[k + 1]));
          if (isNumber && !Array.isArray(currentObj[keys[k]])) {
            currentObj[keys[k]] = [];
          } else if (!isNumber && !currentObj[keys[k]]) {
            currentObj[keys[k]] = {};
          }
          currentObj = currentObj[keys[k]];
        }
        if (Array.isArray(currentObj[keys[keys.length - 1]])) {
          currentObj[keys[keys.length - 1]].push(v);
        } else {
          currentObj[keys[keys.length - 1]] = v;
        }
      }
      updatedData.push(newItem);
    }
    return updatedData;
  }
}
