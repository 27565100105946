import API from "../../../api";
import { ICourse } from "crm_core";

export class CourseService extends API {
  static NAME = "CourseService";

  constructor() {
    super("/course");
  }
  async getCourses(): Promise<ICourse[]> {
    const res = await this._get({});
    return res.data;
  }

  async getCourse(id: string): Promise<ICourse> {
    const res = await this._get({ url: `/${id}` });
    return res.data;
  }

  async createCourse(course: ICourse): Promise<ICourse> {
    const res = await this._post({ body: course });
    return res.data;
  }

  async updateCourse(course: Partial<ICourse>): Promise<ICourse> {
    const res = await this._put({ url: `/${course._id}`, body: course });
    return res.data;
  }

  async deleteCourse(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }

  async uploadLogo(file: File, courseId: string): Promise<ICourse> {
    const formData = new FormData();
    formData.append("photo", file, file.name);
    const res = await this._postFile({
      url: `/photo/${courseId}`,
      body: formData,
    });
    return res.data as ICourse;
  }
}
