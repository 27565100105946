import { useEffect, useState } from "react";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useNotification } from "src/context/notification_context";
import { DepartmentService } from "src/services/institute/department";
import { FormFieldType } from "src/utils/constants";
import { catchRequest, validateFields } from "src/utils/functions";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import { IDepartment, Permissions } from "crm_core";
import { Col, Row } from "react-bootstrap";
import ArchiveButton from "src/components/widgets/archive_button";
import SaveButton from "src/components/widgets/save_button";
import { useNavigate } from "react-router-dom";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";

export interface AddUpdateDepartmentProps {
  onComplete?: () => void;
  id?: string;
  department?: IDepartment;
}

export default function AddUpdateDepartment({
  onComplete,
  id,
  department,
}: AddUpdateDepartmentProps) {
  const service = new DepartmentService();
  const [departmentName, setDepartmentName] = useState<string>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();
  const { fetchDataForUseCase, selectedSession } = useDataProvider();

  useEffect(() => {
    if (selectedSession) fetchDataForUseCase(DataUseCase.INSTITUTE);
  }, [selectedSession]);

  useEffect(() => {
    const fetchDepartment = async (id: string) => {
      setLoading(true);
      const currentDepartment = department || (await service.getDepartment(id));
      setDepartmentName(currentDepartment.name);
      setLoading(false);
    };
    if (id) {
      fetchDepartment(id);
    }
  }, [id]);

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(getFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);
    let update: any = {
      name: departmentName as any,
    };

    await catchRequest(async () => {
      if (id) {
        await service.updateDepartment({
          _id: id,
          ...update,
        });
        pushNotification({
          title: "Success",
          message: "Department updated successfully",
          type: "success",
        });
      } else {
        await service.createDepartment({
          ...update,
        });
      }
    }, pushNotification)();
    setLoading(false);
    onComplete?.();
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.TEXT,
          value: departmentName,
          onChange: setDepartmentName,
          extras: {
            placeholder: "Department Name",
            label: "Department Name",
          },
          required: true,
          updatable: true,
        },
      ],
    ];

    if (id) {
      return allFields
        .map((fields) => fields.filter((f) => f.updatable))
        .filter((f) => f.length > 0);
    }
    return allFields;
  };

  const handleDeleteDepartment = async () => {
    await catchRequest(async () => {
      await service.deleteDepartment(id as string);
      pushNotification({
        title: "Success",
        message: "Department archived successfully",
        type: "success",
      });
      navigate("/c/departments");
    }, pushNotification)();
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      <Row className="justify-content-end mt-4 align-items-center">
        <Col hidden={!id} md="auto" sm="auto" xs="auto" className="p-0">
          <PermissionRestrictedComponent
            permissions={[Permissions.ARCHIVE_DEPARTMENT]}
          >
            <ArchiveButton
              dialogTitle="Archive Department?"
              dialogContent="Are you sure you want to archive this department?"
              label="Archive Department"
              onClick={() => handleDeleteDepartment()}
            />
          </PermissionRestrictedComponent>
        </Col>
        <Col md="auto" sm="auto" xs="auto" className="">
          <PermissionRestrictedComponent
            permissions={[
              Permissions.CREATE_DEPARTMENT,
              Permissions.UPDATE_DEPARTMENT,
            ]}
          >
            <SaveButton
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            />
          </PermissionRestrictedComponent>
        </Col>
      </Row>
    </div>
  );
}
