import { useState } from "react";
import ConfirmDialog from "../dialogs/confirm_dialog";
import LoadingButton, { LoadingButtonProps } from "./loading_button";

export interface ConfirmableButtonProps extends LoadingButtonProps {
  dialogTitle?: string;
  dialogContent?: string;
}

export default function ConfirmableButton(
  props: ConfirmableButtonProps
) {
  const [confirm, setConfirm] = useState(false);

  return (
    <div>
      <LoadingButton
        {...props}
        onClick={() => setConfirm(true)}
      />
      <ConfirmDialog
        title={props.dialogTitle || " Item?"}
        open={confirm}
        onClose={() => setConfirm(false)}
        onConfirm={props.onClick}
      >
        {props.dialogContent || "Are you sure you want to delete this item?"}
      </ConfirmDialog>
    </div>
  );
}
