import { ICourse } from "crm_core";
import { useEffect, useState } from "react";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useNotification } from "src/context/notification_context";
import { FormFieldType } from "src/utils/constants";
import { catchRequest, validateFields } from "src/utils/functions";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ArchiveButton from "src/components/widgets/archive_button";
import SaveButton from "src/components/widgets/save_button";
import { CourseService } from "src/services/institute/lms/courses/course";
import ImageUpload from "src/components/file_upload/image_upload";
import { BuildingLibraryIcon } from "@heroicons/react/24/outline";

export interface AddUpdateCourseProps {
  onComplete?: () => void;
  id?: string;
  data?: ICourse;
}

export default function AddUpdateCourse({
  onComplete,
  id,
  data,
}: AddUpdateCourseProps) {
  const updating = !!id;
  const service = new CourseService();
  const [course, setCourse] = useState<ICourse>({} as any);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();

  useEffect(() => {
    const fetchCourse = async (id: string) => {
      setLoading(true);
      const currentCourse = data || (await service.getCourse(id));
      setCourse(currentCourse);
      setLoading(false);
    };
    if (id) {
      fetchCourse(id);
    }
  }, [id]);

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(getFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);

    await catchRequest(async () => {
      if (id) {
        await service.updateCourse({
          _id: id,
          name: course.name,
          description: course.description,
        });
        pushNotification({
          title: "Success",
          message: "Course updated successfully",
          type: "success",
        });
      } else {
        await service.createCourse(course);
      }
    }, pushNotification)();
    setLoading(false);
    onComplete?.();
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.TEXT,
          value: course?.name,
          onChange: (v) => setCourse({ ...course, name: v }),
          extras: {
            placeholder: "Course Name",
            label: "Course Name",
          },
          required: true,
          updatable: true,
        },
      ],
      [
        {
          type: FormFieldType.TEXTAREA,
          value: course?.description,
          onChange: (v) => setCourse({ ...course, description: v }),
          extras: {
            placeholder: "Description",
            label: "Description",
          },
          updatable: true,
        },
      ],
    ];

    if (id) {
      return allFields
        .map((fields) => fields.filter((f) => f.updatable))
        .filter((f) => f.length > 0);
    }
    return allFields;
  };

  const handleDeleteCourse = async () => {
    await catchRequest(async () => {
      await service.deleteCourse(id as string);
      pushNotification({
        title: "Success",
        message: "Course archived successfully",
        type: "success",
      });
      navigate("/c/courses");
    }, pushNotification)();
  };

  const uploadPhoto = async (file: File) => {
    setLoading(true);
    await service.uploadLogo(file, id as string);
    setLoading(false);
  };

  return (
    <div>
      <Row className="m-0">
        <Col className="p-0">
          <FormGenerator getFields={getFields} />
        </Col>
        <Col hidden={!updating} className="p-0 mt-4">
          <ImageUpload
            className="w-20 rounded-full p-0"
            placeholder={
              course?.logo ? (
                <img className="w-20 h-20 rounded-full p-0" src={course.logo} />
              ) : (
                <BuildingLibraryIcon
                  className="w-20 rounded-full"
                  strokeWidth={0.8}
                />
              )
            }
            onUpload={uploadPhoto}
          />
        </Col>
      </Row>
      <Row className="justify-content-end mt-4 align-items-center">
        <Col hidden={!id} md="auto" sm="auto" xs="auto" className="p-0">
          <ArchiveButton
            dialogTitle="Archive Course?"
            dialogContent="Are you sure you want to archive this data?"
            label="Archive Course"
            onClick={() => handleDeleteCourse()}
          />
        </Col>
        <Col md="auto" sm="auto" xs="auto" className="">
          <SaveButton
            loading={loading}
            disabled={loading}
            onClick={handleSubmit}
          />
        </Col>
      </Row>
    </div>
  );
}
