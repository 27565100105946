import {
  FilterSpec,
  PaginationPreferences,
  SortPreferences,
} from "../models/utils/preferences";

export interface QueryFields {
  pagination?: PaginationPreferences;
  sort?: SortPreferences;
  filters?: FilterSpec[];
  fields?: string[];
}

export class QueryParams {
  static stringify({ pagination, sort, filters, fields }: QueryFields): {
    [key: string]: string;
  } {
    let params: any = {};
    if (pagination) {
      params.page = pagination.page;
      params.per_page = pagination.perPage;
    }
    if (sort && sort.column?.length > 0) {
      params.sort_column = sort.column;
      params.sort_order = sort.order;
    }
    if (filters && filters.length > 0) {
      params.filters = JSON.stringify(filters);
    }
    if (fields && fields.length > 0) {
      params.fields = fields.join(",");
    }
    return params;
  }

  static parse(params: { [key: string]: string }): QueryFields {
    let res: QueryFields = {};
    if (params.page) {
      res.pagination = {
        page: parseInt(params.page),
        perPage: parseInt(params.per_page),
      };
    }
    if (params.sort_column && params.sort_column?.length > 0) {
      res.sort = { column: params.sort_column, order: params.sort_order };
    }
    if (params.filters) {
      res.filters = JSON.parse(params.filters);
    }
    if (params.fields) {
      res.fields = params.fields.split(",");
    }
    return res;
  }
}
