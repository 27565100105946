import { ICustomRole, Permissions } from "crm_core";
import { useEffect, useState } from "react";
import CustomCard from "src/components/custom_card";
import { RoleCard } from "./card";
import { Col, Row } from "react-bootstrap";
import Aux from "src/components/aux";
import TrackedButton from "src/components/buttons/tracked_button";
import { PlusIcon } from "@heroicons/react/20/solid";
import { ModalTypes, useModal } from "src/context/modal_context";
import { CustomRoleService } from "src/services/custom_role";
import { Loader } from "src/components/loader";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";

export default function Roles() {
  const customRolesService = new CustomRoleService();
  const [loading, setLoading] = useState(false);
  const [customRoles, setCustomRoles] = useState<ICustomRole[]>([]);
  const { setModalType, setModalSize, setModalHeading, setShowModal } =
    useModal();

  const fetchRoles = async () => {
    setLoading(true);
    const roles = await customRolesService.getCustomRoles();
    setCustomRoles(roles);
    setLoading(false);
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    document.title = "Teachbond - Roles & Permissions";
  }, []);

  const handleCreateRole = () => {
    setModalType(ModalTypes.CUSTOM_ROLE);
    setModalSize("xl");
    setModalHeading("Add New Role");
    setShowModal(true);
  };

  return (
    <CustomCard
      title="Staff Roles"
      headerRight={
        <Aux>
          <PermissionRestrictedComponent
            permissions={[Permissions.CREATE_ROLE]}
          >
            <TrackedButton
              icon={<PlusIcon className="h-3 w-3 mr-1" />}
              label={<span className="">Create New Role</span>}
              onClick={handleCreateRole}
              color="accent"
              isSlim
              className="px-2 py-1 text-sm"
            />
          </PermissionRestrictedComponent>
        </Aux>
      }
    >
      <div className="mx-3 -mb-3">
        <div hidden={!loading}>
          <Loader />
        </div>
        {customRoles.length == 0 && (
          <div className="text-center">No custom roles created yet.</div>
        )}
        <Row>
          {customRoles.map((role, idx) => (
            <Col sm="6" md="4" lg="3" key={`role-${idx}`} className="mb-3">
              <RoleCard role={role} forceRefresh={fetchRoles} />
            </Col>
          ))}
        </Row>
      </div>
    </CustomCard>
  );
}
