import moment from "moment";
import FileResizer from "react-image-file-resizer";
import { CustomTableColumn } from "src/components/custom_data_table";
import { FormField } from "src/components/form_generator";
import Swal from "sweetalert2";
import {
  BulkImportDataType,
  CSVHandler,
  IExportConfig,
  IExportHeader,
  Permissions,
  UserRole,
} from "crm_core";
import { useAuth } from "src/context/auth_context";
import { Notification } from "src/context/notification_context";
import { DataExportHandler } from "./export_handler";
import { BulkImportProps } from "src/features/bulk_import";
import { ModalTypes, useModal } from "src/context/modal_context";

export function isProd() {
  return process.env.NODE_ENV === "production";
}

export function joinClasses(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export function getContrastColor(color: string) {
  if (color == "white") return "black";
  return "white";
}

export function truncate(str: string, n: number) {
  return str.length > n + 3 ? str.substring(0, n) + "..." : str;
}

export function flatten<T>(arr: T[][]): T[] {
  return arr.reduce((acc, val) => acc.concat(val), []);
}

export function validateFields(fields: FormField<any>[][]) {
  let valid = true;
  let errors: string[] = [];
  flatten(fields).forEach((f) => {
    if (
      f.required &&
      ((!f.value && f.value != false) ||
        (typeof f.value == "string" && f.value.trim() == ""))
    ) {
      valid = false;
      errors.push(f.extras?.label);
    }
  });
  return { valid, errors };
}

export async function resizeFile(file: File): Promise<File> {
  let updated: string = await new Promise((resolve) => {
    FileResizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      40,
      0,
      (uri) => resolve(uri as string),
      "base64"
    );
  });
  let blob = await fetch(updated).then((r) => r.blob());
  let newFile = new File([blob], file.name, { type: file.type });
  return newFile;
}

export function getYearStartTimestamp() {
  let now = new Date();
  return new Date(now.getFullYear(), 0, 1).getTime();
}

export function getYearEndTimestamp() {
  let now = new Date();
  return new Date(now.getFullYear(), 12, 31).getTime();
}

export function catchAsync(
  fn: any,
  successMessage: string,
  failureMessage: string
) {
  return async function (...args: any[]) {
    try {
      await fn(...args);
      if (successMessage) {
        Swal.fire({
          title: "Success",
          text: successMessage,
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#19c491",
        });
      }
    } catch (error) {
      console.log(error);
      if (failureMessage) {
        Swal.fire({
          title: "Error",
          text: failureMessage,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#19c491",
        });
      }
    }
  };
}

export function deepCopy(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export function conditionalCol<T>(condition: any, col: CustomTableColumn<T>) {
  return condition ? [col] : [];
}

export function getMarksDescription(
  correct_marks?: number,
  negative_marks?: number
) {
  if (correct_marks == undefined || negative_marks == undefined)
    return "--, --";
  return `${correct_marks > 0 ? "+" : ""}${correct_marks}, ${
    negative_marks > 0 ? "+" : ""
  } ${negative_marks}`;
}

export function getFormattedDateTime(dt: Date) {
  return moment(dt).format("DD MMM YYYY hh:mm A");
}

export function isPermissible(...permissions: Permissions[]) {
  const { selectedAuthorization } = useAuth();
  return (
    selectedAuthorization.role == UserRole.OrganizationAdmin ||
    permissions.some((p) => selectedAuthorization?.permissions?.includes(p))
  );
}

export function catchRequest(
  fn: any,
  pushNotification: (notification: Notification) => void
) {
  return async function (...args: any[]) {
    try {
      return await fn(...args);
    } catch (error: any) {
      if (error.message) {
        pushNotification({
          title: "Error",
          message: error.message,
          type: "warning",
          dismiss: {
            duration: 5000,
          },
        });
      }
    }
  };
}

export function downloadURI(url: string, fileName: string) {
  const anchorElement = document.createElement("a");
  document.body.appendChild(anchorElement);
  anchorElement.href = url;
  anchorElement.download = fileName;
  anchorElement.onclick = () => {
    const nav = window.navigator as any;
    if (nav.msSaveOrOpenBlob) {
      const blob = new Blob([url], { type: "text/csv;charset=utf-8;" });
      nav.msSaveBlob(blob, fileName);
    }
  };
  anchorElement.click();
  window.URL.revokeObjectURL(url);
}

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const buildURI = <T>(
  data: T[],
  headers: IExportHeader<T>[],
  uFEFF: boolean = true,
  separator: string = ",",
  enclosingCharacter: string = '"'
) => {
  const csv = new CSVHandler().toCSV(
    data as any,
    headers,
    separator,
    enclosingCharacter
  );
  const type = isSafari() ? "application/csv" : "text/csv";
  const blob = new Blob([uFEFF ? "\uFEFF" : "", csv], { type });
  const dataURI = `data:${type};charset=utf-8,${uFEFF ? "\uFEFF" : ""}${csv}`;

  const URL = window.URL || window.webkitURL;

  return typeof URL.createObjectURL === "undefined"
    ? dataURI
    : URL.createObjectURL(blob);
};

export async function handleDataExport<T>(config: IExportConfig<T>, data: T[]) {
  await catchAsync(
    async () => {
      new DataExportHandler(config, data).handle();
    },
    "Data exported successfully",
    "Something went wrong"
  )();
}

export function handleBulkImport(type: BulkImportDataType, params?: any) {
  const {
    setModalType,
    setModalSize,
    setModalHeading,
    setComponentProps,
    setShowModal,
  } = useModal();

  return () => {
    setModalType(ModalTypes.BULK_IMPORT);
    setModalSize("lg");
    setModalHeading("Bulk Import");
    let props: BulkImportProps = {
      type,
      onComplete: () => {
        setShowModal(false);
      },
      params,
    };
    setComponentProps(props);
    setShowModal(true);
  };
}
