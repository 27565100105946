import { ICustomRole, IInvitation, IOrganization } from "../../../hierarchy";
import {
  Category,
  EmploymentTypes,
  IBankDetails,
  IExportConfig,
  MaritalStatus,
  Religion,
} from "../../../utils";
import { IAttendanceShift } from "../../attendance";
import { IClassroom, IDepartment } from "../academics";
import { IPerson } from "./person";

export interface IExperience {
  organization: string;
  designation: string;
  from: Date;
  to: Date;
  location: string;
  description: string;
  reference_name: string;
  reference_contact?: string;
  reference_email?: string;
}

export interface IStaffMember extends IPerson {
  organization?: string | IOrganization;
  employee_id?: string;
  job_title?: string;
  department?: IDepartment | string;
  designation?: string;
  employment_type?: EmploymentTypes;
  appointment_date?: Date;
  joining_date?: Date;
  leaving_date?: Date;
  experience?: number;
  qualification?: string;
  aadhar_no?: string;
  pan_no?: string;
  religion?: Religion;
  category?: Category;
  father_name?: string;
  mother_name?: string;
  marital_status?: MaritalStatus;
  spouse_name?: string;
  emergency_contact?: string;
  experience_details?: IExperience[];
  bank_details?: IBankDetails;
  classrooms?: string[] | IClassroom[];
  roles?: string[] | ICustomRole[];
  current_shift?: string | IAttendanceShift;
}

export interface IStaffMemberResponse extends IStaffMember {
  invitation: IInvitation;
  current_shift: IAttendanceShift;
  _id: string;
}

export const StaffExportConfig: IExportConfig<IStaffMemberResponse> = {
  fileName: "Staff Members.xlsx",
  headers: [
    { key: "first_name", label: "First Name" },
    { key: "middle_name", label: "Middle Name" },
    { key: "last_name", label: "Last Name" },
    { key: "email", label: "Email" },
    { key: "mobile", label: "Phone" },
    { label: "Date of Birth", key: "dob" },
    { label: "Gender", key: "gender" },
    { label: "Blood Group", key: "blood_group" },
    { key: "employee_id", label: "Employee ID" },
    // { key: "job_title", label: "Job Title" },
    // { key: "department", label: "Department" },
    // { key: "designation", label: "Designation" },
    // { key: "employment_type", label: "Employment Type" },
    // { key: "appointment_date", label: "Appointment Date" },
    // { key: "joining_date", label: "Joining Date" },
    // { key: "leaving_date", label: "Leaving Date" },
    // { key: "experience", label: "Experience" },
    // { key: "qualification", label: "Qualification" },
    // { key: "aadhar_no", label: "Aadhar No" },
    // { key: "pan_no", label: "Pan No" },
    // { key: "religion", label: "Religion" },
    // { key: "category", label: "Category" },
    // { key: "father_name", label: "Father Name" },
    // { key: "mother_name", label: "Mother Name" },
    // { key: "marital_status", label: "Marital Status" },
    // { key: "spouse_name", label: "Spouse Name" },
    // { key: "emergency_contact", label: "Emergency Contact" },
  ],
};
