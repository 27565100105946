import { RegistrationForm } from "crm_core";
import API from "./api";

export class AuthService extends API {
  constructor() {
    super("/auth");
  }

  async signIn(email: string, password: string) {
    return await this._post({ url: "/login/", body: { email, password } });
  }

  async signUp(user: RegistrationForm) {
    return await this._post({ url: "/signup/", body: user });
  }

  async verifyToken() {
    return await this._get({ url: "/verify" });
  }

  async forgotPassword(body: any) {
    return await this._post({ url: "/forgotPassword/", body });
  }

  async resetPassword(
    token: string,
    password: string,
    confirmPassword: string
  ) {
    return await this._post({
      url: `/resetPassword/${token}`,
      body: {
        password,
        passwordConfirm: confirmPassword,
      },
    });
  }

  async sendOTP(email: string) {
    return await this._post({ url: "/otp/", body: { email } });
  }
}
