import { IQuestionAttachment } from "crm_core";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export interface IAttachmentViewerProps {
  attachment: IQuestionAttachment;
}

export function AttachmentViewer({ attachment }: IAttachmentViewerProps) {
  return (
    <DocViewer
      prefetchMethod="GET"
      documents={[{ uri: attachment.url as string, fileName: attachment.name }]}
      pluginRenderers={DocViewerRenderers}
      config={{
        pdfZoom: {
          defaultZoom: 0.4,
          zoomJump: 0.1,
        },
        header: {
          disableHeader: true,
        },
      }}
    />
  );
}
