import API from "./api";
import { RegistrationForm } from "crm_core";
import { IUser } from "crm_core";
import { CURRENT_PLATFORM } from "src/utils/constants";

export class UserService extends API {
  constructor() {
    super("/user");
  }

  async getUser(): Promise<IUser[]> {
    const res = await this._get({});
    return res.data as IUser[];
  }

  async updateUser(user: RegistrationForm): Promise<IUser> {
    const res = await this._put({ body: user });
    return res.data as IUser;
  }

  async uploadPhoto(file: File): Promise<IUser> {
    const formData = new FormData();
    formData.append("photo", file, file.name);
    const res = await this._postFile({ url: "/photo", body: formData });
    return res.data as IUser;
  }

  async updateFcmToken(token: string, expires_at: Date): Promise<IUser> {
    const res = await this._post({
      url: "/update-fcm-token",
      body: { token, expires_at, platform: CURRENT_PLATFORM },
    });
    return res.data as IUser;
  }
}
