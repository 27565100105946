import API from "../../api";
import { IPublishTestInterfaceBody, ITest } from "crm_core";

export class TestService extends API {
  constructor() {
    super("/test");
  }
  async getTests(): Promise<ITest[]> {
    const res = await this._get({});
    return res.data as ITest[];
  }

  async getTest(id: string): Promise<ITest> {
    const res = await this._get({ url: `/${id}` });
    return res.data as ITest;
  }

  async getTestPreview(id: string): Promise<ITest> {
    const res = await this._get({ url: `/preview/${id}` });
    return res.data as ITest;
  }

  async createTest(test: ITest): Promise<ITest> {
    const res = await this._post({ body: test });
    return res.data as ITest;
  }

  async updateTest(test: Partial<ITest>): Promise<ITest> {
    const res = await this._put({ url: `/${test._id}`, body: test });
    return res.data as ITest;
  }

  async deleteTest(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }

  async publishTest(body: IPublishTestInterfaceBody): Promise<void> {
    const res = await this._post({ url: `/publish`, body });
    return res.data as void;
  }

  async addQuestions(id: string, questions: string[]): Promise<ITest> {
    const res = await this._post({
      url: `/add-questions/${id}`,
      body: { questions },
    });
    return res.data as ITest;
  }

  async exportTest(id: string): Promise<any> {
    const res = await this._post({ url: `/export/${id}` });
    return res.data;
  }
}
