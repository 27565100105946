import Select, { MultiValue } from "react-select";
import { joinClasses } from "src/utils/functions";
import LabelledField from "./form_generator/labelled_field";
import { useModal } from "src/context/modal_context";

export interface FormOption {
  value: string;
  label: string;
}
interface FormSelctProps {
  options: FormOption[];
  value: string | string[];
  onChange: (value: string | string[] | undefined) => void;
  className?: string;
  label?: string;
  required?: boolean;
  multi?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
}
export default function FormSelect({
  options,
  value,
  onChange,
  label,
  multi,
  disabled = false,
  isClearable = true,
  ...props
}: FormSelctProps) {
  const { showModal } = useModal();
  return (
    <LabelledField
      label={label}
      className={props.className}
      required={props.required}
    >
      <Select
        menuPortalTarget={
          showModal
            ? (document.getElementsByClassName("modal")[0] as any)
            : document.body
        }
        isDisabled={disabled}
        isMulti={multi}
        classNames={{
          control: (state) =>
            joinClasses(
              "bg-custom-grey border-none ring-1 my-0 py-0",
              state.isFocused ? "ring-dark-accent" : "ring-custom-grey"
            ),
          input: (state) => joinClasses("my-0 py-0"),
          valueContainer: (state) => joinClasses("my-0 py-0 text-sm"),
          indicatorsContainer: (state) => joinClasses("my-0 py-0"),
          option: (state) =>
            joinClasses(
              "text-sm ",
              state.isSelected ? "bg-dark-accent" : "hover:bg-light-accent",
              state.isFocused && !state.isSelected ? "bg-light-accent" : ""
            ),
        }}
        options={options as any}
        value={
          multi
            ? options.filter((option) => value?.includes(option.value))
            : options.filter((option) => option.value === value)
        }
        onChange={(e) =>
          multi
            ? onChange((e as MultiValue<FormOption>)?.map((op) => op.value))
            : e
            ? onChange((e as any).value)
            : onChange(undefined)
        }
        closeMenuOnSelect={!multi}
        isClearable={isClearable}
      />
    </LabelledField>
  );
}
