import API from "../../api";
import {
  IFolder,
  IFolderDocument,
  IFolderExtendedData,
  IFolderListData,
} from "crm_core";

export class FolderService extends API {
  constructor() {
    super("/folder");
  }

  async getFolders(
    params: {
      [x: string]: string;
    } = {}
  ): Promise<IFolderListData[]> {
    const res = await this._get({ params });
    return res.data as IFolderListData[];
  }

  async getFolder(
    id: string,
    params: {
      [x: string]: string;
    } = {}
  ): Promise<IFolder | IFolderExtendedData> {
    const res = await this._get({ url: `/${id}`, params });
    return res.data;
  }

  async createFolder(folder: IFolder): Promise<IFolder> {
    const res = await this._post({ body: folder });
    return res.data as IFolder;
  }

  async updateFolder(folder: IFolder): Promise<IFolder> {
    const res = await this._put({ url: `/${folder._id}`, body: folder });
    return res.data as IFolder;
  }

  async deleteFolder(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }

  async addToFolder(folderId: string, doc: IFolderDocument) {
    const res = await this._post({ url: `/${folderId}/document`, body: doc });
    return res.data as IFolder;
  }

  async removeFromFolder(folderId: string, docKey: string) {
    const res = await this._delete({ url: `/${folderId}/document/${docKey}` });
    return res.data as IFolder;
  }
}
