import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../buttons/button";
import SimpleDatePicker from "../form_generator/date_picker";
import LabelledField from "../form_generator/labelled_field";
import { joinClasses } from "src/utils/functions";
import { DEFAULT_DATE_FORMAT } from "crm_core";

interface HorizontalDatePickerProps {
  selected?: Moment;
  onChange: (date: Moment) => void;
  renderDay?: (day: Moment) => JSX.Element;
  onRangeChange?: (start: Moment, end: Moment) => void;
}

export default function HorizontalDatePicker({
  renderDay,
  onRangeChange,
  ...props
}: HorizontalDatePickerProps) {
  const [startDate, setStartDate] = useState<Moment>();
  const [endDate, setEndDate] = useState<Moment>();
  const [selected, setSelected] = useState<Moment>(props.selected ?? moment());

  useEffect(() => {
    let weekStart = selected.clone().startOf("week");
    let weekEnd = selected.clone().endOf("week");
    startDate?.isSame(weekStart, "date") ? null : setStartDate(weekStart);
    endDate?.isSame(weekEnd, "date") ? null : setEndDate(weekEnd);
    if (!selected?.isSame(props.selected, "date")) {
      props.onChange(selected);
    }
  }, [selected]);

  const getDays = () => {
    if (!startDate || !endDate) return [];
    let days = [];
    let day = startDate;
    while (day <= endDate) {
      days.push(day);
      day = day.clone().add(1, "d");
    }
    return days;
  };

  const days = getDays();

  const handleNext = () => {
    if (!startDate || !endDate) return;
    setStartDate((s) => s?.clone().add(1, "week"));
    setEndDate((s) => s?.clone().add(1, "week"));
  };

  const handlePrev = () => {
    if (!startDate || !endDate) return;
    setStartDate((s) => s?.clone().subtract(1, "week"));
    setEndDate((s) => s?.clone().subtract(1, "week"));
  };

  const handleSelectedChange = (date: Moment) => {
    setSelected((s) => (date > moment() ? s.clone() : date));
  };

  useEffect(() => {
    if (onRangeChange && startDate && endDate) {
      onRangeChange(startDate, endDate);
    }
  }, [startDate, endDate]);

  return (
    <div className="mx-2">
      <Row className="mb-3 ml-1">
        <Col className="col-auto">
          <LabelledField label="Date">
            <SimpleDatePicker
              value={selected?.format(DEFAULT_DATE_FORMAT)}
              onChange={(date) =>
                handleSelectedChange(moment(date, DEFAULT_DATE_FORMAT))
              }
            />
          </LabelledField>
        </Col>
      </Row>
      <Row className="m-0 justify-content-center align-items-center">
        <Col className="col-auto px-2 py-2">
          <Button
            className="p-2"
            color="transparent"
            icon={<ArrowLeftIcon className="h-5 w-5" />}
            isSlim
            onClick={handlePrev}
          />
        </Col>
        {days.map((day, idx) => (
          <Col
            key={`day-${idx}`}
            className={joinClasses(
              "p-0 text-center mx-1 rounded-md",
              day.isSame(selected)
                ? "bg-template-card border-2 border-accent"
                : "bg-custom-grey border-2",
              day > moment()
                ? " cursor-not-allowed text-muted"
                : "cursor-pointer text-black hover:bg-template-card"
            )}
            onClick={() => handleSelectedChange(day)}
          >
            {renderDay ? (
              renderDay(day)
            ) : (
              <div className="text-sm py-2 rounded-md">
                <div className="">{day.format("ddd")}</div>
                <div className="">{day.format("D")}</div>
              </div>
            )}
          </Col>
        ))}
        <Col className="col-auto px-2 py-2">
          <Button
            className="p-2"
            color="transparent"
            icon={<ArrowRightIcon className="h-5 w-5" />}
            isSlim
            onClick={handleNext}
            disabled={moment().isSame(endDate, "w")}
          />
        </Col>
      </Row>
    </div>
  );
}
