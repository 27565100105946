import API from "./api";
import { QueryParams, QueryFields } from "crm_core";
import { PaginatedResult } from "crm_core";

export interface PaginatedResultType<DType> {
  results: DType[];
  totalCount: number;
}

export class PaginatedGetter<Service extends API, DType> {
  service: Service;

  constructor(service: new () => Service) {
    this.service = new service();
  }

  async get(
    props: QueryFields,
    extraParams?: { [key: string]: any }
  ): Promise<PaginatedResult<DType>> {
    let params = QueryParams.stringify(props);
    params = { ...params, ...extraParams };
    const res = await this.service._get({ params });
    return {
      results: res.data,
      totalCount: res.totalCount,
      aggregate: res.aggregate,
    } as PaginatedResult<DType>;
  }
}
