import { useTheme } from "src/context/theme_context";
import { useNavigation } from "./context";
import { joinClasses, truncate } from "src/utils/functions";
import { IRouteData } from "src/routes";
import { useNavigate } from "react-router-dom";
import React from "react";
import { usePageState } from "src/context/page_state_context";

interface SidebarRowProps {
  section: IRouteData;
  dtype: string;
}

export default function SidebarRow({ section, dtype }: SidebarRowProps) {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { isSmallScreen} = usePageState()
  const {
    selectedSection,
    setSelectedSection,
    hoveredSection,
    setHoveredSection,
    setExpanded,
  } = useNavigation();

  const hovered = hoveredSection === section;
  const nothingHovered = hoveredSection === undefined;
  const selected = selectedSection === section;

  let commonClass = joinClasses(
    "my-2 py-2 transition ease-in duration-200 text-xl flex align-items-center cursor-pointer text-white",
    selectedSection === section ? "" : "",
    hovered || selected ? "" : "opacity-60"
  );

  const commonStyle: React.CSSProperties = {
    // color: theme.colors.onSecondary,
    height: "50px",
    // cursor: "pointer",
  };

  const commonProps: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > = {
    className: commonClass,
    style: commonStyle,
    onClick: (e) => {
      if (e.metaKey || e.ctrlKey) {
        window.open(`/${section.layout}${section.path}`, "_blank");
        return;
      }
      setSelectedSection(section);
      setExpanded(false);
      navigate(`/${section.layout}${section.path}`);
    },
    onMouseEnter: () => {
      if (!selected) {
        setHoveredSection(section);
        setExpanded(true);
      }
    },
    onMouseLeave: () => setHoveredSection(undefined as any),
  };

  if (dtype === "icon") {
    return (
      <div
        {...commonProps}
        className={joinClasses(
          commonClass,
          "rounded-l-full",
          isSmallScreen ? "ml-1 px-0" : "ml-2 pr-2 "
        )}
      >
        {section.icon}
      </div>
    );
  } else if (dtype === "title") {
    return (
      <div
        {...commonProps}
        className={joinClasses(commonClass, "mr-2 rounded-r-full")}
        style={{
          ...commonStyle,
        }}
      >
        <span
          className="m-0 font-light"
          style={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {truncate(section.title ?? "", 13)}
        </span>
      </div>
    );
  }
  return <div>NOT IMPLEMENTED</div>;
}
