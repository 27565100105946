import { useEffect, useState } from "react";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useNotification } from "src/context/notification_context";
import { CustomRoleService } from "src/services/custom_role";
import { FormFieldType } from "src/utils/constants";
import { catchRequest, validateFields } from "src/utils/functions";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import {
  ICustomRole,
  Permissions,
  permissionGroups,
  permissionToTitle,
} from "crm_core";
import { Col, Row } from "react-bootstrap";
import ArchiveButton from "src/components/widgets/archive_button";
import SaveButton from "src/components/widgets/save_button";
import { useNavigate } from "react-router-dom";
import { ToggleRow } from "src/components/buttons/toggle_button";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";

export interface AddUpdateCustomRoleProps {
  onComplete?: () => void;
  id?: string;
  role?: ICustomRole;
}

export default function AddUpdateCustomRole({
  onComplete,
  id,
  role,
}: AddUpdateCustomRoleProps) {
  const service = new CustomRoleService();
  const [customRole, setCustomRole] = useState<ICustomRole>({
    name: "",
    permissions: [],
  });
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();
  const { fetchDataForUseCase, selectedSession } = useDataProvider();
  if (role) {
    id = role._id;
  }

  useEffect(() => {
    if (selectedSession) fetchDataForUseCase(DataUseCase.INSTITUTE);
  }, [selectedSession]);

  useEffect(() => {
    const fetchCustomRole = async (id: string) => {
      setLoading(true);
      const currentCustomRole = role || (await service.getCustomRole(id));
      setCustomRole(currentCustomRole);
      setLoading(false);
    };
    if (id) {
      fetchCustomRole(id);
    } else if (role) {
      setCustomRole(role);
    }
  }, [id]);

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(getFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);
    let update: any = {
      name: customRole.name,
      permissions: customRole.permissions,
    };

    await catchRequest(async () => {
      if (id) {
        await service.updateCustomRole({
          _id: id,
          ...update,
        });
      } else {
        await service.createCustomRole({
          ...update,
        });
      }
      onComplete?.();
    }, pushNotification)();
    setLoading(false);
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.TEXT,
          value: customRole.name,
          onChange: (v) => setCustomRole({ ...customRole, name: v }),
          extras: {
            placeholder: "Name",
            label: "Name",
          },
          required: true,
          updatable: true,
        },
      ],
    ];

    if (id) {
      return allFields
        .map((fields) => fields.filter((f) => f.updatable))
        .filter((f) => f.length > 0);
    }
    return allFields;
  };

  const handleDeleteCustomRole = async () => {
    await catchRequest(async () => {
      await service.deleteCustomRole((id as string) || (role?._id as string));
      pushNotification({
        title: "Success",
        message: "CustomRole archived successfully",
        type: "success",
      });
      navigate("/c/roles");
      onComplete?.();
    }, pushNotification)();
  };

  const handleToggle = (checked: boolean, permissions: Permissions[]) => {
    if (checked) {
      setCustomRole({
        ...customRole,
        permissions: Array.from(
          new Set([...customRole.permissions, ...permissions])
        ),
      });
    } else {
      setCustomRole({
        ...customRole,
        permissions: customRole.permissions.filter(
          (p) => !permissions.includes(p)
        ),
      });
    }
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      <h5 className="mt-4">Permissions</h5>
      <Row className="mt-1">
        {permissionGroups.map((group, idx) => (
          <Col sm={12} md={6} lg={4} key={`group-${idx}`} className="px-2 py-2">
            <div className="bg-light-accent text-on-light-accent px-2 py-2 rounded-md h-100">
              <ToggleRow
                rightComp={<div className="font-bold">{group.name}</div>}
                check={group.permissions.some((p) =>
                  customRole.permissions.includes(p)
                )}
                onChange={(checked) => handleToggle(checked, group.permissions)}
              />
              <div className="mt-3">
                {group.permissions.map((permission, idx) => (
                  <div key={`permission-${idx}`} className="ml-4">
                    <ToggleRow
                      rightComp={
                        <div className="">{permissionToTitle(permission)}</div>
                      }
                      check={customRole.permissions.includes(permission)}
                      onChange={(checked) =>
                        handleToggle(checked, [permission])
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </Col>
        ))}
      </Row>
      <Row className="justify-content-end mt-4 align-items-center">
        <Col
          hidden={!id || role?.is_default}
          md="auto"
          sm="auto"
          xs="auto"
          className="p-0"
        >
          <PermissionRestrictedComponent
            permissions={[Permissions.ARCHIVE_ROLE]}
          >
            <ArchiveButton
              label="Archive Role"
              onClick={() => handleDeleteCustomRole()}
            />
          </PermissionRestrictedComponent>
        </Col>
        <Col md="auto" sm="auto" xs="auto" className="">
          <PermissionRestrictedComponent
            permissions={[Permissions.CREATE_ROLE, Permissions.UPDATE_ROLE]}
          >
            <SaveButton
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            />
          </PermissionRestrictedComponent>
        </Col>
      </Row>
    </div>
  );
}
