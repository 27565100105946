import API from "../../api";
import { IFeePayment, IFeePaymentExtended } from "crm_core";

export class FeeRecordService extends API {
  constructor() {
    super("/fee-record");
  }
  async getFeeRecords(): Promise<IFeePaymentExtended[]> {
    const res = await this._get({});
    return res.data as IFeePaymentExtended[];
  }

  async createPaymentRecord(data: IFeePayment): Promise<IFeePayment> {
    const res = await this._post({ url: "/payment", body: data });
    return res.data as IFeePayment;
  }

  async getPaymentRecord(id: string): Promise<IFeePayment> {
    const res = await this._get({ url: `/payment/${id}` });
    return res.data as IFeePayment;
  }

  async updatePaymentRecord(
    id: string,
    data: Partial<IFeePayment>
  ): Promise<IFeePayment> {
    const res = await this._put({ url: `/payment/${id}`, body: data });
    return res.data as IFeePayment;
  }

  async deletePaymentRecord(id: string): Promise<IFeePayment> {
    const res = await this._delete({ url: `/payment/${id}` });
    return res.data as IFeePayment;
  }
}
