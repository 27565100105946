import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import MathInput from "react-math-keyboard";
import { addStyles } from "react-mathquill";
import SaveButton from "src/components/widgets/save_button";
import { useTheme } from "src/context/theme_context";

export interface MathInputModalProps {
  value?: string;
  onComplete?: (value: string) => Promise<void>;
  show?: boolean;
  setShow?: (value: boolean) => void;
}

addStyles();

export default function MathInputModal({
  value,
  onComplete,
  show,
  setShow,
}: MathInputModalProps) {
  const [latex, setLatex] = useState<string>(value ?? "");
  const { getThemeBasedValue } = useTheme();

  return (
    <Modal
      centered
      size={"lg"}
      show={show}
      onHide={() => setShow?.(false)}
      contentClassName={getThemeBasedValue("bg-light", "bg-dark")}
      animation={false}
      backdrop="static"
    >
      <Modal.Header closeButton closeLabel="">
        <Modal.Title>
          <h6>Add Equation</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <MathInput setValue={setLatex} />
          {/* <EditableMathField
        latex={latex}
        className="min-w-full bg-custom-grey border-0 px-2 py-2 rounded-md appearance-none leading-tight ring-0 focus:ring-1 focus:ring-dark-accent focus:outline-none"
        onChange={(mathField) => {
          setLatex(mathField.latex());
        }}
      /> */}
          <Row className="justify-content-end mt-4 align-items-center">
            <Col md="auto" sm="auto" xs="auto" className="">
              <SaveButton
                loading={false}
                label={"Submit"}
                onClick={() => onComplete?.(latex)}
              />
            </Col>
          </Row>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>Footer</Modal.Footer> */}
    </Modal>
  );
}
