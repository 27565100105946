import { Col, Row, Spinner } from "react-bootstrap";
import Button, { ButtonProps } from "./button";

export interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
  loadingText?: string;
}
export default function LoadingButton({
  loading,
  loadingText,
  ...props
}: LoadingButtonProps) {
  return (
    <div>
      <Button
        {...props}
        label={
          <Row className="m-0 align-items-center">
            <Col className="col-auto p-0">{props.label}</Col>
            <Col hidden={!loading} className="col-auto p-0 ml-2">
              <Spinner className="h-5 w-5 p-0 m-0" animation="border" />
            </Col>
          </Row>
        }
      />
    </div>
  );
}
