import { IQuestionAttachment } from "crm_core";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import LabelledField from "src/components/form_generator/labelled_field";
import SaveButton from "src/components/widgets/save_button";
import { useNotification } from "src/context/notification_context";
import { FileService } from "src/services/file";

export interface AddAttachmentProps {
  reference: string;
  onComplete?: (f?: IQuestionAttachment) => void;
  optional?: boolean;
  attachment?: IQuestionAttachment;
}

export default function AddAttachment({
  reference,
  onComplete,
  optional,
  attachment,
}: AddAttachmentProps) {
  const fileService = new FileService();
  const { pushNotification } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone();
  const [file, setFile] = useState<IQuestionAttachment | undefined>(attachment);

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setFile({
        name: acceptedFiles[0].name,
        key: `${reference}/${acceptedFiles[0].name}`,
      });
    }
  }, [acceptedFiles]);


  const handleUpload = async () => {
    if (optional && !file?.key) {
      await onComplete?.();
      return;
    }
    const fileToUpload = acceptedFiles[0];
    if (fileToUpload.name === attachment?.name) {
      await onComplete?.(file);
      return;
    }
    if (fileToUpload.size > 30 * 1024 * 1024) {
      pushNotification({
        title: "File size too large",
        message: "File size should be less than 30MB",
        type: "danger",
      });
      return;
    }
    setLoading(true);
    const fileKey = `${reference}/${fileToUpload.name}`;
    const signedUrl = await fileService.getUploadSignedUrl(
      fileKey,
      fileToUpload.size
    );
    await fetch(signedUrl, {
      method: "PUT",
      body: fileToUpload as any,
    });
    await onComplete?.(file);
    setLoading(false);
  };

  return (
    <div>
      <LabelledField label="Attachment">
        <div
          {...getRootProps({ className: "dropzone" })}
          className="border-accent border-1 border-dashed h-40 bg-custom-grey rounded-md cursor-pointer"
        >
          <input {...getInputProps()} />
          <Row
            hidden={!!file && !!file?.key}
            className="align-items-center h-full"
          >
            <Col className="w-full text-center">
              <div className="text-sm text-gray-500">
                Drag and drop your file here or click to browse
              </div>
            </Col>
          </Row>
          {file && (
            <Row className="align-items-center mt-2 h-full">
              <Col className="w-full text-center col-auto">{file.name}</Col>
            </Row>
          )}
        </div>
      </LabelledField>
      <Row className="mt-4">
        <Col className="col-auto"></Col>
        <Col className="col-auto ml-auto">
          <SaveButton
            disabled={loading || (!file && !optional)}
            loading={loading}
            label={"Upload"}
            onClick={handleUpload}
          />
        </Col>
      </Row>
    </div>
  );
}
