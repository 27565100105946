import API from "../api";
import { IDepartment } from "crm_core"

export class DepartmentService extends API {
  static NAME = "DepartmentService";

  constructor() {
    super("/department");
  }
  async getDepartments(): Promise<IDepartment[]> {
    const res = await this._get({});
    return res.data as IDepartment[];
  }

  async getDepartment(id: string): Promise<IDepartment> {
    const res = await this._get({ url: `/${id}` });
    return res.data as IDepartment;
  }

  async createDepartment(department: IDepartment): Promise<IDepartment> {
    const res = await this._post({ body: department });
    return res.data as IDepartment;
  }

  async updateDepartment(department: IDepartment): Promise<IDepartment> {
    const res = await this._put({
      url: `/${department._id}`,
      body: department,
    });
    return res.data as IDepartment;
  }

  async deleteDepartment(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }
}
