import { IOrganization } from "../../hierarchy";
import { IExportConfig } from "../../utils";
import { IPerson, IStaffMember, IStudent } from "../lms";

export enum LogPersonType {
  Student = "student",
  StaffMember = "staff_member",
}

export interface IAttendanceLog {
  _id?: string;
  person: string | IPerson;
  date: string; // YYYYMMDD
  time?: string; // hh:mm a
  expected_check_in_time?: Date;
  expected_check_out_time?: Date;
  grace_check_in_time?: Date;
  grace_check_out_time?: Date;
  check_in_time?: Date;
  check_out_time?: Date;
  half_day?: boolean;
  grace_used?: number;
  penalty?: number;
  person_type: LogPersonType;
  person_ref?: string;
  organization: string | IOrganization;
  shift?: string;
}

export interface IPersonAttendance extends IAttendanceLog {
  person: IPerson;
  expected_check_in_time: Date;
  expected_check_out_time: Date;
}

export interface IStaffAttendance extends IPersonAttendance {
  person: IStaffMember;
  grace_check_in_time: Date;
  grace_check_out_time: Date;
  max_graces: number;
}

export const ExportStaffAttendanceForDayConfig: IExportConfig<IStaffAttendance> =
  {
    fileName: "Staff Attendance For Day.xlsx",
    headers: [
      { label: "Date", key: "date" },
      { label: "First Name", key: "person.first_name" },
      { label: "Last Name", key: "person.last_name" },
      { label: "Expected Check In Time", key: "expected_check_in_time" },
      { label: "Expected Check Out Time", key: "expected_check_out_time" },
      { label: "Grace Check In Time", key: "grace_check_in_time" },
      { label: "Grace Check Out Time", key: "grace_check_out_time" },
      { label: "Check In Time", key: "check_in_time" },
      { label: "Check Out Time", key: "check_out_time" },
      { label: "Grace Used", key: "grace_used" },
      { label: "Penalty", key: "penalty" },
    ],
  };

export interface IStudentAttendance extends IPersonAttendance {
  person: IStudent;
}

export interface IAggregatedAttendance {
  marked: number;
  not_marked: number;
  present: number;
  absent: number;
  half_day: number;
  penalty: number;
}

export interface IDateAttendanceData extends IAggregatedAttendance {
  date: string;
  holiday?: boolean;
}

export interface ITimeAttendanceData {
  time: string;
  logs: IStudentAttendance[];
  activity: string;
}

export interface IStaffMemberAggregatedAttendance
  extends IAggregatedAttendance {
  person: IStaffMember;
}

export interface IStudentAggregatedAttendance extends IAggregatedAttendance {
  person: IStudent;
}

export const ExportStaffAttendanceSummaryConfig: IExportConfig<IStaffMemberAggregatedAttendance> =
  {
    fileName: "Staff Attendance Summary.xlsx",
    headers: [
      { label: "First Name", key: "person.first_name" },
      { label: "Last Name", key: "person.last_name" },
      { label: "Marked", key: "marked" },
      { label: "Not Marked", key: "not_marked" },
      { label: "Present", key: "present" },
      { label: "Absent", key: "absent" },
      { label: "Half Day", key: "half_day" },
      { label: "Penalty", key: "penalty" },
    ],
  };

export const ExportStudentAttendanceSummaryConfig: IExportConfig<IStudentAggregatedAttendance> =
  {
    fileName: "Student Attendance Summary.xlsx",
    headers: [
      { label: "First Name", key: "person.first_name" },
      { label: "Last Name", key: "person.last_name" },
      { label: "Marked", key: "marked" },
      { label: "Not Marked", key: "not_marked" },
      { label: "Present", key: "present" },
      { label: "Absent", key: "absent" },
    ],
  };
