import {
  ICorrectOption,
  IOption,
  IQuestion,
  ISection,
  QuestionType,
  QuestionTypeOptions,
} from "crm_core";
import Option from "./option";
import FormSelect, { FormOption } from "src/components/form_select";
import { useEffect, useState } from "react";
import LabelledField from "src/components/form_generator/labelled_field";
import Button from "src/components/buttons/button";
import MarkDownEditor from "src/components/text_fields/markdown_editor";
import { Col, Row } from "react-bootstrap";
import { PlusIcon } from "@heroicons/react/20/solid";
import LoadingButton from "src/components/buttons/loading_button";
import { deepCopy, getMarksDescription } from "src/utils/functions";
import { useNotification } from "src/context/notification_context";
import PdfUpload from "src/components/file_upload/pdf_upload";
import Attachments from "./attachments";

interface QuestionProps {
  question: IQuestion;
  editable?: boolean;
  onSave?: (v: IQuestion) => Promise<void>;
  onCancelEdit?: () => void;
  submitter?: boolean;
  sectionOptions?: FormOption[];
  onAnswerChange?: (answer: ICorrectOption) => Promise<void>;
  onAnswerClear?: () => Promise<void>;
  section?: ISection;
  evaluator?: boolean;
  hideSaveCancel?: boolean;
  onChange?: (v: IQuestion) => void;
}

export default function Question({
  question,
  editable,
  onSave,
  onCancelEdit,
  submitter = false,
  sectionOptions = [],
  onAnswerChange = async (v) => Promise.resolve(),
  section,
  evaluator,
  onAnswerClear = async () => Promise.resolve(),
  hideSaveCancel = false,
  onChange = (v) => {},
}: QuestionProps) {
  const [updating, setUpdating] = useState(false);
  const [value, setValue] = useState<IQuestion>(deepCopy(question));
  const [isChanged, setIsChanged] = useState(false);
  const { pushNotification } = useNotification();

  useEffect(() => {
    const isChanged = JSON.stringify(value) != JSON.stringify(question);
    setIsChanged(isChanged);
    if (isChanged) {
      onChange(value);
    }
  }, [value]);

  useEffect(() => {
    const isChanged = JSON.stringify(value) != JSON.stringify(question);
    if (isChanged) {
      setValue(deepCopy(question));
    }
  }, [question]);

  const addOption = () => {
    setValue((v) => ({
      ...v,
      options: [...(value.options ?? []), { text: "" }],
    }));
  };

  const removeOption = (index: number) => {
    setValue((v) => ({
      ...v,
      options: (value.options ?? []).filter((_, i) => i != index),
    }));
  };

  const handleOptionChange = (index: number, option: IOption) => {
    setValue((v) => ({
      ...v,
      options: (value.options ?? []).map((o, i) => (i == index ? option : o)),
    }));
  };

  const handleOnSave = async (value: IQuestion) => {
    setUpdating(true);
    try {
      await onSave?.(value);
      pushNotification({
        title: "Success",
        message: "Question saved",
        type: "success",
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      });
    } catch (e) {
      pushNotification({
        title: "Error",
        message: "Something went wrong",
        type: "danger",
      });
    }
    setUpdating(false);
  };

  const handleOnCancelEdit = () => {
    onCancelEdit?.();
  };

  const isSelectedOption = (idx: number) => {
    return value.correct_option.option_ids.includes(idx);
  };

  const handleOnSelect = async (
    idx?: number,
    isSelected?: boolean,
    fileLocation?: string,
    explanation?: string
  ) => {
    let optionIds = value.correct_option.option_ids;

    if (idx != undefined && isSelected) {
      if (value.type == QuestionType.SingleAnswer) {
        optionIds = [idx];
      } else {
        optionIds.push(idx);
      }
    } else if (idx != undefined) {
      if (value.type == QuestionType.SingleAnswer) {
        optionIds = [];
      } else {
        optionIds = optionIds.filter((i) => i != idx);
      }
    }
    let updatedCorrectOption: ICorrectOption = {
      ...(value.correct_option ?? {}),
      option_ids: optionIds,
      file: fileLocation,
      explanation: explanation,
    };
    setValue({
      ...value,
      correct_option: updatedCorrectOption,
    });
    await onAnswerChange(updatedCorrectOption);
  };

  const questionTypeLabel =
    QuestionTypeOptions.find((t) => t.value == value.type)?.label ?? "";

  return (
    <div className="">
      <Row hidden={editable || submitter} className="align-items-center">
        <Col className="col-auto text-muted">{questionTypeLabel}</Col>
        <Col className="col-auto text-muted ml-auto">
          {section?.name ?? "--"}:{" "}
          {getMarksDescription(section?.correct_marks, section?.negative_marks)}
        </Col>
      </Row>
      <div hidden={!editable} className="pb-2 row">
        <div className="md:w-1/3 sm:w-72 xsm:w-1/2">
          <LabelledField label="Question Type">
            <FormSelect
              value={value.type}
              onChange={(v) =>
                setValue((q) => ({ ...q, type: v as QuestionType }))
              }
              options={QuestionTypeOptions}
            />
          </LabelledField>
        </div>
        <div
          hidden={sectionOptions.length == 0}
          className="md:w-1/3 sm:w-72 xsm:w-1/2"
        >
          <LabelledField label="Section">
            <FormSelect
              value={value.section as string}
              onChange={(v) =>
                setValue((q) => ({ ...q, section: v as string }))
              }
              options={sectionOptions}
            />
          </LabelledField>
        </div>
      </div>
      {/* <LabelledField label="Question"> */}
      <MarkDownEditor
        label="Question"
        viewer={!editable}
        disabled={!editable}
        value={value.text}
        onChange={(v) => setValue((q) => ({ ...q, text: v }))}
      />
      <div
        hidden={!editable && (!question.files || question.files.length == 0)}
        className="mt-2 align-items-center"
      >
        <Attachments
          attachments={value.files ?? []}
          editable={editable}
          onAdd={(attachment) =>
            handleOnSave({
              ...value,
              files: [...(value.files ?? []), attachment],
            })
          }
          onRemove={(attachment) =>
            setValue((v) => ({
              ...v,
              files: (value.files ?? []).filter((f) => f._id != attachment._id),
            }))
          }
          reference={`/questions/${value._id}`}
        />
      </div>
      {/* </LabelledField> */}
      {[QuestionType.SingleAnswer, QuestionType.MultipleAnswer].includes(
        value.type
      ) && (
        <LabelledField className="mt-2" label="Options">
          <div className="">
            {(value.options ?? []).map((o, i) => (
              <div className="my-2" key={`option-${i}`}>
                <Option
                  type={value.type}
                  submitter={submitter}
                  editable={editable}
                  option={o}
                  onChange={(op) => handleOptionChange(i, op)}
                  onSelect={(v) => handleOnSelect(i, v)}
                  isSelected={isSelectedOption(i)}
                  onDelete={() => removeOption(i)}
                />
              </div>
            ))}
            <Row hidden={!editable} className="text-center w-full">
              <Col></Col>
              <Col>
                <Button
                  icon={<PlusIcon className="h-5 w-5" />}
                  onClick={addOption}
                  color="accent"
                  className="mt-2"
                  rounded
                />
              </Col>
            </Row>
          </div>
        </LabelledField>
      )}
      {[QuestionType.FileUpload].includes(value.type) && (
        <LabelledField
          className="mt-2"
          label={evaluator ? "Submitted File" : "Select File"}
        >
          <PdfUpload
            viewer={evaluator}
            disabled={!submitter}
            placeholder={value.correct_option.file}
            onUpload={(v) => handleOnSelect(undefined, undefined, v)}
          />
        </LabelledField>
      )}
      {((!submitter && !evaluator) ||
        [QuestionType.Subjective].includes(value.type)) && (
        // <LabelledField
        //   className="mt-2"
        //   label={submitter || evaluator ? "Answer" : "Explanation"}
        // >
        <div className="mt-2">
          <MarkDownEditor
            label={submitter || evaluator ? "Answer" : "Explanation"}
            viewer={evaluator || (!editable && !submitter)}
            value={value.correct_option.explanation as any}
            disabled={!editable && !submitter}
            onChange={(v) => handleOnSelect(undefined, undefined, undefined, v)}
          />
        </div>
        // </LabelledField>
      )}
      {submitter &&
        value.correct_option &&
        (value.correct_option.explanation ||
          value.correct_option.file ||
          value.correct_option.option_ids?.length > 0) && (
          <Row className="mt-2">
            <Col className="col-auto"></Col>
            <Col className="col-auto ml-auto">
              <Button
                isSlim
                color="transparent"
                className="text-sm text-accent"
                noshadow
                label={"Clear Answer"}
                onClick={onAnswerClear}
              />
            </Col>
          </Row>
        )}
      <Row
        hidden={!editable || hideSaveCancel}
        className="pr-2 pt-2 justify-content-end"
      >
        <Col sm="auto" xs="auto">
          <LoadingButton
            disabled={!isChanged}
            loading={updating}
            color="accent"
            label="Save"
            contentClass="font-light"
            rounded
            className="px-4"
            onClick={() => handleOnSave(value)}
          />
        </Col>
        <Col sm="auto" xs="auto" className="p-0">
          <Button
            color="accent"
            label="Cancel"
            contentClass="font-light"
            rounded
            className="px-4"
            onClick={handleOnCancelEdit}
          />
        </Col>
      </Row>
    </div>
  );
}
