import { Spinner } from "react-bootstrap";

export function Loader({children, text}: {children?: React.ReactNode, text?: string}) {
  return (
    <div className="text-center">
      <Spinner variant="secondary" animation="grow">
        {children}
      </Spinner>
      <div className="text-center">{text ?? "Fetching data"}</div>
    </div>
  );
}
