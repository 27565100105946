import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import LabelledField from "./labelled_field";

interface DateTimeRangePickerProps {
  startDate: Date;
  endDate?: Date;
  onChange: (startDate: Date, endDate?: Date | null) => void;
  required?: boolean;
}

export default function DateTimeRangePicker(props: DateTimeRangePickerProps) {
  const [startDate, setStartDate] = useState<Date>(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);

  useEffect(() => {
    props.onChange(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    if (props.startDate && props.startDate?.getTime() != startDate?.getTime() || props.endDate?.getTime() != endDate?.getTime()) {
      setStartDate(props.startDate);
      setEndDate(props.endDate);
    }
  }, [props.startDate, props.endDate]);

  return (
    <Row className="m-0 p-0">
      <Col className="pl-0">
        <CustomDatePicker
          selected={startDate}
          onChange={(date: any) => (date ? setStartDate(date) : null)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd MMMM yyyy hh:mm aa"
          label="Start Date"
          required={props.required}
        />
      </Col>
      <Col className="pr-0">
        <CustomDatePicker
          selected={endDate}
          onChange={(date: any) => (date ? setEndDate(date) : null)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat="dd MMMM yyyy hh:mm aa"
          label="End Date"
          required={false}
        />
      </Col>
    </Row>
  );
}

export function CustomDatePicker(props: any) {
  return (
    <LabelledField label={props.label} required={props.required}>
      <DatePicker
        className="w-full p-2 text-sm ring-0.5 ring-custom-grey bg-custom-grey rounded-md  outline-none focus:outline-none  focus:ring-1 focus:ring-dark-accent"
        {...props}
        showTimeSelect
      />
    </LabelledField>
  );
}
