import { Link } from "react-router-dom";
import { useTheme } from "src/context/theme_context";
import { joinClasses } from "src/utils/functions";

interface LinkTextProps {
  to?: string;
  className?: string;
  children?: any;
  onClick?: () => void;
}

export default function LinkText(props: LinkTextProps) {
  const { theme } = useTheme();
  return (
    <>
      {props.to ? (
        <Link
          to={props.to}
          className={joinClasses(
            "text-muted hover:text-black text-decoration-none",
            props.className
          )}
        >
          {props.children}
        </Link>
      ) : (
        <div
          className={joinClasses(
            "text-muted hover:text-black text-decoration-none",
            props.className
          )}
          onClick={props.onClick}
        >
          {props.children}
        </div>
      )}
    </>
  );
}
