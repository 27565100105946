import { IOrganization } from "../../hierarchy";
import { IBatch, IDepartment, ISession, IStudent } from "../lms";
import { IFeeStructure } from "./structure";

export enum PaymentMode {
  CASH = "CASH",
  CHEQUE = "CHEQUE",
  BANK_TRANSFER = "BANK_TRANSFER",
  CARD = "CARD",
  UPI = "UPI",
}

export const PaymentModeOptions = [
  { value: PaymentMode.CASH, label: "Cash" },
  { value: PaymentMode.CHEQUE, label: "Cheque" },
  { value: PaymentMode.BANK_TRANSFER, label: "Bank Transfer" },
  { value: PaymentMode.CARD, label: "Card" },
  { value: PaymentMode.UPI, label: "UPI" },
];

export enum PaymentStatus {
  PAID = "PAID",
  UNPAID = "UNPAID",
  OVERDUE = "OVERDUE",
}

export const PaymentStatusOptions = [
  { value: PaymentStatus.PAID, label: "Paid" },
  { value: PaymentStatus.UNPAID, label: "Unpaid" },
  { value: PaymentStatus.OVERDUE, label: "Overdue" },
];

export interface IFeePayment {
  _id?: string;
  payment_mode?: PaymentMode;
  payment_reference?: string;
  discount?: number;
  fine?: number;
  amount: number;
  paid_amount?: number;
  paid_fine?: number;
  date?: Date;
  remarks?: string;
  student:
    | string
    | (Partial<IStudent> & { department: IDepartment; batch: IBatch });
  structure: string | IFeeStructure;
  organization?: string | IOrganization;
  session?: string | ISession;
  status: PaymentStatus;
  due_date: string;
  installment: string;
}

export interface IFeePaymentExtended extends IFeePayment {
  _id: string;
  student: IStudent & { department: IDepartment; batch: IBatch };
  structure: IFeeStructure;
}
