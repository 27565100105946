import { Col, Row } from "react-bootstrap";
import { FormFieldType } from "src/utils/constants";
import { joinClasses } from "src/utils/functions";
import TextField from "./text_field";
import RangeSlider from "./range_slider";
import DateRangePicker from "./date_range_picker";
import TextAreaEditor from "./text_area_editor";
import FormSelect from "../form_select";
import ToggleButton from "../buttons/toggle_button";
import SimpleDatePicker from "./date_picker";
import DatetimePicker from "./datetime_picker";
import DateTimeRangePicker from "./datetime_range_picker";
import TimeRangePicker from "./time_range_picker";

export interface FormField<T> {
  type: FormFieldType;
  value: T;
  onChange?: (value: T) => void;
  extras?: any;
  required?: boolean;
  updatable?: boolean;
}

export default function FormGenerator({
  getFields,
  item,
}: {
  getFields: (i?: any) => FormField<any>[][];
  item?: any;
}) {
  const currentFields = getFields(item);
  return (
    <Col className="m-0 p-0">
      {currentFields.map((row, rowIndex) => {
        let yPaddingClass = "p-0";
        if (currentFields.length > 1) {
          if (currentFields.length == rowIndex + 1) {
            yPaddingClass = "pt-0";
          } else {
            yPaddingClass = "pb-3";
          }
        }
        return (
          <Row className={joinClasses(yPaddingClass, "m-0")} key={rowIndex}>
            {row.map((field, fieldIndex) => {
              let xPaddingClass = "p-0";
              if (currentFields[rowIndex].length > 1) {
                if (currentFields[rowIndex].length == fieldIndex + 1) {
                  xPaddingClass = "pr-0";
                } else {
                  xPaddingClass = "pl-0";
                }
              }

              if (field.type === FormFieldType.TEXT) {
                return (
                  <Col className={joinClasses(xPaddingClass)} key={fieldIndex}>
                    <TextField
                      className=""
                      value={field.value}
                      onChange={field.onChange as any}
                      required={field.required}
                      {...field.extras}
                    />
                  </Col>
                );
              } else if (field.type === FormFieldType.OBSCURED_TEXT) {
                return (
                  <Col className={joinClasses(xPaddingClass)} key={fieldIndex}>
                    <TextField
                      className=""
                      value={field.value}
                      onChange={field.onChange as any}
                      required={field.required}
                      type="password"
                      {...field.extras}
                    />
                  </Col>
                );
              } else if (field.type === FormFieldType.TEXTAREA) {
                return (
                  <Col className={joinClasses(xPaddingClass)} key={fieldIndex}>
                    <TextField
                      className=""
                      value={field.value}
                      onChange={field.onChange as any}
                      required={field.required}
                      rows={3}
                      {...field.extras}
                    />
                  </Col>
                );
              } else if (field.type === FormFieldType.NUMBER) {
                return (
                  <Col className={joinClasses(xPaddingClass)} key={fieldIndex}>
                    <TextField
                      className=""
                      type="number"
                      value={field.value}
                      onChange={(v) => field.onChange?.(Number(v))}
                      required={field.required}
                      {...field.extras}
                    />
                  </Col>
                );
              } else if (field.type === FormFieldType.SELECT) {
                return (
                  <Col className={joinClasses(xPaddingClass)} key={fieldIndex}>
                    <FormSelect
                      className=""
                      value={field.value}
                      onChange={field.onChange as any}
                      required={field.required}
                      {...field.extras}
                    />
                  </Col>
                );
              } else if (field.type == FormFieldType.DATE) {
                return (
                  <Col className={joinClasses(xPaddingClass)} key={fieldIndex}>
                    <SimpleDatePicker
                      value={field.value}
                      onChange={field.onChange as any}
                      label={field.extras.label}
                      required={field.required}
                      {...field.extras}
                    />
                  </Col>
                );
              } else if (field.type == FormFieldType.DATETIME) {
                return (
                  <Col className={joinClasses(xPaddingClass)} key={fieldIndex}>
                    <DatetimePicker
                      value={field.value}
                      onChange={field.onChange as any}
                      label={field.extras.label}
                      required={field.required}
                    />
                  </Col>
                );
              } else if (field.type == FormFieldType.DATETIMERANGE) {
                return (
                  <Col className={joinClasses(xPaddingClass)} key={fieldIndex}>
                    <DateTimeRangePicker
                      onChange={field.onChange as any}
                      required={field.required}
                      {...field.extras}
                    />
                  </Col>
                );
              } else if (field.type == FormFieldType.DATERANGE) {
                return (
                  <Col className={joinClasses(xPaddingClass)} key={fieldIndex}>
                    <DateRangePicker
                      onChange={field.onChange as any}
                      required={field.required}
                      {...field.extras}
                    />
                  </Col>
                );
              } else if (field.type == FormFieldType.TIMERANGE) {
                return (
                  <Col className={joinClasses(xPaddingClass)} key={fieldIndex}>
                    <TimeRangePicker
                      onChange={field.onChange as any}
                      required={field.required}
                      {...field.extras}
                    />
                  </Col>
                );
              } else if (field.type == FormFieldType.MARKDOWN) {
                return (
                  <Col className={joinClasses(xPaddingClass)} key={fieldIndex}>
                    <TextAreaEditor
                      value={field.value}
                      onChange={field.onChange as any}
                      required={field.required}
                      {...field.extras}
                    />
                  </Col>
                );
              } else if (field.type == FormFieldType.SLIDER) {
                return (
                  <Col className={joinClasses(xPaddingClass)} key={fieldIndex}>
                    <RangeSlider
                      value={30}
                      minValue={0}
                      maxValue={100}
                      onChange={field.onChange as any}
                    />
                  </Col>
                );
              } else if (field.type == FormFieldType.TOGGLE) {
                return (
                  <Col className={joinClasses(xPaddingClass)} key={fieldIndex}>
                    <ToggleButton
                      check={field.value}
                      onChange={field.onChange as any}
                      label={field.extras.label}
                      {...field.extras}
                    />
                  </Col>
                );
              } else if (field.type == FormFieldType.CUSTOM) {
                return (
                  <Col className={joinClasses(xPaddingClass)} key={fieldIndex}>
                    <div>{field.value}</div>
                  </Col>
                );
              }
              return <>TYPE NOT IMPLEMENTED</>;
            })}
          </Row>
        );
      })}
    </Col>
  );
}
