import { PencilIcon } from "@heroicons/react/20/solid";
import { ICustomRole, Permissions } from "crm_core";
import { Col, Row } from "react-bootstrap";
import Button from "src/components/buttons/button";
import { ModalTypes, useModal } from "src/context/modal_context";
import { joinClasses } from "src/utils/functions";
import { AddUpdateCustomRoleProps } from "./add_update";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";

interface RoleCardProps {
  role: ICustomRole;
  forceRefresh?: () => void;
}

export function RoleCard({ role, forceRefresh }: RoleCardProps) {
  const {
    setModalType,
    setModalSize,
    setModalHeading,
    setShowModal,
    setComponentProps,
  } = useModal();

  const handleEditRole = () => {
    setModalType(ModalTypes.CUSTOM_ROLE);
    setModalSize("xl");
    setModalHeading("Edit Role");
    setComponentProps({
      role,
      onComplete: () => {
        setShowModal(false);
        forceRefresh?.();
      },
    } as AddUpdateCustomRoleProps);
    setShowModal(true);
  };

  return (
    <a className="text-inherit text-decoration-none">
      <div className={joinClasses("bg-light-accent text-on-light-accent py-3 px-3 rounded-md")}>
        <Row className="m-0">
          <Col className="p-0 col-auto">{role.name}</Col>
          <Col className="p-0 col-auto ml-auto">
            <PermissionRestrictedComponent
              permissions={[Permissions.UPDATE_ROLE]}
            >
              <Button
                label={"Edit"}
                onClick={handleEditRole}
                isSlim
                className="px-2 py-1 text-sm"
                color="transparent"
                icon={<PencilIcon className="h-3 w-3 mr-1" />}
              />
            </PermissionRestrictedComponent>
          </Col>
        </Row>
      </div>
    </a>
  );
}
