import API from "./api";
import { IProject } from "crm_core"

export class ProjectService extends API {
  constructor() {
    super("/project");
  }
  async getProjects(): Promise<IProject[]> {
    const res = await this._get({});
    return res.data as IProject[];
  }

  async getProject(id: string): Promise<IProject> {
    const res = await this._get({ url: `/${id}` });
    return res.data as IProject;
  }

  async createProject(project: IProject): Promise<IProject> {
    const res = await this._post({ body: project });
    return res.data as IProject;
  }

  async updateProject(project: IProject): Promise<IProject> {
    const res = await this._put({ url: `/${project._id}`, body: project });
    return res.data as IProject;
  }

  async deleteProject(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }
}
