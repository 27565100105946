import MDEditor from "@uiw/react-md-editor";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import LabelledField from "./labelled_field";

export default function TextAreaEditor({
  value,
  onChange,
  ...props
}: {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  className?: string;
  required?: boolean;
}) {
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  useEffect(() => {
    onChange(val);
  }, [val]);

  return (
    <Row data-color-mode="light" className="m-0 p-0 w-full">
      <LabelledField
        label={props.label}
        className={props.className}
        required={props.required}
      >
        <MDEditor
          previewOptions={{
            className: "bg-custom-grey",
          }}
          textareaProps={{
            className: "bg-custom-grey h-100",
          }}
          className="ring-0 ring-light-accent focus:ring-1"
          value={val}
          onChange={(v, e) => setVal(v ?? "")}
          height={120}
        />
      </LabelledField>
    </Row>
  );
}
