import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import SearchBox from "../text_fields/search_box";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import SaveButton from "../widgets/save_button";

interface CustomItemSelectorProps<DataType> {
  data: DataType[];
  render: (item: DataType) => JSX.Element;
  isSelected: (item: DataType) => boolean;
  searchHandler: (item: DataType, search: string) => boolean;
  onSave: (selectedItems: DataType[]) => Promise<void>;
  selectedTitle?: string;
  nonSelectedTitle?: string;
}

export default function CustomItemSelector<DataType>({
  data,
  render,
  isSelected,
  searchHandler,
  onSave,
  selectedTitle = "Selected Items",
  nonSelectedTitle = "All Items",
}: CustomItemSelectorProps<DataType>) {
  const [loading, setLoading] = useState(false);
  const [nonSelectedItems, setNonSelectedItems] = useState<DataType[]>([]);
  const [selectedItems, setSelectedItems] = useState<DataType[]>([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const nonSelected = data.filter((item) => !isSelected(item));
    setNonSelectedItems(nonSelected);
    setSelectedItems(data.filter((item) => isSelected(item)));
  }, [data]);

  const handleOnSelect = (item: DataType) => {
    const newSelectedItems = [...selectedItems, item];
    const newNonSelectedItems = nonSelectedItems.filter((i) => i !== item);
    setSelectedItems(newSelectedItems);
    setNonSelectedItems(newNonSelectedItems);
  };

  const handleOnRemove = (item: DataType) => {
    const newSelectedItems = selectedItems.filter((i) => i !== item);
    const newNonSelectedItems = [...nonSelectedItems, item];
    setSelectedItems(newSelectedItems);
    setNonSelectedItems(newNonSelectedItems);
  };

  const handleSave = async () => {
    setLoading(true);
    await onSave(selectedItems);
    setLoading(false);
  };

  const handleSelectAll = () => {
    const newSelectedItems = [...selectedItems, ...nonSelectedItems];
    setSelectedItems(newSelectedItems);
    setNonSelectedItems([]);
  };

  const handleRemoveAll = () => {
    const newNonSelectedItems = [...nonSelectedItems, ...selectedItems];
    setSelectedItems([]);
    setNonSelectedItems(newNonSelectedItems);
  };

  return (
    <div className="">
      <Row className="ml-1 mb-3">
        <Col className="col-auto">
          <SearchBox
            placeholder="Search"
            value={search}
            onChange={(v) => setSearch(v)}
          />
        </Col>
      </Row>
      <Row className="mx-2">
        <Col className="col-6 border-1 rounded-md py-3 px-3">
          <h6 className="text-muted m-0">
            {nonSelectedTitle}{" "}
            <span className="text-sm">({nonSelectedItems.length})</span>
          </h6>
          <a
            onClick={handleSelectAll}
            href="#"
            className="text-sm text-decoration-none"
          >
            Select All
          </a>

          <div className="mt-3">
            {nonSelectedItems
              .filter((i) => searchHandler(i, search))
              .map((item, idx) => (
                <div key={`selectitem-${idx}`}>
                  <ListTile
                    item={item}
                    isSelected={false}
                    onClick={handleOnSelect}
                    render={render}
                  />
                </div>
              ))}
          </div>
        </Col>
        <Col className="col-6 border-1 rounded-md py-3 px-3">
          <h6 className="text-muted m-0">
            {selectedTitle}{" "}
            <span className="text-sm">({selectedItems.length})</span>
          </h6>
          <a
            onClick={handleRemoveAll}
            href="#"
            className="text-sm text-decoration-none"
          >
            Remove All
          </a>
          <div className="mt-3">
            {selectedItems
              .filter((i) => searchHandler(i, search))
              .map((item, idx) => (
                <div key={`deselectitem-${idx}`}>
                  <ListTile
                    item={item}
                    isSelected={true}
                    onClick={handleOnRemove}
                    render={render}
                  />
                </div>
              ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="col-auto ml-auto pt-4 mr-2">
          <SaveButton loading={loading} onClick={handleSave} />
        </Col>
      </Row>
    </div>
  );
}

interface ListTileProps<DataType> {
  item: DataType;
  isSelected: boolean;
  onClick: (item: DataType) => void;
  render: (item: DataType) => JSX.Element;
}

function ListTile<DataType>({
  item,
  isSelected,
  onClick,
  render,
}: ListTileProps<DataType>) {
  return (
    <Row
      onClick={() => onClick(item)}
      className="align-items-center py-2 mx-1 border-1 bg-custom-grey rounded-md hover:bg-template-card mb-2"
    >
      <Col className="col-auto">{render(item)}</Col>
      <Col className="col-auto ml-auto">
        {isSelected ? (
          <ArrowLeftIcon className="h-4 w-4 text-muted" />
        ) : (
          <ArrowRightIcon className="h-4 w-4 text-muted" />
        )}
      </Col>
    </Row>
  );
}
