import { IExportHeader } from "../models/utils/common_interfaces";

export function generateHeaders<T>(data: T[], headers: IExportHeader<T>[]) {
  const newHeaders: IExportHeader<T>[] = [];
  const processObject = (
    object: any,
    parentKey?: string,
    parentLabel?: string,
    child?: boolean,
    whiltelisted_leaves?: string[]
  ) => {
    for (const key in object) {
      const header = headers.find((h) => h.key === key);
      const label =
        !child && header
          ? header?.label
          : parentLabel
          ? `${parentLabel}.${key}`
          : key;
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (!child && !header) {
        continue;
      }
      if (
        object[key] &&
        typeof object[key] === "object" &&
        (header ? header.expand : true)
      ) {
        processObject(
          object[key],
          newKey,
          label,
          true,
          header?.whiltelisted_leaves || whiltelisted_leaves
        );
      } else {
        if (
          child &&
          whiltelisted_leaves &&
          !whiltelisted_leaves.includes(key)
        ) {
          continue;
        }
        newHeaders.push({
          key: newKey as any,
          label: label,
        });
      }
    }
  };
  data.forEach((object) => {
    processObject(object);
  });
  for (let header of headers) {
    if ((header.key as string).includes(".")) {
      newHeaders.push(header);
    }
  }

  const newHeadersMap = new Map<string, IExportHeader<T>>();
  newHeaders.forEach((header) => {
    newHeadersMap.set(header.label, header);
  });
  newHeaders.length = 0;
  newHeadersMap.forEach((header) => {
    newHeaders.push(header);
  });
  return newHeaders;
}

export function dataToHeaders(data: any[], headers: IExportHeader<any>[]) {
  const newHeaders: IExportHeader<any>[] = [];
  const processObject = (
    object: any,
    parentKey?: string,
    parentLabel?: string,
    child?: boolean
  ) => {
    for (const key in object) {
      let header = headers.find((h) => h.label === key);
      if (!header && !parentLabel) {
        const parentKeySplit = key.split(".");
        if (parentKeySplit.length > 1) {
          header = headers.find((h) => h.label === parentKeySplit[0]);
          if (header?.expand) {
            newHeaders.push({
              key: `${header.key as string}.${parentKeySplit
                .splice(1)
                .join(".")}`,
              label: key,
            });
          }
        }
      }
      const newKey =
        !child && header
          ? header?.key
          : parentKey
          ? `${parentKey}.${key}`
          : key;
      const label =
        !child && header
          ? header?.label
          : parentLabel
          ? `${parentLabel}.${key}`
          : key;
      if (!child && !header) {
        continue;
      }
      if (object[key] && typeof object[key] === "object") {
        processObject(object[key], newKey as string, label, true);
      } else {
        newHeaders.push({
          key: newKey as any,
          label: label,
        });
      }
    }
  };
  data.forEach((object) => {
    processObject(object);
  });
  for (let header of headers) {
    if ((header.key as string).includes(".")) {
      newHeaders.push(header);
    }
  }
  const newHeadersMap = new Map<string, IExportHeader<any>>();
  newHeaders.forEach((header) => {
    newHeadersMap.set(header.key as any, header);
  });
  newHeaders.length = 0;
  newHeadersMap.forEach((header) => {
    newHeaders.push(header);
  });

  return newHeaders;
}

export const jsons2arrays = <T>(jsons: T[], headers: IExportHeader<T>[]) => {
  let headerLabels = headers.map((header) => header.label);
  let headerKeys = headers.map((header) => header.key);

  const data = jsons.map((object) =>
    headerKeys.map((header) => getHeaderValue(header as string, object))
  );
  return [headerLabels, ...data];
};

export const getHeaderValue = <T>(property: string, obj: any) => {
  const foundValue = property
    .replace(/\[([^\]]+)]/g, ".$1")
    .split(".")
    .reduce(function (o, p, i, arr) {
      // if at any point the nested keys passed do not exist, splice the array so it doesnt keep reducing
      const value = o[p];
      if (value === undefined || value === null) {
        arr.splice(1);
      } else {
        return value;
      }
    }, obj);
  // if at any point the nested keys passed do not exist then looks for key `property` in object obj
  return foundValue === undefined
    ? property in obj
      ? obj[property]
      : ""
    : foundValue;
};

export const elementOrEmpty = (element: any) =>
  typeof element === "undefined" || element === null ? "" : element;

export const joiner = (
  data: any[][],
  separator = ",",
  enclosingCharacter = '"'
) => {
  return data
    .filter((e) => e)
    .map((row) =>
      row
        .map(
          (element) =>
            `${enclosingCharacter}${elementOrEmpty(element)
              .toString()
              .replace(
                new RegExp(enclosingCharacter, "g"),
                `${enclosingCharacter}${enclosingCharacter}`
              )}${enclosingCharacter}`
        )
        .join(separator)
    )
    .join(`\n`);
};

export class CSVHandler {
  DEFAULT_SEPARATOR = ",";
  DEFAULT_ENCLOSING_CHARACTER = '"';
  toCSV<T>(
    data: T[],
    headers: IExportHeader<any>[],
    separator: string = this.DEFAULT_SEPARATOR,
    enclosingCharacter: string = this.DEFAULT_ENCLOSING_CHARACTER
  ) {
    return joiner(jsons2arrays(data, headers), separator, enclosingCharacter);
  }

  _getSanitizedLine(
    line: string,
    separator: string,
    enclosingCharacter: string
  ) {
    const splitSeparator = `${enclosingCharacter}${separator}${enclosingCharacter}`;
    let splitLines = line.split(splitSeparator);
    splitLines[0] = splitLines[0].trim().slice(1);
    splitLines[splitLines.length - 1] = splitLines[splitLines.length - 1]
      .trim()
      .slice(0, -1);
    return splitLines;
  }

  csvToJSON(
    csv: string,
    headers: IExportHeader<any>[],
    separator: string = this.DEFAULT_SEPARATOR,
    enclosingCharacter: string = this.DEFAULT_ENCLOSING_CHARACTER
  ) {
    const lines = csv.split("\n");
    const csvHeaders = this._getSanitizedLine(
      lines[0],
      separator,
      enclosingCharacter
    );
    const result = [];

    for (let i = 1; i < lines.length; i++) {
      const obj: any = {};
      const currentline = this._getSanitizedLine(
        lines[i],
        separator,
        enclosingCharacter
      );
      for (let j = 0; j < headers.length; j++) {
        const normalizedHeader = headers.find((h) => h.label == csvHeaders[j]);
        if (!normalizedHeader) continue;
        const keys = (normalizedHeader.key as string).split(".");
        let currentObj = obj;
        for (let k = 0; k < keys.length - 1; k++) {
          if (!currentObj[keys[k]]) {
            currentObj[keys[k]] = {};
          }
          currentObj = currentObj[keys[k]];
        }
        currentObj[keys[keys.length - 1]] = currentline[j]
          ? currentline[j]
          : undefined;
      }
      result.push(obj);
    }
    return result;
  }
}
