import { useTheme } from "src/context/theme_context";
import React, { useEffect } from "react";
import { IRouteData } from "src/routes";
import { NavigationProvider, useNavigation } from "./context";
import SidebarRow from "./row";
import { usePageState } from "src/context/page_state_context";
import Logo from "../logo";

interface SideBarProps {
  routes: IRouteData[];
  side?: "left" | "right";
  width?: string;
  fixedWidth?: string;
  variableWidth?: string;
  marginTop?: string;
}

export default function SideBar(props: SideBarProps) {
  return (
    <NavigationProvider>
      <Base {...props} />
    </NavigationProvider>
  );
}

function Base({
  routes,
  side = "left",
  fixedWidth = "5vw",
  variableWidth = "10vw",
  marginTop = "0px",
}: SideBarProps) {
  const { theme } = useTheme();
  const { isSmallScreen } = usePageState();
  const { setSections, expanded, setExpanded, sections } = useNavigation();

  useEffect(() => {
    setSections(routes);
  }, [routes]);

  const handleBlur = () => {
    setExpanded(false);
  };

  const handleFocus = () => {
    setExpanded(true);
  };

  const getTransformStyle = (
    props: React.CSSProperties
  ): React.CSSProperties => {
    return {
      position: "fixed",
      height: "100%",
      backgroundColor: theme.colors.background,
      top: marginTop,
      left: side === "left" ? 0 : undefined,
      right: side === "right" ? 0 : undefined,
      zIndex: 40,
      ...props,
    };
  };

  useEffect(() => {
    const handleWindowMouseLeave = (event: MouseEvent) => {
      if (
        event.clientY <= 0 ||
        event.clientX <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        handleBlur();
      }
    };
    window.addEventListener("mouseout", handleWindowMouseLeave);
    return () => {
      window.removeEventListener("mouseout", handleWindowMouseLeave);
    };
  }, []);

  return (
    <div className="bg-accent">
      <div
        className="bg-accent"
        onMouseEnter={handleFocus}
        style={getTransformStyle({
          zIndex: 41,
          width: fixedWidth,
          // boxShadow: expanded ? undefined: "1px 0 7px 0 rgba(0, 0, 0, 0.5)",
        })}
      >
        {sections.map((section, idx) => (
          <SidebarRow key={idx} section={section} dtype="icon" />
        ))}
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: `calc(${fixedWidth}/4)`,
            width: expanded
              ? `calc(${variableWidth} + ${fixedWidth}/2)`
              : `calc(${fixedWidth}/4)`,
            paddingBottom: "10px",
            maxHeight: "60px",
          }}
          className="opacity-80 hover:opacity-100 transition-opacity duration-200 ease-in-out"
        >
          <Logo white={true} iconOnly={!expanded} />
        </div>
      </div>

      <div
        hidden={isSmallScreen}
        className="bg-accent"
        onMouseLeave={handleBlur}
        style={getTransformStyle({
          left: side === "left" ? fixedWidth : undefined,
          transform: expanded
            ? "translateX(0)"
            : `translateX(${side === "left" ? "-" : ""}100%)`,
          transition: "transform 0.2s ease-in-out",
          width: variableWidth,
          // boxShadow: "1px 0 7px 0 rgba(0, 0, 0, 0.5)",
        })}
      >
        {sections.map((section, idx) => (
          <SidebarRow key={idx} section={section} dtype="title" />
        ))}
      </div>
    </div>
  );
}
