import { DEFAULT_DATE_FORMAT, IDateAttendanceData, UserRole } from "crm_core";
import moment from "moment";
import { useEffect, useState } from "react";
import { Loader } from "src/components/loader";
import { useNotification } from "src/context/notification_context";
import { PeopleAttendanceService } from "src/services/institute/attendance/people";
import { catchRequest } from "src/utils/functions";
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  Line,
  LineChart,
} from "recharts";
import { Col, Row } from "react-bootstrap";
import DateRangePicker from "src/components/form_generator/date_range_picker";
import CustomCard, { CustomCardProps } from "src/components/custom_card";
import Button from "src/components/buttons/button";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useAuth } from "src/context/auth_context";

export enum AttendanceReportEntityType {
  STUDENT = "STUDENT",
  STAFF_MEMBER = "STAFF_MEMBER",
  CLASSROOM = "CLASSROOM",
}

interface EntityAttendanceGraphProps {
  entityId?: string;
  cardProps?: Partial<CustomCardProps>;
  entityType?: AttendanceReportEntityType;
}

export default function EntityAttendanceGraph({
  entityId,
  cardProps = {},
  entityType = AttendanceReportEntityType.STAFF_MEMBER,
}: EntityAttendanceGraphProps) {
  const { selectedAuthorization } = useAuth();
  const service = new PeopleAttendanceService();
  const { pushNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(
    moment().subtract(15, "days").format(DEFAULT_DATE_FORMAT)
  );
  const [end, setEnd] = useState(moment().format(DEFAULT_DATE_FORMAT));
  const [dateRangeAttendance, setDateRangeAttendance] = useState<
    IDateAttendanceData[]
  >([]);

  const fetchDateRangeAttendance = async (start: string, end: string) => {
    setLoading(true);
    await catchRequest(async () => {
      const entityToFunction = {
        [AttendanceReportEntityType.STUDENT]: () =>
          service.getStudentAttendanceSummary(start, end, entityId),
        [AttendanceReportEntityType.STAFF_MEMBER]: () =>
          service.getStaffAttendanceSummary(start, end, entityId),
        [AttendanceReportEntityType.CLASSROOM]: () =>
          service.getStudentAttendanceMarkingStatus(start, end, entityId),
      };
      const res = await entityToFunction[entityType]();
      setDateRangeAttendance(res);
    }, pushNotification)();
    setLoading(false);
  };

  useEffect(() => {
    fetchDateRangeAttendance(start, end);
  }, [start, end]);

  return (
    <CustomCard
      slim
      title="Staff Attendance Summary"
      headerRight={
        <Row className="m-0">
          <Col
            hidden={selectedAuthorization.role == UserRole.Student}
            className="p-0 col-auto"
          >
            <DateRangePicker
              startLabel={""}
              endLabel={""}
              startDate={start}
              endDate={end}
              onChange={(start, end) => {
                setStart(start);
                end ? setEnd(end) : null;
              }}
            />
          </Col>
          <Col hidden={!!entityId} className="p-0 col-auto ml-auto mr-3">
            <Button
              label="View Details"
              icon={<ArrowTopRightOnSquareIcon className="h-4 w-4 mr-2" />}
              href="/c/attendance/analytics"
              isSlim
              className="px-2 py-1"
              color="accent"
            />
          </Col>
        </Row>
      }
      {...cardProps}
    >
      <div hidden={!loading}>
        <Loader />
      </div>
      <ResponsiveContainer
        className="bg-white py-2 rounded-md"
        width="100%"
        height={300}
      >
        <LineChart
          data={dateRangeAttendance
            .filter((d) => !d.holiday)
            .map((d) => ({
              ...d,
              date: moment(d.date, DEFAULT_DATE_FORMAT).format("DD MMM"),
            }))}
          margin={{
            left: 0,
            top: 20,
            right: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="date" padding={{ left: 0, right: 0 }} />
          <YAxis
            type="number"
            domain={["dataMin", "dataMax"]}
            allowDecimals={false}
          />
          <Tooltip content={CustomTooltip} />
          <Legend />
          <Line
            type="monotone"
            strokeWidth="2"
            dataKey="present"
            stroke="#257383"
            name="Present"
          />
          {/* <Line type="monotone" strokeWidth="3" dataKey="absent" stroke="#fd151b" name="Absent" />
          <Line type="monotone" strokeWidth="3"
            dataKey="not_marked"
            stroke="#ffc200"
            name="Attendance not marked"
          />
          <Line type="monotone" strokeWidth="3" dataKey="holiday" stroke="#cffcff" name="Holiday" /> */}
        </LineChart>
      </ResponsiveContainer>
    </CustomCard>
  );
}

const CustomTooltip = ({ active, payload, label }: TooltipProps<any, any>) => {
  if (active && payload && payload.length) {
    const isHoliday = payload[0].payload.holiday;
    const unmarkedCount = payload[0].payload.not_marked;
    const presentCount = payload[0].payload.present;
    const absentCount = payload[0].payload.absent;
    const penaltyCount = payload[0].payload.penalty;
    return (
      <div className="bg-light-accent rounded-md py-2 px-3 text-on-light-accent">
        <div className="font-bold mb-2">{label}</div>
        <div>
          <div hidden={!presentCount} className="font-light">
            Present: {presentCount}
          </div>
          <div hidden={!absentCount} className="font-light">
            Absent: {absentCount}
          </div>
          <div hidden={!penaltyCount} className="font-light">
            Penalties: {penaltyCount}
          </div>
          <div hidden={!unmarkedCount} className="font-light">
            Attendance not marked: {unmarkedCount}
          </div>
        </div>
        <div hidden={!isHoliday}>
          <span className="font-light">Holiday</span>
        </div>
      </div>
    );
  }

  return null;
};
