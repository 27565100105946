import API from "./api";
import { IDashboard, IUserTimeTableSlot } from "crm_core";

export class DashboardService extends API {
  constructor() {
    super("/dashboard");
  }

  async getDashboardData(): Promise<IDashboard> {
    const res = await this._get({});
    return res.data as IDashboard;
  }

  async getUserTimetable(date: string): Promise<IUserTimeTableSlot[]> {
    const res = await this._get({ url: `/timetable/${date}` });
    return res.data as IUserTimeTableSlot[];
  }
}
