import { AuthEntityType, IAuthorization } from "./authorization";

export enum UserRole {
  SuperAdmin = "SuperAdmin",
  OrganizationAdmin = "OrganizationAdmin",
  StaffMember = "StaffMember",
  Student = "Student",
  Parent = "Parent",
}

export const roleToTitle = {
  [UserRole.SuperAdmin]: "Super Admin",
  [UserRole.OrganizationAdmin]: "Admin",
  [UserRole.StaffMember]: "Staff",
  [UserRole.Student]: "Student",
  [UserRole.Parent]: "Parent",
};

export const roleToAllowedInvitationRoles: { [role: string]: UserRole[] } = {
  [UserRole.SuperAdmin]: [
    UserRole.OrganizationAdmin,
    UserRole.StaffMember,
    UserRole.Student,
    UserRole.Parent,
  ],
  [UserRole.OrganizationAdmin]: [
    UserRole.StaffMember,
    UserRole.Student,
    UserRole.Parent,
  ],
  [UserRole.StaffMember]: [UserRole.Student, UserRole.Parent],
  [UserRole.Student]: [],
  [UserRole.Parent]: [],
};

export enum FCMPlatform {
  Android = "android",
  IOS = "ios",
  Web = "web",
}

export interface PlatformFcmToken {
  platform: FCMPlatform;
  token: string;
  expires_at: Date;
}

export interface IUser {
  [x: string]: any;
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone?: string;
  photo?: string;
  selectedAuthorization: IAuthorization;
  selectedRole: UserRole;
  created_at: number;
  last_updated: number;
  password?: string;
  passwordChangedAt?: Date;
  passwordResetAt?: string;
  passwordResetExpires?: Date;
  passwordResetToken?: string;
  active: boolean;
  subscription_id: string;
  authorizations: IAuthorization[];
  fcm_tokens: PlatformFcmToken[];
  correctPassword(
    candidatePassword: string,
    userPassword: string
  ): Promise<boolean>;
  passwordChangedAfter(JWTTimestamp: any): boolean;
  createPasswordResetToken(): string;
  authorize(entityType: AuthEntityType, entityId: string, role: string): void;
  deauthorize(entityType: AuthEntityType, entityId: string): void;
  isAuthorized(
    entityType: AuthEntityType,
    entityId: string,
    role?: UserRole
  ): boolean;
  isSuperAdmin(): boolean;
}
