// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import { CURRENT_PLATFORM, LocalStorageKeys } from "./utils/constants";
import { IUser } from "crm_core";
import { UserService } from "./services/user";
import moment from "moment";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAdVg9pfdek12yTpTScMFdJKJlbpUsnPSw",
  authDomain: "teachbond-app.firebaseapp.com",
  projectId: "teachbond-app",
  storageBucket: "teachbond-app.appspot.com",
  messagingSenderId: "406456032582",
  appId: "1:406456032582:web:1c7fc38795e05226a95947",
};

export const firebaseVapidKey =
  "BAkP9CYS_iAcK6CKmDxQh26fSGwQZVzmG8L_mBHt5cSR-KAp7yLW2q1NDiHyXC5_zx5JNv4ZP6YShiGwzbuZTmw";

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

export const requestToken = async (user: IUser) => {
  const firebaseSupported = await isSupported();
  if (!firebaseSupported) {
    console.log("Firebase not supported");
    return;
  }
  const savedData = user.fcm_tokens.find(
    (t) => t.platform === CURRENT_PLATFORM
  );
  // TODO: Commenting this out for now. Need to figure out how to handle multiple devices.
  // if (savedData && moment(savedData.expires_at) > moment()) {
  //   console.log("Token already exists and is valid");
  //   return;
  // }
  let token: string | undefined;
  const firebaseMessaging = getMessaging(firebaseApp);
  try {
    token = await getToken(firebaseMessaging, {
      vapidKey: firebaseVapidKey,
    });
  } catch (err: any) {
    console.log(
      "An error occurred while retrieving token. ",
      JSON.stringify(err)
    );
    if (err.code === "messaging/permission-blocked") {
      const lastNotified = moment(
        localStorage.getItem(LocalStorageKeys.LAST_NOTIFIED) ??
          moment().subtract(7, "days").toISOString()
      );
      if (lastNotified && moment().diff(lastNotified, "days") < 7) return;
      localStorage.setItem(
        LocalStorageKeys.LAST_NOTIFIED,
        moment().toISOString()
      );
      alert("Please allow notifications to receive updates from TeachBond");
      return;
    }
  }

  if (token) {
    new UserService().updateFcmToken(token, moment().add(30, "days").toDate());
  } else {
    console.log(
      "No registration token available. Request permission to generate one."
    );
  }
};

export const onMessageListener = async () => {
  const firebaseSupported = await isSupported();
  if (!firebaseSupported) {
    console.log("Firebase not supported");
    return;
  }
  const firebaseMessaging = getMessaging(firebaseApp);
  return await new Promise((resolve) => {
    onMessage(firebaseMessaging, (payload) => {
      resolve(payload);
    });
  });
};
