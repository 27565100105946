import {
  IFeePayment,
  IFeePaymentExtended,
  PaymentMode,
  PaymentModeOptions,
  PaymentStatus,
  PaymentStatusOptions,
  Permissions,
} from "crm_core";
import { useState } from "react";
import FormGenerator, { FormField } from "src/components/form_generator";
import { FeeRecordService } from "src/services/institute/fees/record";
import { FormFieldType } from "src/utils/constants";
import moment, { Moment } from "moment";
import { Col, Row } from "react-bootstrap";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import SaveButton from "src/components/widgets/save_button";
import { useNotification } from "src/context/notification_context";

export interface AddUpdateFeePaymentProps {
  feeRecord: IFeePaymentExtended;
  onComplete?: () => void;
}

export default function AddUpdateFeePayment({
  feeRecord,
  onComplete,
}: AddUpdateFeePaymentProps) {
  const service = new FeeRecordService();
  const [payment, setPayment] = useState<IFeePayment>({
    date: moment().toDate(),
    amount: feeRecord.amount,
    fine: feeRecord.fine ?? 0,
    discount: feeRecord.discount ?? 0,
    payment_mode: PaymentMode.CASH,
    student: feeRecord.student._id,
    structure: feeRecord.structure._id,
    status: PaymentStatus.PAID,
  } as IFeePayment);
  const [loading, setLoading] = useState<boolean>(false);
  const { pushNotification } = useNotification();

  const handleSubmit = async () => {
    if (
      feeRecord &&
      (payment.amount < 0 || payment.amount > feeRecord.amount)
    ) {
      pushNotification({
        title: "Invalid Amount",
        message: `Amount should be between 0 and ${feeRecord.amount}`,
        type: "danger",
      });
      return;
    }
    if (
      feeRecord &&
      payment.fine &&
      (payment.fine < 0 || payment.fine > (feeRecord.fine ?? 0))
    ) {
      pushNotification({
        title: "Invalid Fine",
        message: `Fine should be less than ${feeRecord.fine}`,
        type: "danger",
      });
      return;
    }
    const paymentTotal =
      payment.amount + (payment.fine ?? 0) + (payment.discount ?? 0);
    const feeRecordTotal =
      feeRecord.amount + (feeRecord.fine ?? 0) + (feeRecord.discount ?? 0);
    if (paymentTotal !== feeRecordTotal) {
      pushNotification({
        title: "Invalid Total",
        message: `Total should be equal to ${feeRecordTotal}. Amount + Fine + Discount`,
        type: "danger",
      });
      return;
    }
    setLoading(true);
    await service.updatePaymentRecord(feeRecord._id, {
      paid_amount: payment.amount,
      paid_fine: payment.fine,
      discount: payment.discount,
      status: payment.status,
    });
    setLoading(false);
    onComplete?.();
  };

  const getFields = () => {
    const allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.DATETIME,
          value: moment(payment.date),
          onChange: (date: Moment) =>
            setPayment({ ...payment, date: date.toDate() }),
          extras: {
            label: "Date",
            required: true,
          },
        },
        {
          type: FormFieldType.SELECT,
          value: payment.payment_mode,
          onChange: (v) => setPayment({ ...payment, payment_mode: v }),
          extras: {
            options: PaymentModeOptions,
            label: "Payment Mode",
            required: true,
          },
        },
        {
          type: FormFieldType.SELECT,
          value: payment.status,
          onChange: (v) => setPayment({ ...payment, status: v }),
          extras: {
            options: PaymentStatusOptions,
            label: "Status",
            required: true,
          },
        },
      ],
      [
        {
          type: FormFieldType.NUMBER,
          value: payment.amount,
          onChange: (v) => setPayment({ ...payment, amount: v }),
          extras: {
            label: "Amount",
            required: true,
          },
        },
        {
          type: FormFieldType.NUMBER,
          value: payment.fine,
          onChange: (v) => setPayment({ ...payment, fine: v }),
          extras: {
            label: "Fine",
            required: true,
          },
        },
        {
          type: FormFieldType.NUMBER,
          value: payment.discount,
          onChange: (v) => setPayment({ ...payment, discount: v }),
          extras: {
            label: "Discount",
          },
        },
      ],
      [
        {
          type: FormFieldType.TEXTAREA,
          value: payment.remarks,
          onChange: (v) => setPayment({ ...payment, remarks: v }),
          extras: {
            label: "Remarks",
          },
        },
      ],
    ];
    return allFields;
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      <Row className="justify-content-end mt-4 align-items-center">
        <Col className="col-auto ">
          <PermissionRestrictedComponent
            permissions={[Permissions.COLLECT_FEES]}
          >
            <SaveButton
              label="Submit"
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            />
          </PermissionRestrictedComponent>
        </Col>
      </Row>
    </div>
  );
}
