import { createContext, useContext, useEffect, useState } from "react";
import { IRouteData } from "src/routes";

const navigationContext = createContext<NavigationContextProps>({} as any);

export function useNavigation() {
  return useContext(navigationContext);
}

export function NavigationProvider({ children }: any) {
  return (
    <navigationContext.Provider value={useNavigationProvider()}>
      {children}
    </navigationContext.Provider>
  );
}

function useNavigationProvider() {
  const [sections, setSections] = useState<IRouteData[]>([]);
  const [selectedSection, setSelectedSection] = useState<IRouteData>();
  const [urlSectionType, setUrlSectionType] = useState<string>("");
  const [expanded, setExpanded] = useState<boolean>(false);
  const [hoveredSection, setHoveredSection] = useState<IRouteData>();

  useEffect(() => {
    if (!selectedSection && sections.length > 0) {
      let splitted = window.location.pathname.split("/");
      let layout = splitted[1];
      let path = splitted[2];
      let s = sections.find(
        (s) => s.layout === layout && s.path === `/${path}`
      );
      if (s) setSelectedSection(s);
      else {
        let s = sections.find(
          (s) => s.layout === layout && s.path?.includes(path)
        );
        if (s) setSelectedSection(s);
        else setSelectedSection(sections[0]);
      }
    }
  }, [sections, urlSectionType]);

  return {
    sections,
    selectedSection,
    setSelectedSection,
    setUrlSectionType,
    setSections,
    expanded,
    setExpanded,
    hoveredSection,
    setHoveredSection,
  } as NavigationContextProps;
}

interface NavigationContextProps {
  sections: IRouteData[];
  selectedSection: IRouteData;
  setSelectedSection: (section: IRouteData) => void;
  setUrlSectionType: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSections: React.Dispatch<React.SetStateAction<IRouteData[]>>;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  hoveredSection: IRouteData;
  setHoveredSection: React.Dispatch<React.SetStateAction<IRouteData>>;
}
