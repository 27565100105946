import { IFolderDocument } from "crm_core";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import LabelledField from "src/components/form_generator/labelled_field";
import TextField from "src/components/form_generator/text_field";
import SaveButton from "src/components/widgets/save_button";
import { useNotification } from "src/context/notification_context";
import { FileService } from "src/services/file";
import { FolderService } from "src/services/institute/content/folder";

export interface AddFileProps {
  folderId: string;
  onComplete?: () => void;
}

export default function AddFile({ folderId, onComplete }: AddFileProps) {
  const fileService = new FileService();
  const folderService = new FolderService();
  const { pushNotification } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone();
  const [file, setFile] = useState<IFolderDocument>({
    name: "",
    description: "",
  });

  const handleUpload = async () => {
    const fileToUpload = acceptedFiles[0];
    if (fileToUpload.size > 30 * 1024 * 1024) {
      pushNotification({
        title: "File size too large",
        message: "File size should be less than 30MB",
        type: "danger",
      });
      return;
    }
    setLoading(true);
    const fileKey = `folders/${folderId}/${file.name}`;
    const signedUrl = await fileService.getUploadSignedUrl(
      fileKey,
      fileToUpload.size
    );
    await fetch(signedUrl, {
      method: "PUT",
      body: fileToUpload as any,
    });
    await folderService.addToFolder(folderId, {
      name: file.name,
      description: file.description,
      key: fileKey,
    });
    setLoading(false);
    onComplete?.();
  };

  return (
    <div>
      <LabelledField label="Title">
        <TextField
          value={file?.name ?? ""}
          onChange={(v) => setFile({ ...file, name: v })}
        />
      </LabelledField>
      <LabelledField label="Description">
        <TextField
          value={file?.description ?? ""}
          onChange={(v) => setFile({ ...file, description: v })}
        />
      </LabelledField>
      <LabelledField label="File">
        <div
          {...getRootProps({ className: "dropzone" })}
          className="border-accent border-1 border-dashed h-40 bg-custom-grey rounded-md cursor-pointer"
        >
          <input {...getInputProps()} />
          <Row
            hidden={acceptedFiles.length > 0}
            className="align-items-center h-full"
          >
            <Col className="w-full text-center">
              <div className="text-sm text-gray-500">
                Drag and drop your file here or click to browse
              </div>
            </Col>
          </Row>
          {acceptedFiles.length > 0 && (
            <Row className="align-items-center mt-2 h-full">
              <Col className="w-full text-center">{acceptedFiles[0].name}</Col>
            </Row>
          )}
        </div>
      </LabelledField>
      <Row className="mt-4">
        <Col className="col-auto"></Col>
        <Col className="col-auto ml-auto">
          <SaveButton
            disabled={loading || !file.name || acceptedFiles.length === 0}
            loading={loading}
            label={"Upload"}
            onClick={handleUpload}
          />
        </Col>
      </Row>
    </div>
  );
}
