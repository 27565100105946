import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { joinClasses } from "src/utils/functions";
import { useTheme } from "src/context/theme_context";
import LabelledField from "./labelled_field";

export interface TextFieldProps {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  error?: string;
  className?: string;
  placeholder?: string;
  rows?: number;
  type?: string;
  required?: boolean;
  onSubmit?: (value: string) => void;
  disabled?: boolean;
}

export default function TextField({ type = "text", ...props }: TextFieldProps) {
  const { getThemeBasedValue } = useTheme();
  const { label, value, onChange, error, className } = props;
  const [val, setVal] = useState(value);

  useEffect(() => {
    if (value == val) return;
    setVal(value);
  }, [value]);

  useEffect(() => {
    if (val == value) return;
    onChange(val);
  }, [val]);

  const extras = {
    rows: props.rows,
  } as any;

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && props.onSubmit) {
      props.onSubmit(val);
    }
  };

  return (
    <LabelledField
      label={label}
      className={className}
      required={props.required}
    >
      <Form.Control
        disabled={props.disabled}
        style={{
          color: getThemeBasedValue("black", "white"),
        }}
        placeholder={props.placeholder}
        type={type}
        value={val ?? ""}
        onChange={(e: any) => setVal(e.target.value)}
        className={joinClasses(
          error
            ? "border-red-500"
            : getThemeBasedValue("border-none", "border-accent"),
          "bg-custom-grey py-2 rounded-md appearance-none leading-tight focus:ring-1 focus:ring-dark-accent focus:outline-none",
          props.disabled ? "text-muted" : ""
        )}
        as={props.rows ? "textarea" : "input"}
        onKeyDown={handleKeyDown}
        onWheel={(e: any) => e.target.blur()}
        {...extras}
      />
      {error && <Form.Text className="text-danger">{error}</Form.Text>}
    </LabelledField>
  );
}
