import { DEFAULT_DATE_FORMAT, IUserTimeTableSlot, UserRole } from "crm_core";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Aux from "src/components/aux";
import CustomCard, { CustomCardProps } from "src/components/custom_card";
import CustomDataTable, {
  CustomDataTableProps,
  CustomTableColumn,
} from "src/components/custom_data_table";
import SimpleDatePicker from "src/components/form_generator/date_picker";
import ClickableCell from "src/components/widgets/clickable_cell";
import { useAuth } from "src/context/auth_context";
import { useNotification } from "src/context/notification_context";
import { DashboardService } from "src/services/dashboard";

interface UserTimetableProps {
  tableProps?: Partial<CustomDataTableProps<IUserTimeTableSlot>>;
  cardProps?: Partial<CustomCardProps>;
}

export default function UserTimetable({
  tableProps = {},
  cardProps = {},
}: UserTimetableProps) {
  const dashboardService = new DashboardService();
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState<IUserTimeTableSlot[]>([]);
  const { pushNotification } = useNotification();
  const { selectedAuthorization } = useAuth();
  const [date, setDate] = useState<string>(
    moment().format(DEFAULT_DATE_FORMAT)
  );

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await dashboardService.getUserTimetable(date);
      setSlots(res);
    } catch (e) {
      pushNotification({
        title: "Error",
        message: "Failed to fetch timetable",
        type: "danger",
      });
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (date) {
      fetchData();
    }
  }, [date]);

  const tableColumns: CustomTableColumn<IUserTimeTableSlot>[] = [
    {
      name: "Classroom",
      cell: (row) => (
        <ClickableCell
          href={
            selectedAuthorization.role == UserRole.Student
              ? `/c/classrooms/${row.classroom._id}/overview`
              : `/c/classrooms/${row.classroom._id}/view`
          }
        >
          {row.classroom.name}
        </ClickableCell>
      ),
      width: "300px",
    },
    {
      name: "From",
      cell: (row) => <div>{row.from}</div>,
      width: "120px",
    },
    {
      name: "To",
      cell: (row) => <div>{row.to}</div>,
      width: "120px",
    },
  ];

  return (
    <CustomCard
      title="Today's Timetable"
      className="px-0"
      headerRight={
        <Aux>
          <Row className="m-0">
            <Col className="p-0 col-auto">
              <SimpleDatePicker value={date} onChange={setDate} />
            </Col>
          </Row>
        </Aux>
      }
      {...cardProps}
    >
      <CustomDataTable
        className="-mt-4"
        tableKey="timetable"
        data={slots}
        columns={tableColumns}
        selectableRows={false}
        highlightOnHover={false}
        pagination={false}
        fixedHeaderScrollHeight="400px"
        progressPending={loading}
        noDataComponent={
          <div className="py-3">
            Your schedule is not configured for{" "}
            {moment(date, DEFAULT_DATE_FORMAT).format("DD MMM YYYY")}
          </div>
        }
        {...tableProps}
      />
    </CustomCard>
  );
}
