import { ChangeEvent, useRef, useState } from "react";
import Button from "../buttons/button";
import { joinClasses, resizeFile } from "src/utils/functions";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { Col, Row } from "react-bootstrap";

interface ImageUploadProps {
  placeholder?: JSX.Element;
  onUpload: (file: File) => void;
  className?: string;
}

export default function ImageUpload({
  placeholder,
  onUpload,
  ...props
}: ImageUploadProps) {
  const [image, setImage] = useState<File>();

  const hiddenFileInput = useRef<any>(null);

  const handleClick = (event: ChangeEvent) => hiddenFileInput.current.click();

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files && event.target.files[0];
    if (fileUploaded) {
      const resized = await resizeFile(fileUploaded);
      setImage(resized);
      onUpload(resized);
    }
  };

  return (
    <Col>
      <Row className="justify-content-center align-items-center">
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleChange}
          ref={hiddenFileInput}
          className={joinClasses(props.className)}
        />
      </Row>
      <Row className="justify-content-center align-items-center">
        {image ? (
          <img
            src={URL.createObjectURL(image)}
            className={joinClasses(props.className)}
          />
        ) : (
          placeholder
        )}
      </Row>
      <Row className="justify-content-center align-items-center mt-2">
        <Button
          rounded
          className="h-8 w-8"
          onClick={handleClick}
          icon={<PencilSquareIcon className="h-5 w-5" />}
        />
      </Row>
    </Col>
  );
}
