import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PencilIcon,
} from "@heroicons/react/20/solid";
import {
  DEFAULT_DATE_FORMAT,
  IInstituteCalendarDay,
  Permissions,
  UserRole,
} from "crm_core";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "src/components/buttons/button";
import CustomCard from "src/components/custom_card";
import LabelledField from "src/components/form_generator/labelled_field";
import FormSelect from "src/components/form_select";
import { ModalTypes, useModal } from "src/context/modal_context";
import { InstituteCalendarService } from "src/services/institute/calendar";
import { joinClasses } from "src/utils/functions";
import { EditCalendarDayProps } from "./edit_day";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import { useDataProvider } from "src/context/data_provider";
import { useAuth } from "src/context/auth_context";

interface InstituteCalendarProps {
  tableOnly?: boolean;
}

export default function InstituteCalendar({
  tableOnly,
}: InstituteCalendarProps) {
  const MONTH_YEAR_FORMAT = "MMMM YYYY";
  const calendarService = new InstituteCalendarService();
  const { selectedSession } = useDataProvider();
  const { selectedAuthorization } = useAuth();
  const sessionStart = moment(selectedSession?.start_date);
  const sessionEnd = moment(selectedSession?.end_date);
  const currentMonth = moment().isBetween(sessionStart, sessionEnd)
    ? moment()
    : moment.min(sessionStart, moment());

  const [month, setMonth] = useState<string>(
    currentMonth.format(MONTH_YEAR_FORMAT)
  );
  const [startDate, setStartDate] = useState<Moment>();
  const [endDate, setEndDate] = useState<Moment>();
  const [data, setData] = useState<IInstituteCalendarDay[]>([]);
  const {
    setModalSize,
    setComponentProps,
    setShowModal,
    setModalHeading,
    setModalType,
  } = useModal();

  const fetchData = async () => {
    if (startDate && endDate) {
      const res = await calendarService.getInstituteCalendarForDateRange(
        startDate.format(DEFAULT_DATE_FORMAT),
        endDate.format(DEFAULT_DATE_FORMAT)
      );
      setData(res);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  useEffect(() => {
    document.title = "Teachbond - Institute Calendar";
  }, []);

  useEffect(() => {
    let monthStart = moment(month, MONTH_YEAR_FORMAT).startOf("month");
    let monthEnd = moment(month, MONTH_YEAR_FORMAT).endOf("month");
    startDate?.isSame(monthStart, "date") ? null : setStartDate(monthStart);
    endDate?.isSame(monthEnd, "date") ? null : setEndDate(monthEnd);
  }, [month]);

  const getDays = () => {
    if (!startDate || !endDate) return [];
    let days = [];
    let day = startDate;
    while (day <= endDate) {
      days.push(day);
      day = day.clone().add(1, "d");
    }
    return days;
  };

  const days = getDays();

  const handleNext = () => {
    setMonth((s) =>
      moment(s, MONTH_YEAR_FORMAT).add(1, "month").format(MONTH_YEAR_FORMAT)
    );
  };

  const handlePrev = () => {
    setMonth((s) =>
      moment(s, MONTH_YEAR_FORMAT)
        .subtract(1, "month")
        .format(MONTH_YEAR_FORMAT)
    );
  };

  const handleDayEdit = (day: IInstituteCalendarDay) => {
    setModalType(ModalTypes.UPDATE_CALENDAR_DAY);
    setModalSize("lg");
    setModalHeading("Edit Calendar Day");
    let props: EditCalendarDayProps = {
      onComplete: () => {
        fetchData();
        setShowModal(false);
      },
      data: day,
    };
    setComponentProps(props);
    setShowModal(true);
  };

  const sessionMonths: moment.Moment[] = [];

  let monthStart = sessionStart.clone().startOf("month");
  let monthEnd = sessionEnd.clone().endOf("month");
  while (monthStart.isBefore(monthEnd)) {
    sessionMonths.push(monthStart);
    monthStart = monthStart.clone().add(1, "month");
  }

  const getTableView = () => {
    return (
      <div className="mx-2" style={{ minWidth: "1000px" }}>
        <Row className="mb-3 ml-1">
          <Col className="col-auto" style={{ minWidth: "200px" }}>
            <LabelledField label="Month">
              <FormSelect
                isClearable={false}
                value={month}
                onChange={(v) => setMonth(v as string)}
                options={sessionMonths.map((m) => ({
                  label: `${m.format(MONTH_YEAR_FORMAT)}`,
                  value: m.format(MONTH_YEAR_FORMAT),
                }))}
              />
            </LabelledField>
          </Col>
        </Row>
        <Row></Row>
        <Row className="m-0 justify-content-center align-items-center">
          <Col className="col-auto px-2 py-2">
            <Button
              className="p-2"
              color="accent"
              rounded
              icon={<ArrowLeftIcon className="h-5 w-5" />}
              isSlim
              onClick={handlePrev}
              disabled={month === sessionStart.format(MONTH_YEAR_FORMAT)}
            />
          </Col>
          <Col className="px-2 py-2">
            <Row>
              {moment.weekdays().map((day, idx) => (
                <Col
                  key={`day-${idx}`}
                  className={joinClasses(
                    "col-auto p-0 text-center px-1 rounded-md py-1"
                  )}
                  style={{
                    width: "14.28%",
                  }}
                >
                  <div
                    className={joinClasses(
                      "text-normal py-2 rounded-md px-2 bg-white font-semibold"
                    )}
                  >
                    <div className="text-start text-muted">{day}</div>
                    <div className="text-end text-muted"></div>
                  </div>
                </Col>
              ))}
              {days[0] &&
                days[0].day() > 0 &&
                [...Array(days[0].day())].map((_, idx) => (
                  <Col
                    key={`empty-${idx}`}
                    className={joinClasses(
                      "col-auto p-0 text-center px-1 rounded-md py-1"
                    )}
                    style={{
                      width: "14.28%",
                    }}
                  ></Col>
                ))}
              {days.map((day, idx) => {
                const dayCalendarData = data.find((d) =>
                  moment(d.date).isSame(day, "date")
                ) as IInstituteCalendarDay;
                if (!dayCalendarData) return null;
                return (
                  <Col
                    key={`day-${idx}`}
                    className={joinClasses(
                      "col-auto p-0 text-center px-1 rounded-md py-1"
                    )}
                    style={{
                      width: "14.28%",
                    }}
                  >
                    <div
                      className={joinClasses(
                        "text-sm py-2 rounded-md px-2 bg-custom-grey",
                        dayCalendarData.working_for_staff
                          ? dayCalendarData.working_for_students
                            ? "bg-light-accent"
                            : selectedAuthorization.role === UserRole.Student
                            ? "bg-red-200"
                            : "bg-yellow-200"
                          : "bg-red-200"
                      )}
                    >
                      <Row className="m-0">
                        <Col
                          className="col-auto p-0"
                          style={{ minHeight: "80px" }}
                        >
                          <div className="text-start text-muted">
                            {day.format("D")}
                            <div
                              hidden={
                                !dayCalendarData.working_for_staff &&
                                !dayCalendarData.working_for_students
                              }
                              className="mt-2"
                            >
                              {dayCalendarData.events.map((e, idx) => (
                                <div
                                  key={`event-${idx}`}
                                  className="text-muted text-xs"
                                >
                                  {e.title}
                                </div>
                              ))}
                            </div>
                            <div
                              className="mt-2"
                              hidden={
                                dayCalendarData.working_for_staff ||
                                dayCalendarData.working_for_students
                              }
                            >
                              <div className="text-xs">
                                {dayCalendarData.holiday_reason}
                              </div>
                              <div className="text-xs text-muted">
                                {dayCalendarData.holiday_description}
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col className="col-auto p-0 ml-auto">
                          <PermissionRestrictedComponent
                            permissions={[
                              Permissions.UPDATE_INSTITUTE_CALENDAR,
                            ]}
                          >
                            <Button
                              className="p-1"
                              color="white"
                              rounded
                              icon={<PencilIcon className="h-4 w-4" />}
                              isSlim
                              onClick={() => handleDayEdit(dayCalendarData)}
                            />
                          </PermissionRestrictedComponent>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>

          <Col className="col-auto px-2 py-2">
            <Button
              className="p-2"
              color="accent"
              rounded
              icon={<ArrowRightIcon className="h-5 w-5" />}
              isSlim
              onClick={handleNext}
              disabled={sessionEnd.format(MONTH_YEAR_FORMAT) === month}
            />
          </Col>
        </Row>
      </div>
    );
  };

  if (tableOnly) return getTableView();

  return <CustomCard title="Institute Calendar">{getTableView()}</CustomCard>;
}
