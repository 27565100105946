import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthService } from "../services/auth";
import { AuthEntityType, IAuthorization, IUser } from "crm_core";
import { RegistrationForm } from "crm_core";
import { LocalStorageKeys } from "src/utils/constants";
import { IOrganization } from "crm_core";
import { useNotification } from "./notification_context";

const authContext = createContext<AuthContextProps>({} as any);

function useAuth() {
  return useContext(authContext);
}

type CustomAuthorization = IAuthorization & {
  entity: { _id: string; name: string };
};

function AuthProvider({ children }: any) {
  const auth = useAuthProvider();
  return (
    <authContext.Provider value={auth as any}>{children}</authContext.Provider>
  );
}

function useAuthProvider() {
  const service = new AuthService();
  const navigate = useNavigate();
  const authToken = localStorage.getItem(LocalStorageKeys.AUTH_TOKEN) || "";
  const selectedAuthId = localStorage.getItem(LocalStorageKeys.AUTH_ID);
  const [authenticated, setAuthenticated] = useState(
    selectedAuthId ? true : false
  );
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [user, setUser] = useState<IUser>();
  const [homeLayout, setHomeLayout] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loggedIn, setLoggedIn] = useState(authToken ? true : false);
  const { pushNotification } = useNotification();
  const [timezone, setTimezone] = useState<string>();
  const [authorizations, setAuthorizations] = useState<CustomAuthorization[]>(
    []
  );
  const [selectedAuthorization, setSelectedAuthorization] =
    useState<CustomAuthorization>();
  const [selectedOrganization, setSelectedOrganization] =
    useState<IOrganization>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [messagingToken, setMessagingToken] = useState<string>(
    localStorage.getItem(LocalStorageKeys.MESSAGING_TOKEN) || ""
  );

  useEffect(() => {
    const authId = searchParams.get("authId");
    const authToken = searchParams.get("authToken");
    const sessionId = searchParams.get("sessionId");
    if (authToken) {
      localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, authToken);
      if (authId) localStorage.setItem(LocalStorageKeys.AUTH_ID, authId);
      if (sessionId)
        localStorage.setItem(LocalStorageKeys.SESSION_ID, sessionId);
      searchParams.delete("authToken");
      searchParams.delete("authId");
      searchParams.delete("sessionId");
      setSearchParams(searchParams);
      window.location.reload();
    }
  }, [searchParams]);

  const getOrganizationsFromUser = (user: IUser) => {
    return user.authorizations
      .filter(
        (a) =>
          a.entity_type == AuthEntityType.Organization ||
          a.entity_type == AuthEntityType.Company
      )
      .map((a) => a.entity as IOrganization);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      let promises: any = [service.verifyToken()];
      promises = await Promise.all(promises);
      let user = promises[0].data as IUser;
      setUser(user);
      setOrganizations(getOrganizationsFromUser(user));
      setAuthorizations((user.authorizations ?? []) as any[]);
    } catch (e) {
      console.log({ e });
      // navigate("/auth/login");
      setAuthenticated(false);
      setLoggedIn(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (authToken) fetchData();
  }, []);

  useEffect(() => {
    if (selectedOrganization) {
      setTimezone(selectedOrganization.timezone ?? "Asia/Kolkata");
    }
  }, [selectedOrganization]);

  useEffect(() => {
    let authIds = authorizations.map((o) => o._id);
    if (
      selectedAuthId &&
      authIds.includes(selectedAuthId) &&
      authorizations.find((o) => o._id === selectedAuthId)
    ) {
      setSelectedAuthorization(
        authorizations.find((o) => o._id === selectedAuthId)
      );
    } else {
      if (authorizations.length > 0) {
        navigate("/auth/role-selector");
      }
    }
  }, [authorizations]);

  useEffect(() => {
    if (selectedAuthorization && selectedAuthorization._id) {
      localStorage.setItem(LocalStorageKeys.AUTH_ID, selectedAuthorization._id);
      if (selectedAuthorization.entity_type == AuthEntityType.Organization) {
        setSelectedOrganization(
          organizations.find((o) => o._id === selectedAuthorization.entity._id)
        );
      } else if (selectedAuthorization.entity_type == AuthEntityType.Company) {
        setSelectedOrganization(
          organizations.find((o) => o._id === selectedAuthorization.entity._id)
        );
      } else {
        setSelectedOrganization(undefined);
      }
      setAuthenticated(true);
    }
  }, [selectedAuthorization]);

  const signin = async (email: string, password: string) => {
    if (email.trim().length === 0 || password.trim().length === 0) {
      throw Error("Email or Password Empty");
    }
    setLoading(true);
    try {
      const res = await service.signIn(email, password);
      console.log(res);
      if (res !== undefined) {
        localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, res.token);
        localStorage.setItem(LocalStorageKeys.USER, JSON.stringify(res.user));
        localStorage.setItem(LocalStorageKeys.MESSAGING_TOKEN, res.messagingToken);
        setMessagingToken(res.messagingToken);
        setUser(res.user);
        setOrganizations(getOrganizationsFromUser(res.user));
        setAuthorizations((res.user.authorizations ?? []) as any[]);
        // setAuthenticated(true);
        setLoggedIn(true);
      }
    } catch (err: any) {
      pushNotification({
        title: "Error",
        message: err.message,
        type: "danger",
      });
      setLoading(false);
      throw Error(err);
    }
    setLoading(false);
  };

  const signup = async (user: RegistrationForm) => {
    if (user.email?.trim().length === 0 || user.password?.trim().length === 0) {
      throw Error("Email or Password Empty");
    }
    setLoading(true);
    try {
      const res = await service.signUp(user);
      console.log(res);
      if (res !== undefined) {
        // localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, res.token);
        // localStorage.setItem(LocalStorageKeys.USER, JSON.stringify(res.user));
        // setUser(res.user);
        // setAuthenticated(true);
        navigate("/auth/login");
      }
    } catch (err: any) {
      setLoading(false);
      pushNotification({
        title: "Error",
        message: err.message,
        type: "danger",
      });
      throw Error(err);
    }
    setLoading(false);
  };

  const signout = () => {
    let redirect = false;
    if (localStorage.getItem(LocalStorageKeys.AUTH_TOKEN)) {
      redirect = true;
      localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN);
    }
    if (localStorage.getItem(LocalStorageKeys.USER)) {
      redirect = true;
      localStorage.removeItem(LocalStorageKeys.USER);
      localStorage.removeItem(LocalStorageKeys.AUTH_ID);
      localStorage.removeItem(LocalStorageKeys.LAST_NOTIFIED);
      setSelectedAuthorization(undefined);
      // localStorage.clear();
    }
    if (redirect) {
      navigate("/");
    }
    setAuthenticated(false);
    setLoggedIn(false);
  };

  return {
    signin,
    signup,
    signout,
    authenticated,
    user,
    homeLayout,
    setHomeLayout,
    loading,
    setLoading,
    updating,
    setUpdating,
    organizations,
    selectedAuthorization,
    setSelectedAuthorization,
    forceRefresh: fetchData,
    authorizations,
    selectedOrganization,
    loggedIn,
    timezone,
    messagingToken,
  } as AuthContextProps;
}

interface AuthContextProps {
  signin: (email: string, password: string) => Promise<void>;
  signup: (user: RegistrationForm) => Promise<void>;
  signout: any;
  authenticated: boolean;
  user: IUser;
  homeLayout: boolean;
  setHomeLayout: (homeLayout: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  updating: boolean;
  setUpdating: (updating: boolean) => void;
  organizations: IOrganization[];
  selectedAuthorization: CustomAuthorization;
  setSelectedAuthorization: (organization: IAuthorization) => void;
  forceRefresh: () => Promise<void>;
  authorizations: CustomAuthorization[];
  selectedOrganization?: IOrganization;
  loggedIn: boolean;
  timezone: string;
  messagingToken: string;
}

export { useAuth, AuthProvider };
