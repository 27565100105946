import {
  IAttendanceShiftResponse,
  IClassroomExtended,
  IStaffMemberResponse,
} from "crm_core";
import { useEffect, useState } from "react";
import { Loader } from "src/components/loader";
import { useNotification } from "src/context/notification_context";
import { AttendanceShiftService } from "src/services/institute/attendance/shift";
import { StaffMemberService } from "src/services/institute/staff_member";
import CustomItemSelector from "src/components/custom_item_selector";
import { ClassroomService } from "src/services/institute/classroom";

export interface AssociateStaffMembersProps {
  shift?: IAttendanceShiftResponse;
  classroom?: IClassroomExtended;
  onComplete?: () => void;
  remove?: boolean;
}

export default function AssociateStaffMembers({
  shift,
  classroom,
  onComplete,
}: AssociateStaffMembersProps) {
  const service = new AttendanceShiftService();
  const staffService = new StaffMemberService();
  const classService = new ClassroomService();
  const [loading, setLoading] = useState(false);
  const [staffMembers, setStaffMembers] = useState<IStaffMemberResponse[]>([]);
  const { pushNotification } = useNotification();

  const fetchData = async () => {
    setLoading(true);
    const data = await staffService.getStaffMembers();
    setStaffMembers(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async (selectedStaff: IStaffMemberResponse[]) => {
    setLoading(true);

    if (shift) {
      await service.updateAttendanceShift({
        _id: shift._id,
        people: selectedStaff.map((s) => s._id),
      });
      pushNotification({
        title: "Success",
        message: "Updated Shift Staff Successfully",
        type: "success",
      });
    } else if (classroom) {
      await classService.updateTeachers(
        classroom._id,
        selectedStaff.map((s) => s._id)
      );
      pushNotification({
        title: "Success",
        message: "Updated Class Teachers Successfully",
        type: "success",
      });
    }

    onComplete?.();
    setLoading(false);
  };

  return (
    <div className="bg-white rounded-md py-3">
      {loading && <Loader text="Updating Staff Members" />}
      {!loading && (
        <>
          <div className="px-3">
            <CustomItemSelector
              data={staffMembers}
              isSelected={(item) =>
                shift
                  ? shift.people?.findIndex((p) => p._id === item._id) !== -1
                  : classroom
                  ? classroom.teachers?.findIndex((p) => p._id === item._id) !==
                    -1
                  : false
              }
              render={(item) => (
                <div>
                  {item.first_name} {item.last_name}
                </div>
              )}
              searchHandler={(item, search) =>
                item.first_name.toLowerCase().includes(search.toLowerCase()) ||
                (item.last_name ?? "")
                  .toLowerCase()
                  .includes(search.toLowerCase())
              }
              onSave={async (selectedItems) => handleSave(selectedItems)}
              selectedTitle={
                shift
                  ? `Staff Members in ${shift.name}`
                  : classroom
                  ? `Teachers in ${classroom.name}`
                  : ""
              }
              nonSelectedTitle="All Staff Members"
            />
          </div>
        </>
      )}
    </div>
  );
}
