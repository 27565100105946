import { XMarkIcon } from "@heroicons/react/24/outline";
import { Modal } from "react-bootstrap";
import Button from "../buttons/button";
import { joinClasses } from "src/utils/functions";
import { useTheme } from "src/context/theme_context";

interface Props {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}
export default function Dialog(props: Props) {
  const { getThemeBasedValue } = useTheme();
  const { open, onClose } = props;
  if (!open) {
    return <></>;
  }
  return (
    <Modal contentClassName="border-0" centered onHide={onClose} show={open}>
      <Modal.Body className="fixed inset-0 z-50 overflow-auto flex">
        <div
          className={joinClasses(
            "relative pl-4 pr-2 pt-3 pb-2",
            getThemeBasedValue("bg-white", "bg-gray-800"),
            "w-full max-w-md m-auto flex-col flex rounded-lg"
          )}
        >
          <div>{props.children}</div>
          <span className="absolute top-0 right-0 pt-3 pr-2">
            <Button
              noshadow
              onClick={onClose}
              color="transparent"
              rounded
              className="h-8 w-8"
              icon={<XMarkIcon className="h-6 w-6" />}
            />
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
        <div>{props.children}</div>
        <span className="absolute top-0 right-0 p-4">
          <Button
            noshadow
            onClick={onClose}
            color="transparent"
            rounded
            className="h-8 w-8 mr-4"
            icon={<XMarkIcon className="h-6 w-6" />}
          />
        </span>
      </div>
    </div>
  );
}
