import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Header from "src/components/navbar/header";
import { usePageState } from "src/context/page_state_context";
import { getRoutes, routesData } from "src/routes";

export default function AuthLayout({ location }: any) {
  const { isSmallScreen, headless } = usePageState();
  const LAYOUT_NAME = "auth";
  const [headerHeight, setHeaderHeight] = useState("8vh");

  useEffect(() => {
    if (isSmallScreen) {
      setHeaderHeight("60px");
    }
    if (headless) {
      setHeaderHeight("0px");
    }
  }, [headless, isSmallScreen]);

  return (
    <div className="bg-off-white">
      <Header height={headerHeight} brandWidth="60px" />
      <div
        style={{
          position: "fixed",
          top: headerHeight,
          left: 0,
          width: `calc(100%)`,
          height: `calc(100% - ${headerHeight})`,
          overflow: "scroll",
        }}
      >
        <div style={{ minHeight: "100%" }} className="bg-off-white">
          <Routes>
            {getRoutes(
              routesData.filter((route) => route.layout === LAYOUT_NAME)
            )}
            <Route
              path="*"
              element={
                <Navigate
                  to={{ ...location, pathname: "/auth/login" }}
                  replace
                />
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}
