import API from "../../../api";
import { ICourseSubject } from "crm_core";

export class CourseSubjectService extends API {
  static NAME = "CourseSubjectService";

  constructor() {
    super("/subject");
  }
  async getCourseSubjects(courseId: string): Promise<ICourseSubject[]> {
    const res = await this._get({ url: `/${courseId}/list` });
    return res.data;
  }

  async getCourseSubject(id: string): Promise<ICourseSubject> {
    const res = await this._get({ url: `/${id}` });
    return res.data;
  }

  async createCourseSubject(
    courseId: string,
    subject: ICourseSubject
  ): Promise<ICourseSubject> {
    const res = await this._post({ url: `/${courseId}`, body: subject });
    return res.data;
  }

  async updateCourseSubject(
    subject: Partial<ICourseSubject>
  ): Promise<ICourseSubject> {
    const res = await this._put({ url: `/${subject._id}`, body: subject });
    return res.data;
  }

  async deleteCourseSubject(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }

  async uploadLogo(file: File, subjectId: string): Promise<ICourseSubject> {
    const formData = new FormData();
    formData.append("photo", file, file.name);
    const res = await this._postFile({
      url: `/photo/${subjectId}`,
      body: formData,
    });
    return res.data as ICourseSubject;
  }
}
