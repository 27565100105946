import { CustomDataTableProps } from "src/components/custom_data_table";
import {
  IStudent,
  IDepartment,
  SupportedFilterAttributes,
  SupportedFilterOperators,
  IBatch,
  IInvitation,
} from "crm_core";
import { useEffect, useState } from "react";
import { StudentService } from "src/services/institute/student";
import CustomCard, { CustomCardProps } from "src/components/custom_card";
import Aux from "src/components/aux";
import { PaginatedGetter } from "src/services/paginated_getter";
import { useTable } from "src/context/table_context";
import { useDataProvider } from "src/context/data_provider";
import { Col, Row } from "react-bootstrap";
import Filters from "src/features/filters";
import { FilterKeys } from "src/utils/constants";
import { getFilterSpec } from "src/features/filters/utils";
import StudentCard from "./card";
import { Loader } from "src/components/loader";

export type StaffMemberStudentListData = IStudent & {
  invitation: IInvitation;
  _id: string;
  batch: IBatch;
  department: IDepartment;
};
interface StaffMemberStudentsListProps {
  classroom?: string;
  tableOnly?: boolean;
  tableProps?: Partial<CustomDataTableProps<StaffMemberStudentListData>>;
  excludedIds?: string[];
  includedIds?: string[];
  cardProps?: Partial<CustomCardProps>;
  filterKey?: FilterKeys;
}

export default function StaffMemberStudentsList({
  classroom,
  tableOnly = false,
  tableProps = {},
  cardProps = {},
  excludedIds,
  includedIds,
  filterKey,
}: StaffMemberStudentsListProps) {
  const paginatedGetter = new PaginatedGetter<
    StudentService,
    StaffMemberStudentListData
  >(StudentService);
  const FILTER_KEY = filterKey || FilterKeys.STUDENT_IN_STAFF_MEMBER;
  const [fetchedConfig, setFetchedConfig] = useState<string>("");
  const [students, setStudents] = useState<StaffMemberStudentListData[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    getSortPreferences,
    getFilterPreferences,
    sortPreferences,
    filterPreferences,
    updateTotalRows,
    applyFilter,
  } = useTable();
  const { selectedSession } = useDataProvider();

  useEffect(() => {
    applyFilter(
      FILTER_KEY,
      getFilterSpec(
        SupportedFilterAttributes.CLASSROOM,
        SupportedFilterOperators.EQUALS,
        classroom
      ),
      getFilterSpec(
        SupportedFilterAttributes.ID,
        SupportedFilterOperators.NOT_INCLUDES,
        excludedIds
      ),
      getFilterSpec(
        SupportedFilterAttributes.ID,
        SupportedFilterOperators.INCLUDES,
        includedIds
      )
    );
  }, [excludedIds, classroom, includedIds]);

  const fetchData = async () => {
    let config = {
      sort: getSortPreferences(FILTER_KEY),
      filters: getFilterPreferences(FILTER_KEY),
    };
    const derivedFilters = !!(excludedIds || classroom || includedIds);
    if (derivedFilters && !(config.filters.length > 0)) {
      return;
    }
    if (JSON.stringify(config) === fetchedConfig) {
      return;
    }
    setLoading(true);
    const data = await paginatedGetter.get(config, {
      extended: true,
      assigned: true,
    });
    setStudents(data.results);
    updateTotalRows(FILTER_KEY, data.totalCount);
    setFetchedConfig(JSON.stringify(config));
    setLoading(false);
  };

  useEffect(() => {
    if (selectedSession && !loading) {
      fetchData();
    }
  }, [sortPreferences, filterPreferences, selectedSession, loading]);

  useEffect(() => {
    document.title = "Teachbond - Your Students";
  }, []);

  const getTableView = () => {
    return (
      <Aux>
        <Filters
          filterKey={FILTER_KEY}
          visibleFilters={
            classroom || excludedIds || includedIds
              ? [SupportedFilterAttributes.SEARCH]
              : undefined
          }
        />
        {loading ? (
          <Loader text="Fetching Students" />
        ) : (
          <Row className="m-0 pl-0 py-0 pr-3">
            {students.map((student, idx) => (
              <Col
                key={idx}
                xs="12"
                sm="12"
                md="6"
                lg="4"
                className="pl-3 pr-0 mt-3"
              >
                <StudentCard
                  key={idx}
                  student={student}
                  href={`/c/students/${student._id}/view`}
                />
              </Col>
            ))}
          </Row>
        )}
      </Aux>
    );
  };

  if (tableOnly) {
    return getTableView();
  }

  return (
    <CustomCard title="All Students" headerRight={<Aux></Aux>} {...cardProps}>
      {getTableView()}
    </CustomCard>
  );
}
