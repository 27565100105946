import { LocalStorageKeys } from "src/utils/constants";
import { isProd } from "src/utils/functions";

class API {
  BASE_URL = isProd()
    ? "https://api.teachbond.com/api/v1"
    : "http://localhost:8000/api/v1";
  path?: string;

  constructor(path?: string) {
    this.path = path;
  }

  getHeaders = ({
    contentType = "application/json",
  }: {
    contentType?: string;
  }) => {
    var myHeaders = new Headers();
    if (contentType) myHeaders.append("Content-Type", contentType);
    const authToken = localStorage.getItem(LocalStorageKeys.AUTH_TOKEN) || "";
    myHeaders.append("Authorization", "Bearer " + authToken);
    const mid = localStorage.getItem("mid");
    if (mid) myHeaders.append("X-M-ID", mid);
    const selectedOrgId = localStorage.getItem(LocalStorageKeys.ORG_ID);
    if (selectedOrgId) myHeaders.append("X-O-ID", selectedOrgId);
    const selectedSessionId = localStorage.getItem(LocalStorageKeys.SESSION_ID);
    if (selectedSessionId) myHeaders.append("X-S-ID", selectedSessionId);
    const selectedAuthId = localStorage.getItem(LocalStorageKeys.AUTH_ID);
    if (selectedAuthId) myHeaders.append("X-A-ID", selectedAuthId);
    return myHeaders;
  };

  private getStringifiedParams = (params?: { [key: string]: string }) => {
    if (!params) return "";
    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");
    return `?${query}`;
  };

  async _get({
    url = "",
    params = {},
    returnRaw = false,
    path,
  }: {
    url?: string;
    params?: { [key: string]: string };
    returnRaw?: boolean;
    path?: string;
  }) {
    const res = fetch(
      `${this.BASE_URL}${path ?? this.path}${url}` +
        this.getStringifiedParams(params),
      {
        method: "GET",
        headers: this.getHeaders({}),
      }
    );
    if (returnRaw) return await res;
    else
      return res.then(this.handleResponse).then((data) => {
        return data;
      });
  }

  async _post({
    url = "/",
    body = {},
    path,
  }: {
    path?: string;
    url?: string;
    body?: object;
  }) {
    return fetch(`${this.BASE_URL}${path ?? this.path}${url}`, {
      method: "POST",
      headers: this.getHeaders({}),
      body: JSON.stringify(body),
    })
      .then(this.handleResponse)
      .then((data) => {
        return data;
      });
  }

  async _put({
    url = "/",
    body = {},
    path,
  }: {
    url?: string;
    body?: object;
    path?: string;
  }) {
    return fetch(`${this.BASE_URL}${path ?? this.path}${url}`, {
      method: "PUT",
      headers: this.getHeaders({}),
      body: JSON.stringify(body),
    })
      .then(this.handleResponse)
      .then((data) => {
        return data;
      });
  }

  async _delete({
    url = "",
    params = {},
    path,
  }: {
    url?: string;
    params?: { [key: string]: string };
    path?: string;
  }) {
    return fetch(
      `${this.BASE_URL}${path ?? this.path}${url}` +
        this.getStringifiedParams(params),
      {
        method: "DELETE",
        headers: this.getHeaders({}),
      }
    )
      .then(this.handleResponse)
      .then((data) => {
        return data;
      });
  }

  async _postFile({
    url = "/",
    body,
    path,
  }: {
    url?: string;
    body?: FormData;
    path?: string;
  }) {
    let headers = this.getHeaders({ contentType: `` });
    console.log(headers);
    return fetch(`${this.BASE_URL}${path ?? this.path}${url}`, {
      method: "POST",
      headers,
      body: body,
      redirect: "follow",
    })
      .then(this.handleResponse)
      .then((data) => {
        return data;
      });
  }

  private async handleResponse(response: Response) {
    return response.text().then((text) => {
      try {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          const error = {
            message: (data && data.message) || response.statusText,
            statusCode: response.status,
          };
          if (response.status === 401) {
            localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN);
            if (
              !["/auth/login", "/auth/signup", "/"].includes(
                window.location.pathname
              )
            ) {
              localStorage.setItem(
                LocalStorageKeys.REDIRECT,
                window.location.pathname
              );
              localStorage.removeItem(LocalStorageKeys.AUTH_ID);
              window.location.href = "/auth";
            }
          }
          return Promise.reject(error);
        }
        return data;
      } catch (err) {
        return Promise.reject(err);
      }
    });
  }
}

export default API;
