import CustomDataTable, {
  CustomTableColumn,
} from "src/components/custom_data_table";
import {
  BulkImportDataType,
  IQuestion,
  QuestionExportConfig,
  UserRole,
} from "crm_core";
import { useEffect, useState } from "react";
import {
  ArrowUpTrayIcon,
  InboxArrowDownIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import CustomCard from "src/components/custom_card";
import Aux from "src/components/aux";
import { PaginatedGetter } from "src/services/paginated_getter";
import { useTable } from "src/context/table_context";
import { useDataProvider } from "src/context/data_provider";
import ClickableCell from "src/components/widgets/clickable_cell";
import { QuestionService } from "src/services/institute/lms/question";
import { DEFAULT_QUESTION } from "src/features/tests/defaults";
import LoadingButton from "src/components/buttons/loading_button";
import { QuestionTypeBadge } from "src/components/widgets/badges";
import moment from "moment";
import MarkDownEditor from "src/components/text_fields/markdown_editor";
import { useAuth } from "src/context/auth_context";
import { handleBulkImport, handleDataExport } from "src/utils/functions";
import Filters from "src/features/filters";
import { FilterKeys } from "src/utils/constants";

interface QuestionsListProps {
  topicId?: string;
  onAddQuestion?: (question: IQuestion) => Promise<void>;
  selectedQuestions?: IQuestion[];
  onSelectedQuestionsChange?: (questions: IQuestion[]) => void;
  tableOnly?: boolean;
}

export default function QuestionsList({
  topicId,
  onAddQuestion,
  selectedQuestions,
  onSelectedQuestionsChange,
  tableOnly,
}: QuestionsListProps) {
  const FILTER_KEY = FilterKeys.QUESTIONS_IN_TOPIC;
  const service = new QuestionService();
  const paginatedGetter = new PaginatedGetter<QuestionService, IQuestion>(
    QuestionService
  );
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const { selectedAuthorization } = useAuth();
  const {
    getPaginationPreferences,
    getSortPreferences,
    getFilterPreferences,
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    updateTotalRows,
  } = useTable();
  const { selectedSession } = useDataProvider();

  const fetchData = async () => {
    setLoading(true);
    const data = await paginatedGetter.get(
      {
        pagination: getPaginationPreferences(FILTER_KEY),
        sort: getSortPreferences(FILTER_KEY, "last_updated", "desc"),
        filters: getFilterPreferences(FILTER_KEY),
        fields: [
          "text",
          "type",
          "last_updated",
          "options",
          "correct_option",
          "difficulty",
        ],
      },
      { topicId }
    );
    setQuestions(data.results);
    updateTotalRows(FILTER_KEY, data.totalCount);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    selectedSession,
  ]);

  const handleCreateQuestion = async () => {
    setAdding(true);
    const question = DEFAULT_QUESTION;
    let newQuestion = await service.createQuestion(question);
    setQuestions([newQuestion, ...questions]);
    await onAddQuestion?.(newQuestion);
    setAdding(false);
  };

  const columns: CustomTableColumn<IQuestion>[] = [
    {
      name: "Type",
      cell: QuestionTypeBadge,
      reorder: true,
      sortable: true,
      sortField: "type",
      width: "140px",
    },
    {
      name: "Difficulty",
      cell: (row) => <div>{row.difficulty ?? "--"}</div>,
      reorder: true,
      sortable: true,
      sortField: "type",
      width: "140px",
    },
    {
      name: "Question",
      cell: ({ text, _id }) => (
        <ClickableCell
          className="py-2"
          href={
            selectedAuthorization?.role === UserRole.SuperAdmin
              ? `/c/questions/${_id}`
              : undefined
          }
        >
          <MarkDownEditor value={text} disabled viewer />
        </ClickableCell>
      ),
      reorder: true,
      sortable: true,
      sortField: "text",
      minWidth: "350px",
    },
    {
      name: "Last Updated",
      cell: ({ last_updated }) => (
        <div className="text-muted">{moment(last_updated).fromNow()}</div>
      ),
      reorder: true,
      sortable: true,
      sortField: "text",
      width: "300px",
    },
  ];

  const getTableView = () => {
    return (
      <div className="bg-white pt-2 rounded-md">
        <Filters filterKey={FILTER_KEY} />
        <CustomDataTable
          tableKey={FILTER_KEY}
          columns={columns}
          data={questions}
          progressPending={loading}
          selectableRowSelected={(row) =>
            !!selectedQuestions &&
            selectedQuestions?.findIndex((q) => q._id == row._id) != -1
          }
          onSelectedRowsChange={({ selectedRows }) => {
            if (
              JSON.stringify(selectedRows) == JSON.stringify(selectedQuestions)
            )
              return;
            onSelectedQuestionsChange?.(selectedRows);
          }}
        />
      </div>
    );
  };

  if (tableOnly) return getTableView();

  return (
    <CustomCard
      title="Questions"
      headerRight={
        <Aux>
          {selectedAuthorization?.role === UserRole.SuperAdmin && (
            <LoadingButton
              loading={adding}
              icon={<PlusIcon className="h-3 w-3 mr-1" />}
              label={<span className="">Create New Question</span>}
              onClick={handleCreateQuestion}
              color="accent"
              isSlim
              className="px-2 py-1 text-sm"
            />
          )}
        </Aux>
      }
      actionButtonProps={{
        actions: [
          {
            label: "Import Data",
            onClick: handleBulkImport(BulkImportDataType.QUESTION_BANK, {
              topic: topicId,
            }),
            icon: ArrowUpTrayIcon,
          },
          {
            label: "Export CSV",
            onClick: () => handleDataExport(QuestionExportConfig, questions),
            icon: InboxArrowDownIcon,
          },
        ],
      }}
    >
      {getTableView()}
    </CustomCard>
  );
}
