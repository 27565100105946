import {
  IAddress,
  ICorrectOption,
  IDepartment,
  IParent,
  IPerson,
} from "../features";

export enum PushNotificationApplication {
  CHAT = "CHAT",
  GENERIC = "GENERIC",
}

export interface IPushNotification {
  application: PushNotificationApplication;
  title: string;
  body: string;
  userId?: string;
  environment?: string;
  href: {
    web?: string;
    android?: string;
    ios?: string;
  };
}

export interface IMedicalRecord {
  date: string;
  weight?: number;
  height?: number;
  bmi?: number;
  pulse_rate?: number;
  haemoglobin?: number;
  remarks?: string;
}

export interface IBankDetails {
  account_no: string;
  account_holder_name: string;
  bank_name: string;
  ifsc_code: string;
}

export interface IExportHeader<T> {
  label: string;
  key:
    | keyof T
    | `department.${keyof IDepartment}`
    | `batch.${keyof IDepartment}`
    | `current_address.${keyof IAddress}`
    | `permanent_address.${keyof IAddress}`
    | `father.${keyof IParent}`
    | `mother.${keyof IParent}`
    | `person.${keyof IPerson}`
    | `correct_option.${keyof ICorrectOption}`
    | `guardian.${keyof IParent}`;
  whiltelisted_leaves?: string[];
  expand?: boolean;
}

export interface IExportConfig<T> {
  headers: IExportHeader<T>[];
  fileName: string;
}
