import API from "../api";
import { IInvitation, InviteRequest } from "crm_core";

export class InvitationService extends API {
  constructor() {
    super("/invite");
  }
  async getInvitationes(): Promise<IInvitation[]> {
    const res = await this._get({});
    return res.data as IInvitation[];
  }

  async createInvitation(invite: InviteRequest): Promise<IInvitation> {
    const res = await this._post({ body: invite });
    return res.data as IInvitation;
  }

  async deleteInvitation(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }

  async acceptInvitation(id: string): Promise<Boolean> {
    const res = await this._post({ url: `/accept/${id}` });
    return true;
  }

  async rejectInvitation(id: string): Promise<Boolean> {
    const res = await this._post({ url: `/reject/${id}` });
    return true;
  }
}
