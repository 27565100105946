import {
  DEFAULT_DATE_FORMAT,
  IStaffMemberAggregatedAttendance,
  IStudentAggregatedAttendance,
} from "crm_core";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Aux from "src/components/aux";
import CustomCard, { CustomCardProps } from "src/components/custom_card";
import CustomDataTable, {
  CustomTableColumn,
} from "src/components/custom_data_table";
import DateRangePicker from "src/components/form_generator/date_range_picker";
import SearchBox from "src/components/text_fields/search_box";
import ClickableCell from "src/components/widgets/clickable_cell";
import { useNotification } from "src/context/notification_context";
import { PeopleAttendanceService } from "src/services/institute/attendance/people";
import { catchRequest, conditionalCol } from "src/utils/functions";
import { AttendanceReportEntityType } from "./graph";

interface EntityAttendanceListProps {
  cardProps?: Partial<CustomCardProps>;
  tableOnly?: boolean;
  fromDate?: string;
  toDate?: string;
  setExportData?: (v: IStaffMemberAggregatedAttendance[]) => void;
  entityType?: AttendanceReportEntityType;
  entityId?: string;
}

export default function EntityAttendanceList({
  cardProps = {},
  tableOnly,
  fromDate,
  toDate,
  setExportData,
  entityType = AttendanceReportEntityType.STAFF_MEMBER,
  entityId,
}: EntityAttendanceListProps) {
  const service = new PeopleAttendanceService();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const { pushNotification } = useNotification();
  const [start, setStart] = useState(
    fromDate ?? moment().subtract(30, "days").format(DEFAULT_DATE_FORMAT)
  );
  const [end, setEnd] = useState(
    toDate ?? moment().format(DEFAULT_DATE_FORMAT)
  );
  const [data, setData] = useState<
    (IStaffMemberAggregatedAttendance | IStudentAggregatedAttendance)[]
  >([]);

  const fetchDateRangeAttendance = async (start: string, end: string) => {
    setLoading(true);
    await catchRequest(async () => {
      const entityToFunction = {
        [AttendanceReportEntityType.STUDENT]: () =>
          service.getStudentAttendanceForDateRange(
            start,
            end,
            undefined,
            entityId
          ),
        [AttendanceReportEntityType.STAFF_MEMBER]: () =>
          service.getStaffAttendanceForDateRange(start, end),
        [AttendanceReportEntityType.CLASSROOM]: () =>
          service.getStudentAttendanceForDateRange(start, end, entityId),
      };
      const res = await entityToFunction[entityType]();
      setData(res);
      setExportData?.(res);
    }, pushNotification)();
    setLoading(false);
  };

  useEffect(() => {
    if (fromDate) setStart(fromDate);
    if (toDate) setEnd(toDate);
  }, [fromDate, toDate]);

  useEffect(() => {
    fetchDateRangeAttendance(start, end);
  }, [start, end]);

  const columns: CustomTableColumn<
    IStaffMemberAggregatedAttendance | IStudentAggregatedAttendance
  >[] = [
    {
      name: "Name",
      cell: ({ person }) => (
        <ClickableCell
          href={
            entityType == AttendanceReportEntityType.STAFF_MEMBER
              ? `/c/staff/${person._id}/edit`
              : `/c/students/${person._id}/view`
          }
        >
          {person.first_name} {person.last_name}
        </ClickableCell>
      ),
      width: "250px",
    },
    {
      name: "Present Days",
      selector: (row) => row.present,
      sortable: true,
      width: "120px",
    },
    {
      name: "Absent Days",
      selector: (row) => row.absent,
      sortable: true,
      width: "120px",
    },
    {
      name: "Attendance not marked",
      selector: (row) => row.not_marked,
      sortable: true,
      width: "140px",
    },
    ...conditionalCol(entityType == AttendanceReportEntityType.STAFF_MEMBER, {
      name: "Half day count",
      selector: (row: IStaffMemberAggregatedAttendance) => row.half_day,
      sortable: true,
      width: "140px",
    }),
    ...conditionalCol(entityType == AttendanceReportEntityType.STAFF_MEMBER, {
      name: "Penalties",
      selector: (row: IStaffMemberAggregatedAttendance) => row.penalty ?? 0,
      sortable: true,
      width: "120px",
    }),
  ];

  const getTableView = () => {
    return (
      <Aux>
        <Row className="m-0 mb-3">
          <Col className="col-auto">
            <SearchBox
              value={search}
              onChange={setSearch}
              placeholder="Search"
            />
          </Col>
        </Row>
        <CustomDataTable
          tableKey="person-attendance"
          columns={columns}
          data={data.filter((d) =>
            `${d.person.first_name} ${d.person.last_name}`.includes(search)
          )}
          sortServer={false}
          paginationServer={false}
          progressPending={loading}
        />
      </Aux>
    );
  };

  if (tableOnly) return getTableView();

  return (
    <CustomCard
      title="Staff Attendance"
      headerRight={
        <Row className="m-0">
          <Col className="p-0 col-auto">
            <DateRangePicker
              startDate={start}
              endDate={end}
              onChange={(start, end) => {
                setStart(start);
                end ? setEnd(end) : null;
              }}
              startLabel={""}
              endLabel={""}
            />
          </Col>
        </Row>
      }
      {...cardProps}
    >
      {getTableView()}
    </CustomCard>
  );
}
