import { useEffect, useState } from "react";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useAuth } from "src/context/auth_context";
import { useNotification } from "src/context/notification_context";
import { FeeStructureService } from "src/services/institute/fees/structure";
import { FormFieldType } from "src/utils/constants";
import { catchRequest, validateFields } from "src/utils/functions";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import {
  DEFAULT_DATE_FORMAT,
  IFeeParticular,
  IFeeStructure,
  Permissions,
} from "crm_core";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import ArchiveButton from "src/components/widgets/archive_button";
import SaveButton from "src/components/widgets/save_button";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import CustomDataTable, {
  CustomTableColumn,
} from "src/components/custom_data_table";
import TextField from "src/components/form_generator/text_field";
import { PlusIcon } from "@heroicons/react/20/solid";
import TrackedButton from "src/components/buttons/tracked_button";
import moment from "moment";

export interface AddUpdateFeeStructureProps {
  onComplete?: () => void;
  id?: string;
  data?: IFeeStructure;
}

export default function AddUpdateFeeStructure({
  onComplete,
  id,
  data,
}: AddUpdateFeeStructureProps) {
  const service = new FeeStructureService();
  const { selectedOrganization } = useAuth();
  const [feeStructure, setFeeStructure] = useState<IFeeStructure>({
    name: "",
    description: "",
    particulars: [
      {
        name: "Tuition Fee",
        amount: 0,
      },
    ],
    late_fine: 0,
    due_date: moment().add(30, "d").format(DEFAULT_DATE_FORMAT),
    installment_count: 1,
    installment_start_date: moment().format(DEFAULT_DATE_FORMAT),
  });
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();
  const { fetchDataForUseCase, classrooms, selectedSession } =
    useDataProvider();

  useEffect(() => {
    if (selectedSession) fetchDataForUseCase(DataUseCase.CLASSROOMS);
  }, [selectedSession]);

  useEffect(() => {
    const fetchFeeStructure = async (id: string) => {
      setLoading(true);
      const currentFeeStructure = await service.getFeeStructure(id);
      setFeeStructure(currentFeeStructure);
      setLoading(false);
    };
    if (data) {
      setFeeStructure(data);
    } else if (id) {
      fetchFeeStructure(id);
    }
  }, [id, data]);

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(getFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);
    let update: any = {
      name: feeStructure?.name,
      description: feeStructure?.description,
      particulars: feeStructure?.particulars,
      late_fine: feeStructure?.late_fine,
      due_date: feeStructure?.due_date,
      installment_count: feeStructure?.installment_count ?? 1,
      installment_start_date:
        feeStructure?.installment_start_date ??
        moment().format(DEFAULT_DATE_FORMAT),
    };

    await catchRequest(async () => {
      if (id || data) {
        await service.updateFeeStructure({
          _id: id || data?._id,
          ...update,
        });
        pushNotification({
          title: "Success",
          message: "Fee Structure updated successfully",
          type: "success",
        });
      } else {
        await service.createFeeStructure({
          ...update,
          organization: selectedOrganization?._id as any,
        });
      }
    }, pushNotification)();
    setLoading(false);
    onComplete?.();
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.TEXT,
          value: feeStructure?.name,
          onChange: (v) => setFeeStructure((f) => ({ ...f, name: v })),
          extras: {
            placeholder: "Name",
            label: "Name",
          },
          required: true,
          updatable: true,
        },
      ],
      [
        {
          type: FormFieldType.DATE,
          value: feeStructure?.installment_start_date,
          onChange: (v) =>
            setFeeStructure((f) => ({ ...f, installment_start_date: v })),
          extras: {
            label: "Installment Start Date",
          },
          updatable: true,
        },
        {
          type: FormFieldType.NUMBER,
          value: feeStructure?.installment_count,
          onChange: (v) =>
            setFeeStructure((f) => ({ ...f, installment_count: v })),
          extras: {
            label: "Number of Installments",
          },
          updatable: true,
          required: true,
        },
      ],
      [
        {
          type: FormFieldType.DATE,
          value: feeStructure?.due_date,
          onChange: (v) => setFeeStructure((f) => ({ ...f, due_date: v })),
          extras: {
            label: "Due Date",
          },
          required: true,
          updatable: true,
        },

        {
          type: FormFieldType.NUMBER,
          value: feeStructure?.late_fine,
          onChange: (v) => setFeeStructure((f) => ({ ...f, late_fine: v })),
          extras: {
            label: "Late Fine",
          },
          required: true,
          updatable: true,
        },
      ],
      [
        {
          type: FormFieldType.TEXTAREA,
          value: feeStructure?.description,
          onChange: (v) => setFeeStructure((f) => ({ ...f, description: v })),
          extras: {
            placeholder: "Description",
            label: "Description",
          },
          updatable: true,
        },
      ],
    ];

    return allFields;
  };

  const handleDeleteFeeStructure = async () => {
    await catchRequest(async () => {
      await service.deleteFeeStructure(id as string);
      pushNotification({
        title: "Success",
        message: "Fee Structure archived successfully",
        type: "success",
      });
      navigate("/c/fee-management/structures");
    }, pushNotification)();
  };

  const handleParticularsChange = (idx: number, key: string, value: any) => {
    setFeeStructure((f) => ({
      ...f,
      particulars: f.particulars?.map((p, i) => {
        if (i === idx) {
          return { ...p, [key]: value };
        }
        return p;
      }) as any,
    }));
  };

  const handleDeleteParticular = (idx: number) => {
    setFeeStructure((f) => ({
      ...f,
      particulars: f.particulars?.filter((p, i) => i !== idx) as any,
    }));
  };

  const particularColumns: CustomTableColumn<IFeeParticular>[] = [
    {
      name: "Title",
      cell: (row, idx) => (
        <Row className="py-2 w-full">
          <TextField
            value={row.name}
            onChange={(v) => handleParticularsChange(idx, "name", v)}
          />
        </Row>
      ),
      width: "250px",
    },
    {
      name: "Amount",
      cell: (row, idx) => (
        <div className="py-2">
          <TextField
            type="number"
            value={row.amount as any}
            onChange={(v) => handleParticularsChange(idx, "amount", Number(v))}
          />
        </div>
      ),
      width: "150px",
    },
    {
      name: "Actions",
      cell: (row, idx) => (
        <div className="py-2">
          <ArchiveButton
            dialogContent="Are you sure you want to archive this component?"
            dialogTitle="Archive component"
            label={<span className="">Archive</span>}
            onClick={() => handleDeleteParticular(idx)}
          />
        </div>
      ),
      width: "130px",
    },
  ];

  const handleAddParticular = () => {
    setFeeStructure((f) => ({
      ...f,
      particulars: [
        ...f.particulars,
        {
          name: "Other fees",
          amount: 0,
        },
      ],
    }));
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      <Row className="m-0 align-items-center mt-3">
        <Col className="col-auto p-0">
          <div>Components</div>
        </Col>
        <Col className="col-auto">
          <TrackedButton
            icon={<PlusIcon className="h-3 w-3 mr-1" />}
            label={<span className="">Add component</span>}
            onClick={() => handleAddParticular()}
            color="accent"
            isSlim
            className="px-2 py-1 text-sm"
          />
        </Col>
      </Row>
      <CustomDataTable
        tableKey="fee-particulars"
        columns={particularColumns}
        data={feeStructure.particulars}
        highlightOnHover={false}
        pagination={false}
      />
      <Row className="justify-content-end mt-4 align-items-center">
        <Col className="col-auto ">
          <div>
            Total Amount:{" "}
            {feeStructure.particulars.reduce((a, b) => a + b.amount, 0)}
          </div>
        </Col>
        <Col hidden={!id} className="col-auto p-0">
          <PermissionRestrictedComponent
            permissions={[Permissions.ARCHIVE_FEE_STRUCTURE]}
          >
            <ArchiveButton
              dialogTitle="Archive Fee Structure?"
              dialogContent="Are you sure you want to archive this fee structure?"
              label="Archive Fee Structure"
              onClick={() => handleDeleteFeeStructure()}
            />
          </PermissionRestrictedComponent>
        </Col>
        <Col className="col-auto ">
          <PermissionRestrictedComponent
            permissions={[
              Permissions.UPDATE_FEE_STRUCTURE,
              Permissions.CREATE_FEE_STRUCTURE,
            ]}
          >
            <SaveButton
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            />
          </PermissionRestrictedComponent>
        </Col>
      </Row>
    </div>
  );
}
