import { IHomework, ITest } from "crm_core";
import API from "../../api";

export class HomeworkService extends API {
  constructor() {
    super("/homework");
  }
  async getHomeworks(params: any): Promise<IHomework[]> {
    const res = await this._get({ params });
    return res.data as IHomework[];
  }

  async getHomework(id: string): Promise<IHomework> {
    const res = await this._get({ url: `/${id}` });
    return res.data as IHomework;
  }
  async createHomework(homework: IHomework): Promise<IHomework> {
    const res = await this._post({ body: homework });
    return res.data as IHomework;
  }

  async updateHomework(homework: Partial<IHomework>): Promise<IHomework> {
    const res = await this._put({ url: `/${homework._id}`, body: homework });
    return res.data as IHomework;
  }

  async deleteHomework(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }
}
