import { useEffect, useState } from "react";
import DataTable, {
  TableColumn,
  TableProps,
  TableStyles,
} from "react-data-table-component";
import { useTable } from "src/context/table_context";
import { Loader } from "../loader";
import { useNotification } from "src/context/notification_context";
import { CheckIcon } from "@heroicons/react/20/solid";

export interface CustomDataTableProps<T> extends TableProps<T> {
  columns: CustomTableColumn<T>[];
  tableKey: string;
}

export interface CustomTableColumn<T> extends TableColumn<T> {
  /** colId is required if reorderable: true */
  colId?: number | string;
}

const Checkbox = ({ onClick, ...rest }: any) => {
  return (
    <div className="" style={{ backgroundColor: "" }}>
      <button
        onClick={onClick}
        {...rest}
        className="border-2 border-accent rounded-md text-accent font-extrabold"
      >
        {rest.checked ? (
          <CheckIcon className="h-4 w-4" />
        ) : (
          <div className="h-4 w-4"></div>
        )}
      </button>
    </div>
  );
};

export default function CustomDataTable<T>(props: CustomDataTableProps<T>) {
  const {
    getPaginationPreferences,
    getSortPreferences,
    updatePaginationPreferences,
    updateSortPreferences,
    paginationPreferences,
    sortPreferences,
    getColumnOrder,
    updateColumnOrder,
    getTotalRows,
    tableTotalRows,
  } = useTable();

  const { pushNotification } = useNotification();

  const [page, setPage] = useState(
    getPaginationPreferences(props.tableKey).page
  );
  const [perPage, setPerPage] = useState(
    getPaginationPreferences(props.tableKey).perPage
  );
  const [totalRows, setTotalRows] = useState<undefined | number>(
    getTotalRows(props.tableKey)
  );
  const [sortColumn, setSortColumn] = useState(
    getSortPreferences(props.tableKey).column
  );
  const [sortDirection, setSortDirection] = useState(
    getSortPreferences(props.tableKey).order
  );
  const [columns, setColumns] = useState<CustomTableColumn<T>[]>([]);

  useEffect(() => {
    const pref = getPaginationPreferences(props.tableKey);
    setPage(pref.page);
    setPerPage(pref.perPage);
  }, [paginationPreferences]);

  useEffect(() => {
    const pref = getSortPreferences(props.tableKey);
    setSortColumn(pref.column);
    setSortDirection(pref.order);
  }, [sortPreferences]);

  useEffect(() => {
    setTotalRows(getTotalRows(props.tableKey));
  }, [tableTotalRows]);

  const customStyles: TableStyles = {
    headCells: {
      style: {
        fontSize: "14px",
        color: "#888",
        // zIndex: 0,
      },
    },
    rows: {
      style: {
        fontSize: "14px",
      },
    },
    head: {
      style: {
        // zIndex: 0,
      },
    },
  };

  const handleUpdatePaginationPreferences = (page: number, perPage: number) => {
    updatePaginationPreferences(props.tableKey, {
      page,
      perPage,
    });
  };

  const handleSort = (column: TableColumn<T>, sortDirection: string) => {
    if (column.sortField) {
      updateSortPreferences(props.tableKey, {
        column: column.sortField,
        order: sortDirection,
      });
    }
  };

  const reorderColumns = () => {
    const columnOrderState = getColumnOrder(props.tableKey);
    if (!columnOrderState || columnOrderState.length === 0) {
      setColumns(props.columns);
    }
    const reorderedColumns = [];
    for (let column of props.columns) {
      if (!column.reorder) {
        reorderedColumns.push(column);
      }
    }
    for (let colId of columnOrderState) {
      const column = props.columns.find((col) => col.colId === colId);
      if (column) {
        reorderedColumns.push(column);
      }
    }
    if (reorderedColumns.length !== props.columns.length) {
      for (let i = 0; i < props.columns.length; i++) {
        if (!reorderedColumns.includes(props.columns[i])) {
          reorderedColumns.push(props.columns[i]);
        }
      }
    }
    setColumns(reorderedColumns);
  };

  useEffect(() => {
    reorderColumns();
  }, [props.columns]);

  const handleColumnReorder = (columns: CustomTableColumn<T>[]) => {
    let isEligible = columns.every((col) => (col.reorder ? col.colId : true));
    if (!isEligible) {
      pushNotification({
        type: "danger",
        message: "Some columns cannot be reordered",
      });
      return;
    }
    updateColumnOrder(
      props.tableKey,
      columns.map((c: any) => c.colId)
    );
  };

  return (
    <DataTable
      paginationRowsPerPageOptions={[20, 30, 40, 50, 100, 200]}
      customStyles={customStyles}
      highlightOnHover
      selectableRows
      fixedHeader
      fixedHeaderScrollHeight="68vh"
      sortServer
      paginationServer
      pagination
      paginationTotalRows={totalRows}
      paginationDefaultPage={page}
      paginationPerPage={perPage}
      // selectableRowsComponent={Checkbox as any}
      onChangePage={(newPage) =>
        handleUpdatePaginationPreferences(newPage, perPage)
      }
      onChangeRowsPerPage={(newPerPage) =>
        handleUpdatePaginationPreferences(page, newPerPage)
      }
      onSort={handleSort}
      defaultSortAsc={sortDirection === "asc"}
      defaultSortFieldId={sortColumn}
      onColumnOrderChange={handleColumnReorder}
      progressComponent={
        <div className="mt-8">
          <Loader />
        </div>
      }
      {...props}
      columns={columns.map((c) => ({
        ...c,
        name: c.name ? (
          <div style={{ whiteSpace: "normal" }}>{c.name}</div>
        ) : undefined,
      }))}
      data={props.data}
    />
  );
}
