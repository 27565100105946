import CustomDataTable, {
  CustomDataTableProps,
  CustomTableColumn,
} from "src/components/custom_data_table";
import { ModalTypes, useModal } from "src/context/modal_context";
import {
  InvitationStatus,
  AuthEntityType,
  InvitationEntityType,
  UserRole,
  IStaffMemberResponse,
  Permissions,
  BulkImportDataType,
  StaffExportConfig,
} from "crm_core";
import { useEffect, useState } from "react";
import { StaffMemberService } from "src/services/institute/staff_member";
import {
  ArrowUpTrayIcon,
  InboxArrowDownIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import TrackedButton from "src/components/buttons/tracked_button";
import LinkText from "src/components/text_fields/link_text";
import CustomCard, { CustomCardProps } from "src/components/custom_card";
import Aux from "src/components/aux";
import { PaginatedGetter } from "src/services/paginated_getter";
import { useTable } from "src/context/table_context";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import { AddUpdateStaffMemberProps } from "./add_update";
import { Col, Row } from "react-bootstrap";
import Filters from "src/features/filters";
import { FilterKeys } from "src/utils/constants";
import { InvitationService } from "src/services/institute/invite";
import {
  catchAsync,
  handleBulkImport,
  handleDataExport,
} from "src/utils/functions";
import InvitationStatusBadge from "src/components/widgets/badges";
import ClickableCell from "src/components/widgets/clickable_cell";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";

export interface StaffMembersListProps {
  shift?: string;
  filterKey?: FilterKeys;
  cardProps?: Partial<CustomCardProps>;
  tableProps?: Partial<CustomDataTableProps<IStaffMemberResponse>>;
  tableOnly?: boolean;
}

export default function StaffMembersList({
  shift,
  filterKey,
  cardProps = {},
  tableProps = {},
  tableOnly,
}: StaffMembersListProps) {
  const FILTER_KEY = filterKey || FilterKeys.STAFF_MEMBER;

  const paginatedGetter = new PaginatedGetter<
    StaffMemberService,
    IStaffMemberResponse
  >(StaffMemberService);
  const {
    setModalType,
    setShowModal,
    setModalSize,
    setModalHeading,
    setComponentProps,
  } = useModal();
  const [staffMembers, setStaffMembers] = useState<IStaffMemberResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    getPaginationPreferences,
    getSortPreferences,
    getFilterPreferences,
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    updateTotalRows,
  } = useTable();
  const { fetchDataForUseCase, departments, selectedSession } =
    useDataProvider();

  useEffect(() => {
    if (selectedSession) fetchDataForUseCase(DataUseCase.INSTITUTE);
  }, [selectedSession]);

  const fetchData = async () => {
    setLoading(true);
    const data = await paginatedGetter.get({
      pagination: getPaginationPreferences(FILTER_KEY),
      sort: getSortPreferences(FILTER_KEY),
      filters: getFilterPreferences(FILTER_KEY),
    });
    setStaffMembers(data.results);
    updateTotalRows(FILTER_KEY, data.totalCount);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedSession) fetchData();
  }, [
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    selectedSession,
  ]);

  useEffect(() => {
    document.title = "Teachbond - Staff Members";
  }, []);

  const onAddUpdate = () => {
    fetchData();
    fetchDataForUseCase(DataUseCase.INSTITUTE, true);
    setShowModal(false);
  };

  const handleCreateStaffMember = () => {
    setModalType(ModalTypes.STAFF_MEMBER);
    setModalSize("xl");
    setModalHeading("Add New Staff Member");
    let props: AddUpdateStaffMemberProps = {
      onComplete: onAddUpdate,
    };
    setComponentProps(props);
    setShowModal(true);
  };

  const handleResendInvite = async (id: string) => {
    setLoading(true);
    await catchAsync(
      async () => {
        await new InvitationService().createInvitation({
          auth_entity_type: AuthEntityType.Organization,
          entity_id: id,
          entity_type: InvitationEntityType.STAFF_MEMBER,
          role: UserRole.StaffMember,
          resend: true,
        });
      },
      "Invitation sent successfully",
      "Something went wrong"
    )();
    await fetchData();
    setLoading(false);
  };

  const columns: CustomTableColumn<IStaffMemberResponse>[] = [
    {
      colId: 0,
      name: "Name",
      cell: ({ first_name, _id, last_name }) => (
        <ClickableCell href={`/c/staff/${_id}/edit`}>
          {first_name} {last_name}
        </ClickableCell>
      ),
      reorder: true,
      sortable: true,
      sortField: "first_name,last_name",
      width: "200px",
    },
    {
      colId: 1,
      name: "Email",
      cell: ({ email }) => <LinkText to={`mailto:${email}`}>{email}</LinkText>,
      reorder: true,
      sortable: true,
      sortField: "email",
      width: "260px",
    },
    {
      colId: 2,
      name: "Joining Status",
      cell: ({ _id, invitation }) => (
        <Row className="w-full align-items-center">
          <Col md="auto" sm="auto" xs="auto" className="m-0 pr-0">
            <InvitationStatusBadge status={invitation?.status} />
          </Col>
          <Col
            hidden={invitation?.status == InvitationStatus.ACCEPTED}
            md="auto"
            sm="auto"
            xs="auto"
            className="p-0 ml-auto mr-2"
          >
            <LinkText onClick={() => handleResendInvite(_id)}>
              {invitation ? "Resend Invitation" : "Send Invitation"}
            </LinkText>
          </Col>
        </Row>
      ),
      reorder: true,
      sortable: true,
      sortField: "invitation",
      width: "270px",
    },
    {
      colId: 3,
      name: "Shift",
      cell: ({ current_shift }) => <div>{current_shift?.name ?? "--"}</div>,
      reorder: true,
      sortable: true,
      sortField: "current_shift",
      width: "260px",
    },
  ];

  const getTableView = () => {
    return (
      <>
        <Filters filterKey={FILTER_KEY} />
        <CustomDataTable
          tableKey={FILTER_KEY}
          columns={columns}
          data={staffMembers}
          progressPending={loading}
          {...tableProps}
        />
      </>
    );
  };

  if (tableOnly) return getTableView();

  return (
    <CustomCard
      title="All Staff Members"
      actionButtonProps={{
        actions: [
          {
            label: "Import Data",
            onClick: handleBulkImport(BulkImportDataType.STAFF_MEMBERS),
            icon: ArrowUpTrayIcon,
          },
          {
            label: "Export CSV",
            onClick: () => handleDataExport(StaffExportConfig, staffMembers),
            icon: InboxArrowDownIcon,
          },
        ],
      }}
      headerRight={
        <Aux>
          <PermissionRestrictedComponent
            permissions={[Permissions.CREATE_STAFF]}
          >
            <TrackedButton
              icon={<PlusIcon className="h-3 w-3 mr-1" />}
              label={<span className="">Create New Staff Member</span>}
              onClick={() => handleCreateStaffMember()}
              color="accent"
              isSlim
              className="px-2 py-1 text-sm"
            />
          </PermissionRestrictedComponent>
        </Aux>
      }
      {...cardProps}
    >
      {getTableView()}
    </CustomCard>
  );
}
