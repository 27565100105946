
export enum Religion {
  Hindu = "Hindu",
  Muslim = "Muslim",
  Christian = "Christian",
  Sikh = "Sikh",
  Buddhist = "Buddhist",
  Jain = "Jain",
  Parsi = "Parsi",
  Jewish = "Jewish",
  Other = "Other",
}

export enum Category {
  General = "General",
  OBC = "OBC",
  SC = "SC",
  ST = "ST",
  EWS = "EWS",
  Foreigner = "Foreigner",
  Other = "Other",
}

export enum Disablity {
  None = "None",
  Blind = "Blind",
  LowVision = "Low Vision",
  Deaf = "Deaf",
  HardOfHearing = "Hard of Hearing",
  LocomotorDisability = "Locomotor Disability",
  Dwarfism = "Dwarfism",
  IntellectualDisability = "Intellectual Disability",
  MentalIllness = "Mental Illness",
  AutismSpectrumDisorder = "Autism Spectrum Disorder",
  CerebralPalsy = "Cerebral Palsy",
  MuscularDystrophy = "Muscular Dystrophy",
  ChronicNeurologicalConditions = "Chronic Neurological Conditions",
  SpecificLearningDisabilities = "Specific Learning Disabilities",
  MultipleSclerosis = "Multiple Sclerosis",
  SpeechAndLanguageDisabilities = "Speech and Language Disabilities",
  Thalassemia = "Thalassemia",
  Hemophilia = "Hemophilia",
  SickleCellDisease = "Sickle Cell Disease",
  MultipleDisabilitiesIncludingDeafBlindness = "Multiple Disabilities Including Deaf Blindness",
  AcidAttackVictim = "Acid Attack Victim",
  ParkinsonsDisease = "Parkinson's Disease",
  CerebralVisualImpairment = "Cerebral Visual Impairment",
  ChronicBloodDisorders = "Chronic Blood Disorders",
  Others = "Others",
}

export enum EmploymentTypes {
  FULL_TIME = "Full Time",
  PART_TIME = "Part Time",
  CONTRACTUAL = "Contractual",
  VISITING = "Visiting",
  GUEST = "Guest",
  OTHER = "Other",
}

export enum MaritalStatus{
  Married = "Married",
  Unmarried = "Unmarried",
  Divorced = "Divorced",
  Widowed = "Widowed",
  Other = "Other",
}

export enum Days {
  Sunday = "sun",
  Monday = "mon",
  Tuesday = "tue",
  Wednesday = "wed",
  Thursday = "thu",
  Friday = "fri",
  Saturday = "sat",
}

export const dayOptions = [
  { value: Days.Sunday, label: "Sunday" },
  { value: Days.Monday, label: "Monday" },
  { value: Days.Tuesday, label: "Tuesday" },
  { value: Days.Wednesday, label: "Wednesday" },
  { value: Days.Thursday, label: "Thursday" },
  { value: Days.Friday, label: "Friday" },
  { value: Days.Saturday, label: "Saturday" },
];