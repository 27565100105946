import { IChatRoomExtended, IUser, getUserFullName, pluralize } from "crm_core";
import { useEffect, useRef, useState } from "react";
import {
  Button as ChatButton,
  ChatItem,
  Input,
  MessageList,
  MessageType,
} from "react-chat-elements";
import { Loader } from "src/components/loader";
import { ModalTypes, useModal } from "src/context/modal_context";
import { ChatMessageService } from "src/services/chatrooms/messgae";
import { ChatRoomService } from "src/services/chatrooms/room";
import { AddUpdateChatRoomProps } from "./add_update";
import Aux from "src/components/aux";
import { useAuth } from "src/context/auth_context";
import { usePageState } from "src/context/page_state_context";

interface ChatRoomViewProps {
  id: string;
  refreshList?: () => void;
  totalHeight?: string;
}

let clearRef = () => {};

export default function ChatRoomView({
  id,
  refreshList,
  totalHeight = "90vh",
}: ChatRoomViewProps) {
  const chatRoomService = new ChatRoomService();
  const messageService = new ChatMessageService();
  const { user } = useAuth();
  const { chatNotifications } = usePageState();
  const [room, setRoom] = useState<IChatRoomExtended>();
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [message, setMessage] = useState<string>("");
  const inputReferance = useRef();
  const {
    setModalType,
    setModalSize,
    setModalHeading,
    setShowModal,
    setComponentProps,
  } = useModal();

  const fetchChatRoom = async () => {
    setLoading(true);
    const data = await chatRoomService.getChatRoom(id, true);
    setRoom(data as any);
    setLoading(false);
  };

  useEffect(() => {
    fetchChatRoom();
  }, [id]);

  useEffect(() => {
    if (chatNotifications > 0) {
      fetchChatRoom();
    }
  }, [chatNotifications]);

  const visibilityChangeHandler = () => {
    if (!document.hidden) {
      fetchChatRoom();
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", visibilityChangeHandler);
    return () => {
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
    };
  }, []);

  useEffect(() => {
    if (room?.messages && room.messages.length > 0) {
      setMessages(
        room.messages.map((m) => ({
          id: m._id,
          className: `${m._id}`,
          position: (m.sender as IUser)._id === user._id ? "right" : "left",
          type: "text",
          title: getUserFullName(m.sender as IUser),
          text: m.data,
          focus: true,
          date: m.created_at,
          titleColor: "accent",
          forwarded: false,
          replyButton: false,
          removeButton: false,
          status: "sent",
          notch: true,
          retracted: false,
        }))
      );
    } else {
      setMessages([
        {
          id: "1",
          type: "system",
          text: "No messages yet",
          focus: true,
        } as any,
      ]);
    }
  }, [room]);

  const sendMessage = async () => {
    if (!message) return;
    const sentMessage = await messageService.createChatMessage(id, message);
    room?.messages?.push(sentMessage);
    setMessage("");
    setRoom({ ...room } as any);
    refreshList?.();
  };

  const handleUpdateChatRoom = (id: string) => {
    setModalType(ModalTypes.ADD_UPDATE_CHATROOM);
    setModalSize("lg");
    setModalHeading("View Group");
    let props: AddUpdateChatRoomProps = {
      onComplete: () => {
        setShowModal(false);
        refreshList?.();
        fetchChatRoom();
      },
      id,
    };
    setComponentProps(props);
    setShowModal(true);
  };

  const scrollToBottom = () => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      const elem = document.getElementsByClassName(lastMessage.id as string)[0];
      if (elem) {
        elem.scrollIntoView();
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="bg-white rounded-r-md">
      {loading && (
        <div className="text-center">
          <Loader text="Fetching Group Chat" />
        </div>
      )}
      {room && (
        <Aux>
          <ChatItem
            id={room._id}
            letterItem={{
              letter: room.name
                .split(" ")
                .map((n) => (n.length > 0 ? n[0].toUpperCase() : ""))
                .join(""),
              id: room._id,
            }}
            className="rounded-tr-md border-b-2 border-l-2 border-custom-grey"
            avatar=""
            title={room.name}
            subtitle={room.description}
            date={undefined}
            dateString={`${room.classrooms.length} ${pluralize(
              room.classrooms.length,
              "classroom",
              "classrooms"
            )}`}
            unread={0}
            onClick={() => handleUpdateChatRoom(room._id)}
          />
          <div
            className="flex flex-col border-l-2 border-custom-grey"
            style={{ height: `calc(${totalHeight} - 148px)` }}
          >
            <MessageList
              referance={inputReferance}
              className="overflow-auto flex"
              lockable={true}
              // downButton={true}
              // downButtonBadge={messages.length}
              // onDownButtonClick={scrollToBottom as any}
              sendMessagePreview={true}
              messageBoxStyles={{
                backgroundColor: "#D1FFFE",
                maxWidth: "60%",
              }}
              notchStyle={{ fill: "#D1FFFE" }}
              dataSource={messages}
            />
          </div>
          <div className="pt-3 pb-2 px-2 border-l-2 border-custom-grey">
            <Input
              className="border-1 border-accent rounded-md"
              placeholder="Write your message here"
              value={message}
              onChange={(e: any) => setMessage(e.target.value)}
              referance={inputReferance}
              clear={(clear: any) => (clearRef = clear)}
              maxHeight={500}
              multiline={false}
              onKeyPress={(e: any) => {
                if (e.shiftKey && e.charCode === 13) {
                  return true;
                }
                if (e.charCode === 13) {
                  clearRef();
                  sendMessage();
                }
              }}
              rightButtons={
                <ChatButton
                  text="Send"
                  className="bg-accent"
                  onClick={() => sendMessage()}
                />
              }
            />
          </div>
        </Aux>
      )}
    </div>
  );
}
