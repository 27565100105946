import { Form, InputGroup } from "react-bootstrap";
import { TextFieldProps } from "../form_generator/text_field";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { joinClasses } from "src/utils/functions";
import { useTheme } from "src/context/theme_context";
import { useEffect, useState } from "react";

interface SearchBoxProps extends TextFieldProps {}

export default function SearchBox(props: SearchBoxProps) {
  const { getThemeBasedValue } = useTheme();
  const { label, value, onChange, error, className } = props;
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  useEffect(() => {
    onChange(val);
  }, [val]);

  const extras = {
    rows: props.rows,
  } as any;

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && props.onSubmit) {
      props.onSubmit(val);
    }
  };

  return (
    <InputGroup
      className={joinClasses(
        "m-0 p-0 align-items-center",
        className,
        getThemeBasedValue("border-none", "border-accent"),
        "bg-custom-grey rounded-md appearance-none leading-tight focus:ring-1 focus:ring-dark-accent focus:outline-none"
      )}
    >
      <Form.Control
        style={{
          color: getThemeBasedValue("black", "white"),
        }}
        placeholder={props.placeholder}
        type="text"
        value={val ?? ""}
        onChange={(e: any) => setVal(e.target.value)}
        className={joinClasses("bg-custom-grey py-2 border-0 shadow-none")}
        as={props.rows ? "textarea" : "input"}
        onKeyDown={handleKeyDown}
        {...extras}
      />
      {/* <InputGroup.Append> */}
      <MagnifyingGlassIcon
        onClick={() => (props.onSubmit ? props.onSubmit(val) : null)}
        className="mr-4 h-6 w-6 text-gray-400 hover:text-gray-500 cursor-pointer"
      />
      {/* </InputGroup.Append> */}
    </InputGroup>
  );
}
