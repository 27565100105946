import {
  IChatRoom,
  IChatRoomMemberRole,
  IChatRoomMemberType,
  IClassroom,
  Permissions,
} from "crm_core";
import { useEffect, useState } from "react";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useNotification } from "src/context/notification_context";
import { ChatRoomService } from "src/services/chatrooms/room";
import { FormFieldType } from "src/utils/constants";
import {
  catchRequest,
  isPermissible,
  validateFields,
} from "src/utils/functions";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import { Col, Row } from "react-bootstrap";
import ArchiveButton from "src/components/widgets/archive_button";
import SaveButton from "src/components/widgets/save_button";
import { ClassroomService } from "src/services/institute/classroom";

export interface AddUpdateChatRoomProps {
  onComplete?: () => void;
  id?: string;
  data?: IChatRoom;
}

export default function AddUpdateChatRoom({
  onComplete,
  id,
  data,
}: AddUpdateChatRoomProps) {
  const canUpdate = isPermissible(Permissions.UPDATE_CHAT_GROUP);
  const service = new ChatRoomService();
  const [classrooms, setClassrooms] = useState<IClassroom[]>([]);
  const [chatRoom, setChatRoom] = useState<IChatRoom>({} as any);
  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();

  const fetchClassrooms = async () => {
    setLoading(true);
    const res = await new ClassroomService().getClassrooms(undefined, true);
    setClassrooms(res);
    setLoading(false);
  };

  useEffect(() => {
    fetchClassrooms();
  }, []);

  useEffect(() => {
    const fetchChatRoom = async (id: string) => {
      setLoading(true);
      const chatRoom = await service.getChatRoom(id);
      setChatRoom(chatRoom);
      setLoading(false);
    };
    if (data) {
      setChatRoom(data);
    }
    if (id) {
      fetchChatRoom(id);
    }
  }, [id]);

  const handleArchive = async () => {
    await catchRequest(async () => {
      await service.deleteChatRoom(id as string);
      onComplete?.();
    }, pushNotification)();
  };

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(getFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);
    await catchRequest(async () => {
      if (id) {
        await service.updateChatRoom({
          ...chatRoom,
          _id: id,
        });
      } else {
        await service.createChatRoom(chatRoom);
      }
      onComplete?.();
    }, pushNotification)();
    setLoading(false);
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    const updating = !!id;
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.TEXT,
          value: chatRoom.name,
          onChange: (name) => setChatRoom({ ...chatRoom, name }),
          extras: {
            placeholder: "Name",
            label: "Name",
          },
          required: true,
          updatable: true,
        },
      ],
      [
        {
          type: FormFieldType.TEXT,
          value: chatRoom.description,
          onChange: (description) => setChatRoom({ ...chatRoom, description }),
          extras: {
            placeholder: "Description",
            label: "Description",
          },
          updatable: true,
        },
      ],
      [
        {
          type: FormFieldType.SELECT,
          value: (chatRoom.members ?? []).map((m) => m.entity_id),
          onChange: (v) =>
            setChatRoom({
              ...chatRoom,
              members: v.map((m: string) => ({
                entity_type: IChatRoomMemberType.CLASSROOM,
                entity_id: m,
                role: IChatRoomMemberRole.MEMBER,
              })),
            }),
          extras: {
            options: classrooms.map((c) => ({ label: c.name, value: c._id })),
            multi: true,
            placeholder: "Members",
            label: "Members",
          },
          required: true,
          updatable: true,
        },
      ],
    ];

    if (updating && !canUpdate) {
      allFields = allFields.map((fields) =>
        fields.map((f) => ({ ...f, extras: { ...f.extras, disabled: true } }))
      );
    }

    if (id) {
      return allFields
        .map((fields) => fields.filter((f) => f.updatable))
        .filter((f) => f.length > 0);
    }
    return allFields;
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      <Row className="justify-content-end mt-4 align-items-center">
        <Col hidden md="auto" sm="auto" xs="auto" className="p-0">
          <PermissionRestrictedComponent
            permissions={[Permissions.ARCHIVE_CLASSROOM]}
          >
            <ArchiveButton
              dialogTitle="Archive Group?"
              dialogContent="Are you sure you want to archive this group?"
              label="Archive Group"
              onClick={handleArchive}
            />
          </PermissionRestrictedComponent>
        </Col>
        <Col md="auto" sm="auto" xs="auto" className="">
          <PermissionRestrictedComponent
            permissions={[
              Permissions.UPDATE_CHAT_GROUP,
              Permissions.CREATE_CHAT_GROUP,
            ]}
          >
            <SaveButton
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            />
          </PermissionRestrictedComponent>
        </Col>
      </Row>
    </div>
  );
}
