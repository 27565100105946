import API from "../api";
import { IChatRoom, IChatRoomListResponse } from "crm_core";

export class ChatRoomService extends API {
  static NAME = "ChatRoomService";

  constructor() {
    super("/chat-room");
  }
  async getChatRooms(classroomId?: string): Promise<IChatRoomListResponse[]> {
    let params: any = {};
    if (classroomId) {
      params.classroomId = classroomId;
    }
    const res = await this._get({ params });
    return res.data as IChatRoomListResponse[];
  }

  async getChatRoom(id: string, extended?: boolean): Promise<IChatRoom> {
    let params: any = {};
    if (extended) {
      params.extended = "true";
    }
    const res = await this._get({ url: `/${id}`, params });
    return res.data as IChatRoom;
  }

  async createChatRoom(chatRoom: IChatRoom): Promise<IChatRoom> {
    const res = await this._post({ body: chatRoom });
    return res.data as IChatRoom;
  }

  async updateChatRoom(chatRoom: IChatRoom): Promise<IChatRoom> {
    const res = await this._put({ url: `/${chatRoom._id}`, body: chatRoom });
    return res.data as IChatRoom;
  }

  async deleteChatRoom(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }
}
