import API from "../api";
import { IClassroom, IClassroomExtended } from "crm_core";

export class ClassroomService extends API {
  static NAME = "ClassroomService";

  constructor() {
    super("/classroom");
  }

  async getClassrooms(
    extended = false,
    assigned = false,
  ): Promise<(IClassroom | IClassroomExtended)[]> {
    let params: any = {};
    if (extended) {
      params.extended = "true";
    }
    if (assigned) {
      params.assigned = "true";
    }
    const res = await this._get({ params });
    return res.data as IClassroom[];
  }

  async getClassroom(id: string): Promise<IClassroomExtended> {
    const res = await this._get({ url: `/${id}` });
    return res.data as IClassroomExtended;
  }

  async createClassroom(classroom: IClassroom): Promise<IClassroom> {
    const res = await this._post({ body: classroom });
    return res.data as IClassroom;
  }

  async updateClassroom(classroom: Partial<IClassroom>): Promise<IClassroom> {
    const res = await this._put({ url: `/${classroom._id}`, body: classroom });
    return res.data as IClassroom;
  }

  async deleteClassroom(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }

  async updateStudents(id: string, students: string[]): Promise<IClassroom> {
    const res = await this._put({
      url: `/${id}/students`,
      body: { students },
    });
    return res.data as IClassroom;
  }

  async updateTeachers(id: string, teachers: string[]): Promise<IClassroom> {
    const res = await this._put({
      url: `/${id}/teachers`,
      body: { teachers },
    });
    return res.data as IClassroom;
  }
}
