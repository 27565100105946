import { DocumentDuplicateIcon, PlusIcon } from "@heroicons/react/20/solid";
import { IFeeStructure } from "crm_core";
import { useEffect, useState } from "react";
import Aux from "src/components/aux";
import TrackedButton from "src/components/buttons/tracked_button";
import CustomCard from "src/components/custom_card";
import { ModalTypes, useModal } from "src/context/modal_context";
import { FeeStructureService } from "src/services/institute/fees/structure";
import { AddUpdateFeeStructureProps } from "./add_update";
import CustomDataTable, {
  CustomTableColumn,
} from "src/components/custom_data_table";
import ClickableCell from "src/components/widgets/clickable_cell";
import { Col, Row } from "react-bootstrap";
import { FilterKeys } from "src/utils/constants";
import Filters from "../../filters";
import { useTable } from "src/context/table_context";
import { PaginatedGetter } from "src/services/paginated_getter";
import ConfirmableButton from "src/components/buttons/confirmable_button";

interface FeeStructuresProps {
  tableOnly?: boolean;
}

export default function FeeStructures({ tableOnly }: FeeStructuresProps) {
  const FILTER_KEY = FilterKeys.FEE_STRUCTURES;
  const service = new FeeStructureService();
  const paginatedGetter = new PaginatedGetter<
    FeeStructureService,
    IFeeStructure
  >(FeeStructureService);
  const [data, setData] = useState<IFeeStructure[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [cloning, setCloning] = useState<boolean>(false);
  const {
    getPaginationPreferences,
    getSortPreferences,
    getFilterPreferences,
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    updateTotalRows,
  } = useTable();
  const {
    setModalType,
    setModalSize,
    setModalHeading,
    setComponentProps,
    setShowModal,
  } = useModal();

  const fetchData = async () => {
    let config = {
      pagination: getPaginationPreferences(FILTER_KEY),
      sort: getSortPreferences(FILTER_KEY, "last_updated", "desc"),
      filters: getFilterPreferences(FILTER_KEY),
    };
    setLoading(true);
    const data = await paginatedGetter.get(config, { extended: true });
    setData(data.results);
    updateTotalRows(FILTER_KEY, data.totalCount);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [paginationPreferences, sortPreferences, filterPreferences]);

  const handleCreateFeeStructure = () => {
    setModalType(ModalTypes.ADD_FEE_STRUCTURE);
    setModalSize("xl");
    setModalHeading("Add New Fee Structure");
    let props: AddUpdateFeeStructureProps = {
      onComplete: () => {
        fetchData();
        setShowModal(false);
      },
    };
    setComponentProps(props);
    setShowModal(true);
  };

  const columns: CustomTableColumn<IFeeStructure>[] = [
    {
      name: "Name",
      cell: (row) => (
        <ClickableCell href={`/c/fee-structure/${row._id}`} className="">
          {row.name}
        </ClickableCell>
      ),
      width: "200px",
    },
    {
      name: "Description",
      cell: (row) => <span className="">{row.description}</span>,
      width: "200px",
    },
    {
      name: "Components",
      cell: (row) => (
        <div className="py-2 w-full">
          {row.particulars.map((p, idx) => (
            <Row
              key={`comp-${idx}`}
              className="justify-content-between py-1 m-0 w-full align-items-center"
            >
              <Col className="col-8 pl-0">
                <div className="font-semibold">{p.name}</div>
              </Col>
              <Col className="col-4 pr-0">{p.amount}</Col>
            </Row>
          ))}
        </div>
      ),
      width: "240px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <Row>
          <Col className="col-auto">
            <ConfirmableButton
              dialogTitle="Clone Fee Structure"
              dialogContent="Are you sure you want to clone this fee structure?"
              loading={cloning}
              icon={<DocumentDuplicateIcon title="Clone" className="h-4 w-4" />}
              onClick={async () => {
                setCloning(true);
                await service.clone(row._id as string);
                setCloning(false);
                fetchData();
              }}
              rounded
              color="accent"
              isSlim
              className="p-1"
            />
          </Col>
        </Row>
      ),
      width: "100px",
    },
  ];

  const getTableView = () => {
    return (
      <Aux>
        <Filters filterKey={FILTER_KEY} />
        <CustomDataTable
          tableKey={FILTER_KEY}
          columns={columns}
          data={data}
          progressPending={loading}
        />
      </Aux>
    );
  };

  const addButton = (
    <TrackedButton
      icon={<PlusIcon className="h-3 w-3 mr-1" />}
      label={<span className="">Create Fee Structure</span>}
      onClick={() => handleCreateFeeStructure()}
      color="accent"
      isSlim
      className="px-2 py-1 text-sm"
    />
  );

  if (tableOnly)
    return (
      <Aux>
        <div className="ml-3 mb-2">{addButton}</div>
        {getTableView()}
      </Aux>
    );

  return (
    <CustomCard title="Fee Structures" headerRight={<Aux>{addButton}</Aux>}>
      {getTableView()}
    </CustomCard>
  );
}
