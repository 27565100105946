import { Route, PathRouteProps } from "react-router-dom";
import {
  PencilSquareIcon,
  BuildingLibraryIcon,
  ChartBarSquareIcon,
  AcademicCapIcon,
  UserGroupIcon,
  BuildingOfficeIcon,
  HandRaisedIcon,
  CogIcon,
  CalendarIcon,
  CurrencyRupeeIcon,
  ChatBubbleOvalLeftIcon,
} from "@heroicons/react/20/solid";
import { Permissions, UserRole } from "crm_core";
import { lazy } from "react";
import Dashboard from "./features/admin_features/dashboard";
import InstituteStructure from "./features/institute_structure";
import StudentsList from "./features/people/students/list";
import StaffMembersList from "./features/people/staff_members/list";
import ClassroomsList from "./features/institute_structure/classroom/list";
import TestsList from "./features/tests/list";
import StaffMemberStudentsList from "./features/assigned_students/list";
import StaffMemberClassroomList from "./features/assigned_classrooms/list";
import StudentClassroomList from "./features/student_features/classrooms/list";
import StudentDashboard from "./features/student_features/dashboard";
import StaffAttendance from "./features/staff_attendance";
import Roles from "./features/roles/list";
import InstituteCalendar from "./features/institute_structure/calendar";
import Folder from "./features/content_library/view";
import FeeStructure from "./features/fees/structure/view";
import FeeManagement from "./features/fees";
import StudentInstitute from "./features/student_features/institute";
import ChatRooms from "./features/chatrooms";
import AddUpdateHomework from "./features/homework/add_update";
import SuperAdminDashboard from "./features/superadmin_features/dashboard";
import OrganizationList from "./features/superadmin_features/institutes";
import CourseList from "./features/superadmin_features/courses/list";
import AddUpdateStudent from "./features/people/students/add_update";

const ViewHomework = lazy(() => import("./features/homework/view"));
const Home = lazy(() => import("./features/landing_page"));
const Plans = lazy(() => import("./features/plans"));
const OrganizationSignup = lazy(() => import("./features/auth/signup"));
const Signup = lazy(() => import("./features/auth/signup/user"));
const Login = lazy(() => import("./features/auth/login"));
const ForgotPassword = lazy(() => import("./features/auth/forgot_password"));
const ResetPassword = lazy(
  () => import("./features/auth/forgot_password/reset_password")
);
const Test = lazy(() => import("./features/tests/editor"));
const TestSubmitter = lazy(
  () => import("./features/student_features/tests/submitter")
);
const TestViewer = lazy(() => import("./features/tests/viewer"));
const StudentTestViewer = lazy(
  () => import("./features/student_features/tests/viewer")
);
const OrganizationProfile = lazy(
  () => import("./features/profile/organization")
);
const UserProfile = lazy(() => import("./features/profile/user"));
const Batch = lazy(() => import("./features/institute_structure/batch/batch"));
const Department = lazy(
  () => import("./features/institute_structure/department/department")
);
const Student = lazy(() => import("./features/people/students/student"));
const StaffMember = lazy(
  () => import("./features/people/staff_members/staff_member")
);
const TestPublisher = lazy(() => import("./features/tests/publisher"));
const BatchsList = lazy(
  () => import("./features/institute_structure/batch/list")
);
const DepartmentsList = lazy(
  () => import("./features/institute_structure/department/list")
);
const Classroom = lazy(
  () => import("./features/institute_structure/classroom/classroom")
);
const RoleSelector = lazy(() => import("./features/auth/role_selector"));
const StaffMemberClassroomView = lazy(
  () => import("./features/assigned_classrooms/view")
);
const StaffMemberStudentView = lazy(
  () => import("./features/assigned_students/view")
);
const StartTest = lazy(
  () => import("./features/student_features/tests/submitter/start_test")
);
const StudentClassroomView = lazy(
  () => import("./features/student_features/classrooms/view")
);
const Evaluator = lazy(() => import("./features/tests/viewer/evaluator"));
const CourseView = lazy(
  () => import("./features/superadmin_features/courses/view")
);
const SubjectView = lazy(
  () => import("./features/superadmin_features/subjects/view")
);
const TopicView = lazy(
  () => import("./features/superadmin_features/topics/view")
);
const QuestionView = lazy(
  () => import("./features/superadmin_features/questions/view")
);
export interface IRouteData extends PathRouteProps {
  layout?: string;
  icon?: JSX.Element;
  collapse?: boolean;
  items?: IRouteData[];
  category?: string;
  title?: string;
  supportedRoles?: UserRole[];
  requiredPermissionGroups?: Permissions[][];
}

const iconClass = "h-7 w-7 mx-auto";

export const routesData: IRouteData[] = [
  {
    path: "/dashboard",
    layout: "c",
    icon: <ChartBarSquareIcon className={iconClass} />,
    element: <SuperAdminDashboard />,
    title: "Dashboard",
    supportedRoles: [UserRole.SuperAdmin],
  },
  {
    path: "/institutes",
    layout: "c",
    icon: <BuildingLibraryIcon className={iconClass} />,
    element: <OrganizationList />,
    title: "Institutes",
    supportedRoles: [UserRole.SuperAdmin],
  },
  {
    path: "/courses",
    layout: "c",
    icon: <BuildingOfficeIcon className={iconClass} />,
    element: <CourseList />,
    title: "Courses",
    supportedRoles: [UserRole.SuperAdmin],
  },
  {
    path: "/courses/:id",
    layout: "c",
    element: <CourseView />,
    title: "Courses",
    supportedRoles: [UserRole.SuperAdmin],
  },
  {
    path: "/subjects/:id",
    layout: "c",
    element: <SubjectView />,
    title: "Subjects",
    supportedRoles: [UserRole.SuperAdmin],
  },
  {
    path: "/topics/:id",
    layout: "c",
    element: <TopicView />,
    title: "Topics",
    supportedRoles: [UserRole.SuperAdmin],
  },
  {
    path: "/questions/:id",
    layout: "c",
    element: <QuestionView />,
    title: "Questions",
    supportedRoles: [UserRole.SuperAdmin],
  },
  {
    path: "/dashboard",
    layout: "c",
    icon: <ChartBarSquareIcon className={iconClass} />,
    element: <Dashboard />,
    title: "Dashboard",
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
  },
  {
    path: "/dashboard",
    layout: "c",
    icon: <ChartBarSquareIcon className={iconClass} />,
    element: <StudentDashboard />,
    title: "Dashboard",
    supportedRoles: [UserRole.Student],
  },
  {
    path: "/institute",
    layout: "c",
    icon: <BuildingLibraryIcon className={iconClass} />,
    element: <InstituteStructure />,
    title: "Institute",
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
    requiredPermissionGroups: [
      [Permissions.VIEW_BATCHES],
      [Permissions.VIEW_CLASSROOMS],
      [Permissions.VIEW_DEPARTMENTS],
    ],
  },
  {
    path: "/login",
    layout: "auth",
    element: <Login location={{ pathname: "/" }} />,
  },
  {
    path: "/signup",
    layout: "auth",
    element: <Signup />,
  },
  {
    path: "/organization-signup",
    layout: "auth",
    element: <OrganizationSignup />,
  },
  {
    path: "/forgot-password",
    layout: "auth",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password/:token",
    layout: "auth",
    element: <ResetPassword />,
  },
  {
    path: "/plans",
    layout: "home",
    element: <Plans />,
  },
  {
    path: "",
    element: <Home />,
    layout: "home",
  },
  {
    path: "/assigned-classrooms",
    layout: "c",
    element: <StaffMemberClassroomList />,
    title: "Classrooms",
    icon: <BuildingOfficeIcon className={iconClass} />,
    supportedRoles: [UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_ASSIGNED_CLASSROOMS]],
  },
  {
    path: "/classrooms/:id/view/:tab?",
    layout: "c",
    element: <StaffMemberClassroomView />,
    title: "Classroom",
    supportedRoles: [UserRole.StaffMember, UserRole.OrganizationAdmin],
    requiredPermissionGroups: [
      [Permissions.VIEW_ASSIGNED_CLASSROOMS],
      [Permissions.VIEW_CLASSROOMS],
    ],
  },
  {
    path: "/classrooms",
    layout: "c",
    element: <StudentClassroomList />,
    title: "Classrooms",
    icon: <BuildingOfficeIcon className={iconClass} />,
    supportedRoles: [UserRole.Student],
  },
  {
    path: "/classrooms/:id/:tab?",
    layout: "c",
    element: <StudentClassroomView />,
    title: "Classroom",
    supportedRoles: [UserRole.Student],
  },
  {
    path: "/user/profile",
    element: <UserProfile />,
    layout: "c",
    supportedRoles: [
      UserRole.SuperAdmin,
      UserRole.OrganizationAdmin,
      UserRole.StaffMember,
      UserRole.Student,
    ],
  },
  {
    path: "/organization/profile",
    element: <OrganizationProfile />,
    layout: "c",
    supportedRoles: [UserRole.OrganizationAdmin],
  },
  {
    path: "/tests",
    layout: "c",
    icon: <PencilSquareIcon className={iconClass} />,
    element: <TestsList />,
    title: "Tests",
    supportedRoles: [UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_TESTS]],
  },
  // {
  //   path: "/tests",
  //   layout: "c",
  //   icon: <PencilSquareIcon className={iconClass} />,
  //   element: <StudentTestsList />,
  //   title: "Tests",
  //   supportedRoles: [UserRole.Student],
  // },
  {
    path: "/:id/edit",
    layout: "test",
    element: <Test />,
    title: "Edit Test",
    supportedRoles: [UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_TESTS]],
  },
  {
    path: "/tests/:id/view/:tab?",
    layout: "c",
    element: <TestViewer />,
    title: "View Test",
    supportedRoles: [UserRole.StaffMember, UserRole.OrganizationAdmin],
    requiredPermissionGroups: [[Permissions.VIEW_TESTS]],
  },
  {
    path: "/submissions/:id/view/:tab?",
    layout: "c",
    element: <StudentTestViewer />,
    title: "View Test",
    supportedRoles: [UserRole.Student],
  },
  {
    path: "/:id/preview",
    layout: "test",
    element: <TestSubmitter preview />,
    title: "Test Preview",
    supportedRoles: [UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_TESTS]],
  },
  {
    path: "/:id/publish",
    layout: "test",
    element: <TestPublisher />,
    title: "Test Preview",
    supportedRoles: [UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_TESTS]],
  },
  {
    path: "/:id/submit",
    layout: "test",
    element: <TestSubmitter />,
    title: "Submit Test",
    supportedRoles: [UserRole.Student],
  },
  {
    path: "/:id/start",
    layout: "test",
    element: <StartTest />,
    title: "Submit Test",
    supportedRoles: [UserRole.Student],
  },
  {
    path: "/batches",
    layout: "c",
    element: <BatchsList />,
    title: "Batch",
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_BATCHES]],
  },
  {
    path: "/batches/:id/edit",
    layout: "c",
    element: <Batch />,
    title: "Batch",
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_BATCHES]],
  },
  {
    path: "/departments",
    layout: "c",
    element: <DepartmentsList />,
    title: "Department",
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_DEPARTMENTS]],
  },
  {
    path: "/departments/:id/edit",
    layout: "c",
    element: <Department />,
    title: "Department",
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_DEPARTMENTS]],
  },
  {
    path: "/classrooms",
    layout: "c",
    element: <ClassroomsList />,
    title: "Classroom",
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_CLASSROOMS]],
  },
  {
    path: "/classrooms/:id/edit",
    layout: "c",
    element: <Classroom />,
    title: "Classroom",
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_CLASSROOMS]],
  },
  {
    path: "/assigned-students",
    layout: "c",
    icon: <AcademicCapIcon className={iconClass} />,
    element: <StaffMemberStudentsList />,
    title: "Students",
    supportedRoles: [UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_ASSIGNED_STUDENTS]],
  },
  {
    path: "/students/:id/view",
    layout: "c",
    element: <StaffMemberStudentView />,
    title: "View Student",
    supportedRoles: [UserRole.StaffMember, UserRole.OrganizationAdmin],
    requiredPermissionGroups: [
      [Permissions.VIEW_ASSIGNED_STUDENTS],
      [Permissions.VIEW_STUDENTS],
    ],
  },
  {
    path: "/students/add",
    layout: "c",
    element: <AddUpdateStudent />,
    title: "View Student",
    supportedRoles: [UserRole.StaffMember, UserRole.OrganizationAdmin],
    requiredPermissionGroups: [[Permissions.CREATE_STUDENT]],
  },
  {
    path: "/students",
    layout: "c",
    icon: <AcademicCapIcon className={iconClass} />,
    element: <StudentsList />,
    title: "All Students",
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_STUDENTS]],
  },
  {
    path: "/students/:id/edit",
    layout: "c",
    element: <Student />,
    title: "Student",
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_STUDENTS]],
  },
  {
    path: "/staff",
    layout: "c",
    icon: <UserGroupIcon className={iconClass} />,
    element: <StaffMembersList />,
    title: "Staff",
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_STAFF]],
  },
  {
    path: "/staff/:id/edit",
    layout: "c",
    element: <StaffMember />,
    title: "StaffMember",
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
  },
  {
    path: "/role-selector",
    layout: "auth",
    element: <RoleSelector />,
    title: "Role Selector",
  },
  {
    path: "/tests/evaluate/:id/:questionId?",
    layout: "c",
    element: <Evaluator />,
    title: "Test Evaluator",
    supportedRoles: [UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_TESTS]],
  },
  {
    path: "/attendance/:tab?",
    layout: "c",
    icon: <HandRaisedIcon className={iconClass} />,
    element: <StaffAttendance />,
    title: "Attendance",
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
    requiredPermissionGroups: [
      [
        Permissions.CREATE_SHIFTS,
        Permissions.VIEW_STAFF_ATTENDANCE,
        Permissions.MARK_STAFF_ATTENDANCE,
      ],
    ],
  },
  {
    path: "/roles",
    layout: "c",
    icon: <CogIcon className={iconClass} />,
    element: <Roles />,
    title: "Roles",
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
    requiredPermissionGroups: [
      [Permissions.CREATE_ROLE],
      [Permissions.UPDATE_ROLE],
      [Permissions.ARCHIVE_ROLE],
    ],
  },
  {
    path: "/calendar",
    layout: "c",
    icon: <CalendarIcon className={iconClass} />,
    element: <InstituteCalendar />,
    title: "Calendar",
    supportedRoles: [UserRole.OrganizationAdmin],
    requiredPermissionGroups: [
      [Permissions.CREATE_ROLE],
      [Permissions.UPDATE_ROLE],
      [Permissions.ARCHIVE_ROLE],
    ],
  },
  {
    path: "/folders/:id",
    layout: "c",
    element: <Folder />,
    title: "Folders",
    supportedRoles: [
      UserRole.OrganizationAdmin,
      UserRole.StaffMember,
      UserRole.Student,
    ],
    requiredPermissionGroups: [[Permissions.VIEW_ASSIGNED_CLASSROOMS]],
  },
  {
    path: "/fee-management/:tab?",
    layout: "c",
    icon: <CurrencyRupeeIcon className={iconClass} />,
    element: <FeeManagement />,
    title: "Fees",
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
    requiredPermissionGroups: [
      [Permissions.VIEW_FEE_STRUCTURES],
      [Permissions.VIEW_FEES],
      [Permissions.COLLECT_FEES],
    ],
  },
  {
    path: "/fee-structure/:id",
    layout: "c",
    element: <FeeStructure />,
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
    requiredPermissionGroups: [[Permissions.VIEW_FEE_STRUCTURES]],
  },
  {
    path: "/institute/:tab?",
    layout: "c",
    title: "Institute",
    icon: <BuildingLibraryIcon className={iconClass} />,
    element: <StudentInstitute />,
    supportedRoles: [UserRole.Student],
  },
  {
    path: "/chat/:id?",
    layout: "c",
    title: "Chat",
    icon: <ChatBubbleOvalLeftIcon className={iconClass} />,
    element: <ChatRooms />,
    supportedRoles: [
      UserRole.OrganizationAdmin,
      UserRole.StaffMember,
      UserRole.Student,
    ],
    requiredPermissionGroups: [[Permissions.VIEW_CHAT_GROUPS]],
  },
  {
    path: "/homeworks/:id",
    layout: "c",
    title: "Homework",
    element: <ViewHomework />,
    supportedRoles: [
      UserRole.OrganizationAdmin,
      UserRole.StaffMember,
      UserRole.Student,
    ],
  },
  {
    path: "/homeworks/add-update/:classroom/:id?",
    layout: "c",
    title: "Homework",
    element: <AddUpdateHomework />,
    supportedRoles: [UserRole.OrganizationAdmin, UserRole.StaffMember],
  },
];

export function getRoutes(routes: IRouteData[]): any {
  return routes.map((prop, key) => {
    if (prop.layout) {
      return <Route path={`/${prop.path}`} element={prop.element} key={key} />;
    }
    if (prop.collapse || prop.category) {
      return getRoutes(prop.items as any as IRouteData[]);
    } else {
      return null;
    }
  });
}
