import { useEffect, useState } from "react";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useAuth } from "src/context/auth_context";
import { useNotification } from "src/context/notification_context";
import { FolderService } from "src/services/institute/content/folder";
import { FormFieldType } from "src/utils/constants";
import { catchRequest, validateFields } from "src/utils/functions";
import { IClassroom, IFolder, Permissions } from "crm_core";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import ArchiveButton from "src/components/widgets/archive_button";
import SaveButton from "src/components/widgets/save_button";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import { ClassroomService } from "src/services/institute/classroom";

export interface AddUpdateFolderProps {
  onComplete?: () => void;
  id?: string;
  data?: IFolder;
  selectedClassrooms?: string[];
}

export default function AddUpdateFolder({
  onComplete,
  id,
  data,
  selectedClassrooms,
}: AddUpdateFolderProps) {
  const service = new FolderService();
  const [classrooms, setClassrooms] = useState<IClassroom[]>([]);
  const { selectedOrganization } = useAuth();
  const [folder, setFolder] = useState<IFolder>({
    classrooms: selectedClassrooms || [],
  } as any);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();

  const fetchClassrooms = async () => {
    setLoading(true);
    const res = await new ClassroomService().getClassrooms(undefined, true);
    setClassrooms(res);
    setLoading(false);
  };

  useEffect(() => {
    const fetchFolder = async (id: string) => {
      setLoading(true);
      const currentFolder = await service.getFolder(id);
      setFolder(currentFolder);
      setLoading(false);
    };
    fetchClassrooms();
    if (data) {
      setFolder(data);
    } else if (id) {
      fetchFolder(id);
    }
  }, [id]);

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(getFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);
    let update: any = {
      name: folder?.name,
      classrooms: folder?.classrooms,
    };

    await catchRequest(async () => {
      if (id) {
        await service.updateFolder({
          _id: id,
          ...update,
        });
        pushNotification({
          title: "Success",
          message: "Folder updated successfully",
          type: "success",
        });
      } else {
        await service.createFolder({
          ...update,
          organization: selectedOrganization?._id as any,
        });
      }
    }, pushNotification)();
    setLoading(false);
    onComplete?.();
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.TEXT,
          value: folder?.name,
          onChange: (v) => setFolder({ ...folder, name: v }),
          extras: {
            placeholder: "Folder Name",
            label: "Folder Name",
          },
          required: true,
          updatable: true,
        },
      ],
      [
        {
          type: FormFieldType.SELECT,
          value: folder.classrooms,
          onChange: (v) => setFolder({ ...folder, classrooms: v }),
          extras: {
            label: "Classrooms",
            options: classrooms.map((c) => ({ label: c.name, value: c._id })),
            multi: true,
          },
          required: true,
          updatable: true,
        },
      ],
    ];

    if (id) {
      return allFields
        .map((fields) => fields.filter((f) => f.updatable))
        .filter((f) => f.length > 0);
    }
    return allFields;
  };

  const handleDeleteFolder = async () => {
    await catchRequest(async () => {
      await service.deleteFolder(id as string);
      pushNotification({
        title: "Success",
        message: "Folder archived successfully",
        type: "success",
      });
      navigate("/c/folders");
    }, pushNotification)();
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      <Row className="justify-content-end mt-4 align-items-center">
        <Col hidden={!id} md="auto" sm="auto" xs="auto" className="p-0">
          <PermissionRestrictedComponent
            permissions={[Permissions.ARCHIVE_FOLDER]}
          >
            <ArchiveButton
              dialogTitle="Archive Folder?"
              dialogContent="Are you sure you want to archive this folder?"
              label="Archive Folder"
              onClick={() => handleDeleteFolder()}
            />
          </PermissionRestrictedComponent>
        </Col>
        <Col md="auto" sm="auto" xs="auto" className="">
          <PermissionRestrictedComponent
            permissions={[Permissions.UPDATE_FOLDER, Permissions.CREATE_FOLDER]}
          >
            <SaveButton
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            />
          </PermissionRestrictedComponent>
        </Col>
      </Row>
    </div>
  );
}
