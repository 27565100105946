import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../buttons/button";
import { joinClasses } from "src/utils/functions";
import { EyeIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import LoadingButton from "../buttons/loading_button";
import { FileService } from "src/services/file";

interface PdfUploadProps {
  placeholder?: string;
  onUpload: (location: string) => void;
  className?: string;
  disabled?: boolean;
  viewer?: boolean;
}

export default function PdfUpload({
  placeholder,
  onUpload,
  className,
  disabled,
  viewer,
}: PdfUploadProps) {
  const service = new FileService();
  const [uploading, setUploading] = useState(false);
  const [location, setLocation] = useState(placeholder);
  const [pdf, setPdf] = useState<File>();

  useEffect(() => {
    setLocation(placeholder);
  }, [placeholder]);

  const hiddenFileInput = useRef<any>(null);

  const handleClick = (event: ChangeEvent) => hiddenFileInput.current.click();

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files && event.target.files[0];
    if (fileUploaded) {
      if (fileUploaded.size > 10000000) {
        alert("File size must be less than 10MB");
        return;
      }
      setPdf(fileUploaded);
      setUploading(true);
      const location = await service.uploadPdf(fileUploaded);
      setUploading(false);
      setLocation(location);
      onUpload(location);
    }
  };

  const handleViewPdf = async () => {
    if (location) {
      const signed = await service.getSignedUrl(location);
      window.open(signed, "_blank");
    }
  };

  return (
    <Row className="align-items-center mt-2">
      <Col hidden={!location} className="col-auto">
        <Button
          label="View PDF"
          color="transparent"
          className="px-2 py-1 text-sm"
          icon={<EyeIcon className="h-4 w-4 mr-2" />}
          onClick={handleViewPdf}
          rounded
          isSlim
        />
      </Col>
      <Col hidden={!viewer || !!location} className="col-auto">
        <span className="text-sm text-gray-500 ml-1 text-sm">
          No PDF Uploaded
        </span>
      </Col>

      <Col hidden={viewer} className="col-auto">
        <input
          type="file"
          accept="application/pdf"
          style={{ display: "none" }}
          onChange={handleChange}
          ref={hiddenFileInput}
          className={joinClasses(className)}
        />
        <LoadingButton
          loading={uploading}
          rounded
          label={"Upload PDF"}
          isSlim
          className="px-2 py-1 text-sm"
          color="transparent"
          onClick={handleClick}
          icon={<PencilSquareIcon className="h-4 w-4 mr-2" />}
          disabled={disabled || uploading}
        />
      </Col>
    </Row>
  );
}
