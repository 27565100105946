import { BulkImportDataType, QuestionExportConfig } from "crm_core";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import SaveButton from "src/components/widgets/save_button";
import { useNotification } from "src/context/notification_context";
import { FileService } from "src/services/file";
import { ImportRecordService } from "src/services/institute/content/import_record";
import { handleDataExport } from "src/utils/functions";

export interface BulkImportProps {
  type: BulkImportDataType;
  onComplete?: () => void;
  params?: any;
}

export default function BulkImport({
  type,
  onComplete,
  params,
}: BulkImportProps) {
  const fileService = new FileService();
  const importRecordService = new ImportRecordService();
  const [loading, setLoading] = useState<boolean>(false);
  const { pushNotification } = useNotification();
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: {
      "text/xlsx": [".xlsx"],
      "application/json": [".json"],
      "application/zip": [".zip"],
    },
  });

  const handleSubmit = async () => {
    if (acceptedFiles.length === 0) {
      pushNotification({
        title: "No file selected",
        message: "Please select a file to upload",
        type: "danger",
      });
      return;
    }
    setLoading(true);
    let fileToUpload = acceptedFiles[0];

    if (fileToUpload.type === "application/json") {
      const json = await fileToUpload.text();
      const jsonParsed = JSON.parse(json);
      await handleDataExport(QuestionExportConfig, jsonParsed);
      alert("Upload the new file");
      setLoading(false);
      return;
    }
    const fileKey = `bulk_uploads/${type}/${fileToUpload.name}-${Date.now()}`;
    const signedUrl = await fileService.getUploadSignedUrl(
      fileKey,
      fileToUpload.size
    );
    await fetch(signedUrl, {
      method: "PUT",
      body: fileToUpload as any,
    });
    await importRecordService.createImportRecord({
      key: fileKey,
      type,
      params,
    });
    setLoading(false);
    onComplete?.();
  };

  return (
    <div>
      <div
        {...getRootProps({ className: "dropzone" })}
        className="border-accent border-1 border-dashed h-40 bg-custom-grey rounded-md cursor-pointer"
      >
        <input {...getInputProps()} />
        <Row
          hidden={acceptedFiles.length > 0}
          className="align-items-center h-full"
        >
          <Col className="w-full text-center">
            <div className="text-sm text-gray-500">
              Drag and drop your file here or click to browse
            </div>
          </Col>
        </Row>
        {acceptedFiles.length > 0 && (
          <Row className="align-items-center mt-2 h-full">
            <Col className="w-full text-center">{acceptedFiles[0].name}</Col>
          </Row>
        )}
      </div>
      <Row className="mt-4">
        <Col className="col-auto"></Col>
        <Col className="col-auto ml-auto">
          <SaveButton
            disabled={loading || acceptedFiles.length === 0}
            loading={loading}
            label={"Submit"}
            onClick={handleSubmit}
          />
        </Col>
      </Row>
    </div>
  );
}
