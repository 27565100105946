import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Aux from "src/components/aux";
import CountCard from "../../../components/widgets/count_card";
import {
  BuildingOfficeIcon,
  FlagIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { IDashboard, Permissions, UserRole } from "crm_core";
import { DashboardService } from "src/services/dashboard";
import { useDataProvider } from "src/context/data_provider";
import { AcademicCapIcon, UserGroupIcon } from "@heroicons/react/20/solid";
import UserTimetable from "src/features/timetable/user_timetable";
import { isPermissible } from "src/utils/functions";
import { useAuth } from "src/context/auth_context";
import EntityAttendanceGraph from "../../staff_attendance/analytics/graph";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";

export default function Dashboard() {
  const [data, setData] = useState<IDashboard>();
  const { selectedSession } = useDataProvider();
  const { selectedAuthorization } = useAuth();

  useEffect(() => {
    document.title = "Teachbond - Dashboard";
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await new DashboardService().getDashboardData();
      setData(data);
    };
    fetchData();
  }, [selectedSession]);

  let className = "h-10 w-10";
  const countCards = [
    {
      count: data?.counts.department ?? 0,
      title: "Departments",
      icon: <BuildingOfficeIcon className={className} />,
      clickPath: "/c/departments",
      required_permissions: [Permissions.VIEW_DEPARTMENTS],
      adminByPass: true,
    },
    {
      count: data?.counts.batch ?? 0,
      title: "Batches",
      icon: <FlagIcon className={className} />,
      clickPath: "/c/batches",
      required_permissions: [Permissions.VIEW_BATCHES],
      adminByPass: true,
    },
    {
      count: data?.counts.classroom ?? 0,
      title: "Classrooms",
      icon: <MapPinIcon className={className} />,
      clickPath: "/c/classrooms",
      required_permissions: [Permissions.VIEW_CLASSROOMS],
      adminByPass: true,
    },
    {
      count: data?.counts.staffMember ?? 0,
      title: "Staff Members",
      icon: <UserGroupIcon className={className} />,
      clickPath: "/c/staff",
      required_permissions: [Permissions.VIEW_STAFF],
      adminByPass: true,
    },
    {
      count: data?.counts.student ?? 0,
      title: "Students",
      icon: <AcademicCapIcon className={className} />,
      clickPath: "/c/students",
      required_permissions: [Permissions.VIEW_STUDENTS],
      adminByPass: true,
    },
    {
      count: data?.counts.assigned_classroom ?? 0,
      title: "Your Classrooms",
      icon: <MapPinIcon className={className} />,
      clickPath: "/c/assigned-classrooms",
      required_permissions: [Permissions.VIEW_ASSIGNED_CLASSROOMS],
      adminByPass: false,
    },
    {
      count: data?.counts.assigned_student ?? 0,
      title: "Your Students",
      icon: <AcademicCapIcon className={className} />,
      clickPath: "/c/assigned-students",
      required_permissions: [Permissions.VIEW_ASSIGNED_STUDENTS],
      adminByPass: false,
    },
  ];

  return (
    <Aux>
      <div className="p-3">
        <Row className="">
          {countCards
            .filter((c) =>
              selectedAuthorization.role == UserRole.StaffMember
                ? isPermissible(...c.required_permissions)
                : c.adminByPass
            )
            .map((card, index) => (
              <Col className="mb-3" lg="3" md="4" sm="12" xs="12" key={index}>
                <CountCard {...card} />
              </Col>
            ))}
        </Row>
        <Row
          hidden={selectedAuthorization.role === UserRole.OrganizationAdmin}
          className="mt-2"
        >
          <Col md={6}>
            <UserTimetable />
          </Col>
        </Row>
        <PermissionRestrictedComponent
          permissions={[Permissions.VIEW_STAFF_ATTENDANCE]}
        >
          <div className="mt-4">
            <EntityAttendanceGraph
              cardProps={{ className: "px-0 py-0", slim: false }}
            />
          </div>
        </PermissionRestrictedComponent>
      </div>
    </Aux>
  );
}
