import { createContext, useContext, useEffect, useState } from "react";

export enum ThemeModes {
  LIGHT = "light",
  DARK = "dark",
}

const themeContext = createContext<ThemeContextProps>({} as any);

export function useTheme() {
  return useContext(themeContext);
}

export function ThemeProvider({ children }: any) {
  return (
    <themeContext.Provider value={useThemeProvider()}>
      {children}
    </themeContext.Provider>
  );
}

function useThemeProvider() {
  const [mode, setMode] = useState<ThemeModes>(ThemeModes.LIGHT);

  useEffect(() => {
    let saved = localStorage.getItem("preferredTheme");
    setMode(saved == ThemeModes.DARK ? ThemeModes.DARK : ThemeModes.LIGHT);
  }, []);

  useEffect(() => {
    localStorage.setItem("preferredTheme", mode);
  }, [mode]);

  const getThemeBasedValue = (ifLight: any, ifDark: any) => {
    return mode == ThemeModes.LIGHT ? ifLight : ifDark;
  };

  const flipMode = () => {
    return setMode(
      mode == ThemeModes.LIGHT ? ThemeModes.DARK : ThemeModes.LIGHT
    );
  };

  return {
    mode,
    setMode,
    flipMode,
    getThemeBasedValue,
    theme: {
      colors: {
        accentLight: getThemeBasedValue("#257383", "#1A1A1A"),
        button: getThemeBasedValue("pink", "black"),
        primary: getThemeBasedValue("#6ef0b8", "#6ef0b8"),
        secondary: getThemeBasedValue("#22253e", "#060806"),
        onSecondary: getThemeBasedValue("#ffffff", "#ffffff"),
        background: getThemeBasedValue("white", "black"),
        textPrimary: getThemeBasedValue("black", "white"),
        textSecondary: getThemeBasedValue("#b8b4b4", "#b8b4b4"),
      },
    },
  } as ThemeContextProps;
}

interface ThemeContextProps {
  mode: ThemeModes;
  setMode: React.Dispatch<React.SetStateAction<ThemeModes>>;
  flipMode: () => void;
  getThemeBasedValue: (ifLight: any, ifDark: any) => any;
  theme: {
    colors: {
      button: string;
      primary: string;
      secondary: string;
      onSecondary: string;
      background: string;
      textPrimary: string;
      textSecondary: string;
    };
  };
}
