import { IInvitation, IOrganization } from "../../../hierarchy";
import { Category, Disablity, Religion } from "../../../utils";
import {
  IExportConfig,
  IMedicalRecord,
} from "../../../utils/common_interfaces";
import { IFeeStructure } from "../../fees";
import { IBatch, IClassroom, IDepartment } from "../academics";
import { IParent } from "./parent";
import { IPerson } from "./person";

export enum SingleParent {
  Father = "Father",
  Mother = "Mother",
}

export interface ISessionWiseDetails {
  session: string;
  department?: string;
  batch?: string;
  roll_no?: number;
  is_last_session?: boolean;
  classrooms?: string[];
  applicable_fees?: string[] | IFeeStructure[];
}

export enum StudentExitReason {
  Graduated = "Graduated",
  Expelled = "Expelled",
  DroppedOut = "DroppedOut",
  Transferred = "Transferred",
  Other = "Other",
}

export interface IStudent extends IPerson {
  father?: IParent;
  mother?: IParent;
  guardian?: IParent;
  admission_date?: Date;
  enrollment_no?: string;
  roll_no?: number;
  religion?: Religion;
  category?: Category;
  disablity?: Disablity;
  nationality?: string;
  is_single_parent?: boolean;
  single_parent?: SingleParent;
  is_sponsored?: boolean;
  sponsor_name?: string;
  medical_records?: IMedicalRecord[];
  organization?: string | IOrganization;
  classrooms: string[] | IClassroom[];
  department?: string | IDepartment;
  batch?: string | IBatch;
  session_wise_details: ISessionWiseDetails[];
  is_exited?: boolean;
  exit_date?: Date;
  exit_reason?: StudentExitReason;
  applicable_fees: string[] | IFeeStructure[];
}

export interface IStudentExtended extends IStudent {
  invitation: IInvitation;
  _id: string;
  batch: IBatch;
  department: IDepartment;
}

export const StudentExportConfig: IExportConfig<IStudentExtended> = {
  fileName: "Students.xlsx",
  headers: [
    { label: "First Name", key: "first_name" },
    { label: "Middle Name", key: "middle_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "mobile" },
    { label: "Enrollment No", key: "enrollment_no" },
    { label: "Admission Date", key: "admission_date" },
    { label: "Department", key: "department.name" },
    { label: "Batch", key: "batch.name" },
    { label: "Roll No", key: "roll_no" },
    { label: "Date of Birth", key: "dob" },
    { label: "Gender", key: "gender" },
    { label: "Blood Group", key: "blood_group" },
    { label: "Current Address Line 1", key: "current_address.line1" },
    { label: "Current Address Line 2", key: "current_address.line2" },
    { label: "Current Address City", key: "current_address.city" },
    { label: "Current Address State", key: "current_address.state" },
    { label: "Current Address Country", key: "current_address.country" },
    { label: "Current Address Pincode", key: "current_address.pincode" },
    { label: "Permanent Address Line 1", key: "permanent_address.line1" },
    { label: "Permanent Address Line 2", key: "permanent_address.line2" },
    { label: "Permanent Address City", key: "permanent_address.city" },
    { label: "Permanent Address State", key: "permanent_address.state" },
    { label: "Permanent Address Country", key: "permanent_address.country" },
    { label: "Permanent Address Pincode", key: "permanent_address.pincode" },
    { label: "Father's Name", key: "father.name" },
    { label: "Father's Mobile", key: "father.mobile" },
    { label: "Father's Email", key: "father.email" },
    { label: "Father's Qualification", key: "father.qualification" },
    { label: "Father's Occupation", key: "father.occupation" },
    { label: "Father's Company Name", key: "father.company_name" },
    { label: "Father's Designation", key: "father.designation" },
    { label: "Father's Annual Income", key: "father.income" },
    { label: "Mother's Name", key: "mother.name" },
    { label: "Mother's Mobile", key: "mother.mobile" },
    { label: "Mother's Email", key: "mother.email" },
    { label: "Mother's Qualification", key: "mother.qualification" },
    { label: "Mother's Occupation", key: "mother.occupation" },
    { label: "Mother's Company Name", key: "mother.company_name" },
    { label: "Mother's Designation", key: "mother.designation" },
    { label: "Mother's Annual Income", key: "mother.income" },
    { label: "Guardian's Name", key: "guardian.name" },
    { label: "Guardian's Mobile", key: "guardian.mobile" },
    { label: "Guardian's Email", key: "guardian.email" },
    { label: "Guardian's Qualification", key: "guardian.qualification" },
    { label: "Guardian's Occupation", key: "guardian.occupation" },
    { label: "Guardian's Company Name", key: "guardian.company_name" },
    { label: "Guardian's Designation", key: "guardian.designation" },
    { label: "Guardian's Annual Income", key: "guardian.income" },
    { label: "Religion", key: "religion" },
    { label: "Category", key: "category" },
    { label: "Disablity", key: "disablity" },
    { label: "Nationality", key: "nationality" },
    { label: "Single Parent", key: "single_parent" },
    { label: "Sponsor Name", key: "sponsor_name" },
  ],
};
