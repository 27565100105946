import { useState } from "react";
import LoadingButton from "src/components/buttons/loading_button";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useNotification } from "src/context/notification_context";
import { ClientService } from "src/services/client";
import { ErrorReporterService } from "src/services/error_reporter";
import { FormFieldType, ClientStatuses } from "src/utils/constants";
import { validateFields } from "src/utils/functions";

interface AddClientProps {
  onAdd?: () => void;
  id?: string;
}

export default function AddClient({ onAdd, id }: AddClientProps) {
  const service = new ClientService();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState(ClientStatuses[0]);
  const [companyName, setCompanyName] = useState("");
  const { pushNotification } = useNotification();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(getFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);
    await service
      .createClient({
        first_name: firstName,
        last_name: lastName,
        email,
        status,
        phone,
        company_name: companyName,
      })
      .catch((e) => {
        new ErrorReporterService().sendErrorReport(
          "Add Client Failed",
          e,
          "/clients/add"
        );
      });
    setLoading(false);
    onAdd?.();
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    return [
      [
        {
          type: FormFieldType.TEXT,
          value: firstName,
          onChange: setFirstName,
          extras: {
            placeholder: "First Name",
            label: "First Name",
          },
          required: true,
        },
        {
          type: FormFieldType.TEXT,
          value: lastName,
          onChange: setLastName,
          extras: {
            placeholder: "Last Name",
            label: "Last Name",
          },
          required: true,
        },
      ],
      [
        {
          type: FormFieldType.TEXT,
          value: email,
          onChange: setEmail,
          extras: {
            placeholder: "Email",
            label: "Email",
          },
          required: true,
        },
        {
          type: FormFieldType.SELECT,
          value: status,
          onChange: setStatus,
          extras: {
            label: "Status",
            options: ClientStatuses.map((s) => ({ label: s, value: s })),
          },
        },
      ],
      [
        {
          type: FormFieldType.TEXT,
          value: phone,
          onChange: setPhone,
          extras: {
            placeholder: "Phone",
            label: "Phone",
          },
        },
        {
          type: FormFieldType.TEXT,
          value: companyName,
          onChange: setCompanyName,
          extras: {
            placeholder: "Company Name",
            label: "Company Name",
          },
          required: true,
        },
      ],
    ];
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      <div className="d-flex justify-content-end mt-4">
        <LoadingButton
          color="accent"
          loading={loading}
          disabled={loading}
          label="Save"
          contentClass="font-light"
          rounded
          className="px-4"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
