import moment from "moment";
import { useEffect, useState } from "react";
import LabelledField, { LabelledFieldProps } from "./labelled_field";
import DatePicker from "react-datepicker";

interface DatetimePickerProps extends LabelledFieldProps {
  value?: moment.Moment;
  onChange: (date: moment.Moment) => void;
}

export default function DatetimePicker(props: DatetimePickerProps) {
  const [date, setDate] = useState<moment.Moment>();

  useEffect(() => {
    if (props.value && !props.value.isSame(date)) {
      setDate(props.value.clone());
    }
  }, [props.value]);

  useEffect(() => {
    if (date && !date.isSame(props.value, "minute")) {
      props.onChange(date);
    }
  }, [date]);

  return (
    <LabelledField label={props.label} required={props.required}>
      <DatePicker
        className="w-full p-2 text-sm ring-0.5 ring-custom-grey bg-custom-grey rounded-md  outline-none focus:outline-none  focus:ring-1 focus:ring-dark-accent"
        selected={date?.toDate()}
        onChange={(date: Date | null) => setDate(moment(date))}
        dateFormat="dd MMM yyyy hh:mm a"
        showTimeInput
        showTimeSelect
      />
    </LabelledField>
  );
}
