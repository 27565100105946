import { CheckIcon } from "@heroicons/react/20/solid";
import LoadingButton, { LoadingButtonProps } from "../buttons/loading_button";

interface SaveButtonProps extends Partial<LoadingButtonProps> {
  loading: boolean;
}

export default function SaveButton(props: SaveButtonProps) {
  return (
    <LoadingButton
      color="accent"
      label="Save Changes"
      contentClass="font-light"
      isSlim
      icon={<CheckIcon className="h-4 w-4 mr-1" />}
      className="px-2 py-1 text-sm"
      {...props}
    />
  );
}
