import {
  FilterSpec,
  SupportedFilterAttributes,
  SupportedFilterOperators,
} from "crm_core";

export function getFilterSpec(
  attribute: SupportedFilterAttributes,
  operator: SupportedFilterOperators,
  value: any
): FilterSpec {
  return {
    attribute,
    operator,
    value,
  };
}
