import CustomDataTable, {
  CustomDataTableProps,
  CustomTableColumn,
} from "src/components/custom_data_table";
import { ModalTypes, useModal } from "src/context/modal_context";
import {
  IDepartment,
  SupportedFilterAttributes,
  SupportedFilterOperators,
  InvitationStatus,
  AuthEntityType,
  InvitationEntityType,
  UserRole,
  IStudentExtended,
  StudentExportConfig,
  BulkImportDataType,
  pluralize,
  Permissions,
} from "crm_core";
import { useEffect, useState } from "react";
import { StudentService } from "src/services/institute/student";
import {
  ArrowRightOnRectangleIcon,
  ArrowUpTrayIcon,
  InboxArrowDownIcon,
  PencilIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import TrackedButton from "src/components/buttons/tracked_button";
import LinkText from "src/components/text_fields/link_text";
import CustomCard, { CustomCardProps } from "src/components/custom_card";
import Aux from "src/components/aux";
import { PaginatedGetter } from "src/services/paginated_getter";
import { useTable } from "src/context/table_context";
import { useDataProvider } from "src/context/data_provider";
import { AddUpdateStudentProps } from "./add_update";
import { Col, Row } from "react-bootstrap";
import Filters from "src/features/filters";
import { FilterKeys } from "src/utils/constants";
import { getFilterSpec } from "src/features/filters/utils";
import {
  catchAsync,
  conditionalCol,
  handleBulkImport,
  handleDataExport,
} from "src/utils/functions";
import InvitationStatusBadge, {
  ActiveStatusBadge,
} from "src/components/widgets/badges";
import { InvitationService } from "src/services/institute/invite";
import ClickableCell from "src/components/widgets/clickable_cell";
import { AssignStudentProps } from "./assign";
import Button from "src/components/buttons/button";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import { ExitStudentsProps } from "./exit_students";
import { useNotification } from "src/context/notification_context";

interface StudentsListProps {
  department?: string;
  batch?: string;
  classroom?: string;
  tableOnly?: boolean;
  tableProps?: Partial<CustomDataTableProps<IStudentExtended>>;
  excludedIds?: string[];
  includedIds?: string[];
  cardProps?: Partial<CustomCardProps>;
  filterKey?: FilterKeys;
}

export default function StudentsList({
  department,
  batch,
  classroom,
  tableOnly = false,
  tableProps = {},
  cardProps = {},
  excludedIds,
  includedIds,
  filterKey,
}: StudentsListProps) {
  const paginatedGetter = new PaginatedGetter<StudentService, IStudentExtended>(
    StudentService
  );
  const {
    setModalType,
    setShowModal,
    setModalSize,
    setModalHeading,
    setComponentProps,
    setModalBodyProps,
  } = useModal();
  const FILTER_KEY = filterKey || FilterKeys.STUDENT;
  const [fetchedConfig, setFetchedConfig] = useState<string>("");
  const [students, setStudents] = useState<IStudentExtended[]>([]);
  const { pushNotification } = useNotification();
  const [selectedStudents, setSelectedStudents] = useState<IStudentExtended[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const {
    getPaginationPreferences,
    getSortPreferences,
    getFilterPreferences,
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    updateTotalRows,
    applyFilter,
  } = useTable();
  const { selectedSession } = useDataProvider();

  useEffect(() => {
    applyFilter(
      FILTER_KEY,
      getFilterSpec(
        SupportedFilterAttributes.DEPARTMENT,
        SupportedFilterOperators.EQUALS,
        department
      ),
      getFilterSpec(
        SupportedFilterAttributes.BATCH,
        SupportedFilterOperators.EQUALS,
        batch
      ),
      getFilterSpec(
        SupportedFilterAttributes.CLASSROOM,
        SupportedFilterOperators.EQUALS,
        classroom
      ),
      getFilterSpec(
        SupportedFilterAttributes.ID,
        SupportedFilterOperators.NOT_INCLUDES,
        excludedIds
      ),
      getFilterSpec(
        SupportedFilterAttributes.ID,
        SupportedFilterOperators.INCLUDES,
        includedIds
      )
    );
  }, [batch, excludedIds, classroom, includedIds]);

  const fetchData = async (force = false) => {
    let config = {
      pagination: getPaginationPreferences(FILTER_KEY),
      sort: getSortPreferences(FILTER_KEY),
      filters: getFilterPreferences(FILTER_KEY),
    };
    const derivedFilters = !!(
      department ||
      batch ||
      excludedIds ||
      classroom ||
      includedIds
    );
    if (derivedFilters && !(config.filters.length > 0)) {
      return;
    }
    if (JSON.stringify(config) === fetchedConfig && !force) {
      return;
    }
    setLoading(true);
    const data = await paginatedGetter.get(config, { extended: true });
    setStudents(data.results);
    updateTotalRows(FILTER_KEY, data.totalCount);
    setFetchedConfig(JSON.stringify(config));
    setLoading(false);
  };

  useEffect(() => {
    document.title = "Teachbond - Students";
  }, []);

  useEffect(() => {
    if (selectedSession) {
      fetchData();
    }
  }, [
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    selectedSession,
  ]);

  const onAddUpdate = () => {
    fetchData(true);
    setShowModal(false);
  };

  const handleCreateStudent = () => {
    setModalType(ModalTypes.STUDENT);
    setModalSize("xl");
    setModalHeading("Add New Student");
    let props: AddUpdateStudentProps = {
      onComplete: onAddUpdate,
    };
    setComponentProps(props);
    setModalBodyProps({ className: "px-0 pt-0" });
    setShowModal(true);
  };

  const handleExitStudents = () => {
    const eligibleStudents = selectedStudents.filter((s) => !s.is_exited);
    if (eligibleStudents.length == 0) {
      pushNotification({
        title: "No active students selected",
        message: "Please select at least one active student to exit",
        type: "warning",
      });
      return;
    }
    setModalType(ModalTypes.EXIT_STUDENTS);
    setModalSize("lg");
    setModalHeading(
      `Exit ${eligibleStudents.length} ${pluralize(
        eligibleStudents.length,
        "Student",
        "Students"
      )}`
    );
    setComponentProps({
      students: eligibleStudents,
      onComplete: async () => {
        await fetchData(true);
        setSelectedStudents([]);
        setShowModal(false);
      },
    } as ExitStudentsProps);
    setShowModal(true);
  };

  const handleResendInvite = async (id: string) => {
    setLoading(true);
    await catchAsync(
      async () => {
        await new InvitationService().createInvitation({
          auth_entity_type: AuthEntityType.Organization,
          entity_id: id,
          entity_type: InvitationEntityType.STUDENT,
          role: UserRole.Student,
          resend: true,
        });
      },
      "Invitation sent successfully",
      "Something went wrong"
    )();
    await fetchData();
    setLoading(false);
  };

  const handleAssignBatch = (student: IStudentExtended) => {
    setModalType(ModalTypes.ASSIGN_STUDENT);
    setModalSize("lg");
    setModalHeading("Assign Batch");
    let props: AssignStudentProps = {
      onComplete: onAddUpdate,
      student: student,
    };
    setComponentProps(props);
    setShowModal(true);
  };

  const columns: CustomTableColumn<IStudentExtended>[] = [
    {
      colId: -1,
      name: "Active",
      cell: ({ is_exited }) => <ActiveStatusBadge active={!is_exited} />,
      reorder: true,
      sortable: true,
      sortField: "invitation",
      width: "80px",
    },
    {
      colId: 0,
      name: "Student Name",
      cell: ({ first_name, _id, last_name }) => (
        <ClickableCell href={`/c/students/${_id}/view`}>
          {first_name} {last_name}
        </ClickableCell>
      ),
      reorder: true,
      sortable: true,
      sortField: "first_name,last_name",
      width: "300px",
    },
    ...conditionalCol(!batch && !classroom, {
      colId: 1,
      name: "Batch",
      cell: (row: IStudentExtended) => {
        if (!row.batch) {
          return (
            <LinkText onClick={() => handleAssignBatch(row)}>
              Assign Batch
            </LinkText>
          );
        }
        return (
          <ClickableCell href={`/c/batches/${row.batch?._id}/edit`}>
            {row.batch?.name}
          </ClickableCell>
        );
      },
      reorder: true,
      sortable: true,
      sortField: "name",
      width: "150px",
    }),
    ...conditionalCol(!department && !batch && !classroom, {
      colId: 2,
      name: "Department",
      cell: ({ department }: IStudentExtended) => {
        if (!department) {
          return <div className="text-muted">Not Assigned</div>;
        }
        return (
          <ClickableCell
            href={`/c/departments/${(department as IDepartment)?._id}/edit`}
          >
            {(department as IDepartment)?.name}
          </ClickableCell>
        );
      },
      reorder: true,
      sortable: true,
      sortField: "name",
      width: "220px",
    }),
    {
      name: "Classrooms",
      colId: 3,
      reorder: true,
      cell: ({ classrooms }) => (
        <Row className="align-items-center">
          <Col xs="auto" sm="auto" md="auto" lg="auto">
            <div className="text-muted">
              {classrooms?.length ?? 0}{" "}
              {pluralize(classrooms?.length ?? 0, "Classroom", "Classrooms")}
            </div>
          </Col>
        </Row>
      ),
      width: "200px",
    },
    {
      colId: 4,
      name: "Joining Status",
      cell: ({ _id, invitation }) => (
        <Row className="w-full align-items-center">
          <Col md="auto" sm="auto" xs="auto" className="m-0 pr-0">
            <InvitationStatusBadge status={invitation?.status} />
          </Col>
          <Col
            hidden={invitation?.status == InvitationStatus.ACCEPTED}
            md="auto"
            sm="auto"
            xs="auto"
            className="p-0 ml-auto mr-2"
          >
            <LinkText onClick={() => handleResendInvite(_id)}>
              {invitation ? "Resend Invitation" : "Send Invitation"}
            </LinkText>
          </Col>
        </Row>
      ),
      reorder: true,
      sortable: true,
      sortField: "invitation",
      width: "270px",
    },
    {
      colId: 5,
      reorder: true,
      name: "Actions",
      cell: (row) => (
        <Row>
          <Col className="col-auto">
            <Button
              label={"Edit"}
              icon={<PencilIcon className="h-4 w-4 mr-2" />}
              isSlim
              className="py-1 px-2 text-sm"
              color="accent"
              href={`/c/students/${row._id}/edit`}
            />
          </Col>
        </Row>
      ),
    },
  ];

  const getTableView = () => {
    return (
      <Aux>
        <Filters
          filterKey={FILTER_KEY}
          visibleFilters={
            department || batch || classroom || excludedIds || includedIds
              ? [SupportedFilterAttributes.SEARCH]
              : undefined
          }
        />
        <CustomDataTable
          tableKey={FILTER_KEY}
          columns={columns}
          data={students}
          progressPending={loading}
          selectableRows
          onSelectedRowsChange={(s) => {
            const alreadySelected = selectedStudents.map((r) => r._id);
            const newlySelected = s.selectedRows.map((r) => r._id);
            const added = newlySelected.filter(
              (r) => !alreadySelected.includes(r)
            );
            const removed = alreadySelected.filter(
              (r) => !newlySelected.includes(r)
            );
            if (added.length > 0 || removed.length > 0) {
              setSelectedStudents(s.selectedRows);
            }
          }}
          selectableRowSelected={(row) =>
            selectedStudents.map((s) => s._id).includes(row._id)
          }
          {...tableProps}
        />
      </Aux>
    );
  };

  if (tableOnly) {
    return getTableView();
  }

  return (
    <CustomCard
      title="All Students"
      actionButtonProps={{
        actions: [
          {
            label: "Import Data",
            onClick: handleBulkImport(BulkImportDataType.STUDENT),
            icon: ArrowUpTrayIcon,
          },
          {
            label: "Export CSV",
            onClick: () => handleDataExport(StudentExportConfig, students),
            icon: InboxArrowDownIcon,
          },
          {
            label: "Exit Students",
            onClick: handleExitStudents,
            icon: ArrowRightOnRectangleIcon,
          },
        ],
      }}
      headerRight={
        <Aux>
          <PermissionRestrictedComponent
            permissions={[Permissions.CREATE_STUDENT]}
          >
            <TrackedButton
              icon={<PlusIcon className="h-3 w-3 mr-1" />}
              label={<span className="">Create New Student</span>}
              href="/c/students/add"
              // onClick={() => handleCreateStudent()}
              color="accent"
              isSlim
              className="px-2 py-1 text-sm"
            />
          </PermissionRestrictedComponent>
        </Aux>
      }
      {...cardProps}
    >
      {getTableView()}
    </CustomCard>
  );
}
