import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Aux from "src/components/aux";
import {
  BuildingOfficeIcon,
  FlagIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { Permissions, UserRole } from "crm_core";
import { DashboardService } from "src/services/dashboard";
import { AcademicCapIcon, UserGroupIcon } from "@heroicons/react/20/solid";
import { isPermissible } from "src/utils/functions";
import { useAuth } from "src/context/auth_context";
import CountCard from "src/components/widgets/count_card";

export default function SuperAdminDashboard() {
  const [data, setData] = useState<any>();
  const { selectedAuthorization } = useAuth();

  useEffect(() => {
    document.title = "Teachbond - Dashboard";
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await new DashboardService().getDashboardData();
      setData(data);
    };
    fetchData();
  }, []);

  let className = "h-10 w-10";
  const countCards = [
    {
      count: data?.counts.organization ?? 0,
      title: "Institutes",
      icon: <BuildingOfficeIcon className={className} />,
      clickPath: "/c/institutes",
      required_permissions: [Permissions.VIEW_DEPARTMENTS],
    },
    {
      count: data?.counts.session ?? 0,
      title: "Sessions",
      icon: <FlagIcon className={className} />,
      required_permissions: [Permissions.VIEW_BATCHES],
    },
    {
      count: data?.counts.classroom ?? 0,
      title: "Classrooms",
      icon: <MapPinIcon className={className} />,
      required_permissions: [Permissions.VIEW_CLASSROOMS],
    },
    {
      count: data?.counts.staff ?? 0,
      title: "Staff Members",
      icon: <UserGroupIcon className={className} />,
      required_permissions: [Permissions.VIEW_STAFF],
    },
    {
      count: data?.counts.student ?? 0,
      title: "Students",
      icon: <AcademicCapIcon className={className} />,
      required_permissions: [Permissions.VIEW_STUDENTS],
    },
    {
      count: data?.counts.test ?? 0,
      title: "Tests conducted",
      icon: <MapPinIcon className={className} />,
      required_permissions: [Permissions.VIEW_ASSIGNED_CLASSROOMS],
    },
    {
      count: data?.counts.homework ?? 0,
      title: "Homework published",
      icon: <AcademicCapIcon className={className} />,
      required_permissions: [Permissions.VIEW_ASSIGNED_STUDENTS],
    },
  ];

  return (
    <Aux>
      <div className="p-3">
        <Row className="">
          {countCards.map((card, index) => (
            <Col className="mb-3" lg="3" md="4" sm="12" xs="12" key={index}>
              <CountCard {...card} />
            </Col>
          ))}
        </Row>
      </div>
    </Aux>
  );
}
