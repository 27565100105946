import {
  IBatch,
  IClassroom,
  IDepartment,
  IStudent,
  SupportedFilterAttributes,
  SupportedFilterOperators,
} from "crm_core";
import { useEffect, useState } from "react";
import { ClassroomService } from "src/services/institute/classroom";
import { Loader } from "src/components/loader";
import { useNotification } from "src/context/notification_context";
import { StudentService } from "src/services/institute/student";
import CustomItemSelector from "src/components/custom_item_selector";
import { PaginatedGetter } from "src/services/paginated_getter";
import { getFilterSpec } from "src/features/filters/utils";

export interface AssociateStudentsProps {
  classroom: IClassroom & {
    _id: string;
    batch: IBatch;
    department: IDepartment;
  };
  onComplete?: () => void;
}

export default function AssociateStudents({
  classroom,
  onComplete,
}: AssociateStudentsProps) {
  const paginatedGetter = new PaginatedGetter<StudentService, IStudent>(
    StudentService
  );
  const service = new StudentService();
  const classService = new ClassroomService();
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState<IStudent[]>([]);
  const { pushNotification } = useNotification();

  const fetchData = async () => {
    setLoading(true);
    const res = await paginatedGetter.get({
      filters: [
        getFilterSpec(
          SupportedFilterAttributes.DEPARTMENT,
          SupportedFilterOperators.EQUALS,
          classroom.department._id
        ),
        getFilterSpec(
          SupportedFilterAttributes.BATCH,
          SupportedFilterOperators.EQUALS,
          classroom.batch._id
        ),
      ],
    });
    setStudents(res.results);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async (selectedStudents: IStudent[]) => {
    setLoading(true);

    if (classroom) {
      await classService.updateStudents(
        classroom._id,
        selectedStudents.map((s) => s._id as string)
      );
      pushNotification({
        title: "Success",
        message: "Updated Class Students Successfully",
        type: "success",
      });
    }

    onComplete?.();
    setLoading(false);
  };

  return (
    <div className="bg-white rounded-md py-3">
      {loading && <Loader text="Updating Staff Members" />}
      {!loading && (
        <>
          <div className="px-3">
            <CustomItemSelector
              data={students}
              isSelected={(item) =>
                classroom
                  ? classroom.students?.findIndex((s) => s === item._id) !== -1
                  : false
              }
              render={(item) => (
                <div>
                  {item.first_name} {item.last_name}
                </div>
              )}
              searchHandler={(item, search) =>
                item.first_name.toLowerCase().includes(search.toLowerCase()) ||
                (item.last_name ?? "")
                  .toLowerCase()
                  .includes(search.toLowerCase())
              }
              onSave={async (selectedItems) => handleSave(selectedItems)}
              selectedTitle={`Students in ${classroom.name}`}
              nonSelectedTitle="All Students"
            />
          </div>
        </>
      )}
    </div>
  );
}
