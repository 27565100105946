import { IAddress } from "crm_core";
import { Dispatch, SetStateAction } from "react";
import FormGenerator, { FormField } from "src/components/form_generator";
import { FormFieldType } from "src/utils/constants";

interface AddressEditorProps {
  address: IAddress;
  onChange: Dispatch<SetStateAction<IAddress>>;
  canEdit?: boolean;
}

export default function AddressEditor({
  address,
  onChange,
  canEdit = true,
}: AddressEditorProps) {
  const getFields = () => {
    let allFields: FormField<any>[][] = [
      [
        {
          value: address.line1,
          onChange: (v) => onChange(a => ({ ...a, line1: v })),
          type: FormFieldType.TEXT,
          extras: {
            label: "Address Line 1",
          },
        },
        {
          value: address.line2,
          onChange: (v) => onChange(a => ({ ...a, line2: v })),
          type: FormFieldType.TEXT,
          extras: {
            label: "Address Line 2",
          },
        },
      ],
      [
        {
          value: address.city,
          onChange: (v) => onChange(a => ({ ...a, city: v })),
          type: FormFieldType.TEXT,
          extras: {
            label: "City",
          },
        },
        {
          value: address.state,
          onChange: (v) => onChange(a => ({ ...a, state: v })),
          type: FormFieldType.TEXT,
          extras: {
            label: "State",
          },
        },
      ],
      [
        {
          value: address.country,
          onChange: (v) => onChange(a => ({ ...a, country: v })),
          type: FormFieldType.TEXT,
          extras: {
            label: "Country",
          },
        },
        {
          value: address.pincode,
          onChange: (v) => onChange(a => ({ ...a, pincode: v })),
          type: FormFieldType.TEXT,
          extras: {
            label: "Zip Code",
          },
        },
      ],
    ];
    if (!canEdit) {
      allFields = allFields.map((row) =>
        row.map((field) => ({ ...field, extras: { ...field.extras, disabled: true } }))
      );
    }
    return allFields;
  };

  return <FormGenerator getFields={getFields} />;
}
