import moment = require("moment-timezone");

/**
 * Ingest time in format "hh:mm a" and date in format "YYYYMMDD" and return a moment object
 * @param date 20230720
 * @param time 09:00 pm
 * @param timezone Asia/Kolkata
 * @returns 2023-07-20T21:00:00+05:30
 */
export function combineTimeDateTimezone(
  time: string,
  date: string,
  timezone: string
) {
  const timeFormat = "hh:mm a";
  const timeDate = moment.tz(time, timeFormat, timezone);
  const dateFormat = "YYYYMMDD";
  const tzDate = moment.tz(date, dateFormat, timezone);
  tzDate.set({
    hour: timeDate.hour(),
    minutes: timeDate.minutes(),
  });
  return tzDate;
}
