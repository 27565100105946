import { AuthEntityType, IAuthorization } from "./authorization";
import { IUser, UserRole } from "./user";

export enum InvitationEntityType { 
  STUDENT = "Student",
  STAFF_MEMBER = "StaffMember",
  PARENT = "Parent",
}

export enum InvitationStatus {
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
}

export interface IInvitation {
  _id: string;
  email: string;
  authorization: IAuthorization;
  valid_until: Date;
  created_by?: string | IUser;
  created_at?: number;
  last_updated?: number;
  status: InvitationStatus;
}

export interface InviteRequest{
  entity_type: InvitationEntityType;
  entity_id: string;
  auth_entity_type: AuthEntityType;
  auth_entity_id?: string;
  role: UserRole;
  resend: boolean;
}