export default function Logo({ white, iconOnly }: { white?: boolean, iconOnly?: boolean }) {
  const logoMap = {
    white: {
      iconOnly: "https://teachbondprod.blob.core.windows.net/logos/logo-icon-white.png",
      full: "https://teachbondprod.blob.core.windows.net/logos/logo-white.png",
    },
    black: {
      iconOnly: "https://teachbondprod.blob.core.windows.net/logos/logo-icon-color.png",
      full: "https://teachbondprod.blob.core.windows.net/logos/logo-no-background.png",
    },
    }

  return (
    <a
      style={{
        // padding: "0px 15px",
        justifyContent: "center",
        display: "flex",
        height: "100%",
        width: "100%",
      }}
      href="https://www.teachbond.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={logoMap[white ? "white" : "black"][iconOnly ? "iconOnly" : "full"]}
        alt="logo"
        style={{
          height: "100%",
          width: "100%",
          objectFit: "scale-down",
        }}
      />
    </a>
  );
}
