import { IStudent, Permissions } from "crm_core";
import { useEffect, useState } from "react";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useNotification } from "src/context/notification_context";
import { StudentService } from "src/services/institute/student";
import { FormFieldType } from "src/utils/constants";
import { catchRequest, validateFields } from "src/utils/functions";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import { Col, Row } from "react-bootstrap";
import SaveButton from "src/components/widgets/save_button";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";

export interface AssignStudentProps {
  onComplete?: () => void;
  student: IStudent;
}

export default function AssignStudent({
  onComplete,
  student,
}: AssignStudentProps) {
  const service = new StudentService();
  const [studentDetails, setStudentDetails] = useState<IStudent>({} as any);

  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();
  const { fetchDataForUseCase, departments, selectedSession, batches } =
    useDataProvider();

  useEffect(() => {
    if (selectedSession) fetchDataForUseCase(DataUseCase.INSTITUTE);
  }, [selectedSession]);

  useEffect(() => {
    if (student) {
      setStudentDetails(student);
    }
  }, [student]);

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(getFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);
    await catchRequest(async () => {
      await service.updateStudent({
        _id: studentDetails?._id,
        batch: studentDetails.batch,
        department: studentDetails.department,
        session_wise_details: studentDetails?.session_wise_details || [],
      });
      pushNotification({
        title: "Success",
        message: "Student updated successfully",
        type: "success",
      });
    }, pushNotification)();
    setLoading(false);
    onComplete?.();
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.SELECT,
          value: studentDetails?.department,
          onChange: (v) => {
            setStudentDetails((s) => ({
              ...s,
              department: v,
              batch: undefined,
            }));
          },
          extras: {
            label: "Department",
            options: departments.map((d) => ({ label: d.name, value: d._id })),
            searchable: true,
          },
          required: true,
        },
        {
          type: FormFieldType.SELECT,
          value: studentDetails.batch,
          onChange: (v) =>
            setStudentDetails((s) => ({
              ...s,
              batch: v,
            })),
          extras: {
            label: "Batch",
            options: batches
              .filter((b) => b.department == studentDetails.department)
              .map((b) => ({ label: b.name, value: b._id })),
            searchable: true,
          },
          required: true,
        },
      ],
    ];

    return allFields;
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      <Row className="justify-content-end mt-4 align-items-center">
        <Col md="auto" sm="auto" xs="auto" className="">
          <PermissionRestrictedComponent
            permissions={[Permissions.UPDATE_STUDENT]}
          >
            <SaveButton
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            />
          </PermissionRestrictedComponent>
        </Col>
      </Row>
    </div>
  );
}
