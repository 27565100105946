import { IQuestion, ITest, QuestionType, TestStatus } from "crm_core";

export const DEFAULT_QUESTION: IQuestion = {
  text: "",
  options: [{ text: "" }],
  type: QuestionType.SingleAnswer,
  correct_option: {
    option_ids: [],
  },
};

export const DEFAULT_TEST: ITest = {
  name: "New test",
  description: "",
  sections: [
    {
      name: "MCQs",
      correct_marks: 4,
      negative_marks: -1,
    },
    {
      name: "Subjective",
      correct_marks: 8,
      negative_marks: 0,
    },
  ],
  questions: [],
  status: TestStatus.DRAFT,
};
