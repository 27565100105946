import { IFeePaymentExtended, IStudent, PaymentStatus } from "crm_core";
import moment from "moment";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Aux from "src/components/aux";
import CustomCard from "src/components/custom_card";
import CustomDataTable, {
  CustomTableColumn,
} from "src/components/custom_data_table";
import HelpTip from "src/components/helptip";
import { Loader } from "src/components/loader";
import { useAuth } from "src/context/auth_context";
import InstituteCalendar from "src/features/institute_structure/calendar";
import AddUpdateStudent from "src/features/people/students/add_update";
import { FeeRecordService } from "src/services/institute/fees/record";
import { StudentService } from "src/services/institute/student";
import { tabClassName } from "src/utils/constants";

export default function StudentInstitute() {
  const [loading, setLoading] = useState(false);
  const [studentDetails, setStudentDetails] = useState<IStudent>();
  const { user, selectedAuthorization } = useAuth();
  const [feeRecords, setFeeRecords] = useState<IFeePaymentExtended[]>([]);
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState(tab || "fees");

  useEffect(() => {
    if (activeTab) {
      window.history.replaceState(null, "", `/c/institute/${activeTab}`);
    }
  }, [activeTab]);

  const fetchStudentDetails = async () => {
    setLoading(true);
    const response = await new StudentService().getStudent(user._id);
    setStudentDetails(response);
    setLoading(false);
  };

  const fetchFeeRecords = async () => {
    setLoading(true);
    const data = await new FeeRecordService().getFeeRecords();
    setFeeRecords(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchStudentDetails();
    fetchFeeRecords();
  }, []);

  useEffect(() => {
    document.title = "Teachbond - Institute";
  }, []);

  const columns: CustomTableColumn<IFeePaymentExtended>[] = [
    {
      name: "Fee",
      cell: (row) => `${row.structure.name} - ${row.installment}`,
      sortable: true,
      width: "300px",
      sortField: "structure.name",
    },
    {
      name: "Due Date",
      cell: (row) => <div>{moment(row.due_date).format("DD MMM YYYY")}</div>,
      width: "150px",
      sortable: true,
      sortField: "due_date",
    },
    {
      name: "Paid Amount",
      cell: (row) => row.paid_amount || "--",
      width: "100px",
      sortable: true,
      sortField: "paid_amount",
    },
    {
      name: "Paid Fine",
      cell: (row) => row.paid_fine || "--",
      width: "100px",
      sortable: true,
      sortField: "paid_fine",
    },
    {
      name: "Due Amount",
      cell: (row) => (
        <div>
          {row.amount - (row.paid_amount ?? 0)}{" "}
          <span hidden={!row.fine} className="text-danger">
            <HelpTip
              id={`${row.structure._id} ${row.student.enrollment_no}`}
              text="Late Fee"
            >
              <span>+ {row.fine}</span>
            </HelpTip>
          </span>
        </div>
      ),
      width: "150px",
      sortable: true,
      sortField: "amount",
    },
  ];

  // const tabClassName = "p-0 py-1 px-3 font-sm mr-1";

  return (
    <Aux>
      <CustomCard title={selectedAuthorization.entity.name} className="pb-0">
        <Tabs
          mountOnEnter
          color="primary"
          className="ml-3"
          variant="pills"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k as string)}
        >
          <Tab eventKey="fees" title="Fees" tabClassName={tabClassName}>
            <CustomDataTable
              tableKey={""}
              columns={columns}
              data={feeRecords}
              progressPending={loading}
              conditionalRowStyles={[
                {
                  when: (row) => row.status == PaymentStatus.OVERDUE,
                  style: {
                    backgroundColor: "#f8d7da",
                  },
                },
              ]}
            />
          </Tab>
          <Tab eventKey="calender" title="Calendar" tabClassName={tabClassName}>
            <InstituteCalendar tableOnly />
          </Tab>
          <Tab
            eventKey="details"
            title="Your Details"
            tabClassName={tabClassName}
          >
            {loading && <Loader />}
            {!loading && <AddUpdateStudent student={studentDetails} />}
          </Tab>
        </Tabs>
      </CustomCard>
    </Aux>
  );
}
