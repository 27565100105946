export enum LeaveStatus {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
}

export interface ILeaveRequest {
  _id?: string;
  from_date: string; // YYYYMMDD
  to_date: string; // YYYYMMDD
  description: string;
  status: LeaveStatus;
}
