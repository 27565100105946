import { Disclosure, Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { joinClasses } from "src/utils/functions";

interface DropdownProps {
  buttonChild: JSX.Element;
  children: any;
  origin?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  menuStyle?: React.CSSProperties;
  showChevron?: boolean;
  buttonStyle?: React.CSSProperties;
  buttonClassName?: string;
  className?: string;
}

export default function Dropdown({
  buttonStyle,
  buttonChild,
  children,
  origin = "top-right",
  menuStyle = { width: "100px", marginTop: "20px", padding: "10px" },
  showChevron = true,
  buttonClassName,
  className,
}: DropdownProps) {
  return (
    <Menu as="div" className={joinClasses("relative", className)}>
      <div>
        <Menu.Button
          style={buttonStyle}
          className={joinClasses(
            "w-full flex items-center text-sm focus:outline-none focus:ring-offset-gray-800 focus:ring-white",
            buttonClassName
          )}
        >
          {buttonChild}
          {showChevron && (
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-200"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={menuStyle}
          className={joinClasses(
            "z-50 absolute rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none",
            origin === "top-left" && "origin-top-left left-0",
            origin === "top-right" && "origin-top-right right-0",
            origin === "bottom-left" && "origin-bottom-left left-0",
            origin === "bottom-right" && "origin-bottom-right right-0"
          )}
        >
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
