import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { IQuestionAttachment } from "crm_core";
import { Col, Row } from "react-bootstrap";
import Button from "src/components/buttons/button";
import { ModalTypes, useModal } from "src/context/modal_context";
import { AddAttachmentProps } from "./add_attachment";
import LabelledField from "src/components/form_generator/labelled_field";
import { IAttachmentViewerProps } from "./attachment_viewer";

interface IAttachmentsProps {
  attachments: IQuestionAttachment[];
  onRemove: (attachment: IQuestionAttachment) => void;
  onAdd: (attachment: IQuestionAttachment) => void;
  reference: string;
  editable?: boolean;
}

export default function Attachments({
  attachments,
  onRemove,
  onAdd,
  reference,
  editable,
}: IAttachmentsProps) {
  const {
    setModalType,
    setModalSize,
    setModalHeading,
    setComponentProps,
    setShowModal,
    setModalBodyProps,
  } = useModal();

  const handleAddAttachment = () => {
    setModalType(ModalTypes.ADD_QUESTION_ATTACHMENT);
    setModalSize("lg");
    setModalHeading("Add Attachment");
    let props: AddAttachmentProps = {
      onComplete: (attachment) => {
        setShowModal(false);
        if (!attachment) return Promise.resolve();
        onAdd(attachment);
      },
      reference,
    };
    setComponentProps(props);
    setShowModal(true);
  };

  const handleViewAttachment = (attachment: IQuestionAttachment) => {
    setModalType(ModalTypes.VIEW_ATTACHMENT);
    setModalSize("xl");
    setModalHeading(attachment.name);
    setModalBodyProps({ className: "pt-0" });
    setComponentProps({ attachment } as IAttachmentViewerProps);
    setShowModal(true);
  };

  return (
    <div hidden={attachments.length == 0 && !editable}>
      <LabelledField label="Attachments">
        <Row className="m-0 align-items-center">
          {attachments.map((f, i) => (
            <Col className="col-auto pl-0" key={i}>
              <Row className="m-0 bg-extra-light-accent rounded-md px-1 py-1 align-items-center">
                <Col className="p-0 col-auto">
                  <a
                    onClick={(e) => handleViewAttachment(f)}
                    className="text-decoration-none"
                  >
                    {f.name}
                  </a>
                </Col>
                <Col hidden={!editable} className="pr-0 col-auto">
                  <Button
                    icon={<XMarkIcon className="h-3 w-3" />}
                    color="red"
                    className="p-1"
                    rounded
                    isSlim
                    onClick={() => onRemove(f)}
                  />
                </Col>
              </Row>
            </Col>
          ))}
          <Col className="p-0 col-auto"></Col>
          <Col hidden={!editable} className="p-0 col-auto ml-auto">
            <Button
              icon={<PlusIcon className="h-5 w-5 mr-2" />}
              color="accent"
              label="Add File"
              className="px-2 py-1 text-sm"
              isSlim
              onClick={handleAddAttachment}
            />
          </Col>
        </Row>
      </LabelledField>
    </div>
  );
}
