import {
  PaymentStatus,
  FilterSpec,
  InvitationFilterValues,
  SupportedFilterAttributes,
  SupportedFilterOperators,
  TestStatus,
  ActiveStatusValues,
  QuestionType,
  QuestionTypeOptions,
  QuestionDifficulty,
  QuestionDifficultyOptions,
} from "crm_core";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import TextField from "src/components/form_generator/text_field";
import FormSelect, { FormOption } from "src/components/form_select";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import { useTable } from "src/context/table_context";
import { FilterKeys } from "src/utils/constants";
import { FilterTypes } from "./types";
import { FilterLabels } from "./labels";
import SearchBox from "src/components/text_fields/search_box";
import { usePageState } from "src/context/page_state_context";
import { joinClasses } from "src/utils/functions";

interface AttributeFilter {
  label: string;
  attribute: SupportedFilterAttributes;
  type: FilterTypes;
  options?: FormOption[];
  keys: FilterKeys[];
  parentFilters?: SupportedFilterAttributes[];
  getFilteredOptions?: (parentFilterValue: string) => FormOption[];
}

interface FilterProps {
  filterKey: FilterKeys;
  searchPlaceholder?: string;
  visibleFilters?: SupportedFilterAttributes[];
}

export default function Filters({
  filterKey,
  searchPlaceholder = "Search",
  visibleFilters,
}: FilterProps) {
  const { departments, batches, classrooms, fetchDataForUseCase } =
    useDataProvider();
  const { getFilterPreferences, updateFilterPreferences, filterPreferences } =
    useTable();
  const { isSmallScreen } = usePageState();
  const [filters, setFilters] = useState<FilterSpec[]>([]);

  useEffect(() => {
    setFilters(getFilterPreferences(filterKey));
  }, [filterPreferences]);

  const allFilters: AttributeFilter[] = [
    {
      label: FilterLabels.SEARCH,
      attribute: SupportedFilterAttributes.SEARCH,
      type: FilterTypes.TEXT,
      keys: [
        FilterKeys.BATCH,
        FilterKeys.BATCH_LIST_IN_DEPARTMENT,
        FilterKeys.CLASSROOM,
        FilterKeys.CLASSROOM_LIST_IN_DEPARTMENT,
        FilterKeys.CLASSROOM_LIST_IN_BATCH,
        FilterKeys.STUDENT,
        FilterKeys.STUDENT_LIST_IN_DEPARTMENT,
        FilterKeys.STUDENT_LIST_IN_BATCH,
        FilterKeys.STUDENT_LIST_IN_CLASSROOM,
        FilterKeys.TEST,
        FilterKeys.STUDENT_LIST_IN_CLASSROOM,
        FilterKeys.STAFF_MEMBER,
        FilterKeys.STUDENT_IN_STAFF_MEMBER,
        FilterKeys.CLASSROOM_LIST_IN_STUDENT,
        FilterKeys.TESTS_IN_CLASSROOM,
        FilterKeys.SHIFTS_LIST,
        FilterKeys.FEE_STRUCTURES,
        FilterKeys.FEE_COLLECTIONS,
        FilterKeys.HOMEWORK_IN_CLASSROOM,
      ],
    },
    {
      label: FilterLabels.DEPARTMENT,
      attribute: SupportedFilterAttributes.DEPARTMENT,
      type: FilterTypes.SINGLE_SELECT,
      keys: [
        FilterKeys.BATCH,
        FilterKeys.CLASSROOM,
        FilterKeys.STUDENT,
        FilterKeys.FEE_COLLECTIONS,
      ],
      options: departments.map(
        (d) => ({ label: d.name, value: d._id } as FormOption)
      ),
    },
    {
      label: FilterLabels.BATCH,
      attribute: SupportedFilterAttributes.BATCH,
      type: FilterTypes.SINGLE_SELECT,
      keys: [
        FilterKeys.CLASSROOM,
        FilterKeys.STUDENT,
        FilterKeys.FEE_COLLECTIONS,
      ],
      options: batches.map(
        (b) => ({ label: b.name, value: b._id } as FormOption)
      ),
      parentFilters: [SupportedFilterAttributes.DEPARTMENT],
      getFilteredOptions: (departmentId: string) =>
        batches
          .filter((b) => b.department === departmentId)
          .map((b) => ({ label: b.name, value: b._id } as FormOption)),
    },
    {
      label: FilterLabels.CLASSROOM,
      attribute: SupportedFilterAttributes.CLASSROOM,
      type: FilterTypes.SINGLE_SELECT,
      keys: [FilterKeys.TESTS_IN_STUDENT],
      parentFilters: [
        SupportedFilterAttributes.BATCH,
        SupportedFilterAttributes.DEPARTMENT,
      ],
      options: classrooms.map(
        (c) => ({ label: c.name, value: c._id } as FormOption)
      ),
      getFilteredOptions: (parentId: string) =>
        classrooms
          .filter((c) => c.batch == parentId || c.department == parentId)
          .map((c) => ({ label: c.name, value: c._id } as FormOption)),
    },
    {
      label: FilterLabels.INVITATION_STATUS,
      attribute: SupportedFilterAttributes.INVITATION_STATUS,
      type: FilterTypes.SINGLE_SELECT,
      keys: [FilterKeys.STUDENT, FilterKeys.STAFF_MEMBER],
      options: [
        { label: "Not Joined", value: InvitationFilterValues.NOT_JOINED },
        { label: "Joined", value: InvitationFilterValues.JOINED },
        { label: "Not Invited", value: InvitationFilterValues.NOT_INVITED },
      ],
    },
    {
      label: FilterLabels.QUESTION_TYPE,
      attribute: SupportedFilterAttributes.QUESTION_TYPE,
      type: FilterTypes.SINGLE_SELECT,
      keys: [FilterKeys.QUESTIONS_IN_TOPIC],
      options: QuestionTypeOptions,
    },
    {
      label: FilterLabels.DIFFICULTY,
      attribute: SupportedFilterAttributes.QUESTION_DIFFICULTY,
      type: FilterTypes.SINGLE_SELECT,
      keys: [FilterKeys.QUESTIONS_IN_TOPIC],
      options: QuestionDifficultyOptions,
    },
    {
      label: FilterLabels.ACTIVE_STATUS,
      attribute: SupportedFilterAttributes.ACTIVE_STATUS,
      type: FilterTypes.SINGLE_SELECT,
      keys: [FilterKeys.STUDENT],
      options: [
        { label: "Active", value: ActiveStatusValues.ACTIVE },
        { label: "Exited", value: ActiveStatusValues.EXITED },
      ],
    },
    {
      label: FilterLabels.STATUS,
      attribute: SupportedFilterAttributes.STATUS,
      type: FilterTypes.SINGLE_SELECT,
      keys: [FilterKeys.TEST],
      options: [
        { label: "Draft", value: TestStatus.DRAFT },
        { label: "Published", value: TestStatus.PUBLISHED },
      ],
    },
    {
      label: FilterLabels.COLLECTION_STATUS,
      attribute: SupportedFilterAttributes.COLLECTION_STATUS,
      type: FilterTypes.SINGLE_SELECT,
      keys: [FilterKeys.FEE_COLLECTIONS],
      options: [
        { label: "Paid", value: PaymentStatus.PAID },
        { label: "Unpaid", value: PaymentStatus.UNPAID },
        { label: "Overdue", value: PaymentStatus.OVERDUE },
      ],
    },
  ];

  const eligibleFilters = allFilters.filter(
    (f) =>
      f.keys.indexOf(filterKey) > -1 &&
      (!visibleFilters || visibleFilters.indexOf(f.attribute) > -1)
  );

  useEffect(() => {
    if (
      eligibleFilters
        .map((f) => f.attribute)
        .includes(SupportedFilterAttributes.CLASSROOM)
    ) {
      fetchDataForUseCase(DataUseCase.CLASSROOMS);
    }
    if (
      eligibleFilters.some((f) =>
        [
          SupportedFilterAttributes.BATCH,
          SupportedFilterAttributes.DEPARTMENT,
        ].includes(f.attribute)
      )
    ) {
      fetchDataForUseCase(DataUseCase.FILTERS);
    }
  }, []);

  const updateFilter = (
    attribute: SupportedFilterAttributes,
    value: any,
    operator: SupportedFilterOperators
  ) => {
    const filter = filters.find((f) => f.attribute === attribute);
    if (filter) {
      filter.value = value;
      filter.operator = operator;
    } else {
      filters.push({ attribute, value, operator });
    }
    setFilters([...filters]);
    handleParentFilterChange(attribute);
    updateFilterPreferences(filterKey, filters);
  };

  const handleParentFilterChange = (attribute: SupportedFilterAttributes) => {
    const childrenFilters = allFilters.filter((f) =>
      f.parentFilters?.includes(attribute)
    );
    childrenFilters.forEach((f) => {
      removeFilter(f.attribute);
    });
  };

  const removeFilter = (attribute: SupportedFilterAttributes) => {
    const filter = filters.find((f) => f.attribute === attribute);
    if (filter) {
      filters.splice(filters.indexOf(filter), 1);
      setFilters([...filters]);
      handleParentFilterChange(attribute);
      updateFilterPreferences(filterKey, filters);
    }
  };

  return (
    <div hidden={eligibleFilters.length == 0} className="bg-white mx-3 mb-2">
      <Row>
        {eligibleFilters.map((filter, idx) => {
          const { type, attribute, options, label } = filter;
          let eligibleOptions = options;
          const value = filters.find((f) => f.attribute === attribute)?.value;
          const parentFilterValue = filters.find((f) =>
            filter.parentFilters?.includes(f.attribute)
          )?.value;
          if (filter.getFilteredOptions && parentFilterValue) {
            eligibleOptions = filter.getFilteredOptions(parentFilterValue);
          }
          if (eligibleOptions?.length === 0) {
            return null;
          }
          return (
            <Col
              key={idx}
              md={2}
              sm={6}
              className={joinClasses(isSmallScreen ? "mb-2" : "")}
            >
              <div className="text-muted mb-2 ml-1 text-sm">{label}</div>
              {type === FilterTypes.SINGLE_SELECT && (
                <FormSelect
                  isClearable={false}
                  className="w-full"
                  options={[
                    { value: "", label: "All" },
                    ...(eligibleOptions ?? []),
                  ]}
                  value={value ?? ""}
                  onChange={(v) =>
                    v === ""
                      ? removeFilter(attribute)
                      : updateFilter(
                          attribute,
                          v,
                          SupportedFilterOperators.EQUALS
                        )
                  }
                />
              )}
              {type === FilterTypes.MULTI_SELECT && (
                <FormSelect
                  isClearable={false}
                  className="w-full"
                  options={eligibleOptions ?? []}
                  value={value ?? ""}
                  onChange={(v) =>
                    updateFilter(attribute, v, SupportedFilterOperators.EQUALS)
                  }
                />
              )}
              {type === FilterTypes.TEXT && (
                <SearchBox
                  value={value ?? ""}
                  placeholder={searchPlaceholder}
                  onChange={() => {}}
                  onSubmit={(v) =>
                    updateFilter(
                      attribute,
                      v,
                      SupportedFilterOperators.TEXT_CONTAINS
                    )
                  }
                />
              )}
              {type === FilterTypes.NUMBER && (
                <TextField
                  type="number"
                  value={value ?? ""}
                  onChange={() => {}}
                  onSubmit={(v) =>
                    updateFilter(
                      attribute,
                      v,
                      SupportedFilterOperators.GREATER_THAN
                    )
                  }
                />
              )}
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
