import { PlusIcon } from "@heroicons/react/20/solid";
import {
  HolidayReasonOptions,
  IInstituteCalendarDay,
  Permissions,
} from "crm_core";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "src/components/buttons/button";
import FormGenerator, { FormField } from "src/components/form_generator";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import SaveButton from "src/components/widgets/save_button";
import { InstituteCalendarService } from "src/services/institute/calendar";
import { FormFieldType } from "src/utils/constants";

export interface EditCalendarDayProps {
  data: IInstituteCalendarDay;
  onComplete?: () => void;
}

export default function EditCalendarDay({
  data,
  onComplete,
}: EditCalendarDayProps) {
  const calendarService = new InstituteCalendarService();
  const [calendarDay, setCalendarDay] = useState<IInstituteCalendarDay>(data);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSave = async () => {
    setLoading(true);
    await calendarService.updateInstituteCalendarDay(calendarDay);
    setLoading(false);
    onComplete?.();
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.TOGGLE,
          value: calendarDay.working_for_students,
          onChange: (val) =>
            setCalendarDay({
              ...calendarDay,
              working_for_students: val,
              working_for_staff: val ? val : calendarDay.working_for_staff,
            }),
          extras: {
            label: "Working day for students",
          },
          required: true,
          updatable: true,
        },
        {
          type: FormFieldType.TOGGLE,
          value: calendarDay.working_for_staff,
          onChange: (val) =>
            setCalendarDay({
              ...calendarDay,
              working_for_staff: val,
              working_for_students: val
                ? calendarDay.working_for_students
                : val,
            }),
          extras: {
            label: "Working day for Staff",
          },
          required: true,
          updatable: true,
        },
      ],
    ];
    if (!calendarDay.working_for_students && !calendarDay.working_for_staff) {
      allFields.push([
        {
          type: FormFieldType.SELECT,
          value: calendarDay.holiday_reason,
          onChange: (val) =>
            setCalendarDay({
              ...calendarDay,
              holiday_reason: val,
            }),
          extras: {
            options: HolidayReasonOptions,
            label: "Holiday Reason",
          },
          required: true,
          updatable: true,
        },
        {
          type: FormFieldType.TEXT,
          value: calendarDay.holiday_description,
          onChange: (val) =>
            setCalendarDay({
              ...calendarDay,
              holiday_description: val,
            }),
          extras: {
            label: "Holiday Description",
            placeholder: "Annual Holiday, etc.",
          },
          updatable: true,
        },
      ]);
    }
    if (calendarDay.working_for_students || calendarDay.working_for_staff) {
      (calendarDay.events ?? []).forEach((event, index) => {
        const handleUpdate = (val: any, key: string) =>
          setCalendarDay({
            ...calendarDay,
            events: [
              ...calendarDay.events.slice(0, index),
              { ...event, [key]: val },
              ...calendarDay.events.slice(index + 1),
            ],
          });
        allFields.push([
          {
            type: FormFieldType.TEXT,
            value: event.title,
            onChange: (val) => handleUpdate(val, "title"),

            extras: {
              label: "Event Title",
              placeholder: "HOD Meeting, etc.",
            },
            updatable: true,
          },
          {
            type: FormFieldType.TEXTAREA,
            value: event.description,
            onChange: (val) => handleUpdate(val, "description"),

            extras: {
              label: "Event Description",
              placeholder: "Meeting with Mr. X, etc.",
            },
            updatable: true,
          },
        ]);
      });
    }

    return allFields;
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      {(calendarDay.working_for_students || calendarDay.working_for_staff) && (
        <Button
          icon={<PlusIcon className="h-4 w-4 mr-2" />}
          label={"Add Event"}
          isSlim
          color="white"
          className="px-2 py-1 mt-3"
          onClick={() =>
            setCalendarDay({
              ...calendarDay,
              events: [
                ...(calendarDay.events ?? []),
                { title: "", description: "" },
              ],
            })
          }
        />
      )}
      <Row className="justify-content-end mt-4 align-items-center">
        <Col md="auto" sm="auto" xs="auto" className="">
          <PermissionRestrictedComponent
            permissions={[Permissions.UPDATE_INSTITUTE_CALENDAR]}
          >
            <SaveButton
              loading={loading}
              disabled={loading}
              onClick={handleSave}
            />
          </PermissionRestrictedComponent>
        </Col>
      </Row>
    </div>
  );
}
