import {
  IBatch,
  IStudent,
  IDepartment,
  Genders,
  BloodGroups,
  IAddress,
  IParent,
  Religion,
  Category,
  Disablity,
  SingleParent,
  IMedicalRecord,
  Permissions,
  ParentType,
  DEFAULT_DATE_FORMAT,
} from "crm_core";
import { useEffect, useState } from "react";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useNotification } from "src/context/notification_context";
import { StudentService } from "src/services/institute/student";
import { FormFieldType } from "src/utils/constants";
import {
  catchRequest,
  isPermissible,
  validateFields,
} from "src/utils/functions";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import { Col, Row } from "react-bootstrap";
import SaveButton from "src/components/widgets/save_button";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import ExpandableCard from "src/components/custom_card/expandable_card";
import ToggleButton from "src/components/buttons/toggle_button";
import Button from "src/components/buttons/button";
import { PlusIcon } from "@heroicons/react/20/solid";
import ArchiveButton from "src/components/widgets/archive_button";
import moment from "moment";
import AddressEditor from "../address_editor";
import MedicalRecordEditor from "../medical_record_editor";
import CustomCard from "src/components/custom_card";
import { useNavigate } from "react-router-dom";

export interface AddUpdateStudentProps {
  onComplete?: () => void;
  id?: string;
  student?: IStudent;
}

export default function AddUpdateStudent({
  onComplete,
  id,
  student,
}: AddUpdateStudentProps) {
  const canEdit = isPermissible(
    Permissions.UPDATE_STUDENT,
    Permissions.CREATE_STUDENT
  );
  const service = new StudentService();
  const navigate = useNavigate();
  const [studentDetails, setStudentDetails] = useState<IStudent>({} as any);
  const [firstName, setFirstName] = useState<string>();
  const [middleName, setMiddleName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [mobile, setMobile] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [dob, setDob] = useState<string>();
  const [gender, setGender] = useState<Genders>();
  const [bloodGroup, setBloodGroup] = useState<BloodGroups>();
  const [currentAddress, setCurrentAddress] = useState<IAddress>({} as any);
  const [permanentAddress, setPermanentAddress] = useState<IAddress>({} as any);
  const [permanentAddressSameAsCurrent, setPermanentAddressSameAsCurrent] =
    useState<boolean>();

  const [father, setFather] = useState<IParent>({} as any);
  const [mother, setMother] = useState<IParent>({} as any);
  const [guardian, setGuardian] = useState<IParent>({} as any);
  const [admissionDate, setAdmissionDate] = useState<Date>();
  const [department, setDepartment] = useState<string>();
  const [batch, setBatch] = useState<string>();
  const [enrollmentNumber, setEnrollmentNumber] = useState<string>();
  const [rollNumber, setRollNumber] = useState<number>();
  const [religion, setReligion] = useState<Religion>();
  const [category, setCategory] = useState<Category>();
  const [disablity, setDisablity] = useState<Disablity>(Disablity.None);
  const [nationality, setNationality] = useState<string>();
  const [isSingleParent, setIsSingleParent] = useState<boolean>();
  const [singleParent, setSingleParent] = useState<SingleParent>();
  const [isSponsored, setIsSponsored] = useState<boolean>();
  const [sponsor, setSponsor] = useState<string>();
  const [medicalRecords, setMedicalRecords] = useState<IMedicalRecord[]>([]);
  const [sendInvite, setSendInvite] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();
  const {
    fetchDataForUseCase,
    departments,
    selectedSession,
    batches,
    feeStructures,
  } = useDataProvider();

  useEffect(() => {
    if (selectedSession) {
      fetchDataForUseCase(DataUseCase.INSTITUTE);
      fetchDataForUseCase(DataUseCase.FEE_STRUCTURES);
    }
  }, [selectedSession]);

  useEffect(() => {
    const fetchStudent = async (id?: string) => {
      setLoading(true);
      const currentStudent =
        student || (await service.getStudent(id as string));
      setStudentDetails(currentStudent);
      setFirstName(currentStudent.first_name);
      setMiddleName(currentStudent.middle_name);
      setLastName(currentStudent.last_name);
      setMobile(currentStudent.mobile);
      setEmail(currentStudent.email);
      setDob(currentStudent.dob);
      setDepartment(((currentStudent.department ?? {}) as IDepartment)._id);
      setBatch(((currentStudent.batch ?? {}) as IBatch)._id);
      setAdmissionDate(currentStudent.admission_date);
      setEnrollmentNumber(currentStudent.enrollment_no);
      setRollNumber(currentStudent.roll_no);
      setCategory(currentStudent.category);
      setReligion(currentStudent.religion);
      setDisablity(currentStudent.disablity ?? Disablity.None);
      setCurrentAddress(currentStudent.current_address ?? ({} as any));
      setPermanentAddress(currentStudent.permanent_address ?? ({} as any));
      setNationality(currentStudent.nationality);
      setFather(currentStudent.father ?? ({} as any));
      setMother(currentStudent.mother ?? ({} as any));
      setGuardian(currentStudent.guardian ?? ({} as any));
      setIsSingleParent(currentStudent.is_single_parent);
      setSingleParent(currentStudent.single_parent);
      setIsSponsored(currentStudent.is_sponsored);
      setSponsor(currentStudent.sponsor_name);
      setMedicalRecords(currentStudent.medical_records ?? []);
      setPermanentAddressSameAsCurrent(
        JSON.stringify(currentStudent.current_address) ===
          JSON.stringify(currentStudent.permanent_address)
      );
      setGender(currentStudent.gender);
      setBloodGroup(currentStudent.blood_group);
      setLoading(false);
    };
    if (id || student) {
      fetchStudent(id);
    }
  }, [id, student]);

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(basicDetailsGetFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);
    let student = {
      first_name: firstName as any,
      batch: batch as any,
      department: department as any,
      middle_name: middleName,
      last_name: lastName,
      mobile,
      email: email as any,
      dob,
      gender,
      blood_group: bloodGroup,
      current_address: currentAddress,
      permanent_address: permanentAddressSameAsCurrent
        ? currentAddress
        : permanentAddress,
      father,
      mother,
      guardian,
      admission_date: admissionDate,
      enrollment_no: enrollmentNumber,
      roll_no: rollNumber,
      religion,
      category,
      disablity,
      nationality,
      is_single_parent: isSingleParent,
      single_parent: singleParent,
      is_sponsored: isSponsored,
      sponsor_name: sponsor,
      medical_records: medicalRecords,
      session_wise_details: studentDetails?.session_wise_details || [],
      applicable_fees: studentDetails?.applicable_fees || [],
    };

    await catchRequest(async () => {
      if (id) {
        await service.updateStudent({
          _id: id,
          ...student,
        });
        pushNotification({
          title: "Success",
          message: "Student updated successfully",
          type: "success",
        });
      } else {
        await service.createStudent({
          ...(student as any),
        });
        pushNotification({
          title: "Success",
          message: "Student created successfully",
          type: "success",
        });
        navigate("/c/students");
      }
    }, pushNotification)();
    setLoading(false);
    onComplete?.();
  };

  const basicDetailsGetFields: (i?: any) => FormField<any>[][] = () => {
    const updating = !!id || !!student;
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.TEXT,
          value: firstName,
          onChange: setFirstName,
          extras: {
            label: "First Name",
          },
          required: true,
        },
        {
          type: FormFieldType.TEXT,
          value: middleName,
          onChange: setMiddleName,
          extras: {
            label: "Middle Name",
          },
        },
        {
          type: FormFieldType.TEXT,
          value: lastName,
          onChange: setLastName,
          extras: {
            label: "Last Name",
          },
          required: true,
        },
      ],
      [
        {
          type: FormFieldType.SELECT,
          value: department,
          onChange: (v) => {
            setDepartment(v);
            setBatch(undefined);
          },
          extras: {
            label: "Department",
            options: departments.map((d) => ({ label: d.name, value: d._id })),
            searchable: true,
            disabled: updating,
          },
          required: true,
        },
        {
          type: FormFieldType.SELECT,
          value: batch,
          onChange: setBatch,
          extras: {
            label: "Batch",
            options: batches
              .filter((b) => b.department == department)
              .map((b) => ({ label: b.name, value: b._id })),
            searchable: true,
            disabled: updating,
          },
          required: true,
        },
        {
          type: FormFieldType.TEXT,
          value: enrollmentNumber,
          onChange: setEnrollmentNumber,
          extras: {
            label: "Enrollment Number",
            disabled: updating,
          },
          required: true,
        },
      ],
      [
        {
          type: FormFieldType.NUMBER,
          value: rollNumber,
          onChange: setRollNumber,
          extras: {
            label: "Roll Number",
          },
        },
        {
          type: FormFieldType.TEXT,
          value: email,
          onChange: setEmail,
          extras: {
            label: "Email",
            disabled: updating,
          },
          required: true,
        },
        {
          type: FormFieldType.TEXT,
          value: mobile,
          onChange: setMobile,
          extras: {
            label: "Mobile",
          },
        },
      ],
      [
        {
          type: FormFieldType.DATE,
          value: dob,
          onChange: setDob,
          extras: {
            label: "Date of Birth",
          },
          // required: true,
        },
        {
          type: FormFieldType.SELECT,
          value: gender,
          onChange: setGender,
          extras: {
            label: "Gender",
            options: Object.values(Genders).map((b) => ({
              label: b,
              value: b,
            })),
            searchable: true,
          },
        },
        {
          type: FormFieldType.SELECT,
          value: bloodGroup,
          onChange: setBloodGroup,
          extras: {
            label: "Blood Group",
            options: Object.values(BloodGroups).map((b) => ({
              label: b,
              value: b,
            })),
            searchable: true,
          },
        },
      ],
      updating
        ? []
        : [
            {
              type: FormFieldType.TOGGLE,
              value: sendInvite,
              onChange: setSendInvite,
              extras: {
                label: "Send Invitation",
              },
              required: true,
            },
          ],
    ];
    if (!canEdit) {
      allFields = allFields.map((fields) =>
        fields.map((f) => ({ ...f, extras: { ...f.extras, disabled: true } }))
      );
    }

    if (!updating) {
      return (
        allFields
          // .map((fields) => fields.filter((f) => f.required))
          .filter((f) => f.length > 0)
      );
    }
    return allFields.filter((f) => f.length > 0);
  };

  useEffect(() => {
    if (permanentAddressSameAsCurrent) {
      setPermanentAddress(currentAddress);
    }
  }, [permanentAddressSameAsCurrent, currentAddress]);

  const handleParentChange = (type: ParentType) => {
    if (type === ParentType.Father) {
      return setFather;
    }
    if (type === ParentType.Mother) {
      return setMother;
    }
    return setGuardian;
  };

  const getParentValue = (type: ParentType, key: keyof IParent) => {
    if (type === ParentType.Father) {
      return father[key];
    }
    if (type === ParentType.Mother) {
      return mother[key];
    }
    return guardian[key];
  };

  const getParentFields = (type: ParentType) => {
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.TEXT,
          value: getParentValue(type, "name"),
          onChange: (v) => handleParentChange(type)((p) => ({ ...p, name: v })),
          extras: {
            label: "Name",
          },
          required: true,
        },
      ],
      [
        {
          type: FormFieldType.TEXT,
          value: getParentValue(type, "email"),
          onChange: (v) =>
            handleParentChange(type)((p) => ({ ...p, email: v })),
          extras: {
            label: "Email",
          },
        },
        {
          type: FormFieldType.TEXT,
          value: getParentValue(type, "mobile"),
          onChange: (v) =>
            handleParentChange(type)((p) => ({ ...p, mobile: v })),
          extras: {
            label: "Mobile",
          },
          required: true,
        },
      ],
      [
        {
          type: FormFieldType.TEXT,
          value: getParentValue(type, "qualification"),
          onChange: (v) =>
            handleParentChange(type)((p) => ({ ...p, qualification: v })),
          extras: {
            label: "Qualification",
          },
        },
        {
          type: FormFieldType.TEXT,
          value: getParentValue(type, "occupation"),
          onChange: (v) =>
            handleParentChange(type)((p) => ({ ...p, occupation: v })),
          extras: {
            label: "Occupation",
          },
        },
      ],
      [
        {
          type: FormFieldType.TEXT,
          value: getParentValue(type, "company_name"),
          onChange: (v) =>
            handleParentChange(type)((p) => ({ ...p, company_name: v })),
          extras: {
            label: "Company",
          },
        },
        {
          type: FormFieldType.TEXT,
          value: getParentValue(type, "designation"),
          onChange: (v) =>
            handleParentChange(type)((p) => ({ ...p, designation: v })),
          extras: {
            label: "Designation",
          },
        },
        {
          type: FormFieldType.TEXT,
          value: getParentValue(type, "income"),
          onChange: (v) =>
            handleParentChange(type)((p) => ({ ...p, income: v })),
          extras: {
            label: "Annual Income",
          },
        },
      ],
    ];
    if (!canEdit) {
      allFields = allFields.map((fields) =>
        fields.map((f) => ({ ...f, extras: { ...f.extras, disabled: true } }))
      );
    }
    return allFields;
  };

  const getMiscellaneousFields = () => {
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.SELECT,
          value: religion,
          onChange: setReligion,
          extras: {
            label: "Religion",
            options: Object.values(Religion).map((b) => ({
              label: b,
              value: b,
            })),
            searchable: true,
          },
        },
        {
          type: FormFieldType.SELECT,
          value: category,
          onChange: setCategory,
          extras: {
            label: "Category",
            options: Object.values(Category).map((b) => ({
              label: b,
              value: b,
            })),
            searchable: true,
          },
        },
        {
          type: FormFieldType.SELECT,
          value: disablity,
          onChange: setDisablity,
          extras: {
            label: "Disability",
            options: Object.values(Disablity).map((b) => ({
              label: b,
              value: b,
            })),
            searchable: true,
          },
        },
      ],
      [
        // {
        //   type: FormFieldType.TOGGLE,
        //   value: isSingleParent,
        //   onChange: setIsSingleParent,
        //   extras: {
        //     label: "Is Single Parent Child?",
        //   },
        // },
        // {
        //   type: FormFieldType.TOGGLE,
        //   value: isSponsored,
        //   onChange: setIsSponsored,
        //   extras: {
        //     label: "Is Sponsored Student?",
        //   },
        // },
        // {
        //   type: FormFieldType.CUSTOM,
        //   value: "",
        // },
        // {
        //   type: FormFieldType.CUSTOM,
        //   value: "",
        // },
      ],
      [
        {
          type: FormFieldType.SELECT,
          value: singleParent,
          onChange: setSingleParent,
          extras: {
            label: "Single Parent",
            options: Object.keys(SingleParent).map((b) => ({
              label: b,
              value: b,
            })),
            // disabled: !isSingleParent,
          },
        },
        {
          type: FormFieldType.TEXT,
          value: sponsor,
          onChange: setSponsor,
          extras: {
            label: "Sponsor Name",
            // disabled: !isSponsored,
          },
        },
        {
          type: FormFieldType.CUSTOM,
          value: "",
        },
        {
          type: FormFieldType.CUSTOM,
          value: "",
        },
      ],
      [
        {
          type: FormFieldType.SELECT,
          value: studentDetails?.applicable_fees ?? [],
          onChange: (v) =>
            setStudentDetails({ ...studentDetails, applicable_fees: v ?? [] }),
          extras: {
            options: feeStructures.map((f) => ({
              label: `${f.name} - ${f.total_amount}`,
              value: f._id,
            })),
            label: "Applicable Fees",
            multi: true,
          },
        },
      ],
    ];
    if (!canEdit) {
      allFields = allFields.map((fields) =>
        fields.map((f) => ({ ...f, extras: { ...f.extras, disabled: true } }))
      );
    }
    return allFields;
  };

  const handleDeleteStudent = async () => {
    await catchRequest(async () => {
      await service.deleteStudent(id as string);
      pushNotification({
        title: "Success",
        message: "Student archived successfully",
        type: "success",
      });
      navigate("/c/students");
    }, pushNotification)();
  };

  return (
    <CustomCard title={id ? "Update Student" : "Add Student"}>
      <ExpandableCard
        headerClassName="bg-custom-grey border-custom-grey"
        className="-mt-3 pt-0"
        title="Basic Details"
        defaultExpanded
        renderBody={(toggle, isOpen, inTransition) => (
          <div className="border-2 -mt-4 py-2 pt-3 px-2 ">
            <FormGenerator getFields={basicDetailsGetFields} />
          </div>
        )}
      />
      <ExpandableCard
        headerClassName="bg-custom-grey border-custom-grey"
        className="pt-0"
        title="Address"
        renderBody={(toggle, isOpen, inTransition) => (
          <div className="border-2 -mt-4 py-2 pt-3 px-2">
            <b>Current Address</b>
            <AddressEditor
              address={currentAddress}
              onChange={setCurrentAddress}
              canEdit={canEdit}
            />
            <div className="mt-4"></div>
            <b>Permanent Address</b>
            <ToggleButton
              label="Same as Current Address"
              check={permanentAddressSameAsCurrent}
              onChange={(v) => setPermanentAddressSameAsCurrent(v)}
              disabled={!canEdit}
            />
            <div hidden={permanentAddressSameAsCurrent}>
              <AddressEditor
                address={permanentAddress}
                onChange={setPermanentAddress}
                canEdit={canEdit}
              />
            </div>
          </div>
        )}
      />
      <ExpandableCard
        headerClassName="bg-custom-grey border-custom-grey"
        className="pt-0"
        title="Parent Details (Father)"
        renderBody={(toggle, isOpen, inTransition) => (
          <div className="border-2 -mt-4 py-2 pt-3 px-2">
            <FormGenerator
              getFields={() => getParentFields(ParentType.Father)}
            />
          </div>
        )}
      />
      <ExpandableCard
        headerClassName="bg-custom-grey border-custom-grey"
        className="pt-0"
        title="Parent Details (Mother)"
        renderBody={(toggle, isOpen, inTransition) => (
          <div className="border-2 -mt-4 py-2 pt-3 px-2">
            <FormGenerator
              getFields={() => getParentFields(ParentType.Mother)}
            />
          </div>
        )}
      />
      <ExpandableCard
        headerClassName="bg-custom-grey border-custom-grey"
        className="pt-0"
        title="Guardian Details"
        renderBody={(toggle, isOpen, inTransition) => (
          <div className="border-2 -mt-4 py-2 pt-3 px-2">
            <FormGenerator
              getFields={() => getParentFields(ParentType.Guardian)}
            />
          </div>
        )}
      />
      <ExpandableCard
        headerClassName="bg-custom-grey border-custom-grey"
        className="pt-0"
        title="Miscallaneous Details"
        renderBody={(toggle, isOpen, inTransition) => (
          <div className="border-2 -mt-4 py-2 pt-3 px-2">
            <FormGenerator getFields={() => getMiscellaneousFields()} />
          </div>
        )}
      />

      <ExpandableCard
        headerClassName="bg-custom-grey border-custom-grey"
        className="pt-0"
        title="Medical Records"
        renderBody={(toggle, isOpen, inTransition) => (
          <div className="border-2 -mt-4 py-2 pt-3 px-2">
            <div hidden={!canEdit} className="mb-3">
              <Button
                color="accent"
                isSlim
                className="px-2 py-1 text-sm"
                icon={<PlusIcon className="h-4 w-4 mr-2" />}
                label="Add record"
                onClick={() =>
                  setMedicalRecords((p) => [
                    ...p,
                    { date: moment().format(DEFAULT_DATE_FORMAT) },
                  ])
                }
              />
            </div>
            {medicalRecords.map((m, i) => (
              <div
                className="mb-3 bg-template-card px-3 pt-2 rounded-md"
                key={i}
              >
                <MedicalRecordEditor
                  medicalRecord={m}
                  onChange={(fn: any) =>
                    setMedicalRecords((p) =>
                      p.map((m, index) => (index === i ? fn(m) : m))
                    )
                  }
                  canEdit={canEdit}
                />
                <div className="d-flex justify-content-end py-2 py-3">
                  <div hidden={!canEdit}>
                    <ArchiveButton
                      label="Archive"
                      dialogContent="Are you sure you want to archive this medical record?"
                      dialogTitle="Archive Medical Record?"
                      onClick={() =>
                        setMedicalRecords((p) =>
                          p.filter((_, index) => index !== i)
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      />

      <Row className="justify-content-end align-items-center px-3 mb-4">
        <Col hidden={!id} md="auto" sm="auto" xs="auto" className="p-0">
          <PermissionRestrictedComponent
            permissions={[Permissions.ARCHIVE_STUDENT]}
          >
            <ArchiveButton
              dialogTitle="Archive Student?"
              dialogContent="Are you sure you want to archive this student?"
              label="Archive Student"
              onClick={() => handleDeleteStudent()}
            />
          </PermissionRestrictedComponent>
        </Col>
        <Col md="auto" sm="auto" xs="auto" className="">
          <PermissionRestrictedComponent
            permissions={[
              Permissions.UPDATE_STUDENT,
              Permissions.CREATE_STUDENT,
            ]}
          >
            <SaveButton
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            />
          </PermissionRestrictedComponent>
        </Col>
      </Row>
    </CustomCard>
  );
}
