import API from "../api";
import { IChatMessageExtended } from "crm_core";

export class ChatMessageService extends API {
  static NAME = "ChatMessageService";

  constructor() {
    super("/chat-message");
  }

  async createChatMessage(
    room: string,
    data: string
  ): Promise<IChatMessageExtended> {
    const res = await this._post({ body: { room, data } });
    return res.data as IChatMessageExtended;
  }
}
