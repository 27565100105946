import { getAuthBasedRoutes } from "crm_core";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "src/components/navbar/header";
import BottomNavigation from "src/components/sidebar/bottom_navigation";
import { useAuth } from "src/context/auth_context";
import { usePageState } from "src/context/page_state_context";
import { IRouteData, getRoutes, routesData } from "src/routes";

export default function MobileCompanyLayout() {
  const { selectedAuthorization } = useAuth();
  const { headless } = usePageState();
  const LAYOUT_NAME = "c";
  const [bottomNavHeight, setBottomNavHeight] = useState("70px");
  const [headerHeight, setHeaderHeight] = useState("60px");

  const roleBasedRoutes = getAuthBasedRoutes<IRouteData>(
    routesData.filter((route) => route.layout === LAYOUT_NAME),
    selectedAuthorization
  );

  const bottomNavRoutes = roleBasedRoutes.filter((route) => route.icon);
  let defaultRoute = "/c/dashboard";
  if (bottomNavRoutes.length > 0) {
    defaultRoute = `/${bottomNavRoutes[0].layout}${bottomNavRoutes[0].path}`;
  }

  useEffect(() => {
    if (headless) {
      setBottomNavHeight("0px");
      setHeaderHeight("0px");
    }
  }, [headless]);

  return (
    <div>
      <Header height={headerHeight} brandWidth="50px" />
      <div
        style={{
          position: "fixed",
          top: headerHeight,
          bottom: bottomNavHeight,
          width: `100%`,
          height: `calc(100% - ${headerHeight} - ${bottomNavHeight})`,
          overflow: "scroll",
        }}
      >
        <div style={{ minHeight: "100%" }} className="bg-off-white">
          <Routes>
            {getRoutes(roleBasedRoutes)}
            <Route
              path="/"
              element={
                <Navigate
                  to={{ ...location, pathname: defaultRoute }}
                  replace
                />
              }
            />
          </Routes>
        </div>
      </div>
      <BottomNavigation routes={bottomNavRoutes} height={bottomNavHeight} />
    </div>
  );
}
