import { Menu } from "@headlessui/react";
import Dropdown from "../navbar/dropdown";
import { joinClasses } from "src/utils/functions";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { Col, Row } from "react-bootstrap";
import { actionItemClassname } from "src/utils/constants";

export interface BulkAction {
  icon?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  label: string | JSX.Element;
  onClick: () => void;
}

export interface ActionButtonProps {
  actions: BulkAction[];
}

export default function ActionButton({ actions }: ActionButtonProps) {
  return (
    <Dropdown
      className=""
      buttonClassName="pl-2 py-1 pr-1 rounded-md bg-accent hover:bg-accent-900 text-white"
      buttonChild={<div>Actions</div>}
      menuStyle={{
        // minWidth: "210px",
        width: "300px",
        marginTop: "8px",
        zIndex: 102,
        borderRadius: "20px",
        backgroundColor: "#fff",
        padding: "5px 5px",
      }}
    >
      {actions.map((action, idx) => (
        <Menu.Item key={`action-${idx}`}>
          {({ active }: any) => (
            <div className="w-full">
              <div
                onClick={action.onClick}
                className={joinClasses(
                  active ? "" : "",
                  "pl-3 pr-3 py-2 w-full hover:bg-light-accent text-muted hover:text-black cursor-pointer rounded-md"
                )}
              >
                <Row className="align-items-center">
                  <Col className="col-auto">
                    {action.icon ? (
                      <action.icon className={actionItemClassname} />
                    ) : (
                      <ArrowRightIcon className={actionItemClassname} />
                    )}
                  </Col>
                  <Col className="col-auto pl-0">
                    <h6 className="m-0">{action.label}</h6>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Menu.Item>
      ))}
    </Dropdown>
  );
}
