import { ITest, TestStatus } from "crm_core";
import { Badge, Col, Row } from "react-bootstrap";

interface TestCardProps {
  test: ITest;
  href?: string;
}

export default function TestCard({ test, href }: TestCardProps) {
  const statusToColor = (status: string) => {
    switch (status) {
      case TestStatus.DRAFT:
        return "secondary";
      case TestStatus.PUBLISHED:
        return "accent";
      case TestStatus.ARCHIVED:
        return "danger";
    }
  };

  return (
    <a href={href} className="text-decoration-none text-inherit">
      <div className="py-3 px-3 bg-custom-grey rounded-lg hover:shadow-md cursor-pointer">
        <Row className="align-items-center">
          <Col lg="auto" md="auto" sm="auto" xs="auto">
            <h5 className="m-0 font-light">{test.name}</h5>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg="auto" md="auto" sm="auto" xs="auto" className="mt-2">
            <Badge bg={statusToColor(test.status)}>
              {test.status.toUpperCase()}
            </Badge>
          </Col>
        </Row>
      </div>
    </a>
  );
}
