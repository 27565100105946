import { XMarkIcon } from "@heroicons/react/20/solid";
import { IOption, QuestionType } from "crm_core";
import { Col, FormCheck, Row } from "react-bootstrap";
import Button from "src/components/buttons/button";
import MarkDownEditor from "src/components/text_fields/markdown_editor";

interface OptionProps {
  type: QuestionType;
  option: IOption;
  editable?: boolean;
  onChange?: (option: IOption) => void;
  isSelected?: boolean;
  onSelect?: (value: boolean) => void;
  onDelete?: () => void;
  submitter?: boolean;
}

export default function Option({
  type,
  option,
  editable,
  onChange,
  onSelect,
  isSelected,
  onDelete,
  submitter,
}: OptionProps) {
  return (
    <Row className="m-0 p-0 align-items-center">
      <Col md="auto" sm="auto" xs="auto" className="my-0 p-0 mr-2">
        <FormCheck
          type={type == QuestionType.SingleAnswer ? "radio" : "checkbox"}
          disabled={!editable && !submitter}
          className="w-5 h-5 text-accent"
          onChange={(e) => onSelect?.(e.target.checked)}
          checked={isSelected}
        />
      </Col>
      <Col className="m-0 p-0">
        <MarkDownEditor
          viewer={!editable}
          disabled={!editable}
          value={option.text}
          onChange={(v) => {
            onChange?.({ ...option, text: v });
          }}
        />
      </Col>
      <Col
        hidden={!editable}
        md="auto"
        sm="auto"
        xs="auto"
        className="my-0 p-0 ml-2"
      >
        <Button
          rounded
          className="h-6 w-6"
          color="yellow"
          icon={<XMarkIcon className="h-5 w-5" />}
          onClick={onDelete}
        />
      </Col>
    </Row>
  );
}
