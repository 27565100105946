import { FCMPlatform } from "crm_core";

export const CURRENT_PLATFORM = FCMPlatform.Web;

export const actionItemClassname = "h-5 w-5 text-accent";

export enum FormFieldType {
  TEXT = "text",
  OBSCURED_TEXT = "obscured_text",
  TEXTAREA = "textarea",
  DATE = "date",
  DATETIME = "datetime",
  DATETIMERANGE = "datetimerange",
  DATERANGE = "daterange",
  TIMERANGE = "timerange",
  MARKDOWN = "markdown",
  SLIDER = "slider",
  NUMBER = "number",
  SELECT = "select",
  TOGGLE = "toggle",
  CUSTOM = "custom",
}

export const ProjectStatuses = ["Not Started", "In Progress", "Completed"];

export const ClientStatuses = ["Active", "Inactive", "Archived"];

export const LocalStorageKeys = {
  AUTH_TOKEN: "authToken",
  USER: "user",
  ORGANIZATION: "organization",
  ORG_ID: "orgId",
  SESSION_ID: "sessionId",
  AUTH_ID: "authId",
  REDIRECT: "redirect",
  LAST_NOTIFIED: "lastNotified",
  MESSAGING_TOKEN: "messagingToken",
};

export enum FilterKeys {
  BATCH = "batch",
  CLASSROOM = "classroom",
  STUDENT = "student",
  STAFF_MEMBER = "staffMember",
  TEST = "test",
  TESTS_IN_STUDENT = "testsInStudent",
  STUDENT_LIST_IN_CLASSROOM = "studentListInClassroom",
  STUDENT_LIST_IN_ASSOCIATED_CLASSROOMS = "studentListInAssociatedClassrooms",
  STUDENT_LIST_IN_DEPARTMENT = "studentListInDepartment",
  STUDENT_LIST_IN_BATCH = "studentListInBatch",
  STUDENT_IN_STAFF_MEMBER = "studentInStaffMember",
  BATCH_LIST_IN_DEPARTMENT = "batchListInDepartment",
  CLASSROOM_LIST_IN_BATCH = "classroomListInBatch",
  CLASSROOM_LIST_IN_DEPARTMENT = "classroomListInDepartment",
  CLASSROOM_LIST_IN_STUDENT = "classroomListInStudent",
  CLASSROOM_LIST_IN_STAFF_MEMBER = "classroomListInStaffMember",
  SHIFTS_LIST = "shiftsList",

  TESTS_IN_CLASSROOM = "testsInClassroom",
  FEE_STRUCTURES = "feeStructures",
  FEE_COLLECTIONS = "feeCollections",

  HOMEWORK = "homework",
  HOMEWORK_IN_CLASSROOM = "homeworkInClassroom",

  QUESTIONS_IN_TOPIC = "questionsInTopic",
}

export const tabClassName =
  "p-0 py-1 px-3 font-sm mr-1 mb-3 aria-selected:bg-accent aria-selected:text-white text-accent";
