import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";

const pageStateContext = createContext<PageStateContextProps>({} as any);

export function usePageState() {
  return useContext(pageStateContext);
}

export function PageStateProvider({ children }: any) {
  return (
    <pageStateContext.Provider value={usePageStateProvider()}>
      {children}
    </pageStateContext.Provider>
  );
}

function usePageStateProvider() {
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
  const [chatNotifications, setChatNotifications] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [headless, setHeadless] = useState(false);

  useEffect(() => {
    const headless = searchParams.get("headless");
    setHeadless(!!headless)
  }, []);


  const fn = () => setIsSmallScreen(window.innerWidth <= 1000);

  useEffect(() => {
    fn();
    window.addEventListener("resize", fn);
    return () => {
      window.removeEventListener("resize", fn);
    };
  }, []);

  const scrollToAnchor = () => {
    const hashParts = window.location.hash.split("#");
    if (hashParts.length >= 2) {
      const hash = hashParts.slice(-1)[0];
      document.querySelector(`#${hash}`)?.scrollIntoView();
    }
  };

  useEffect(() => {
    window.location.hash = window.decodeURIComponent(window.location.hash);
    scrollToAnchor();
    window.onhashchange = scrollToAnchor;
    return () => {
      window.onhashchange = null;
    };
  }, []);

  return {
    isSmallScreen,
    scrollToAnchor,
    chatNotifications,
    setChatNotifications,
    headless
  } as PageStateContextProps;
}

interface PageStateContextProps {
  isSmallScreen: boolean;
  scrollToAnchor: () => void;
  chatNotifications: number;
  setChatNotifications: Dispatch<SetStateAction<number>>;
  headless: boolean;
}
