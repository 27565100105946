import API from "../api";
import { ISession } from "crm_core"

export class SessionService extends API {
  static NAME = "SessionService";

  constructor() {
    super("/session");
  }
  async getSessions(): Promise<ISession[]> {
    const res = await this._get({});
    return res.data as ISession[];
  }

  async getSession(id: string): Promise<ISession> {
    const res = await this._get({ url: `/${id}` });
    return res.data as ISession;
  }

  async createSession(project: ISession): Promise<ISession> {
    const res = await this._post({ body: project });
    return res.data as ISession;
  }

  async updateSession(project: ISession): Promise<ISession> {
    const res = await this._put({ url: `/${project._id}`, body: project });
    return res.data as ISession;
  }

  async deleteSession(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }
}
