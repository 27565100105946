import { IOrganization } from "../../hierarchy";
import { IExportConfig } from "../../utils";
import { IStaffMember } from "../lms";

export interface IAttendanceShift {
  _id?: string;
  name: string;
  check_in_time: string;
  check_out_time: string;
  grace_minutes: number;
  max_grace_per_month: number;
  organization?: string | IOrganization;
  days: string[];
}

export interface IAttendanceShiftResponse extends IAttendanceShift {
  _id: string;
  people: IStaffMember[];
}

export const ExportAttendanceShiftConfig: IExportConfig<IAttendanceShift> = {
  fileName: "Attendance Shifts.xlsx",
  headers: [
    { label: "Name", key: "name" },
    { label: "Check In Time", key: "check_in_time" },
    { label: "Check Out Time", key: "check_out_time" },
    { label: "Grace Period (Minutes)", key: "grace_minutes" },
    {
      label: "Maximum use of grace period per month",
      key: "max_grace_per_month",
    },
    { label: "Days", key: "days" },
  ],
};
