import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Aux from "src/components/aux";
import Button from "src/components/buttons/button";
import CustomCard from "src/components/custom_card";
import CustomDataTable, {
  CustomTableColumn,
} from "src/components/custom_data_table";
import { ModalTypes, useModal } from "src/context/modal_context";
import { useTable } from "src/context/table_context";
import Filters from "src/features/filters";
import { FeeRecordService } from "src/services/institute/fees/record";
import { PaginatedGetter } from "src/services/paginated_getter";
import { FilterKeys } from "src/utils/constants";
import { AddUpdateFeePaymentProps } from "./add_update_payment";
import HelpTip from "src/components/helptip";
import moment from "moment";
import { IFeePayment, IFeePaymentExtended, PaymentStatus } from "crm_core";
import { PaymentStatusBadge } from "src/components/widgets/badges";

interface FeeCollectionProps {
  tableOnly?: boolean;
}

type TableData = IFeePaymentExtended & {
  aggregate?: boolean;
};

export default function FeeCollection({ tableOnly }: FeeCollectionProps) {
  const FILTER_KEY = FilterKeys.FEE_COLLECTIONS;
  const paginatedGetter = new PaginatedGetter<FeeRecordService, IFeePayment>(
    FeeRecordService
  );
  const [data, setData] = useState<TableData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    getPaginationPreferences,
    getSortPreferences,
    getFilterPreferences,
    paginationPreferences,
    sortPreferences,
    filterPreferences,
    updateTotalRows,
  } = useTable();
  const {
    setModalType,
    setModalSize,
    setModalHeading,
    setComponentProps,
    setShowModal,
  } = useModal();

  const fetchData = async () => {
    let config = {
      pagination: getPaginationPreferences(FILTER_KEY),
      sort: getSortPreferences(FILTER_KEY),
      filters: getFilterPreferences(FILTER_KEY),
    };
    setLoading(true);
    const data = await paginatedGetter.get(config, { extended: true });
    setData([{ aggregate: true, ...data.aggregate } as any, ...data.results]);
    updateTotalRows(FILTER_KEY, data.totalCount);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [paginationPreferences, sortPreferences, filterPreferences]);

  const handleAddUpdateFeePayment = (record: IFeePaymentExtended) => {
    setModalType(ModalTypes.ADD_UPDATE_FEE_PAYMENT);
    setModalSize("lg");
    setModalHeading(record ? "Collect Fee" : "Update Fee Payment");
    let props: AddUpdateFeePaymentProps = {
      onComplete: () => {
        fetchData();
        setShowModal(false);
      },
      feeRecord: record,
    };
    setComponentProps(props);
    setShowModal(true);
  };

  const columns: CustomTableColumn<TableData>[] = [
    {
      name: "Status",
      cell: (row) =>
        row.aggregate ? "" : <PaymentStatusBadge status={row.status} />,
    },
    {
      name: "Student",
      cell: (row) =>
        row.aggregate
          ? "Total"
          : row.student.first_name + " " + row.student.last_name,
      sortable: true,
      width: "300px",
      sortField: "student.first_name,student.last_name",
    },
    {
      name: "Fee",
      cell: (row) =>
        row.aggregate ? "" : `${row.structure.name} - ${row.installment}`,
      sortable: true,
      width: "300px",
      sortField: "structure.name",
    },
    {
      name: "Paid Amount",
      cell: (row) =>
        row.aggregate
          ? row.paid_amount
          : row.status == PaymentStatus.PAID
          ? row.paid_amount
          : "--",
      width: "100px",
      sortable: true,
      sortField: "fine",
    },
    {
      name: "Total Amount",
      cell: (row) => (
        <div>
          {row.amount}{" "}
          <span hidden={!row.fine} className="text-danger">
            <HelpTip
              id={
                row.aggregate
                  ? "aggregate-late-fee"
                  : `${row.structure._id} ${row.student.enrollment_no}`
              }
              text="Late Fee"
            >
              <span>+ {row.fine}</span>
            </HelpTip>
          </span>
        </div>
      ),
      width: "150px",
      sortable: true,
      sortField: "amount",
    },
    {
      name: "Due Date",
      cell: (row) =>
        row.aggregate ? (
          ""
        ) : (
          <div>{moment(row.due_date).format("DD MMM YYYY")}</div>
        ),
      width: "150px",
      sortable: true,
      sortField: "due_date",
    },
    {
      name: "Discount",
      cell: (row) =>
        row.aggregate || row.status == PaymentStatus.PAID ? row.discount : "--",
      width: "100px",
      sortable: true,
      sortField: "discount",
    },
    {
      name: "Paid Fine",
      cell: (row) => (row.aggregate ? row.paid_fine : row.paid_fine ?? "--"),
      width: "100px",
      sortable: true,
      sortField: "paid_fine",
    },
    {
      name: "Enrollment No",
      cell: (row) => (row.aggregate ? "" : row.student.enrollment_no),
      sortable: true,
      width: "150px",
      sortField: "student.enrollment_no",
    },
    {
      name: "Mobile",
      cell: (row) => (row.aggregate ? "" : row.student.mobile),
      sortable: true,
      width: "150px",
      sortField: "student.mobile",
    },
    {
      name: "Department",
      cell: (row) =>
        row.aggregate ? "" : row.student.department?.name ?? "Not Assigned",
      width: "200px",
    },
    {
      name: "Batch",
      cell: (row) =>
        row.aggregate ? "" : row.student.batch?.name ?? "Not Assigned",
      width: "150px",
    },
    {
      name: "Actions",
      cell: (row) =>
        row.aggregate ? (
          ""
        ) : (
          <div>
            <Row className="m-0">
              <Col className="p-0">
                <Button
                  disabled={
                    row.status == PaymentStatus.PAID ||
                    (row.amount === 0 && row.fine === 0)
                  }
                  isSlim
                  label="Collect Fee"
                  className="px-2 py-1 text-sm"
                  color="accent"
                  onClick={() => handleAddUpdateFeePayment(row)}
                />
              </Col>
            </Row>
          </div>
        ),
      width: "200px",
    },
  ];

  const getTableView = () => {
    return (
      <Aux>
        <Filters filterKey={FILTER_KEY} />
        <CustomDataTable
          tableKey={FILTER_KEY}
          columns={columns}
          data={data}
          progressPending={loading}
          conditionalRowStyles={[
            {
              when: (row) => row.status === PaymentStatus.OVERDUE,
              style: {
                backgroundColor: "#f8d7da",
              },
            },
            {
              when: (row) => row.aggregate === true,
              style: {
                minHeight: "35px",
                backgroundColor: "#fafafa",
                fontWeight: "bold",
              },
            },
          ]}
          selectableRowDisabled={(row) => row.aggregate === true}
        />
      </Aux>
    );
  };

  if (tableOnly) {
    return getTableView();
  }

  return (
    <CustomCard title="Fee Collection" className="">
      {getTableView()}
    </CustomCard>
  );
}
