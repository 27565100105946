import API from "../../api";
import { IQuestion } from "crm_core";

export class QuestionService extends API {
  constructor() {
    super("/question");
  }
  async getQuestions(): Promise<IQuestion[]> {
    const res = await this._get({});
    return res.data as IQuestion[];
  }

  async getQuestion(id: string): Promise<IQuestion> {
    const res = await this._get({ url: `/${id}` });
    return res.data as IQuestion;
  }

  async createQuestion(question: IQuestion): Promise<IQuestion> {
    const res = await this._post({ body: question });
    return res.data as IQuestion;
  }

  async updateQuestion(question: Partial<IQuestion>): Promise<IQuestion> {
    const res = await this._put({ url: `/${question._id}`, body: question });
    return res.data as IQuestion;
  }

  async deleteQuestion(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }
}
