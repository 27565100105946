import { IOrganization, IUser } from "../../../hierarchy";
import { ISession } from "../academics";

export enum BulkImportDataType {
  STUDENT = "student",
  STAFF_MEMBERS = "staff_members",
  STAFF_ATTENDANCE_FOR_DAY = "staff_attendance_for_day",
  WORK_SHIFTS = "work_shifts",
  STAFF_ATTENDANCE_REPORT = "staff_attendance_report",
  QUESTION_BANK = "question_bank",
  COURSE_WITH_SUBJECTS_AND_QUESTIONS = "course_with_subjects_and_questions",
}

export interface IImportRecord {
  _id?: string;
  key: string;
  type: BulkImportDataType;
  organization?: string | IOrganization;
  session?: string | ISession;
  created_by?: string | IUser;
  params?: any;
}

export interface IImportRecordExtended extends IImportRecord {
  created_by: IUser;
}
