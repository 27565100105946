import { Col, Row } from "react-bootstrap";
import { Routes, Route, Navigate } from "react-router-dom";
import { Loader } from "src/components/loader";
import Header from "src/components/navbar/header";
import SideBar from "src/components/sidebar";
import { useAuth } from "src/context/auth_context";
import { usePageState } from "src/context/page_state_context";
import { IRouteData, getRoutes, routesData } from "src/routes";
import MobileCompanyLayout from "./mobile";
import { UserRole, getAuthBasedRoutes, getUserFullName } from "crm_core";
import { useEffect } from "react";

export default function CompanyLayout({}: any) {
  const { isSmallScreen } = usePageState();
  const { messagingToken, user, selectedOrganization } = useAuth();
  const { selectedAuthorization } = useAuth();
  const LAYOUT_NAME = "c";
  let SIDEBAR_WIDTH = isSmallScreen ? "50px" : "5vw";
  const HEADER_HEIGHT = isSmallScreen ? "60px" : "7vh";

  const roleBasedRoutes = getAuthBasedRoutes<IRouteData>(
    routesData.filter((route) => route.layout === LAYOUT_NAME),
    selectedAuthorization
  );

  const siderBarRoutes = roleBasedRoutes.filter((route) => route.icon);
  let defaultRoute = "/c/dashboard";
  if (siderBarRoutes.length > 0) {
    defaultRoute = `/${siderBarRoutes[0].layout}${siderBarRoutes[0].path}`;
  }

  const setMessagingUser = () => {
    (window as any).$chatwoot?.setUser(user._id, {
      name: getUserFullName(user),
      email: user.email,
      identifier_hash: messagingToken,
      avatar_url: user.photo,
      phone_number: user.phone,
      company_name: selectedOrganization?.name,
      description: user.phone,
    });
    (window as any).chatwootSettings = {
      showPopoutButton: true,
    };
  };

  if (
    [UserRole.StaffMember, UserRole.OrganizationAdmin].includes(
      selectedAuthorization?.role
    )
  ) {
    const elem = "script";
    var BASE_URL = "https://communication.teachbond.com";
    var g = document.createElement(elem),
      s = document.getElementsByTagName(elem)[0];
    g.src = BASE_URL + "/packs/js/sdk.js";
    g.defer = true;
    g.async = true;
    s.parentNode?.insertBefore(g, s);
    g.onload = function () {
      (window as any).chatwootSDK.run({
        websiteToken: "8W63tm7aCGxLnhj9mdzQndsw",
        baseUrl: BASE_URL,
      });
      setMessagingUser();
    };
  }

  useEffect(() => {
    if (
      selectedAuthorization &&
      [UserRole.StaffMember, UserRole.OrganizationAdmin].includes(
        selectedAuthorization?.role
      ) &&
      messagingToken
    ) {
      setMessagingUser();
    }
  }, [messagingToken, (window as any).$chatwoot, selectedAuthorization]);

  return (
    <div className="bg-off-white">
      {/* <div style={{position: "fixed", bottom: 15, right: 15, zIndex: 100, overflow: "hidden"}}>
        <img
          src="https://teachbondprod.blob.core.windows.net/logos/logo-no-background.png"
          alt="logo"
          style={{ height: "30px" }}
        />
      </div> */}
      {!selectedAuthorization && (
        <Row style={{ height: "100vh", paddingTop: "40vh" }}>
          <Col>
            <Loader text="" />
          </Col>
        </Row>
      )}
      {selectedAuthorization && isSmallScreen && <MobileCompanyLayout />}
      {selectedAuthorization && !isSmallScreen && (
        <>
          <Header
            height={HEADER_HEIGHT}
            brandWidth={`calc(${SIDEBAR_WIDTH}+${SIDEBAR_WIDTH}+${SIDEBAR_WIDTH})`}
          />
          <SideBar
            routes={siderBarRoutes}
            fixedWidth={SIDEBAR_WIDTH}
            variableWidth="10vw"
            marginTop={HEADER_HEIGHT}
          />
          <div
            style={{
              position: "fixed",
              top: HEADER_HEIGHT,
              left: SIDEBAR_WIDTH,
              width: `calc(100% - ${SIDEBAR_WIDTH})`,
              height: `calc(100% - ${HEADER_HEIGHT})`,
              overflow: "scroll",
            }}
          >
            <div
              style={{ minHeight: "100%", paddingBottom: "60px" }}
              className="bg-off-white"
            >
              <Routes>
                {getRoutes(roleBasedRoutes)}
                <Route
                  path="/"
                  element={
                    <Navigate
                      to={{ ...location, pathname: defaultRoute }}
                      replace
                    />
                  }
                />
              </Routes>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
