import { useEffect, useState } from "react";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useAuth } from "src/context/auth_context";
import { useNotification } from "src/context/notification_context";
import { AttendanceShiftService } from "src/services/institute/attendance/shift";
import { FormFieldType } from "src/utils/constants";
import { validateFields } from "src/utils/functions";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import { Days, IAttendanceShift, Permissions, dayOptions } from "crm_core";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import ArchiveButton from "src/components/widgets/archive_button";
import SaveButton from "src/components/widgets/save_button";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";

export interface AddUpdateAttendanceShiftProps {
  onComplete?: () => void;
  id?: string;
  data?: IAttendanceShift;
}

export default function AddUpdateAttendanceShift({
  onComplete,
  id,
  data,
}: AddUpdateAttendanceShiftProps) {
  const service = new AttendanceShiftService();
  const { selectedOrganization } = useAuth();
  const [shift, setShift] = useState<IAttendanceShift>({
    name: "",
    check_in_time: "09:00 am",
    check_out_time: "05:00 pm",
    grace_minutes: 10,
    max_grace_per_month: 3,
    days: [
      Days.Monday,
      Days.Tuesday,
      Days.Wednesday,
      Days.Thursday,
      Days.Friday,
    ],
  });
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();
  const { fetchDataForUseCase, departments, selectedSession } =
    useDataProvider();

  useEffect(() => {
    if (selectedSession) fetchDataForUseCase(DataUseCase.INSTITUTE);
  }, [selectedSession]);

  useEffect(() => {
    const fetchAttendanceShift = async (id: string) => {
      setLoading(true);
      const currentAttendanceShift = data
        ? data
        : await service.getAttendanceShift(id);
      setShift(currentAttendanceShift);
      setLoading(false);
    };
    if (id) {
      fetchAttendanceShift(id);
    }
  }, [id]);

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(getFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);
    let update: any = {
      name: shift.name,
      check_in_time: shift.check_in_time,
      check_out_time: shift.check_out_time,
      grace_minutes: shift.grace_minutes,
      max_grace_per_month: shift.max_grace_per_month,
      days: shift.days,
    };

    if (id) {
      await service.updateAttendanceShift({
        _id: id,
        ...update,
      });
      pushNotification({
        title: "Success",
        message: "Shift updated successfully",
        type: "success",
      });
    } else {
      await service.createAttendanceShift({
        ...update,
        organization: selectedOrganization?._id as any,
      });
    }
    setLoading(false);
    onComplete?.();
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.TEXT,
          value: shift.name,
          onChange: (v) => setShift({ ...shift, name: v }),
          extras: {
            placeholder: "Shift Name",
            label: "Shift Name",
          },
          required: true,
          updatable: true,
        },
      ],
      [
        {
          type: FormFieldType.TIMERANGE,
          value: {},
          extras: {
            start: shift.check_in_time,
            end: shift.check_out_time,
            onChange: (start: string, end: string) => {
              console.log(start, end);
              setShift({
                ...shift,
                check_in_time: start,
                check_out_time: end,
              });
            },
          },
          required: true,
          updatable: true,
        },
      ],
      [
        {
          type: FormFieldType.NUMBER,
          value: shift.grace_minutes,
          onChange: (v) => setShift({ ...shift, grace_minutes: v }),
          extras: {
            placeholder: "10",
            label: "Grace Period (Minutes)",
          },
          updatable: true,
        },
        {
          type: FormFieldType.NUMBER,
          value: shift.max_grace_per_month,
          onChange: (v) => setShift({ ...shift, max_grace_per_month: v }),
          extras: {
            placeholder: "3",
            label: "Maximum use of grace period per month",
          },
          updatable: true,
        },
      ],
      [
        {
          type: FormFieldType.SELECT,
          value: shift.days,
          onChange: (v) => setShift({ ...shift, days: v }),
          extras: {
            multi: true,
            placeholder: "Select Days",
            label: "Days",
            options: dayOptions,
          },
          updatable: true,
        },
      ],
    ];

    if (id) {
      return allFields
        .map((fields) => fields.filter((f) => f.updatable))
        .filter((f) => f.length > 0);
    }
    return allFields;
  };

  const handleDeleteAttendanceShift = async () => {
    await service.deleteAttendanceShift(id as string);
    pushNotification({
      title: "Success",
      message: "Shift archived successfully",
      type: "success",
    });
    navigate("/c/attendance");
    onComplete?.();
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      <Row className="justify-content-end mt-4 align-items-center">
        <Col hidden={!id} md="auto" sm="auto" xs="auto" className="p-0">
          <PermissionRestrictedComponent
            permissions={[Permissions.CREATE_SHIFTS]}
          >
            <ArchiveButton
              label="Archive Shift"
              onClick={() => handleDeleteAttendanceShift()}
            />
          </PermissionRestrictedComponent>
        </Col>
        <Col md="auto" sm="auto" xs="auto" className="">
          <PermissionRestrictedComponent
            permissions={[Permissions.CREATE_SHIFTS]}
          >
            <SaveButton
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            />
          </PermissionRestrictedComponent>
        </Col>
      </Row>
    </div>
  );
}
