import { IAuthorization, IUser, Permissions, UserRole } from "../models";

/**
 *
 * @param args Abhishek, Khedekar
 * @returns AK
 */
export function getInitials(...args: any[]) {
  let initials = "";
  for (let i = 0; i < args.length; i++) {
    if (args[i]) {
      initials += args[i][0];
    }
  }
  return initials;
}

export function pluralize(count: number, singular: string, plural?: string) {
  return count === 1 ? singular : plural || singular + "s";
}

export function getUserFullName(user: IUser) {
  return `${user.first_name} ${user.last_name}`;
}

export interface IRoutePermissions {
  supportedRoles?: UserRole[];
  requiredPermissionGroups?: Permissions[][];
}

export function getAuthBasedRoutes<T>(
  routes: IRoutePermissions[],
  auth: IAuthorization
) {
  return routes.filter(
    (route) =>
      (route.supportedRoles ?? []).includes(auth?.role) &&
      (route.requiredPermissionGroups && auth.role == UserRole.StaffMember
        ? route.requiredPermissionGroups.some((permissions) =>
            permissions.every((p) => auth.permissions?.includes(p))
          )
        : true)
  ) as T[];
}

export function minutesToDescription(X: number) {
  const days = Math.floor(X / (24 * 60));
  const hours = Math.floor((X % (24 * 60)) / 60);
  const minutes = X % 60;

  let description = [];
  if (days > 0) {
    description.push(`${days} ${pluralize(days, "day")}`);
  }
  if (hours > 0) {
    description.push(`${hours} ${pluralize(hours, "hour")}`);
  }
  if (minutes > 0) {
    description.push(`${minutes} ${pluralize(minutes, "minute")}`);
  }
  return description.join(" ");
}

export function splitInLimit<T>(arr: T[], limit: number) {
  const batches: T[][] = [];
  for (let i = 0; i < arr.length; i += limit) {
    batches.push(arr.slice(i, i + limit));
  }
  return batches;
}
