import { useEffect, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ShiftList from "./shifts/list";
import CustomCard from "src/components/custom_card";
import AttendanceMarker from "./marker";
import { handleDataExport, isPermissible } from "src/utils/functions";
import {
  DEFAULT_DATE_FORMAT,
  ExportAttendanceShiftConfig,
  ExportStaffAttendanceForDayConfig,
  ExportStaffAttendanceSummaryConfig,
  Permissions,
} from "crm_core";
import { ModalTypes, useModal } from "src/context/modal_context";
import { AddUpdateAttendanceShiftProps } from "./shifts/add_update";
import Aux from "src/components/aux";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import TrackedButton from "src/components/buttons/tracked_button";
import { InboxArrowDownIcon, PlusIcon } from "@heroicons/react/20/solid";
import EntityAttendanceList from "./analytics/list";
import DateRangePicker from "src/components/form_generator/date_range_picker";
import moment from "moment";
import { BulkAction } from "src/components/buttons/action_button";
import { AttendanceReportEntityType } from "./analytics/graph";
import { tabClassName } from "src/utils/constants";

export default function StaffAttendance() {
  const [exportData, setExportData] = useState<any[]>([]);
  const { tab } = useParams();
  const canMarkAttendance = isPermissible(Permissions.MARK_STAFF_ATTENDANCE);
  const canManageShifts = isPermissible(Permissions.CREATE_SHIFTS);
  const canViewAttendance =
    canMarkAttendance || isPermissible(Permissions.VIEW_STAFF_ATTENDANCE);
  let supportedTabs = [];
  if (canMarkAttendance) supportedTabs.push("marker");
  if (canManageShifts) supportedTabs.push("shifts");
  if (canViewAttendance) supportedTabs.push("analytics");
  const [activeTab, setActiveTab] = useState<string>(
    supportedTabs.includes(tab || "") ? tab ?? "" : supportedTabs[0]
  );

  const {
    setModalType,
    setModalSize,
    setModalHeading,
    setComponentProps,
    setShowModal,
  } = useModal();
  const [fromDate, setFromDate] = useState(
    moment().subtract(30, "days").format(DEFAULT_DATE_FORMAT)
  );
  const [toDate, setToDate] = useState(moment().format(DEFAULT_DATE_FORMAT));

  useEffect(() => {
    if (activeTab) {
      window.history.replaceState(null, "", `/c/attendance/${activeTab}`);
    }
  }, [activeTab]);

  useEffect(() => {
    document.title = "Teachbond - Attendance";
  }, []);

  const handleCreateUpdateAttendanceShift = (id?: string) => {
    setModalType(ModalTypes.ATTENDANCE_SHIFT);
    setModalSize("lg");
    setModalHeading("Add New Shift");
    let props: AddUpdateAttendanceShiftProps = {
      onComplete: () => {
        setShowModal(false);
        window.location.reload();
      },
      id,
    };
    setComponentProps(props);
    setShowModal(true);
  };

  let actions: BulkAction[] = [];
  if (activeTab == "marker") {
    actions = [
      {
        label: "Export Attendance",
        onClick: () =>
          handleDataExport(ExportStaffAttendanceForDayConfig, exportData),
        icon: InboxArrowDownIcon,
      },
    ];
  } else if (activeTab == "shifts") {
    actions = [
      {
        label: "Export Shifts",
        onClick: () =>
          handleDataExport(ExportAttendanceShiftConfig, exportData),
      },
    ];
  } else if (activeTab == "analytics") {
    actions = [
      {
        label: "Export Report",
        onClick: () =>
          handleDataExport(ExportStaffAttendanceSummaryConfig, exportData),
        icon: InboxArrowDownIcon,
      },
    ];
  }

  // const tabClassName = "p-0 py-1 px-3 font-sm mr-1 mb-3";

  return (
    <CustomCard
      title="Staff Attendance"
      headerRight={
        <Aux>
          {activeTab == "shifts" && (
            <PermissionRestrictedComponent
              permissions={[Permissions.CREATE_SHIFTS]}
            >
              <TrackedButton
                icon={<PlusIcon className="h-3 w-3 mr-1" />}
                label={<span className="">Add New Shift</span>}
                onClick={() => handleCreateUpdateAttendanceShift()}
                color="accent"
                isSlim
                className="px-2 py-1 text-sm"
              />
            </PermissionRestrictedComponent>
          )}
          {activeTab == "analytics" && (
            <Row className="m-0">
              <Col className="p-0 col-auto">
                <DateRangePicker
                  startLabel=""
                  endLabel=""
                  startDate={fromDate}
                  endDate={toDate}
                  onChange={(fromDate, toDate) => {
                    setFromDate(fromDate);
                    toDate ? setToDate(toDate) : null;
                  }}
                />
              </Col>
            </Row>
          )}
        </Aux>
      }
      actionButtonProps={{ actions }}
    >
      <Tabs
        mountOnEnter
        color="primary"
        className="ml-3"
        variant="pills"
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k as string)}
      >
        {canMarkAttendance && (
          <Tab
            eventKey="marker"
            title="Mark Attendance"
            tabClassName={tabClassName}
          >
            <AttendanceMarker setExportData={setExportData} />
          </Tab>
        )}

        {canManageShifts && (
          <Tab
            eventKey="shifts"
            title="Work Shifts"
            tabClassName={tabClassName}
          >
            <ShiftList tableOnly setExportData={setExportData} />
          </Tab>
        )}
        {canViewAttendance && (
          <Tab eventKey="analytics" title="Report" tabClassName={tabClassName}>
            <EntityAttendanceList
              tableOnly
              fromDate={fromDate}
              toDate={toDate}
              setExportData={setExportData}
              entityType={AttendanceReportEntityType.STAFF_MEMBER}
            />
          </Tab>
        )}
      </Tabs>
    </CustomCard>
  );
}
