import API from "../../api";
import { IFeeStructure } from "crm_core";

export class FeeStructureService extends API {
  constructor() {
    super("/fee-structure");
  }
  async getFeeStructures(): Promise<IFeeStructure[]> {
    const res = await this._get({});
    return res.data as IFeeStructure[];
  }

  async getFeeStructure(id: string): Promise<IFeeStructure> {
    const res = await this._get({ url: `/${id}` });
    return res.data as IFeeStructure;
  }

  async createFeeStructure(
    feeStructure: IFeeStructure
  ): Promise<IFeeStructure> {
    const res = await this._post({ body: feeStructure });
    return res.data as IFeeStructure;
  }

  async updateFeeStructure(
    feeStructure: Partial<IFeeStructure>
  ): Promise<IFeeStructure> {
    const res = await this._put({
      url: `/${feeStructure._id}`,
      body: feeStructure,
    });
    return res.data as IFeeStructure;
  }

  async deleteFeeStructure(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }

  async clone(id: string): Promise<IFeeStructure> {
    const res = await this._post({ body: { clone: id } });
    return res.data as IFeeStructure;
  }
}
