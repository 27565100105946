import { IOrganization, IUser } from "../hierarchy";

export enum DirectMessageStatus {
  SENT = "SENT",
  PENDING = "PENDING",
  FAILED = "FAILED",
  DELIVERED = "DELIVERED",
  READ = "READ",
}

export enum ISenderEntity {
  ORGANIZATION = "ORGANIZATION",
  USER = "USER",
}

export interface IDirectMessage {
  _id?: string;
  ref_id: string; // #org_id#student_id#purpose => #AAA#BBB#CCC
  sender_entity: ISenderEntity;
  sender: string | IUser | IOrganization;
  receiver: string | IUser;
  message: string;
  status: DirectMessageStatus;
  failure_reason?: string;
  receiver_phone?: string;
  created_at?: Date;
  last_updated?: Date;
}
