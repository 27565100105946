import { IFolderDocumentListData, IFolderExtendedData, Permissions, getUserFullName } from "crm_core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomCard from "src/components/custom_card";
import { Loader } from "src/components/loader";
import { FolderService } from "src/services/institute/content/folder";
import { ModalTypes, useModal } from "src/context/modal_context";
import { AddFileProps } from "./add_file";
import Aux from "src/components/aux";
import { ArrowUpIcon } from "@heroicons/react/20/solid";
import Button from "src/components/buttons/button";
import ClickableCell from "src/components/widgets/clickable_cell";
import CustomDataTable, {
  CustomTableColumn,
} from "src/components/custom_data_table";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";

export default function Folder() {
  const service = new FolderService();
  const { id }: any = useParams();
  const [folder, setFolder] = useState<IFolderExtendedData>();
  const [loading, setLoading] = useState<boolean>(true);
  const {
    setModalType,
    setModalSize,
    setModalHeading,
    setComponentProps,
    setShowModal,
  } = useModal();

  const fetchData = async () => {
    setLoading(true);
    const data = (await service.getFolder(id, {
      extended: "true",
    })) as IFolderExtendedData;
    setFolder(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddFile = () => {
    setModalType(ModalTypes.ADD_FILE);
    setModalSize("xl");
    setModalHeading("Upload File");
    let props: AddFileProps = {
      folderId: id,
      onComplete: () => {
        fetchData();
        setShowModal(false);
      },
    };
    setComponentProps(props);
    setShowModal(true);
  };

  const columns: CustomTableColumn<IFolderDocumentListData>[] = [
    {
      name: "Name",
      sortable: true,
      cell: (row) => <ClickableCell href={row.url}>{row.name}</ClickableCell>,
      width: "200px",
    },
    {
      name: "Uploaded By",
      sortable: true,
      cell: (row) => (
        <div className="text-muted">{getUserFullName(row.created_by)}</div>
      ),
    },
  ];

  return (
    <div className="">
      <div hidden={!loading}>
        <Loader />
      </div>
      <div hidden={loading || !!folder}>Folder not found</div>
      <div hidden={loading || !folder}>
        <CustomCard
          title={`Files in ${folder?.name}`}
          className=""
          headerRight={
            <Aux>
              <PermissionRestrictedComponent
                permissions={[Permissions.ADD_TO_FOLDER]}
              >
                <Button
                  label="Upload File"
                  isSlim
                  className="px-2 py-1 text-sm"
                  icon={<ArrowUpIcon className="h-4 w-4 mr-2" />}
                  color="accent"
                  onClick={handleAddFile}
                />
              </PermissionRestrictedComponent>
            </Aux>
          }
        >
          <CustomDataTable
            tableKey="folder-documents"
            columns={columns}
            data={folder?.files ?? []}
            noDataComponent={
              <div
                hidden={!folder || folder.files.length > 0}
                className="mt-4 text-center"
              >
                <PermissionRestrictedComponent
                  permissions={[Permissions.ADD_TO_FOLDER]}
                >
                  No files uploaded here yet. Click{" "}
                  <a href="#" onClick={handleAddFile}>
                    here
                  </a>{" "}
                  to upload a file.
                </PermissionRestrictedComponent>
              </div>
            }
          />
        </CustomCard>
      </div>
    </div>
  );
}
