import { IOrganization } from "../../../hierarchy";
import { Days } from "../../../utils";
import { IStudent, IStaffMember } from "../people";
import { IBatch } from "./batch";
import { IDepartment } from "./department";
import { ISession } from "./session";

export enum ClassroomType {
  Compulsory = "compulsory",
  Elective = "elective",
}

export interface IClassroom {
  _id?: string;
  name: string;
  type: ClassroomType;
  batch: IBatch | string;
  department: IDepartment | string;
  organization?: IOrganization | string;
  session?: ISession | string;
  class_code?: string;
  teachers?: string[] | IStaffMember[];
  students?: string[] | IStudent[];
  created_at?: number;
  last_updated?: number;
  timetable?: ITimeTableSlot[];
}

export type IClassroomExtended = IClassroom & {
  _id: string;
  batch: IBatch;
  department: IDepartment;
  teachers: IStaffMember[];
  students: string[];
};

export interface ITimeTableSlot {
  day: Days;
  from: string;
  to: string;
}

export interface IUserTimeTableSlot extends ITimeTableSlot {
  classroom: Partial<IClassroom>;
}
