import { createRef, forwardRef, useEffect, useRef, useState } from "react";

interface ExpandableProps {
  expanded?: boolean;
  transitionTime?: number;
  easing?: string;
  render: (
    expandableProps: object,
    toggle: () => void,
    isOpen: boolean,
    inTransition: boolean
  ) => any;
  onOpen?: () => void;
  onClose?: () => void;
}

export default function Expandable({
  expanded = false,
  render,
  transitionTime = 400,
  easing = "linear",
  onOpen,
  onClose,
}: ExpandableProps) {
  const [isOpen, setIsOpen] = useState(expanded);
  const [shouldSwitchAutoOnNextCycle, setShouldSwitchAutoOnNextCycle] =
    useState(false);
  const [height, setHeight] = useState<any>(expanded ? "auto" : 0);
  const [transition, setTransition] = useState(
    `height ${transitionTime}ms ${easing}`
  );
  const [overflow, setOverflow] = useState("hidden");
  const [inTransition, setInTransition] = useState(false);
  const [shouldOpenOnNextCycle, setShouldOpenOnNextCycle] = useState(false);
  let innerRef = createRef<any>();

  let timeout: any = undefined;
  let contentId = `collapsible-content-${Date.now()}`;
  let triggerId = `collapsible-trigger-${Date.now()}`;

  useEffect(() => {
    if (shouldOpenOnNextCycle) {
      continueOpenExpandable();
    }
  }, [shouldOpenOnNextCycle]);

  useEffect(() => {
    if (shouldSwitchAutoOnNextCycle) {
      window.clearTimeout(timeout);
      timeout = window.setTimeout(() => {
        setHeight(0);
        setOverflow("hidden");
        setIsOpen(false);
        setShouldSwitchAutoOnNextCycle(false);
      }, 50);
    }
    return () => {
      window.clearTimeout(timeout);
    };
  }, [shouldSwitchAutoOnNextCycle, height]);

  useEffect(() => {
    if (isOpen) {
      if (height === 0) {
        openExpandable();
      }
    } else {
      closeExpandable();
    }
  }, [isOpen]);

  const closeExpandable = () => {
    setShouldSwitchAutoOnNextCycle(true);
    setHeight(innerRef.current.scrollHeight);
    setTransition(`height ${transitionTime}ms ${easing}`);
    setInTransition(innerRef.current.scrollHeight !== 0);
  };

  const openExpandable = () => {
    setInTransition(innerRef.current.scrollHeight !== 0);
    setShouldOpenOnNextCycle(true);
    onOpen && onOpen();
  };

  const continueOpenExpandable = () => {
    setHeight(innerRef.current.scrollHeight);
    setTransition(`height ${transitionTime}ms ${easing}`);
    setIsOpen(true);
    setInTransition(innerRef.current.scrollHeight !== 0);
    setShouldOpenOnNextCycle(false);
  };

  const handleTransitionEnd = (e: any) => {
    if (e.target !== innerRef.current) return;
    if (isOpen) {
      setHeight("auto");
      setOverflow("visible");
      setInTransition(false);
    } else {
      setInTransition(false);
      onClose && onClose();
    }
  };

  const toggleExpandable = () => {
    if (isOpen) {
      closeExpandable();
    } else {
      openExpandable();
    }
  };

  return render(
    {
      id: contentId,
      onTransitionEnd: handleTransitionEnd,
      style: {
        transition: transition,
        overflow: overflow,
        WebkitTransition: transition,
        msTransition: transition,
        height: height,
      },
      ref: innerRef,
      hidden: !isOpen && !inTransition,
      role: "region",
      "aria-labelledby": triggerId,
    },
    toggleExpandable,
    isOpen,
    inTransition
  );
}
