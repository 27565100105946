import { Col, Row } from "react-bootstrap";
import { IRouteData } from "src/routes";
import { NavigationProvider, useNavigation } from "./context";
import { useNavigate } from "react-router-dom";
import { joinClasses } from "src/utils/functions";
import { useEffect } from "react";

interface BottomNavigationProps {
  routes: IRouteData[];
  height?: string;
}

export default function BottomNavigation(props: BottomNavigationProps) {
  return (
    <NavigationProvider>
      <Base {...props} />
    </NavigationProvider>
  );
}

function Base({ routes, height }: BottomNavigationProps) {
  const { setSelectedSection, setExpanded, selectedSection, setSections } =
    useNavigation();
  const navigate = useNavigate();

  useEffect(() => {
    setSections(routes);
  }, [routes]);

  return (
    <Row
      style={{
        position: "fixed",
        bottom: 0,
        width: `100%`,
        height: height,
      }}
      className="m-0 align-items-center"
    >
      {routes.map((route) => (
        <Col
          key={route.path}
          className={joinClasses(
            "p-0 bg-white h-full hover:bg-template-card rounded-md",
            selectedSection == route ? "text-accent" : ""
          )}
          onClick={() => {
            setSelectedSection(route);
            setExpanded(false);
            navigate(`/${route.layout}${route.path}`);
          }}
        >
          <Row className="m-0 h-full align-items-center justify-content-center">
            <Col className="p-0 text-center">{route.icon}</Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
}
