import { IOrganization } from "../../../hierarchy";
import { IStudent } from "../people";
import { ITestEvaluation } from "./evaluation";
import { ITest } from "./test";

export enum TestSubmissionStatus {
  NOT_STARTED = "not_started",
  STARTED = "started",
  SUBMITTED = "submitted",
}

export interface ITestSubmission {
  _id: string;
  test: string | ITest;
  user: string | IStudent;
  status: TestSubmissionStatus;
  start: Date;
  end?: Date;
  organization: string | IOrganization;
  duration_minutes: number;
}

export interface ITestSubmissionRequestResponse {
  submission: ITestSubmission;
  test_details: Partial<ITest>;
}

export interface IStudentSubmissionListResponse extends ITestSubmission {
  test_details: Partial<ITest>;
}

export interface ITestSubmissionListResponse extends ITestSubmission {
  user: IStudent;
  evaluation: ITestEvaluation;
  is_pending_evaluation: boolean;
}