import { PlusIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Aux from "src/components/aux";
import TrackedButton from "src/components/buttons/tracked_button";
import CustomCard from "src/components/custom_card";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import { ModalTypes, useModal } from "src/context/modal_context";
import DepartmentCard from "../department/card";
import { Loader } from "src/components/loader";
import { AddUpdateDepartmentProps } from "../department/add_update";
import { DepartmentService } from "src/services/institute/department";
import { IDepartment, Permissions } from "crm_core";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";

export default function InstituteOverview() {
  const {
    setModalType,
    setShowModal,
    setModalSize,
    setModalHeading,
    setComponentProps,
  } = useModal();
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const { selectedSession, fetchDataForUseCase } = useDataProvider();

  const fetchData = async () => {
    setLoading(true);
    const data = await new DepartmentService().getDepartments();
    setDepartments(data);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedSession) {
      fetchData();
      fetchDataForUseCase(DataUseCase.INSTITUTE);
    }
  }, [selectedSession]);

  const onAddDepartment = () => {
    fetchData();
    setShowModal(false);
  };

  const handleCreateDepartment = (id?: string) => {
    setModalType(ModalTypes.DEPARTMENT);
    setModalSize("lg");
    setModalHeading("Add New Department");
    let props: AddUpdateDepartmentProps = {
      onComplete: onAddDepartment,
      id,
    };
    setComponentProps(props);
    setShowModal(true);
  };

  return (
    <CustomCard
      title="Overview"
      headerRight={
        <Aux>
          <PermissionRestrictedComponent
            permissions={[Permissions.CREATE_DEPARTMENT]}
          >
            <TrackedButton
              icon={<PlusIcon className="h-3 w-3 mr-1" />}
              label={<span className="">Add New Department</span>}
              onClick={() => handleCreateDepartment()}
              color="accent"
              isSlim
              className="px-2 py-1 text-sm"
            />
          </PermissionRestrictedComponent>
        </Aux>
      }
    >
      {loading && (
        <div className="mt-8">
          <Loader text="Fetching Institute Data" />
        </div>
      )}
      {!loading && (
        <Row className="mx-2">
          {departments.map((department, idx) => (
            <Col md="3" key={`department-${idx}`} className="pb-2">
              <DepartmentCard department={department} />
            </Col>
          ))}
        </Row>
      )}
    </CustomCard>
  );
}
