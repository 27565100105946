import { IQuestion, ISection, ITest } from "../models";

/**
 * A class that evaluates a test and calculates the maximal score.
 */
export class TestEvaluator {
  questions: IQuestion[];
  sections: ISection[];

  /**
   * Creates a new TestEvaluator instance.
   * @param test - The test to evaluate.
   */
  constructor(private test: ITest) {
    this.questions = (test.questions ?? []) as IQuestion[];
    this.sections = (test.sections ?? []) as ISection[];
  }

  /**
   * Calculates the maximal score that can be obtained in the test.
   * @returns The maximal score.
   */
  getMaximalScore(): number {
    let totalScore = 0;
    for (const section of this.sections) {
      const questionsInSection = this.questions.filter(
        (q) => String(q.section) == String(section._id)
      );
      const questionsCount = questionsInSection.length;
      const sectionScore = section.correct_marks * questionsCount;
      totalScore += sectionScore;
    }
    return totalScore;
  }
}
