import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "src/components/buttons/button";
import { joinClasses } from "src/utils/functions";

export interface ICountCardProps {
  count: number;
  title: string;
  icon: JSX.Element;
  clickPath?: string;
  formatter?: (value: number) => string;
}
export default function CountCard({
  count,
  title,
  icon,
  clickPath,
  formatter,
}: ICountCardProps) {
  const navigate = useNavigate();

  return (
    <div
      className={joinClasses(
        clickPath ? "cursor-pointer" : "",
        "text-black h-100 hover:shadow-lg"
      )}
      onClick={(e) => {
        if (!clickPath) return;
        if (e.metaKey || e.ctrlKey) {
          window.open(clickPath, "_blank");
          return;
        }
        navigate(clickPath);
      }}
    >
      <Card
        className={joinClasses(
          "h-100 bg-white text-on-white border-0 rounded-md p-0 shadow-md",
          clickPath ? "cursor-pointer hover:shadow-lg" : ""
        )}
      >
        <Card.Body className="px-3 py-3">
          <Row className="justify-content-between align-items-center">
            <Col lg="auto" md="auto" sm="auto" xs="auto">
              <h5 className="font-weight-bold">
                {formatter?.(count) ?? count}
              </h5>
              <p className="text-muted">{title}</p>
              {clickPath && (
                <Button
                  label="View"
                  icon={<ArrowTopRightOnSquareIcon className="h-4 w-4 mr-2" />}
                  className="text-sm px-2 py-1 text-accent"
                  isSlim
                  color="transparent"
                />
              )}
            </Col>
            <Col lg="auto" md="auto" sm="auto" xs="auto">
              <div className="text-accent">{icon}</div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}
