export enum FilterLabels {
  DEPARTMENT = "Department",
  SEARCH = "Search",
  BATCH = "Batch",
  CLASSROOM = "Classroom",
  INVITATION_STATUS = "Joining Status",
  STATUS = "Status",
  COLLECTION_STATUS = "Collection Status",
  ACTIVE_STATUS = "Status",
  QUESTION_TYPE = "Question Type",
  DIFFICULTY = "Difficulty",
}
