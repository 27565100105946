import { createContext, useContext } from "react";
import {
  ReactNotifications,
  Store,
  iNotification,
} from "react-notifications-component";
import Aux from "src/components/aux";

const notificationContext = createContext<NotificationContextProps>({} as any);

export function useNotification() {
  return useContext(notificationContext);
}

export function NotificationProvider({ children }: any) {
  return (
    <Aux>
      <ReactNotifications />
      <notificationContext.Provider value={useNotificationProvider()}>
        {children}
      </notificationContext.Provider>
    </Aux>
  );
}

export type Notification = Partial<iNotification> & {
  container?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
}

function useNotificationProvider() {
  const pushNotification = (notification: Notification) => {
    Store.addNotification({
      container: notification.container || "top-right",
      dismiss: {
        duration: 2000,
        onScreen: true,
      },
      insert: "top",
      type: "success",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      ...notification,
    });
  };

  return {
    pushNotification,
  } as NotificationContextProps;
}

interface NotificationContextProps {
  pushNotification: (notification: Notification) => void;
}
