import { IOrganization } from "crm_core";
import { useEffect, useState } from "react";
import CustomCard from "src/components/custom_card";
import CustomDataTable, {
  CustomTableColumn,
} from "src/components/custom_data_table";
import ProfilePhoto from "src/components/profile_photo";
import { useNotification } from "src/context/notification_context";
import { OrganizationService } from "src/services/organization";

interface OrganizationListProps {}

export default function OrganizationList({}: OrganizationListProps) {
  const organizationService = new OrganizationService();
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const { pushNotification } = useNotification();

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await organizationService.getOrganizations();
      setOrganizations(res);
    } catch (e) {
      pushNotification({
        title: "Error",
        message: "Failed to fetch institutes",
        type: "danger",
      });
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const tableColumns: CustomTableColumn<IOrganization>[] = [
    {
      name: "",
      cell: (row) => (
        <div className="py-2">
          <ProfilePhoto url={row.logo} className="h-12 w-12" />
        </div>
      ),
      width: "80px",
    },
    {
      name: "Name",
      cell: (row) => <div>{row.name}</div>,
      width: "300px",
    },
  ];

  return (
    <CustomCard title="All Institutes">
      <CustomDataTable
        tableKey="institutes"
        data={organizations}
        columns={tableColumns}
        selectableRows={false}
        highlightOnHover={false}
        progressPending={loading}
        noDataComponent={
          <div className="py-3">
            <h5 className="text-center">No data available</h5>
          </div>
        }
      />
    </CustomCard>
  );
}
