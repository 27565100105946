export class FilterConfig {
  fieldSelectors: { [key: string]: string };
  constructor(fieldSelectors: { [key: string]: string }) {
    this.fieldSelectors = fieldSelectors;
  }
}

export enum SupportedFilterAttributes {
  ID = "_id",
  DEPARTMENT = "department",
  SEARCH = "search",
  BATCH = "batch",
  STATUS = "status",
  INVITATION_STATUS = "invitation.status",
  CLASSROOM = "classroom",
  COLLECTION_STATUS = "collection_status",
  TOPIC = "topic",
  COURSE = "course",
  SUBJECT = "subject",
  ACTIVE_STATUS = "active_status",
  QUESTION_TYPE = "question_type",
  QUESTION_DIFFICULTY = "question_difficulty",
}

export enum SupportedFilterOperators {
  EQUALS = "equals",
  NOT_EQUALS = "not_equals",
  INCLUDES = "includes",
  NOT_INCLUDES = "not_includes",
  TEXT_CONTAINS = "regex",
  GREATER_THAN = "gt",
}

export enum InvitationFilterValues {
  JOINED = "joined",
  NOT_JOINED = "not_joined",
  NOT_INVITED = "not_invited",
}

export enum ActiveStatusValues {
  ACTIVE = "active",
  EXITED = "exited",
}