import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "src/components/loader";
import { FeeStructureService } from "src/services/institute/fees/structure";
import AddUpdateFeeStructure from "./add_update";
import { IFeeStructure } from "crm_core";
import CustomCard from "src/components/custom_card";

export default function FeeStructure() {
  const service = new FeeStructureService();
  const { id }: any = useParams();
  const [feeStructure, setFeeStructure] = useState<IFeeStructure>();
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async () => {
    setLoading(true);
    const data = await service.getFeeStructure(id);
    setFeeStructure(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CustomCard title="Fee Structure" className="">
      <div hidden={!loading}>
        <Loader />
      </div>
      <div hidden={loading || !!feeStructure}>Fee Structure not found</div>
      <div hidden={loading || !feeStructure} className="px-3">
        <AddUpdateFeeStructure id={id} data={feeStructure} />
      </div>
    </CustomCard>
  );
}
