import { Days, ITimeTableSlot, dayOptions } from "crm_core";
import Button from "../buttons/button";
import { CheckIcon, PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Col, Row } from "react-bootstrap";
import LabelledField from "../form_generator/labelled_field";
import FormSelect from "../form_select";
import TimeRangePicker from "../form_generator/time_range_picker";
import { useState } from "react";

export interface TimeSlotsEditorProps {
  slots: ITimeTableSlot[];
  onSave: (slots: ITimeTableSlot[]) => void;
}

export default function TimeSlotsEditor(props: TimeSlotsEditorProps) {
  const [slots, setSlots] = useState<ITimeTableSlot[]>(
    JSON.parse(JSON.stringify(props.slots))
  );

  const handleAddSlot = () => {
    const lastSlot = slots[slots.length - 1];
    const newSlot: ITimeTableSlot = {
      day: lastSlot ? lastSlot.day : Days.Monday,
      from: lastSlot ? lastSlot.to : "01:00 am",
      to: lastSlot ? lastSlot.to : "01:30 am",
    };
    setSlots([...slots, newSlot]);
  };

  const handleRemoveSlot = (idx: number) => {
    const newSlots = [...slots];
    newSlots.splice(idx, 1);
    setSlots(newSlots);
  };

  return (
    <div>
      <div hidden={slots.length > 0}>
        <div className="text-center text-gray-500">No slots added</div>
      </div>
      {slots.map((slot, idx) => (
        <div key={idx}>
          <Row className="align-items-center my-1">
            <Col className="col-4">
              <LabelledField label="Day">
                <FormSelect
                  options={dayOptions}
                  value={slot.day}
                  onChange={(v: any) => {
                    const newSlots = [...slots];
                    newSlots[idx].day = v;
                    setSlots(newSlots);
                  }}
                  isClearable={false}
                />
              </LabelledField>
            </Col>
            <Col className="col-7">
              <TimeRangePicker
                start={slot.from}
                end={slot.to}
                onChange={(start, end) => {
                  const newSlots = [...slots];
                  newSlots[idx].from = start;
                  newSlots[idx].to = end;
                  setSlots(newSlots);
                }}
              />
            </Col>
            <Col className="col-1">
              <LabelledField label="">
                <Button
                  icon={<XMarkIcon className="h-4 w-4" />}
                  isSlim
                  className="p-1"
                  color="red"
                  rounded
                  onClick={() => handleRemoveSlot(idx)}
                />
              </LabelledField>
            </Col>
          </Row>
        </div>
      ))}
      <Row className="mt-4">
        <Col className="col-auto"></Col>
        <Col className="col-auto ml-auto">
          <Button
            label="Add Slot"
            icon={<PlusIcon className="w-4 h-4 mr-1" />}
            className="py-1 px-2 text-sm"
            color="accent"
            onClick={handleAddSlot}
            isSlim
          />
        </Col>
        <Col className="col-auto">
          <Button
            label="Save"
            icon={<CheckIcon className="w-4 h-4 mr-1" />}
            className="py-1 px-2 text-sm"
            color="accent"
            onClick={() => props.onSave(slots)}
            isSlim
          />
        </Col>
      </Row>
    </div>
  );
}
