import { IClassroom } from "../features";
import { ICompany } from "./company";
import { IOrganization } from "./organization";
import { Permissions } from "./roles";
import { UserRole } from "./user";

export enum AuthEntityType {
  Organization = "Organization",
  Classroom = "Classroom",
  Company = "Company",
}

export interface IAuthorization {
  _id?: string;
  entity_type: AuthEntityType;
  entity: string | IOrganization | IClassroom | ICompany;
  role: UserRole;
  permissions?: Permissions[]
}
