import { ArchiveBoxIcon } from "@heroicons/react/20/solid";
import ConfirmableButton, {
  ConfirmableButtonProps,
} from "../buttons/confirmable_button";
import { LoadingButtonProps } from "../buttons/loading_button";

interface ArchiveButtonProps
  extends Partial<LoadingButtonProps & ConfirmableButtonProps> {}

export default function ArchiveButton(props: ArchiveButtonProps) {
  return (
    <ConfirmableButton
      loading={false}
      icon={<ArchiveBoxIcon className="h-3 w-3 mr-1" />}
      label="Archive Test"
      color="red"
      isSlim
      className="px-2 py-1 text-sm"
      {...props}
    />
  );
}
