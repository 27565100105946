import { Routes } from "react-router-dom";
import { getRoutes, routesData } from "src/routes";

export default function HomeLayout({}: any) {
  const LAYOUT_NAME = "home";

  return (
    <Routes>
      {getRoutes(routesData.filter((route) => route.layout === LAYOUT_NAME))}
    </Routes>
  );
}
