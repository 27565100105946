import API from "./api";
import { ICustomRole } from "crm_core";

export class CustomRoleService extends API {
  static NAME = "CustomRoleService";

  constructor() {
    super("/custom-role");
  }
  async getCustomRoles(): Promise<ICustomRole[]> {
    const res = await this._get({});
    return res.data as ICustomRole[];
  }

  async getCustomRole(id: string): Promise<ICustomRole> {
    const res = await this._get({ url: `/${id}` });
    return res.data as ICustomRole;
  }

  async createCustomRole(customRole: ICustomRole): Promise<ICustomRole> {
    const res = await this._post({ body: customRole });
    return res.data as ICustomRole;
  }

  async updateCustomRole(customRole: ICustomRole): Promise<ICustomRole> {
    const res = await this._put({
      url: `/${customRole._id}`,
      body: customRole,
    });
    return res.data as ICustomRole;
  }

  async deleteCustomRole(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }
}
