import { IStaffMember, getInitials } from "crm_core";
import HelpTip from "../helptip";
import { Badge } from "react-bootstrap";

interface StaffMemberBadgeProps {
  staffMember: IStaffMember;
}

export default function StaffMemberBadge({ staffMember }: StaffMemberBadgeProps) {
  return (
    <HelpTip
      id={`${staffMember._id}`}
      text={`${staffMember.first_name} ${staffMember.last_name}`}
    >
      <Badge className="mr-1 text-md bg-accent" bg="secondary">
        {getInitials(staffMember.first_name, staffMember.last_name)}
      </Badge>
    </HelpTip>
  );
}
