import API from "../../../api";
import { ISubjectTopic } from "crm_core";

export class SubjectTopicService extends API {
  static NAME = "SubjectTopicService";

  constructor() {
    super("/topic");
  }
  async getSubjectTopics(subjectId: string): Promise<ISubjectTopic[]> {
    const res = await this._get({ url: `/${subjectId}/list` });
    return res.data;
  }

  async getSubjectTopic(id: string): Promise<ISubjectTopic> {
    const res = await this._get({ url: `/${id}` });
    return res.data;
  }

  async createSubjectTopic(
    subjectId: string,
    topic: ISubjectTopic
  ): Promise<ISubjectTopic> {
    const res = await this._post({ url: `/${subjectId}`, body: topic });
    return res.data;
  }

  async updateSubjectTopic(
    topic: Partial<ISubjectTopic>
  ): Promise<ISubjectTopic> {
    const res = await this._put({ url: `/${topic._id}`, body: topic });
    return res.data;
  }

  async deleteSubjectTopic(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }

  async uploadLogo(file: File, topicId: string): Promise<ISubjectTopic> {
    const formData = new FormData();
    formData.append("photo", file, file.name);
    const res = await this._postFile({
      url: `/photo/${topicId}`,
      body: formData,
    });
    return res.data as ISubjectTopic;
  }
}
