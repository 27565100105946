import { DEFAULT_DATE_FORMAT, IStaffAttendance, combineTimeDateTimezone } from "crm_core";
import moment from "moment-timezone";
import { Col, Row } from "react-bootstrap";
import TimeRangePicker from "src/components/form_generator/time_range_picker";
import { useAuth } from "src/context/auth_context";
import { joinClasses } from "src/utils/functions";

interface PersonAttendanceCardProps {
  attendance: IStaffAttendance;
  onChange: (attendance: IStaffAttendance) => void;
}

export default function PersonAttendanceCard({
  attendance,
  onChange,
}: PersonAttendanceCardProps) {
  const { timezone } = useAuth();
  const dt = moment
    .tz(attendance.expected_check_in_time, timezone)
    .format(DEFAULT_DATE_FORMAT);

  return (
    <div className="rounded-md border-1 bg-custom-grey py-2 px-2 shadow-md">
      <div className="text-bold">
        {attendance.person.first_name} {attendance.person.last_name}
      </div>
      <div
        className="text-muted text-sm cursor-pointer"
        onClick={() =>
          onChange({
            ...attendance,
            check_in_time: attendance.expected_check_in_time,
          })
        }
      >
        Expected check-in:{" "}
        {attendance.expected_check_in_time
          ? moment(attendance.expected_check_in_time).format("hh:mm a")
          : "--"}
      </div>{" "}
      <div
        className="text-muted text-sm cursor-pointer"
        onClick={() =>
          onChange({
            ...attendance,
            check_out_time: attendance.expected_check_out_time,
          })
        }
      >
        Expected check-out:{" "}
        {attendance.expected_check_out_time
          ? moment(attendance.expected_check_out_time).format("hh:mm a")
          : "--"}
      </div>
      <div className="text-muted text-sm mt-2 bg-white py-2 px-2 rounded-md">
        <TimeRangePicker
          start={
            attendance.check_in_time
              ? moment(attendance.check_in_time).format("hh:mm a")
              : ""
          }
          end={
            attendance.check_out_time
              ? moment(attendance.check_out_time).format("hh:mm a")
              : ""
          }
          onChange={(start, end) =>
            onChange({
              ...attendance,
              check_in_time: combineTimeDateTimezone(
                start,
                dt,
                timezone
              ).toDate(),
              check_out_time: end
                ? combineTimeDateTimezone(end, dt, timezone).toDate()
                : undefined,
            })
          }
          startLabel="Check in"
          endLabel="Check out"
        />
      </div>
      <Row className="mt-2 m-0 align-items-center bg-white py-2 px-2 rounded-md">
        <Col
          className={joinClasses(
            "py-2 px-2 mr-1 rounded-md cursor-pointer",
            attendance.check_in_time ? "bg-green-200" : "bg-custom-grey"
          )}
          onClick={() => onChange({ ...attendance, check_in_time: new Date() })}
        >
          <div>Present</div>
        </Col>
        <Col
          className={joinClasses(
            "py-2 px-2 ml-1 rounded-md cursor-pointer",
            !attendance.check_in_time ? "bg-red-200" : "bg-custom-grey"
          )}
          onClick={() =>
            onChange({
              ...attendance,
              check_in_time: undefined,
              check_out_time: undefined,
            })
          }
        >
          <div>Absent</div>
        </Col>
      </Row>
    </div>
  );
}
