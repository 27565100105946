import { IChatRoomListResponse, Permissions, getUserFullName } from "crm_core";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ChatList } from "react-chat-elements";
import { ChatRoomService } from "src/services/chatrooms/room";
import ChatRoomView from "./room";
import { ModalTypes, useModal } from "src/context/modal_context";
import { AddUpdateChatRoomProps } from "./add_update";
import Aux from "src/components/aux";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";
import TrackedButton from "src/components/buttons/tracked_button";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Loader } from "src/components/loader";
import { useParams } from "react-router-dom";
import { usePageState } from "src/context/page_state_context";
import { joinClasses } from "src/utils/functions";

interface ChatRoomsProps {
  classroomId?: string;
}

export default function ChatRooms({classroomId}: ChatRoomsProps) {
  const chatRoomService = new ChatRoomService();
  const { id } = useParams();
  const [rooms, setRooms] = useState<IChatRoomListResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { isSmallScreen, chatNotifications } = usePageState();
  const [selectedRoomId, setSelectedRoomId] = useState<string>(
    (id ?? "").length > 5 ? (id as string) : ""
  );
  const {
    setModalType,
    setModalSize,
    setModalHeading,
    setShowModal,
    setComponentProps,
  } = useModal();

  const fetchChatRooms = async () => {
    setLoading(true);
    const data = await chatRoomService.getChatRooms(classroomId);
    setRooms(data);
    if (!selectedRoomId && data.length > 0) {
      // setSelectedRoomId(data[0]._id);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedRoomId && !classroomId) {
      window.history.replaceState(null, "", `/c/chat/${selectedRoomId}`);
    }
  }, [selectedRoomId]);

  useEffect(() => {
    document.title = "Teachbond - Chat";
  }, []);

  useEffect(() => {
    fetchChatRooms();
  }, []);

  useEffect(() => {
    if (chatNotifications > 0) {
      fetchChatRooms();
    }
  }, [chatNotifications]);

  const visibilityChangeHandler = () => {
    if (!document.hidden) {
      fetchChatRooms();
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", visibilityChangeHandler);
    return () => {
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
    };
  }, []);

  const handleAddChatRoom = () => {
    setModalType(ModalTypes.ADD_UPDATE_CHATROOM);
    setModalSize("lg");
    setModalHeading("Create Group");
    let props: AddUpdateChatRoomProps = {
      onComplete: () => {
        setShowModal(false);
        fetchChatRooms();
      },
    };
    setComponentProps(props);
    setShowModal(true);
  };

  const CHAT_HEIGHT = isSmallScreen ? "80vh" : "85vh";

  return (
    <div className="pt-3 mx-3">
      <Row
        style={{ height: CHAT_HEIGHT }}
        className="m-0 border-2 border-custom-grey rounded-md"
      >
        <Col md={5} className="p-0">
          <Aux>
            <div className="bg-white rounded-l-md">
              <div className="p-3">
                <Row
                  style={{ paddingTop: "4px" }}
                  className="m-0 align-items-center pb-4 border-b-2 border-accent"
                >
                  <Col className="col-auto p-0">
                    <h5 className="text-muted m-0">Groups</h5>
                  </Col>
                  <Col className="col-auto">
                    <PermissionRestrictedComponent
                      permissions={[Permissions.CREATE_CHAT_GROUP]}
                    >
                      <TrackedButton
                        icon={<PlusIcon className="h-3 w-3 mr-1" />}
                        label={<span className="">Create New Group</span>}
                        onClick={handleAddChatRoom}
                        color="accent"
                        isSlim
                        className="px-2 py-1 text-sm"
                      />
                    </PermissionRestrictedComponent>
                  </Col>
                </Row>
                <div
                  style={{
                    height: `calc(${CHAT_HEIGHT} - 118px)`,
                    overflow: "scroll",
                  }}
                  className="mt-4"
                >
                  {loading && <Loader text="Fetching Groups" />}
                  {!loading && (
                    <ChatList
                      dataSource={rooms.map((r) => ({
                        id: r._id,
                        avatar: "",
                        title: r.name,
                        subtitle: r.last_message?.data
                          ? `${getUserFullName(
                              r.last_message.sender as any
                            )}: ${r.last_message.data}`
                          : "",
                        letterItem: {
                          id: r._id,
                          letter: r.name
                            ? r.name
                                .split(" ")
                                .map((n) =>
                                  n.length > 0 ? n[0].toUpperCase() : ""
                                )
                                .join("")
                            : "",
                        },
                        className: joinClasses(
                          "rounded-md mb-1",
                          selectedRoomId === r._id
                            ? "bg-custom-grey"
                            : "bg-white"
                        ),
                        date: r.last_message
                          ? new Date(r.last_message.created_at)
                          : new Date(),
                      }))}
                      id={selectedRoomId ?? ""}
                      onClick={(room) => setSelectedRoomId(room.id as string)}
                      lazyLoadingImage={""}
                    />
                  )}
                </div>
              </div>
            </div>
          </Aux>
        </Col>
        <Col md={7} className="p-0">
          {selectedRoomId ? (
            <ChatRoomView
              id={selectedRoomId ?? ""}
              refreshList={fetchChatRooms}
              totalHeight={CHAT_HEIGHT}
            />
          ) : (
            <div className="mt-20">
              <h5 className="text-muted text-center">No Group Selected</h5>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
