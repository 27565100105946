import { useEffect, useState } from "react";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useAuth } from "src/context/auth_context";
import { useNotification } from "src/context/notification_context";
import { BatchService } from "src/services/institute/batch";
import { FormFieldType } from "src/utils/constants";
import { catchRequest, validateFields } from "src/utils/functions";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import { IBatch, Permissions } from "crm_core";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import ArchiveButton from "src/components/widgets/archive_button";
import SaveButton from "src/components/widgets/save_button";
import PermissionRestrictedComponent from "src/components/permission_restricted_component";

export interface AddUpdateBatchProps {
  onComplete?: () => void;
  id?: string;
  batch?: IBatch;
}

export default function AddUpdateBatch({
  onComplete,
  id,
  batch,
}: AddUpdateBatchProps) {
  const service = new BatchService();
  const { selectedOrganization } = useAuth();
  const [batchName, setBatchName] = useState<string>();
  const [department, setDepartment] = useState<string>();
  const [staffMember, setStaffMember] = useState<string>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();
  const { fetchDataForUseCase, departments, selectedSession } =
    useDataProvider();

  useEffect(() => {
    if (selectedSession) fetchDataForUseCase(DataUseCase.INSTITUTE);
  }, [selectedSession]);

  useEffect(() => {
    const fetchBatch = async (id: string) => {
      setLoading(true);
      const currentBatch = batch ? batch : await service.getBatch(id);
      setBatchName(currentBatch.name);
      setLoading(false);
    };
    if (id) {
      fetchBatch(id);
    }
  }, [id]);

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(getFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);
    let update: any = {
      name: batchName as any,
    };

    await catchRequest(async () => {
      if (id) {
        await service.updateBatch({
          _id: id,
          ...update,
        });
        pushNotification({
          title: "Success",
          message: "Batch updated successfully",
          type: "success",
        });
      } else {
        await service.createBatch({
          ...update,
          organization: selectedOrganization?._id as any,
          department: department as any,
          staffMember: staffMember as any,
        });
      }
    }, pushNotification)();
    setLoading(false);
    onComplete?.();
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.TEXT,
          value: batchName,
          onChange: setBatchName,
          extras: {
            placeholder: "Batch Name",
            label: "Batch Name",
          },
          required: true,
          updatable: true,
        },
      ],
      [
        {
          type: FormFieldType.SELECT,
          value: department,
          onChange: setDepartment,
          extras: {
            label: "Department",
            options: departments.map((d) => ({ label: d.name, value: d._id })),
          },
          required: true,
        },
      ],
    ];

    if (id) {
      return allFields
        .map((fields) => fields.filter((f) => f.updatable))
        .filter((f) => f.length > 0);
    }
    return allFields;
  };

  const handleDeleteBatch = async () => {
    await catchRequest(async () => {
      await service.deleteBatch(id as string);
      pushNotification({
        title: "Success",
        message: "Batch archived successfully",
        type: "success",
      });
      navigate("/c/batches");
    }, pushNotification)();
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      <Row className="justify-content-end mt-4 align-items-center">
        <Col hidden={!id} md="auto" sm="auto" xs="auto" className="p-0">
          <PermissionRestrictedComponent
            permissions={[Permissions.ARCHIVE_BATCH]}
          >
            <ArchiveButton
              dialogTitle="Archive Batch?"
              dialogContent="Are you sure you want to archive this batch?"
              label="Archive Batch"
              onClick={() => handleDeleteBatch()}
            />
          </PermissionRestrictedComponent>
        </Col>
        <Col md="auto" sm="auto" xs="auto" className="">
          <PermissionRestrictedComponent
            permissions={[Permissions.UPDATE_BATCH, Permissions.CREATE_BATCH]}
          >
            <SaveButton
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
            />
          </PermissionRestrictedComponent>
        </Col>
      </Row>
    </div>
  );
}
