import { ISection } from "crm_core";
import { useEffect, useState } from "react";
import LoadingButton from "src/components/buttons/loading_button";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useNotification } from "src/context/notification_context";
import { FormFieldType } from "src/utils/constants";
import { validateFields } from "src/utils/functions";

export interface AddUpdateSectionProps {
  section?: ISection;
  onSave?: (v: ISection) => Promise<void>;
}

export default function AddUpdateSection({
  section,
  onSave,
}: AddUpdateSectionProps) {
  const [value, setValue] = useState<ISection>({
    name: "",
    correct_marks: 1,
    negative_marks: 0,
  });
  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();

  useEffect(() => {
    if (section) {
      setValue(section);
    }
  }, [section]);

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(getFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);
    await onSave?.(value as ISection);
    setLoading(false);
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    let allFields: FormField<any>[][] = [
      [
        {
          type: FormFieldType.TEXT,
          value: value.name,
          onChange: (v) => setValue({ ...value, name: v }),
          extras: {
            placeholder: "Section Name",
            label: "Section Name",
          },
          required: true,
          updatable: true,
        },
      ],
      [
        {
          type: FormFieldType.NUMBER,
          value: value.correct_marks,
          onChange: (v) => setValue({ ...value, correct_marks: v }),
          extras: {
            placeholder: "Correct Answer Marks",
            label: "Correct Answer Marks",
          },
          required: true,
          updatable: true,
        },
        {
          type: FormFieldType.NUMBER,
          value: value.negative_marks,
          onChange: (v) => setValue({ ...value, negative_marks: v }),
          extras: {
            placeholder: "Incorrect Answer Marks",
            label: "Incorrect Answer Marks",
          },
          required: true,
          updatable: true,
        },
      ],
    ];

    if (section) {
      return allFields
        .map((fields) => fields.filter((f) => f.updatable))
        .filter((f) => f.length > 0);
    }
    return allFields;
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      <div className="d-flex justify-content-end mt-4">
        <LoadingButton
          color="accent"
          loading={loading}
          disabled={loading}
          label="Save"
          contentClass="font-light"
          rounded
          className="px-4"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
