import {
  HomeworkStatus,
  InvitationStatus,
  PaymentStatus,
  QuestionType,
} from "crm_core";
import { Badge } from "react-bootstrap";

interface InvitationStatusBadgeProps {
  status: InvitationStatus;
}

export default function InvitationStatusBadge({
  status,
}: InvitationStatusBadgeProps) {
  const statusToBadge = (status: InvitationStatus) => {
    switch (status) {
      case InvitationStatus.ACCEPTED:
        return <Badge bg="accent">Joined</Badge>;
      case InvitationStatus.CREATED:
        return <Badge bg="warning">Pending</Badge>;
      case InvitationStatus.REJECTED:
        return <Badge bg="danger">Rejected</Badge>;
      case InvitationStatus.EXPIRED:
        return <Badge bg="warning">Invite Expired</Badge>;
      case undefined:
        return <Badge bg="danger">Not invited</Badge>;
    }
  };

  return statusToBadge(status);
}

export function HomeworkStatusBadge({ status }: { status: HomeworkStatus }) {
  const statusToBadge = (status: HomeworkStatus) => {
    switch (status) {
      case HomeworkStatus.SUBMITTED:
        return <Badge bg="accent">Completed</Badge>;
      case HomeworkStatus.PENDING:
        return <Badge bg="warning">Pending</Badge>;
      case HomeworkStatus.LATE:
        return <Badge bg="danger">Late</Badge>;
    }
  };

  return statusToBadge(status);
}

export function QuestionTypeBadge({ type }: { type: QuestionType }) {
  const typeToBadge = (type: QuestionType) => {
    switch (type) {
      case QuestionType.MultipleAnswer:
        return <Badge bg="accent">Multiple Answer</Badge>;
      case QuestionType.SingleAnswer:
        return <Badge bg="accent">Single Answer</Badge>;
      case QuestionType.FileUpload:
        return <Badge bg="accent">File Upload</Badge>;
      case QuestionType.Subjective:
        return <Badge bg="accent">Subjective</Badge>;
    }
  };
  return typeToBadge(type);
}

export function PaymentStatusBadge({ status }: { status: PaymentStatus }) {
  const statusToBadge = (status: PaymentStatus) => {
    switch (status) {
      case PaymentStatus.PAID:
        return <Badge bg="accent">Paid</Badge>;
      case PaymentStatus.UNPAID:
        return <Badge bg="warning">Unpaid</Badge>;
      case PaymentStatus.OVERDUE:
        return <Badge bg="danger">Overdue</Badge>;
    }
  };
  return statusToBadge(status);
}

export function ActiveStatusBadge({ active }: { active: boolean }) {
  const activeToBadge = (active: boolean) => {
    switch (active) {
      case true:
        return <Badge bg="accent">Active</Badge>;
      case false:
        return <Badge bg="danger">Exited</Badge>;
    }
  };
  return activeToBadge(active);
}
