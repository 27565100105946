import { Permissions, UserRole } from "crm_core";
import { useAuth } from "src/context/auth_context";

interface Props {
  permissions: Permissions[];
  children: any;
}

export default function PermissionRestrictedComponent({
  permissions,
  children,
}: Props) {
  const { selectedAuthorization } = useAuth();

  return (
    <div>
      {selectedAuthorization.role == UserRole.OrganizationAdmin ||
      selectedAuthorization.permissions?.some((p) => permissions.includes(p))
        ? children
        : null}
    </div>
  );
}
