import {
  DEFAULT_DATE_FORMAT,
  IDateAttendanceData,
  IStaffAttendance,
} from "crm_core";
import moment, { Moment } from "moment";
import { Dispatch, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import HorizontalDatePicker from "src/components/date_picker/horizontal";
import { Loader } from "src/components/loader";
import SaveButton from "src/components/widgets/save_button";
import { PeopleAttendanceService } from "src/services/institute/attendance/people";
import PersonAttendanceCard from "./person_attendance_card";
import { catchRequest, joinClasses } from "src/utils/functions";
import SearchBox from "src/components/text_fields/search_box";
import LabelledField from "src/components/form_generator/labelled_field";
import { useNotification } from "src/context/notification_context";

interface AttendanceMarkerProps {
  setExportData?: (v: IStaffAttendance[]) => void;
}

export default function AttendanceMarker({
  setExportData,
}: AttendanceMarkerProps) {
  const service = new PeopleAttendanceService();
  const [date, setDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [attendance, setAttendance] = useState<IStaffAttendance[]>([]);
  const [copy, setCopy] = useState<IStaffAttendance[]>([]);
  const [isChanged, setIsChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  const [search, setSearch] = useState("");
  const { pushNotification } = useNotification();
  const [dateRangeAttendance, setDateRangeAttendance] = useState<
    IDateAttendanceData[]
  >([]);

  const fetchData = async () => {
    setLoading(true);
    await catchRequest(async () => {
      const res = await service.getStaffAttendance(
        date.format(DEFAULT_DATE_FORMAT)
      );
      setAttendance(res);
      setCopy(res);
      setExportData?.(res);
    }, pushNotification)();
    setLoading(false);
  };

  const fetchDateRangeAttendance = async (start: Moment, end: Moment) => {
    setLoading(true);
    await catchRequest(async () => {
      const res = await service.getStaffAttendanceSummary(
        start.format(DEFAULT_DATE_FORMAT),
        end.format(DEFAULT_DATE_FORMAT)
      );
      setDateRangeAttendance(res);
    }, pushNotification)();
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [date]);

  useEffect(() => {
    setIsChanged(
      !copy.every(
        (a, idx) => JSON.stringify(a) === JSON.stringify(attendance[idx])
      )
    );
  }, [copy, attendance]);

  const handleAttendanceChange = async (idx: number, pa: IStaffAttendance) => {
    const newAttendance = [...attendance];
    newAttendance[idx] = pa;
    setAttendance(newAttendance);
  };

  const handleOnSave = async () => {
    setSaving(true);
    await service.updateStaffAttendance(
      date.format(DEFAULT_DATE_FORMAT),
      attendance
    );
    setCopy(attendance);
    await fetchDateRangeAttendance(
      date.clone().startOf("week"),
      date.clone().endOf("week")
    );
    setSaving(false);
  };

  return (
    <div>
      <HorizontalDatePicker
        onRangeChange={(start, end) => fetchDateRangeAttendance(start, end)}
        selected={date}
        onChange={setDate}
        renderDay={(day) => {
          const dayAttendance = dateRangeAttendance.find(
            (d) => d.date === day.format(DEFAULT_DATE_FORMAT)
          );
          return (
            <div
              className={joinClasses(
                "text-sm py-2 rounded-md",
                dayAttendance?.holiday || day.isAfter(moment())
                  ? "bg-custom-grey"
                  : dayAttendance?.marked
                  ? "bg-green-100"
                  : "bg-red-100"
              )}
            >
              <div className="">{day.format("ddd")}</div>
              <div className="">{day.format("D")}</div>
            </div>
          );
        }}
      />
      {loading && <Loader />}
      <div hidden={loading} className="mx-2">
        <Row className="m-0 mt-3">
          <Col className="col-auto">
            <LabelledField label="Search">
              <SearchBox
                value={search}
                onChange={setSearch}
                placeholder="Name"
              />
            </LabelledField>
          </Col>
        </Row>
        <Row className="m-0">
          {attendance
            .filter((a) =>
              `${a.person.first_name} ${a.person.last_name}`
                .toLowerCase()
                .includes(search.toLowerCase())
            )
            .map((a, idx) => (
              <Col
                sm={6}
                md={4}
                lg={3}
                className="mt-3"
                key={`attendance-${idx}`}
              >
                <PersonAttendanceCard
                  onChange={(pa) => handleAttendanceChange(idx, pa)}
                  attendance={a}
                />
              </Col>
            ))}
        </Row>
        <Row className="mt-3">
          <Col className="ml-auto col-auto">
            <SaveButton onClick={handleOnSave} loading={saving} />
          </Col>
        </Row>
      </div>
    </div>
  );
}
