export interface ICustomRole {
  _id?: string;
  name: string;
  permissions: Permissions[];
  organization?: string;
  is_default?: boolean;
}

export enum Permissions {
  // Student Management
  CREATE_STUDENT = "CREATE_STUDENT",
  VIEW_STUDENTS = "VIEW_STUDENTS",
  UPDATE_STUDENT = "UPDATE_STUDENT",
  ARCHIVE_STUDENT = "ARCHIVE_STUDENT",
  VIEW_ASSIGNED_STUDENTS = "VIEW_ASSIGNED_STUDENTS",

  // Staff Management
  VIEW_STAFF = "VIEW_STAFF",
  CREATE_STAFF = "CREATE_STAFF",
  ARCHIVE_STAFF = "ARCHIVE_STAFF",
  UPDATE_STAFF_DETAILS = "UPDATE_STAFF_DETAILS",
  ASSIGN_ROLE = "ASSIGN_ROLE",

  // Batch Management
  CREATE_BATCH = "CREATE_BATCH",
  VIEW_BATCHES = "VIEW_BATCHES",
  UPDATE_BATCH = "UPDATE_BATCH",
  ARCHIVE_BATCH = "ARCHIVE_BATCH",

  // Department Management
  CREATE_DEPARTMENT = "CREATE_DEPARTMENT",
  VIEW_DEPARTMENTS = "VIEW_DEPARTMENTS",
  UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT",
  ARCHIVE_DEPARTMENT = "ARCHIVE_DEPARTMENT",

  // Classrooms Management
  CREATE_CLASSROOM = "CREATE_CLASSROOM",
  VIEW_CLASSROOMS = "VIEW_CLASSROOMS",
  UPDATE_CLASSROOM = "UPDATE_CLASSROOM",
  ARCHIVE_CLASSROOM = "ARCHIVE_CLASSROOM",
  ASSIGN_STUDENTS = "ASSIGN_STUDENTS",
  ASSIGN_TEACHERS = "ASSIGN_TEACHERS",
  VIEW_ASSIGNED_CLASSROOMS = "VIEW_ASSIGNED_CLASSROOMS",
  UPDATE_CLASSROOM_TIMETABLE = "UPDATE_CLASSROOM_TIMETABLE",

  // Session Management
  UPDATE_SESSION = "UPDATE_SESSION",
  ARCHIVE_SESSION = "ARCHIVE_SESSION",

  // Staff Attendance Management
  CREATE_SHIFTS = "CREATE_SHIFTS",
  // VIEW_OWN_ATTENDANCE = "VIEW_OWN_ATTENDANCE",
  VIEW_STAFF_ATTENDANCE = "VIEW_STAFF_ATTENDANCE",
  MARK_STAFF_ATTENDANCE = "MARK_STAFF_ATTENDANCE",

  // Student Attendance Management
  MARK_STUDENT_ATTENDANCE = "MARK_STUDENT_ATTENDANCE",
  VIEW_STUDENT_ATTENDANCE = "VIEW_STUDENT_ATTENDANCE",

  // Tests
  CREATE_TESTS = "CREATE_TESTS",
  VIEW_TESTS = "VIEW_TESTS",

  // IAM
  CREATE_ROLE = "CREATE_ROLE",
  UPDATE_ROLE = "UPDATE_ROLE",
  ARCHIVE_ROLE = "ARCHIVE_ROLE",

  // Calendar
  VIEW_INSTITUTE_CALENDAR = "VIEW_INSTITUTE_CALENDAR",
  UPDATE_INSTITUTE_CALENDAR = "UPDATE_INSTITUTE_CALENDAR",

  // Fees
  CREATE_FEE_STRUCTURE = "CREATE_FEE_STRUCTURE",
  VIEW_FEE_STRUCTURES = "VIEW_FEE_STRUCTURES",
  UPDATE_FEE_STRUCTURE = "UPDATE_FEE_STRUCTURE",
  ARCHIVE_FEE_STRUCTURE = "ARCHIVE_FEE_STRUCTURE",

  COLLECT_FEES = "COLLECT_FEES",
  VIEW_FEES = "VIEW_FEES",
  // DISCOUNT_FEES = "DISCOUNT_FEES",
  // REFUND_FEES = "REFUND_FEES",

  // Folders
  CREATE_FOLDER = "CREATE_FOLDER",
  VIEW_FOLDERS = "VIEW_FOLDERS",
  UPDATE_FOLDER = "UPDATE_FOLDER",
  ARCHIVE_FOLDER = "ARCHIVE_FOLDER",
  ADD_TO_FOLDER = "ADD_TO_FOLDER",
  REMOVE_FROM_FOLDER = "REMOVE_FROM_FOLDER",

  // Chat Rooms
  CREATE_CHAT_GROUP = "CREATE_CHAT_GROUP",
  VIEW_CHAT_GROUPS = "VIEW_CHAT_GROUPS",
  UPDATE_CHAT_GROUP = "UPDATE_CHAT_GROUP",
  ARCHIVE_CHAT_GROUP = "ARCHIVE_CHAT_GROUP",
}

export function permissionToTitle(permission: Permissions) {
  // convert AAA_BBB to Aaa Bbb
  const words = permission.split("_");
  return words.map((word) => word[0] + word.slice(1).toLowerCase()).join(" ");
}

export const permissionGroups = [
  {
    name: "Student Management",
    permissions: [
      Permissions.CREATE_STUDENT,
      Permissions.VIEW_STUDENTS,
      Permissions.UPDATE_STUDENT,
      Permissions.ARCHIVE_STUDENT,
      Permissions.VIEW_ASSIGNED_STUDENTS,
    ],
  },
  {
    name: "Staff Management",
    permissions: [
      Permissions.VIEW_STAFF,
      Permissions.UPDATE_STAFF_DETAILS,
      Permissions.CREATE_STAFF,
      Permissions.ARCHIVE_STAFF,
      Permissions.ASSIGN_ROLE,
    ],
  },
  {
    name: "Batch Management",
    permissions: [
      Permissions.CREATE_BATCH,
      Permissions.VIEW_BATCHES,
      Permissions.UPDATE_BATCH,
      Permissions.ARCHIVE_BATCH,
    ],
  },
  {
    name: "Department Management",
    permissions: [
      Permissions.CREATE_DEPARTMENT,
      Permissions.VIEW_DEPARTMENTS,
      Permissions.UPDATE_DEPARTMENT,
      Permissions.ARCHIVE_DEPARTMENT,
    ],
  },
  {
    name: "Classrooms Management",
    permissions: [
      Permissions.CREATE_CLASSROOM,
      Permissions.VIEW_CLASSROOMS,
      Permissions.UPDATE_CLASSROOM,
      Permissions.ARCHIVE_CLASSROOM,
      Permissions.UPDATE_CLASSROOM_TIMETABLE,
      Permissions.ASSIGN_STUDENTS,
      Permissions.ASSIGN_TEACHERS,
      Permissions.VIEW_ASSIGNED_CLASSROOMS,
    ],
  },
  {
    name: "Attendance Management",
    permissions: [
      Permissions.CREATE_SHIFTS,
      Permissions.MARK_STAFF_ATTENDANCE,
      Permissions.MARK_STUDENT_ATTENDANCE,
      Permissions.VIEW_STAFF_ATTENDANCE,
      Permissions.VIEW_STUDENT_ATTENDANCE,
    ],
  },
  {
    name: "Assessment",
    permissions: [Permissions.CREATE_TESTS, Permissions.VIEW_TESTS],
  },
  {
    name: "Identity and Access Management",
    permissions: [
      Permissions.CREATE_ROLE,
      Permissions.UPDATE_ROLE,
      Permissions.ARCHIVE_ROLE,
    ],
  },
  {
    name: "Session Management",
    permissions: [
      Permissions.UPDATE_SESSION,
      Permissions.ARCHIVE_SESSION,
      Permissions.VIEW_INSTITUTE_CALENDAR,
      Permissions.UPDATE_INSTITUTE_CALENDAR,
    ],
  },
  {
    name: "Fees Management",
    permissions: [
      Permissions.CREATE_FEE_STRUCTURE,
      Permissions.VIEW_FEE_STRUCTURES,
      Permissions.UPDATE_FEE_STRUCTURE,
      Permissions.ARCHIVE_FEE_STRUCTURE,
      Permissions.COLLECT_FEES,
      Permissions.VIEW_FEES,
      // Permissions.DISCOUNT_FEES,
      // Permissions.REFUND_FEES,
    ],
  },
  {
    name: "Folders Management",
    permissions: [
      Permissions.CREATE_FOLDER,
      Permissions.VIEW_FOLDERS,
      Permissions.UPDATE_FOLDER,
      Permissions.ARCHIVE_FOLDER,
      Permissions.ADD_TO_FOLDER,
      Permissions.REMOVE_FROM_FOLDER,
    ],
  },
  {
    name: "Chat Rooms Management",
    permissions: [
      Permissions.CREATE_CHAT_GROUP,
      Permissions.VIEW_CHAT_GROUPS,
      Permissions.UPDATE_CHAT_GROUP,
      Permissions.ARCHIVE_CHAT_GROUP,
    ],
  },
];

export enum DefaultRoles {
  ADMIN = "Admin",
  TEACHER = "Teacher",
}

export const defaultRoles: ICustomRole[] = [
  {
    name: DefaultRoles.ADMIN,
    permissions: [
      Permissions.CREATE_STUDENT,
      Permissions.VIEW_STUDENTS,
      Permissions.UPDATE_STUDENT,
      Permissions.ARCHIVE_STUDENT,
      Permissions.VIEW_STAFF,
      Permissions.UPDATE_STAFF_DETAILS,
      Permissions.CREATE_BATCH,
      Permissions.VIEW_BATCHES,
      Permissions.UPDATE_BATCH,
      Permissions.ARCHIVE_BATCH,
      Permissions.CREATE_DEPARTMENT,
      Permissions.VIEW_DEPARTMENTS,
      Permissions.UPDATE_DEPARTMENT,
      Permissions.ARCHIVE_DEPARTMENT,
      Permissions.CREATE_CLASSROOM,
      Permissions.VIEW_CLASSROOMS,
      Permissions.UPDATE_CLASSROOM,
      Permissions.ARCHIVE_CLASSROOM,
      Permissions.UPDATE_CLASSROOM_TIMETABLE,
      Permissions.CREATE_SHIFTS,
      Permissions.MARK_STAFF_ATTENDANCE,
      Permissions.UPDATE_SESSION,
      Permissions.ARCHIVE_SESSION,
      Permissions.CREATE_CHAT_GROUP,
      Permissions.VIEW_CHAT_GROUPS,
      Permissions.UPDATE_CHAT_GROUP,
      Permissions.ARCHIVE_CHAT_GROUP,
    ],
  },
  {
    name: DefaultRoles.TEACHER,
    permissions: [
      Permissions.VIEW_ASSIGNED_STUDENTS,
      Permissions.VIEW_ASSIGNED_CLASSROOMS,
      Permissions.UPDATE_CLASSROOM_TIMETABLE,
      Permissions.MARK_STUDENT_ATTENDANCE,
      Permissions.CREATE_TESTS,
      Permissions.VIEW_TESTS,
      Permissions.VIEW_STUDENT_ATTENDANCE,
      Permissions.CREATE_FOLDER,
      Permissions.VIEW_FOLDERS,
      Permissions.UPDATE_FOLDER,
      Permissions.ARCHIVE_FOLDER,
      Permissions.ADD_TO_FOLDER,
      Permissions.REMOVE_FROM_FOLDER,
      Permissions.CREATE_CHAT_GROUP,
      Permissions.VIEW_CHAT_GROUPS,
      Permissions.UPDATE_CHAT_GROUP,
      Permissions.ARCHIVE_CHAT_GROUP,
    ],
  },
];

export const defaultRolesToInsert: ICustomRole[] = [
  {
    name: "Admission Section",
    permissions: [
      Permissions.CREATE_STUDENT,
      Permissions.VIEW_STUDENTS,
      Permissions.UPDATE_STUDENT,
      Permissions.ARCHIVE_STUDENT,
    ],
  },
  {
    name: "Student Section",
    permissions: [
      Permissions.VIEW_STUDENTS,
      Permissions.UPDATE_STUDENT,
      Permissions.ARCHIVE_STUDENT,
    ],
  },
  {
    name: "Human Resources",
    permissions: [
      Permissions.VIEW_STAFF,
      Permissions.UPDATE_STAFF_DETAILS,
      Permissions.CREATE_SHIFTS,
      Permissions.CREATE_STAFF,
      Permissions.ARCHIVE_STAFF,
    ],
  },
  {
    name: "Academic Section",
    permissions: [
      Permissions.CREATE_BATCH,
      Permissions.VIEW_BATCHES,
      Permissions.UPDATE_BATCH,
      Permissions.ARCHIVE_BATCH,
      Permissions.CREATE_DEPARTMENT,
      Permissions.VIEW_DEPARTMENTS,
      Permissions.UPDATE_DEPARTMENT,
      Permissions.ARCHIVE_DEPARTMENT,
      Permissions.CREATE_CLASSROOM,
      Permissions.VIEW_CLASSROOMS,
      Permissions.UPDATE_CLASSROOM,
      Permissions.ARCHIVE_CLASSROOM,
      Permissions.UPDATE_SESSION,
      Permissions.ARCHIVE_SESSION,
      Permissions.VIEW_INSTITUTE_CALENDAR,
      Permissions.UPDATE_INSTITUTE_CALENDAR,
    ],
  },
  {
    name: "Finance Section",
    permissions: [
      Permissions.CREATE_FEE_STRUCTURE,
      Permissions.VIEW_FEE_STRUCTURES,
      Permissions.UPDATE_FEE_STRUCTURE,
      Permissions.ARCHIVE_FEE_STRUCTURE,
    ],
  },
  {
    name: "Reception",
    permissions: [Permissions.MARK_STAFF_ATTENDANCE],
  },
  ...defaultRoles.map((role) => ({ ...role, is_default: true })),
];
