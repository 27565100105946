import { useEffect, useState } from "react";
import { useNotification } from "src/context/notification_context";
import { catchRequest } from "src/utils/functions";
import { DataUseCase, useDataProvider } from "src/context/data_provider";
import { IQuestion } from "crm_core";
import { Col, Row } from "react-bootstrap";
import ArchiveButton from "src/components/widgets/archive_button";
import SaveButton from "src/components/widgets/save_button";
import { QuestionService } from "src/services/institute/lms/question";
import Question from "src/features/tests/components/question";

export interface AddUpdateQuestionProps {
  onComplete?: () => void;
  id?: string;
  data?: IQuestion;
}

export default function AddUpdateQuestion({
  onComplete,
  id,
  data,
}: AddUpdateQuestionProps) {
  const service = new QuestionService();
  const [question, setQuestion] = useState<IQuestion>();
  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();
  const { fetchDataForUseCase, selectedSession } = useDataProvider();

  useEffect(() => {
    if (selectedSession) fetchDataForUseCase(DataUseCase.INSTITUTE);
  }, [selectedSession]);

  useEffect(() => {
    const fetchQuestion = async (id: string) => {
      setLoading(true);
      const currentQuestion = data || (await service.getQuestion(id));
      setQuestion(currentQuestion);
      setLoading(false);
    };
    if (id) {
      fetchQuestion(id);
    }
  }, [id]);

  const handleSubmit = async () => {
    setLoading(true);
    let update: any = question;

    await catchRequest(async () => {
      if (id) {
        await service.updateQuestion({
          _id: id,
          ...update,
        });
        pushNotification({
          title: "Success",
          message: "Question updated successfully",
          type: "success",
        });
      }
    }, pushNotification)();
    setLoading(false);
    onComplete?.();
  };

  const handleDeleteQuestion = async () => {
    await catchRequest(async () => {
      await service.deleteQuestion(id as string);
      pushNotification({
        title: "Success",
        message: "Question archived successfully",
        type: "success",
      });
      onComplete?.();
    }, pushNotification)();
  };

  return (
    <div>
      {question && (
        <Question
          hideSaveCancel
          editable
          question={question as IQuestion}
          onChange={(q) => setQuestion(q)}
        />
      )}
      <Row className="justify-content-end mt-4 align-items-center">
        <Col hidden={!id} md="auto" sm="auto" xs="auto" className="p-0">
          <ArchiveButton
            dialogTitle="Archive Question?"
            dialogContent="Are you sure you want to archive this question?"
            label="Archive Question"
            onClick={() => handleDeleteQuestion()}
          />
        </Col>
        <Col md="auto" sm="auto" xs="auto" className="">
          <SaveButton
            loading={loading}
            disabled={loading}
            onClick={handleSubmit}
          />
        </Col>
      </Row>
      {question && (
        <div className="mt-4">
          <h5>Preview</h5>
          <Question
            hideSaveCancel
            submitter
            onAnswerChange={async (v) => {}}
            question={question as IQuestion}
          />
        </div>
      )}
    </div>
  );
}
