import API from "./api";
import { IClient } from "crm_core"

export class ClientService extends API {
  static NAME = "ClientService";
  
  constructor() {
    super("/client");
  }
  async getClients(): Promise<IClient[]> {
    const res = await this._get({});
    return res.data as IClient[];
  }

  async getClient(id: string): Promise<IClient> {
    const res = await this._get({ url: `/${id}` });
    return res.data as IClient;
  }

  async createClient(project: IClient): Promise<IClient> {
    const res = await this._post({ body: project });
    return res.data as IClient;
  }

  async updateClient(project: IClient): Promise<IClient> {
    const res = await this._put({ url: `/${project._id}`, body: project });
    return res.data as IClient;
  }

  async deleteClient(id: string): Promise<Boolean> {
    const res = await this._delete({ url: `/${id}` });
    return true;
  }
}
