import { Modal } from "react-bootstrap";
import { ModalTypes, useModal } from "src/context/modal_context";
import { useTheme } from "src/context/theme_context";
import AddProject from "src/features/projects/add";
import AddClient from "src/features/clients/add";
import AddUpdateDepartment from "src/features/institute_structure/department/add_update";
import AddUpdateBatch from "src/features/institute_structure/batch/add_update";
import AddUpdateClassroom from "src/features/institute_structure/classroom/add_update";
import AddUpdateSession from "src/features/session/add_update";
import AddUpdateStudent from "src/features/people/students/add_update";
import AddUpdateStaffMember from "src/features/people/staff_members/add_update";
import AddUpdateSection from "src/features/tests/editor/add_update_section";
import AssociateStudents from "src/features/institute_structure/classroom/associate_students";
import { useEffect } from "react";
import TimeSlotsEditor from "../widgets/time_slots_editor";
import AddUpdateAttendanceShift from "src/features/staff_attendance/shifts/add_update";
import AssociateStaffMembers from "src/features/staff_attendance/shifts/associate_staff";
import AddUpdateCustomRole from "src/features/roles/add_update";
import EditCalendarDay from "src/features/institute_structure/calendar/edit_day";
import AssignStudent from "src/features/people/students/assign";
import AddUpdateFolder from "src/features/content_library/add_update";
import AddFile from "src/features/content_library/add_file";
import AddUpdateFeeStructure from "src/features/fees/structure/add_update";
import AddUpdateFeePayment from "src/features/fees/record/add_update_payment";
import BulkImport from "src/features/bulk_import";
import AddUpdateChatRoom from "src/features/chatrooms/add_update";
import AddUpdateHomework from "src/features/homework/add_update";
import AddAttachment from "src/features/tests/components/add_attachment";
import AddUpdateCourse from "src/features/superadmin_features/courses/add_update";
import AddUpdateCourseSubject from "src/features/superadmin_features/subjects/add_update";
import AddUpdateSubjectTopic from "src/features/superadmin_features/topics/add_update";
import AddUpdateQuestion from "src/features/superadmin_features/questions/add_update";
import { AttachmentViewer } from "src/features/tests/components/attachment_viewer";
import AddNewQuestions from "src/features/tests/editor/add_questions";
import ExitStudents from "src/features/people/students/exit_students";

export default function GenericModal() {
  const { getThemeBasedValue } = useTheme();
  const {
    showModal,
    setShowModal,
    modalHeading,
    modalSize,
    modalType,
    componentProps,
    modalBodyProps,
    setModalBodyProps,
  } = useModal();

  const typeToComponent = {
    [ModalTypes.PROJECT]: AddProject,
    [ModalTypes.CLIENT]: AddClient,
    [ModalTypes.DEPARTMENT]: AddUpdateDepartment,
    [ModalTypes.BATCH]: AddUpdateBatch,
    [ModalTypes.CLASSROOM]: AddUpdateClassroom,
    [ModalTypes.SESSION]: AddUpdateSession,
    [ModalTypes.STUDENT]: AddUpdateStudent,
    [ModalTypes.STAFF_MEMBER]: AddUpdateStaffMember,
    [ModalTypes.SECTION]: AddUpdateSection,
    [ModalTypes.ASSOCIATE_STUDENTS]: AssociateStudents,
    [ModalTypes.SLOTS_EDITOR]: TimeSlotsEditor,
    [ModalTypes.ATTENDANCE_SHIFT]: AddUpdateAttendanceShift,
    [ModalTypes.ASSOCIATE_STAFF_MEMBERS]: AssociateStaffMembers,
    [ModalTypes.CUSTOM_ROLE]: AddUpdateCustomRole,
    [ModalTypes.UPDATE_CALENDAR_DAY]: EditCalendarDay,
    [ModalTypes.ASSIGN_STUDENT]: AssignStudent,
    [ModalTypes.ADD_UPDATE_FOLDER]: AddUpdateFolder,
    [ModalTypes.ADD_FILE]: AddFile,
    [ModalTypes.ADD_FEE_STRUCTURE]: AddUpdateFeeStructure,
    [ModalTypes.ADD_UPDATE_FEE_PAYMENT]: AddUpdateFeePayment,
    [ModalTypes.BULK_IMPORT]: BulkImport,
    [ModalTypes.ADD_UPDATE_CHATROOM]: AddUpdateChatRoom,
    [ModalTypes.ADD_UPDATE_HOMEWORK]: AddUpdateHomework,
    [ModalTypes.ADD_QUESTION_ATTACHMENT]: AddAttachment,
    [ModalTypes.ADD_UDPATE_COURSE]: AddUpdateCourse,
    [ModalTypes.ADD_UPDATE_COURSE_SUBJECT]: AddUpdateCourseSubject,
    [ModalTypes.ADD_UPDATE_SUBJECT_TOPIC]: AddUpdateSubjectTopic,
    [ModalTypes.ADD_UPDATE_QUESTION]: AddUpdateQuestion,
    [ModalTypes.VIEW_ATTACHMENT]: AttachmentViewer,
    [ModalTypes.ADD_NEW_QUESTIONS]: AddNewQuestions,
    [ModalTypes.EXIT_STUDENTS]: ExitStudents,
  };

  const BodyComp = typeToComponent[modalType];

  useEffect(() => {
    if (!showModal) {
      setModalBodyProps({});
    }
  }, [showModal]);

  return (
    <Modal
      centered
      size={modalSize}
      show={showModal}
      onHide={() => setShowModal(false)}
      contentClassName={getThemeBasedValue("bg-light", "bg-dark")}
      animation={false}
      backdrop="static"
    >
      <Modal.Header closeButton closeLabel="">
        <Modal.Title>
          <h6>{modalHeading}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body {...modalBodyProps}>
        <BodyComp {...componentProps} />
      </Modal.Body>
      {/* <Modal.Footer>Footer</Modal.Footer> */}
    </Modal>
  );
}
