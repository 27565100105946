import { useState } from "react";
import LoadingButton from "src/components/buttons/loading_button";
import FormGenerator, { FormField } from "src/components/form_generator";
import { useNotification } from "src/context/notification_context";
import { ProjectService } from "src/services/project";
import { FormFieldType, ProjectStatuses } from "src/utils/constants";
import { validateFields } from "src/utils/functions";

interface AddProjectProps {
  onAdd?: () => void;
  id?: string;
}

export default function AddProject({ onAdd, id }: AddProjectProps) {
  const service = new ProjectService();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [startDate, setStartDate] = useState<number>(Date.now());
  const [endDate, setEndDate] = useState<number>();
  const [status, setStatus] = useState(ProjectStatuses[0]);
  const [members, setMembers] = useState([]);
  const [client, setClient] = useState(undefined as any);
  const [budget, setBudget] = useState();
  const [loading, setLoading] = useState(false);
  const { pushNotification } = useNotification();

  const handleSubmit = async () => {
    let { valid, errors } = validateFields(getFields());
    if (!valid) {
      pushNotification({
        title: "Error",
        message: `Please fill in the following fields: ${errors.join(", ")}`,
        type: "warning",
      });
      return;
    }
    setLoading(true);
    await service.createProject({
      title: title as any,
      description: description as any,
      start_date: startDate,
      end_date: endDate,
      status,
      members,
      client,
    });
    setLoading(false);
    onAdd?.();
  };

  const getFields: (i?: any) => FormField<any>[][] = () => {
    return [
      [
        {
          type: FormFieldType.TEXT,
          value: title,
          onChange: setTitle,
          extras: {
            placeholder: "Title",
            label: "Title",
          },
          required: true,
        },
      ],
      [
        {
          type: FormFieldType.MARKDOWN,
          value: description,
          onChange: setDescription,
          extras: {
            placeholder: "Description",
            label: "Description",
          },
        },
      ],
      [
        {
          type: FormFieldType.NUMBER,
          value: budget,
          onChange: setBudget,
          extras: {
            placeholder: "Budge",
            label: "Budget",
          },
          required: true,
        },
        {
          type: FormFieldType.SELECT,
          value: status,
          onChange: setStatus,
          extras: {
            label: "Status",
            options: ProjectStatuses.map((s) => ({ label: s, value: s })),
          },
          required: true,
        },
      ],
      [
        // {
        //   type: FormFieldType.DATERANGE,
        //   value: { description },
        //   extras: {
        //     placeholder: "Description",
        //     label: "Description",
        //     startDate: startDate,
        //     endDate: endDate,
        //     onChange: (startDate: number, endDate: number) => {
        //       setStartDate(startDate);
        //       setEndDate(endDate);
        //     },
        //   },
        //   required: true,
        // },
      ],
    ];
  };

  return (
    <div>
      <FormGenerator getFields={getFields} />
      <div className="d-flex justify-content-end mt-4">
        <LoadingButton
          color="accent"
          loading={loading}
          disabled={loading}
          label="Save"
          contentClass="font-light"
          rounded
          className="px-4"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
