import API from "./api";
import { IOrganization } from "crm_core"

export class OrganizationService extends API {
  constructor() {
    super("/organization");
  }

  async getOrganizations(): Promise<IOrganization[]> {
    const res = await this._get({});
    return res.data as IOrganization[];
  }

  async updateOrganization(
    organization: Partial<IOrganization>
  ): Promise<IOrganization> {
    const res = await this._put({ body: organization });
    return res.data as IOrganization;
  }

  async uploadPhoto(file: File): Promise<IOrganization> {
    const formData = new FormData();
    formData.append("photo", file, file.name);
    const res = await this._postFile({ url: "/photo", body: formData });
    return res.data as IOrganization;
  }
}