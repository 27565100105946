import "react-datepicker/dist/react-datepicker.css";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import SimpleDatePicker from "./date_picker";

interface DateRangePickerProps {
  startDate: string;
  endDate?: string;
  onChange: (startDate: string, endDate?: string) => void;
  required?: boolean;
  startLabel?: string;
  endLabel?: string;
}

export default function DateRangePicker(props: DateRangePickerProps) {
  return (
    <Row className="m-0 p-0 align-items-center">
      <Col className="px-0">
        <SimpleDatePicker
          value={props.startDate}
          onChange={(date: any) => props.onChange(date, props.endDate)}
          label={props.startLabel ?? "Start Date"}
          required={props.required}
        />
      </Col>
      <Col className="col-auto">to</Col>
      <Col className="px-0">
        <SimpleDatePicker
          value={props.endDate}
          onChange={(date: any) => props.onChange(props.startDate, date)}
          label={props.endLabel ?? "End Date"}
          required={props.required}
        />
      </Col>
    </Row>
  );
}
